import { Typography, css, styled } from '@mui/material';

const EmptyTable = styled(Typography)(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.palette.text.secondary};
  `,
);

export default EmptyTable;
