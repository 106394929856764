import React from 'react';
import { Link } from '@mui/material';
import PrivacyListItem from './PrivacyListItem';
import PrivacyWrapper from './PrivacyWrapper';
import PrivacyTypography from './PrivacyTypography';
import PrivacyHeading from './PrivacyHeading';
import PrivacyReceiverTable from './PrivacyReceiverTable';

type PrivacyCommuterProps = { full?: boolean };

const PrivacyCommuter = ({ full = true }: PrivacyCommuterProps) => (
  <PrivacyWrapper>
    <PrivacyTypography>
      Im Rahmen des kooperativen Forschungsprojekts „ActNow – Allianz Aktive Mobilität“, gefördert aus Mitteln des
      nationalen Förderprogramms Mobilität der Zukunft (FFG Nr. 884334), wurde ein digitales Werkzeug für die
      Optimierung gesundheitsrelevanter Arbeitswege (kurz ActNow-Tool) erstellt. Im Zuge der Evaluierung des ActNow
      Tools sind Sie eingeladen, als Testperson das ActNow-Tool probeweise zu verwenden. Damit Sie diese Anwendung
      nutzen können, werden von Ihnen eingegebene Adressdaten, Ihre Unternehmenszugehörigkeit sowie Ihre E-Mail-Adresse,
      die als personenbezogene Daten gelten, verarbeitet. Zur Überwachung und Steuerung unserer Systeme werden
      Systemdaten in Protokolldateien (Serverlogs) gespeichert. Wir verwenden ausschließlich technisch notwendige
      Cookies sowie Local Storage, z.B. um den Login-Status zu speichern. Darüber hinaus werden keine Cookies
      eingesetzt. Wir verwenden keine automatisierten Entscheidungsfindungen und führen kein Profiling nach Art. 22
      DSGVO durch.
    </PrivacyTypography>
    <PrivacyTypography>
      Der verantwortungsbewusste Umgang mit personenbezogenen Daten hat für uns hohe Priorität. Wir möchten Sie darüber
      informieren, welche Daten erhoben werden und wie wir sie verwenden.
    </PrivacyTypography>

    {full && (
      <>
        <PrivacyHeading>Zwecke, Rechtsgrundlagen und Speicherdauer</PrivacyHeading>
        <PrivacyTypography>
          Für die Nutzung des ActNow-Tools ist die Eingabe Ihres Wohnorts in Form einer Adresseingabe, die Zuordnung zu
          einem Unternehmen, sowie die Hinterlegung einer gültigen E-Mail-Adresse erforderlich. Darüber hinaus werden
          keine personenbezogenen Daten erhoben. Die E-Mail-Adresse und die Zuordnung zu einem Unternehmen werden für
          die Benutzerverwaltung im ActNow-Tool sowie für die Kommunikation im Zuge der Koordination von
          Fahrgemeinschaften oder gemeinsamen Kinderbegleitwegen (optional) verwendet. Wenn Sie bei der Registrierung
          Ihr Interesse an Fahrgemeinschaften oder gemeinsamen Kinderbegleitwegen anmelden, wird Ihre E-Mail-Adresse zur
          Kontaktaufnahme mit weiteren Interessenten verwendet bzw. an diese weitergegeben. Die Koordination erfolgt in
          weiterer Folge eigenständig und außerhalb des ActNow-Tools. Die Adressdaten werden zum Zweck der Berechnung
          von Routenvorschlägen für den Arbeitsweg verarbeitet. Sofern Sie uns diese Daten nicht zur Verfügung stellen,
          können Sie das ActNow-Tool nicht nutzen.
        </PrivacyTypography>
        <PrivacyTypography>
          Die Protokolldateien (Serverlogs) werden ausschließlich für die Gewährleistung der Systemsicherheit verwendet
          und nicht mit sonstigen Eingaben verbunden.
        </PrivacyTypography>
        <PrivacyTypography>
          Wir verarbeiten Ihre personenbezogenen Daten (Adressen) im Zusammenhang mit der testweisen Verwendung des
          ActNow-Tools auf Grundlage gesetzlicher Bestimmungen (Art. 6 Abs. 1 lit. a DSGVO).
        </PrivacyTypography>
        <PrivacyTypography>
          Wir speichern die von Ihnen eingegebenen Daten bis zum Ende der Projektlaufzeit von „ActNow – Allianz Aktive
          Mobilität“ (31.08.2023). Danach werden die Daten unwiderruflich und vollständig gelöscht. Protokolldateien
          (Serverlogs) werden nach 30 Tagen automatisch gelöscht.
        </PrivacyTypography>

        <PrivacyHeading>Datenkategorien</PrivacyHeading>
        <PrivacyTypography>
          Folgende Datenkategorien werden gemäß Ihrer Eingabe bzw. Ihres Systems verarbeitet:
        </PrivacyTypography>

        <PrivacyTypography component="ul">
          <PrivacyListItem>
            Stammdaten:
            <PrivacyTypography component="ul">
              <PrivacyListItem>E-Mail-Adresse</PrivacyListItem>
              <PrivacyListItem>Passwort</PrivacyListItem>
              <PrivacyListItem>Unternehmenszugehörigkeit und Adresse des Unternehmensstandorts</PrivacyListItem>
              <PrivacyListItem>Wohnadresse</PrivacyListItem>
              <PrivacyListItem>
                Adressen von bis zu zwei Kinderbetreuungseinrichtungen (falls angegeben)
              </PrivacyListItem>
              <PrivacyListItem>Angegebener üblicher Mobilitätsmodus</PrivacyListItem>
            </PrivacyTypography>
          </PrivacyListItem>
          <PrivacyListItem>
            Protokolldateien:
            <PrivacyTypography component="ul">
              <PrivacyListItem>Besuchte Website</PrivacyListItem>
              <PrivacyListItem>Uhrzeit zum Zeitpunkt des Zugriffes</PrivacyListItem>
              <PrivacyListItem>Menge der übertragenen Daten in Byte</PrivacyListItem>
              <PrivacyListItem>Verwendeter Browsertyp und Browserversion</PrivacyListItem>
              <PrivacyListItem>Verwendetes Betriebssystem</PrivacyListItem>
              <PrivacyListItem>Verwendete IP-Adresse</PrivacyListItem>
            </PrivacyTypography>
          </PrivacyListItem>
        </PrivacyTypography>

        <PrivacyHeading>Empfänger</PrivacyHeading>
        <PrivacyTypography>
          Wir übermitteln Ihre unmittelbar personenbezogenen Daten (Stammdaten) nicht an Dritte. Ihre Wohnadresse sowie
          die generierten Routenempfehlungen werden in aggregierter Form in der Unternehmensansicht des ActNow-Tools
          visualisiert. Durch die Aggregation wird ein Rückschluss auf Einzelpersonen ausgeschlossen. Im Falle Ihres
          angemeldeten Interesses an Fahrgemeinschaften oder gemeinsamen Kinderbegleitwegen wird Ihre E-Mail-Adresse an
          weitere interessierte NutzerInnen zur eigenständigen, weiteren Koordination übermittelt. Dabei kann es sich
          auch um Personen außerhalb Ihres Unternehmens handeln.
        </PrivacyTypography>
        <PrivacyTypography>
          Systemdaten (Protokolldateien) können auch an von uns beauftragte Dritte für IT- Dienstleistungen (wie zum
          Beispiel IT-Supportdienstleistungen) übermittelt werden. In solchen Fällen stellt der
          Datenschutzverantwortliche vertraglich sicher, dass Ihre Daten von unseren Auftragsverarbeitern den
          datenschutzrechtlichen Vorgaben entsprechend sicher und unter Achtung Ihrer Rechte verarbeitet werden.
        </PrivacyTypography>
        <PrivacyReceiverTable />

        <PrivacyHeading>Datensicherheitsmaßnahmen</PrivacyHeading>
        <PrivacyTypography>
          Die Sicherheit Ihrer Daten ist uns ein großes Anliegen. Zum Schutz Ihrer Daten setzen wir daher auf technische
          und organisatorische Schutzmaßnahmen. Zu diesen zählen unter anderem:
        </PrivacyTypography>
        <PrivacyTypography component="ul">
          <PrivacyListItem>
            Beschränkung der Zugriffe auf jene Personenkreise, welche die Daten zur Zweckerfüllung benötigen;
          </PrivacyListItem>
          <PrivacyListItem>
            Verpflichtung der mit der Datenverarbeitung betrauten Mitarbeiterinnen und Mitarbeiter zur Geheimhaltung;
          </PrivacyListItem>
          <PrivacyListItem>Ausschließlich verschlüsselte Datenübertragung;</PrivacyListItem>
          <PrivacyListItem>
            Trennungskontrolle: Daten, die unterschiedlichen Zwecken dienen, werden nie gemeinsam verarbeitet;
          </PrivacyListItem>
          <PrivacyListItem>
            Hosting der Dienste in ISO27001-zertifizierten Rechenzentren in Deutschland;
          </PrivacyListItem>
          <PrivacyListItem>Vertragliche Vereinbarungen mit Dienstleistern (Auftragsverarbeiter).</PrivacyListItem>
        </PrivacyTypography>

        <PrivacyHeading>Links zu Webseiten Dritter</PrivacyHeading>
        <PrivacyTypography>
          Im ActNow-Tool sind Links zu Webseiten Dritter enthalten. Für deren Inhalte und Einhaltung der
          Datenschutzbestimmungen wird keine Gewährleistung übernommen.
        </PrivacyTypography>

        <PrivacyHeading>Ihre Betroffenenrechte</PrivacyHeading>
        <PrivacyTypography>
          Sie können Ihre Einwilligung jederzeit widerrufen, falls unsere Datenverarbeitung auf einer Einwilligung
          beruht, die Sie uns erteilt haben. Durch den Widerruf wird die Rechtmäßigkeit, der aufgrund der Einwilligung
          bis zum Widerruf erfolgten Verarbeitung nicht berührt. Sie haben grundsätzlich das Recht auf Auskunft durch
          die Verantwortlichen über die Sie betreffenden personenbezogenen Daten, sowie das Recht auf Berichtigung,
          Löschung, Einschränkung und Datenübertragbarkeit. Unter bestimmten Umständen können Sie der Verarbeitung der
          Sie betreffenden personenbezogenen Daten widersprechen. Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
          Daten gegen das Datenschutzrecht verstößt, besteht ein Beschwerderecht bei der Aufsichtsbehörde. In Österreich
          ist dies die Datenschutzbehörde. Die Kontaktinformationen finden Sie{' '}
          <Link href="https://edpb.europa.eu/about-edpb/board/members" target="_blank" rel="noopener">
            hier
          </Link>
          .
        </PrivacyTypography>

        <PrivacyHeading>Verantwortlicher und Anlaufstelle für Betroffene</PrivacyHeading>
        <PrivacyTypography>
          Die Trafficon – Traffic Consultants GmbH ist Datenschutzverantwortlicher im Sinne der DSGVO für die
          personenbezogenen Daten. Ihre Rechte können Sie mündlich am Unternehmenssitz oder telefonisch, per E-Mail oder
          per Post gegenüber dem Verantwortlichen ausüben:
        </PrivacyTypography>
        <PrivacyTypography component="address">
          GF Dr. Stefan Krampe
          <br />
          Trafficon – Traffic Consultants GmbH
          <br />
          Strubergasse 26
          <br />
          5020 Salzburg
          <br />
          <Link href="tel:00436624611020">+43 662 46 11 02-0</Link>
          <br />
          <Link href="mailto:sbg@trafficon.eu">sbg@trafficon.eu</Link>
        </PrivacyTypography>
      </>
    )}
  </PrivacyWrapper>
);

export default PrivacyCommuter;
