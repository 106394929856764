import React from 'react';
import { useQuery } from 'react-query';
import QueryKeys from '../../services/QueryKeys';
import { AggregationsService } from '../../services/api';
import PotentialsCarpoolingPage from '../../common/potentials/carpooling/PotentialsCarpoolingPage';
import useQueryAggregation from '../../hooks/queries/useQueryAggregation';
import HelpPopUp from '../../components/HelpPopUp';

const AggregationPotentialsCarpoolingPageProps = () => {
  const { aggregationId, data: aggregation } = useQueryAggregation();

  const { data: potentials } = useQuery(QueryKeys.aggregations.potentialsCarpooling(aggregationId as string), () =>
    AggregationsService.getPotentialsCarpooling({ id: aggregationId as string }),
  );

  return (
    <PotentialsCarpoolingPage
      potentials={potentials}
      distanceCircles={false}
      printHeading={`Fahrgemeinschaften | Aggregierte Ansicht: ${aggregation?.name}`}
    >
      <HelpPopUp>
        Hier sehen Sie eine Übersicht über die Fahrgemeinschaftspotenziale in der aggregierten Ansicht. Spannend, woher
        die PendlerInnen täglich einreisen oder? Nutzen Sie eine der vorgeschlagenen Maßnahmen, um Fahrgemeinschaften zu
        fördern. Aus Datenschutzgründen werden die Wohnstandorte nur angezeigt, wenn mindestens fünf PendlerInnen in
        einer Gemeinde wohnen.
      </HelpPopUp>
    </PotentialsCarpoolingPage>
  );
};

export default AggregationPotentialsCarpoolingPageProps;
