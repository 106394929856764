import React from 'react';
import { Link } from '@mui/material';
import PrivacyTypography from './PrivacyTypography';
import PrivacyHeading from './PrivacyHeading';
import PrivacyListItem from './PrivacyListItem';
import PrivacyWrapper from './PrivacyWrapper';
import PrivacyReceiverTable from './PrivacyReceiverTable';

type PrivacyOrganizationProps = { full?: boolean };

const PrivacyOrganization = ({ full = true }: PrivacyOrganizationProps) => (
  <PrivacyWrapper>
    <PrivacyTypography>
      Im Rahmen des kooperativen Forschungsprojekts „ActNow – Allianz Aktive Mobilität“, gefördert aus Mitteln des
      nationalen Förderprogramms Mobilität der Zukunft (FFG Nr. 884334), wurde ein digitales Werkzeug für die
      Optimierung gesundheitsrelevanter Arbeitswege (kurz ActNow-Tool) erstellt. Im Zuge der Evaluierung des ActNow
      Tools sind Sie als UnternehmensvertreterIn eingeladen, das ActNow-Tool probeweise zu verwenden und es Angehörigen
      Ihres Unternehmensstandorts zur Verfügung zu stellen. Seitens des Unternehmens werden keinerlei Daten von
      MitarbeiterInnen übermittelt. MitarbeiterInnen Ihres Unternehmens registrieren sich bei Interesse eigenständig.
    </PrivacyTypography>
    <PrivacyTypography>
      Damit Sie diese Anwendung nutzen können, werden von Ihnen eingegebene Adressdaten, Ihre Unternehmenszugehörigkeit
      sowie Ihre E-Mail-Adresse, die als personenbezogene Daten gelten, verarbeitet. Zur Überwachung und Steuerung
      unserer Systeme werden Systemdaten in Protokolldateien (Serverlogs) gespeichert. Wir verwenden ausschließlich
      technisch notwendige Cookies sowie Local Storage, z.B. um den Login-Status zu speichern. Darüber hinaus werden
      keine Cookies eingesetzt. Wir verwenden keine automatisierten Entscheidungsfindungen und führen kein Profiling
      nach Art. 22 DSGVO durch.
    </PrivacyTypography>
    <PrivacyTypography>
      Der verantwortungsbewusste Umgang mit personenbezogenen Daten hat für uns hohe Priorität. Wir möchten Sie darüber
      informieren, welche Daten erhoben werden und wie wir sie verwenden.
    </PrivacyTypography>

    {full && (
      <>
        <PrivacyHeading>Zwecke, Rechtsgrundlagen und Speicherdauer</PrivacyHeading>
        <PrivacyTypography>
          Für die Nutzung des ActNow-Tools für Ihr Unternehmen ist unter anderem die Eingabe des Unternehmensstandorts
          in Form einer Adresseingabe, sowie Angaben zu einer Ansprechperson im Unternehmen (Vorname, Name,
          Telefonnummer und E-Mail-Adresse) erforderlich. Darüber hinaus werden keinerlei personenbezogene Daten
          erhoben. Die Kontaktdaten der Ansprechperson dienen ausschließlich dazu, diese im Zusammenhang mit der Nutzung
          des Tools im Unternehmen bei Bedarf kontaktieren zu können. Die E-Mail-Adresse wird für die Benutzerverwaltung
          im ActNow-Tool verwendet. Die Adresse des Unternehmensstandorts wird zum Zweck der Berechnung von
          Routenvorschlägen für den Arbeitsweg verarbeitet. Sofern Sie uns diese Daten nicht zur Verfügung stellen,
          können Sie das ActNow-Tool nicht für Ihr Unternehmen nutzen.
        </PrivacyTypography>
        <PrivacyTypography>
          Die Protokolldateien (Serverlogs) werden ausschließlich für die Gewährleistung der Systemsicherheit verwendet
          und nicht mit sonstigen Eingaben verbunden.
        </PrivacyTypography>
        <PrivacyTypography>
          Wir verarbeiten Ihre personenbezogenen Daten (Adressen) im Zusammenhang mit der testweisen Verwendung des
          ActNow-Tools auf Grundlage gesetzlicher Bestimmungen (Art. 6 Abs. 1 lit. a DSGVO).
        </PrivacyTypography>
        <PrivacyTypography>
          Wir speichern die von Ihnen eingegebenen Daten bis zum Ende der Projektlaufzeit von „ActNow – Allianz Aktive
          Mobilität“ (31.08.2023). Danach werden die Daten unwiderruflich und vollständig gelöscht. Protokolldateien
          (Serverlogs) werden nach 30 Tagen automatisch gelöscht.
        </PrivacyTypography>

        <PrivacyHeading>Datenkategorien</PrivacyHeading>
        <PrivacyTypography>Folgende Datenkategorien werden verarbeitet:</PrivacyTypography>
        <PrivacyTypography component="ul">
          <PrivacyListItem>
            Stammdaten:
            <PrivacyTypography component="ul">
              <PrivacyListItem>Name des Unternehmens</PrivacyListItem>
              <PrivacyListItem>Adresse des Unternehmensstandorts</PrivacyListItem>
              <PrivacyListItem>Anzahl der MitarbeiterInnen</PrivacyListItem>
              <PrivacyListItem>Vor- und Nachname der Ansprechperson</PrivacyListItem>
              <PrivacyListItem>Telefonnummer der Ansprechperson</PrivacyListItem>
              <PrivacyListItem>E-Mail-Adresse der Ansprechperson</PrivacyListItem>
              <PrivacyListItem>Passwort</PrivacyListItem>
            </PrivacyTypography>
          </PrivacyListItem>

          <PrivacyListItem>
            Protokolldateien:
            <PrivacyTypography component="ul">
              <PrivacyListItem>Besuchte Website</PrivacyListItem>
              <PrivacyListItem>Uhrzeit zum Zeitpunkt des Zugriffes</PrivacyListItem>
              <PrivacyListItem>Menge der übertragenen Daten in Byte</PrivacyListItem>
              <PrivacyListItem>Verwendeter Browsertyp und Browserversion</PrivacyListItem>
              <PrivacyListItem>Verwendetes Betriebssystem</PrivacyListItem>
              <PrivacyListItem>Verwendete IP-Adresse</PrivacyListItem>
            </PrivacyTypography>
          </PrivacyListItem>
        </PrivacyTypography>

        <PrivacyHeading>Empfänger</PrivacyHeading>
        <PrivacyTypography>
          Wir übermitteln Ihre unmittelbar personenbezogenen Daten (Stammdaten) nicht an Dritte, außerhalb des
          Forschungsprojekts. Im Rahmen des Projekts ActNow kommt das ActNow-Tool im Zusammenhang mit einem Begleit- und
          Beratungsprozess der Konsortialpartner komobile GmbH, Kirchengasse 3, 4810 Gmunden und IBG Innovatives
          Betriebliches Gesundheitsmanagement GmbH, Mariahilfer Straße 50/14, 1070 Wien, zum Einsatz. Diese bekommen
          einen Beraterzugang zum ActNow-Tool und können Ihre Angaben, insbesondere die Unternehmens-Stammdaten sowie
          die berechneten unternehmensweiten Mobilitäts- und Fahrgemeinschaftspotenziale einsehen. Dies dient dazu, Sie
          in der Benutzung des Tools zu unterstützen und Ihnen optimale Beratungsleistungen anbieten zu können. Die
          Daten einzelner MitarbeiterInnen sind davon nicht berührt.
        </PrivacyTypography>
        <PrivacyTypography>
          Systemdaten (Protokolldateien) können auch an von uns beauftragte Dritte für IT- Dienstleistungen (wie zum
          Beispiel IT-Supportdienstleistungen) übermittelt werden. In solchen Fällen stellt der
          Datenschutzverantwortliche vertraglich sicher, dass Ihre Daten von unseren Auftragsverarbeitern den
          datenschutzrechtlichen Vorgaben entsprechend sicher und unter Achtung Ihrer Rechte verarbeitet werden.
        </PrivacyTypography>
        <PrivacyReceiverTable />

        <PrivacyHeading>Datensicherheitsmaßnahmen</PrivacyHeading>
        <PrivacyTypography>
          Die Sicherheit Ihrer Daten ist uns ein großes Anliegen. Zum Schutz Ihrer Daten setzen wir daher auf technische
          und organisatorische Schutzmaßnahmen. Zu diesen zählen unter anderem:
        </PrivacyTypography>
        <PrivacyTypography component="ul">
          <PrivacyListItem>
            Beschränkung der Zugriffe auf jene Personenkreise, welche die Daten zur Zweckerfüllung benötigen;
          </PrivacyListItem>
          <PrivacyListItem>
            Verpflichtung der mit der Datenverarbeitung betrauten Mitarbeiterinnen und Mitarbeiter zur Geheimhaltung;
          </PrivacyListItem>
          <PrivacyListItem>Ausschließlich verschlüsselte Datenübertragung;</PrivacyListItem>
          <PrivacyListItem>
            Trennungskontrolle: Daten, die unterschiedlichen Zwecken dienen, werden nie gemeinsam verarbeitet;
          </PrivacyListItem>
          <PrivacyListItem>
            Hosting der Dienste in ISO27001-zertifizierten Rechenzentren in Deutschland;
          </PrivacyListItem>
          <PrivacyListItem>Vertragliche Vereinbarungen mit Dienstleistern (Auftragsverarbeiter).</PrivacyListItem>
        </PrivacyTypography>

        <PrivacyHeading>Links zu Webseiten Dritter</PrivacyHeading>
        <PrivacyTypography>
          Im ActNow-Tool sind Links zu Webseiten Dritter enthalten. Für deren Inhalte und Einhaltung der
          Datenschutzbestimmungen wird keine Gewährleistung übernommen.
        </PrivacyTypography>

        <PrivacyHeading>Ihre Betroffenenrechte</PrivacyHeading>
        <PrivacyTypography>
          Sie können Ihre Einwilligung jederzeit widerrufen, falls unsere Datenverarbeitung auf einer Einwilligung
          beruht, die Sie uns erteilt haben. Durch den Widerruf wird die Rechtmäßigkeit, der aufgrund der Einwilligung
          bis zum Widerruf erfolgten Verarbeitung nicht berührt. Sie haben grundsätzlich das Recht auf Auskunft durch
          die Verantwortlichen über die Sie betreffenden personenbezogenen Daten, sowie das Recht auf Berichtigung,
          Löschung, Einschränkung und Datenübertragbarkeit. Unter bestimmten Umständen können Sie der Verarbeitung der
          Sie betreffenden personenbezogenen Daten widersprechen. Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
          Daten gegen das Datenschutzrecht verstößt, besteht ein Beschwerderecht bei der Aufsichtsbehörde. In Österreich
          ist dies die Datenschutzbehörde. Die Kontaktinformationen finden Sie{' '}
          <Link href="https://edpb.europa.eu/about-edpb/board/members" target="_blank" rel="noopener">
            hier
          </Link>
          .
        </PrivacyTypography>

        <PrivacyHeading>Verantwortlicher und Anlaufstelle für Betroffene</PrivacyHeading>
        <PrivacyTypography>
          Die Trafficon – Traffic Consultants GmbH ist Datenschutzverantwortlicher im Sinne der DSGVO für die
          personenbezogenen Daten. Ihre Rechte können Sie mündlich am Unternehmenssitz oder telefonisch, per E-Mail oder
          per Post gegenüber dem Verantwortlichen ausüben:
        </PrivacyTypography>
        <PrivacyTypography component="address">
          GF Dr. Stefan Krampe
          <br />
          Trafficon – Traffic Consultants GmbH
          <br />
          Strubergasse 26
          <br />
          5020 Salzburg
          <br />
          <Link href="tel:00436624611020">+43 662 46 11 02-0</Link>
          <br />
          <Link href="mailto:sbg@trafficon.eu">sbg@trafficon.eu</Link>
        </PrivacyTypography>
      </>
    )}
  </PrivacyWrapper>
);

export default PrivacyOrganization;
