import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CommuterSignUpStep1 from './CommuterSignUpStep1';
import CommuterSignUpStep2 from './CommuterSignUpStep2';
import CommuterSignUpStep3 from './CommuterSignUpStep3';
import CommuterSignUpStep4 from './CommuterSignUpStep4';

const CommuterSignUp = () => (
  <Routes>
    <Route path="1" element={<CommuterSignUpStep1 />} />
    <Route path="2" element={<CommuterSignUpStep2 />} />
    <Route path="3" element={<CommuterSignUpStep3 />} />
    <Route path="4" element={<CommuterSignUpStep4 />} />
    <Route path="*" element={<Navigate to="1" />} />
  </Routes>
);

export default CommuterSignUp;
