/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DirectionRefStructure } from './DirectionRefStructure';
import type { GeneralAttributeStructure } from './GeneralAttributeStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { JourneyRefStructure } from './JourneyRefStructure';
import type { LineRefStructure } from './LineRefStructure';
import type { ModeStructure } from './ModeStructure';
import type { OperatingDayRefStructure } from './OperatingDayRefStructure';
import type { OperatorRefStructure } from './OperatorRefStructure';
import type { ServiceViaPointStructure } from './ServiceViaPointStructure';
import type { SharingServiceStructure } from './SharingServiceStructure';
import type { SituationFullRefStructure } from './SituationFullRefStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { VehicleRefStructure } from './VehicleRefStructure';

export type ContinuousServiceStructure = {
    attribute?: Array<GeneralAttributeStructure>;
    continuousMode?: ContinuousServiceStructure.continuousMode;
    destinationStopPointRef?: StopPointRefStructure;
    destinationText?: InternationalTextStructure;
    directionRef?: DirectionRefStructure;
    individualMode?: ContinuousServiceStructure.individualMode;
    journeyRef?: JourneyRefStructure;
    lineRef?: LineRefStructure;
    mode?: ModeStructure;
    operatingDayRef?: OperatingDayRefStructure;
    operatorRef?: OperatorRefStructure;
    originStopPointRef?: StopPointRefStructure;
    originText?: InternationalTextStructure;
    publishedLineName?: InternationalTextStructure;
    routeDescription?: InternationalTextStructure;
    sharingService?: SharingServiceStructure;
    situationFullRef?: Array<SituationFullRefStructure>;
    vehicleRef?: VehicleRefStructure;
    via?: Array<ServiceViaPointStructure>;
};

export namespace ContinuousServiceStructure {

    export enum continuousMode {
        WALK = 'WALK',
        DEMAND_RESPONSIVE = 'DEMAND_RESPONSIVE',
        REPLACEMENT_SERVICE = 'REPLACEMENT_SERVICE',
    }

    export enum individualMode {
        WALK = 'WALK',
        CYCLE = 'CYCLE',
        TAXI = 'TAXI',
        SELF_DRIVE_CAR = 'SELF_DRIVE_CAR',
        OTHERS_DRIVE_CAR = 'OTHERS_DRIVE_CAR',
        MOTORCYCLE = 'MOTORCYCLE',
        TRUCK = 'TRUCK',
    }


}

