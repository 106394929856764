import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm, FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import QueryKeys from '../../services/QueryKeys';
import { Consultant, OrganizationsService, OrganizationStatus } from '../../services/api';
import PageContainer from '../../components/PageContainer';
import OrganizationsTable from './OrganizationsTable';
import FilterHeader from '../../components/forms/FilterHeader';
import CheckboxesGroup from '../../components/forms/CheckboxesGroup';

type FilterFormData = { search: string; status: OrganizationStatus[]; consultant: Consultant[] };

const OrganizationsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<FilterFormData>({ search: '', status: [], consultant: [] });

  const methods = useForm<FilterFormData>({
    defaultValues: {
      search: '',
      status: [],
      consultant: [],
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const searchQueryKey = QueryKeys.organizations.filter(filter);
  const { data: organizations = [] } = useQuery(searchQueryKey, () => OrganizationsService.findAll(filter), {
    keepPreviousData: true,
  });

  const onSubmit = handleSubmit((newFilter) => {
    setFilter(newFilter);

    const { search, ...others } = newFilter;
    setSearchParams({ ...others, ...(search && { search }) });
  });

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (['status', 'consultant'].includes(name || '')) {
        onSubmit();
      }
    });

    return () => subscription.unsubscribe();
  }, [onSubmit, watch]);

  useEffect(() => {
    const queries = { status: [] as OrganizationStatus[], consultant: [] as Consultant[] };

    new URLSearchParams(searchParams).forEach((value, name) => {
      switch (name) {
        case 'search':
          setValue(name, value);
          break;
        case 'status':
          queries[name].push(value as OrganizationStatus);
          break;
        case 'consultant':
          queries[name].push(value as Consultant);
          break;
        default:
          break;
      }
    });

    setValue('status', queries.status);
    setValue('consultant', queries.consultant);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <FilterHeader onSubmit={onSubmit} filterCount={filter.status.length + filter.consultant.length}>
          <CheckboxesGroup
            label="Status"
            name="status"
            i18nKey="OrganizationStatus"
            options={Object.values(OrganizationStatus)}
          />
          <CheckboxesGroup label="Berater" name="consultant" i18nKey="Consultant" options={Object.values(Consultant)} />
        </FilterHeader>
      </FormProvider>

      <OrganizationsTable
        organizations={organizations}
        searchQueryKey={searchQueryKey}
        showConsultant
        showStatus
        emptyText="Keine Unternehmen gefunden"
      />
    </PageContainer>
  );
};

export default OrganizationsPage;
