import { styled, css } from '@mui/material';
import StyledTableCell from './StyledTableCell';

const StyledTableHeading = styled(StyledTableCell)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    line-height: 1.2;
  `,
);

export default StyledTableHeading;
