import React from 'react';
import NavigationLinks from './NavigationLinks';
import RouteFactory from '../../services/RouteFactory';
import SubNavigationBefore from './SubNavigationBefore';
import useQueryOrganization from '../../hooks/queries/useQueryOrganization';

const OrganizationSubNavigation = () => {
  const { organizationId, data: organization } = useQueryOrganization();

  return (
    <NavigationLinks
      before={
        <SubNavigationBefore>
          {organization?.name}, {organization?.address}
        </SubNavigationBefore>
      }
      links={RouteFactory.organizationLinks(organizationId as string)}
    />
  );
};

export default OrganizationSubNavigation;
