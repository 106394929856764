import React, { ReactNode } from 'react';
import { Box, styled, Typography, css } from '@mui/material';
import { ReactComponent as StopwatchIcon } from '../assets/icons/stopwatch.svg';

const Root = styled(Box)<{ image: string }>(
  ({ theme, image }) =>
    css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0 auto;
      flex-direction: column;
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.spacing(8)};
      font-weight: bold;
      letter-spacing: 4.8px;
      text-transform: uppercase;
      font-size: 1.5rem;
    `,
);

const StyledStopwatchIcon = styled(StopwatchIcon)(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.spacing(8)};
    `,
);

type SubmittedPageProps = {
  title: string;
  children: ReactNode;
  image: string;
};

const SubmittedPage = ({ title, children, image }: SubmittedPageProps) => (
  <Root image={image}>
    <StyledStopwatchIcon />
    <StyledTypography variant="h1">{title}</StyledTypography>
    {children}
  </Root>
);

export default SubmittedPage;
