/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Consultant } from '../models/Consultant';
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { Organization } from '../models/Organization';
import type { OrganizationStatus } from '../models/OrganizationStatus';
import type { PotentialsCarpoolingDto } from '../models/PotentialsCarpoolingDto';
import type { PotentialsRoutingDto } from '../models/PotentialsRoutingDto';
import type { PublicOrganizationDto } from '../models/PublicOrganizationDto';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * @returns PublicOrganizationDto
     * @throws ApiError
     */
    public static validateSignUpToken({
        signUpToken,
    }: {
        signUpToken: string,
    }): CancelablePromise<PublicOrganizationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/validate-sign-up-token/{signUpToken}',
            path: {
                'signUpToken': signUpToken,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateOrganizationDto,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static findAll({
        search,
        consultant,
        status,
    }: {
        search?: string,
        consultant?: Array<Consultant>,
        status?: Array<OrganizationStatus>,
    }): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations',
            query: {
                'search': search,
                'consultant': consultant,
                'status': status,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static findOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Organization
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateOrganizationDto,
    }): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PotentialsRoutingDto
     * @throws ApiError
     */
    public static getPotentialsRouting({
        id,
    }: {
        id: string,
    }): CancelablePromise<PotentialsRoutingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/{id}/potentials/routing',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PotentialsCarpoolingDto
     * @throws ApiError
     */
    public static getPotentialsCarpooling({
        id,
    }: {
        id: string,
    }): CancelablePromise<PotentialsCarpoolingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/organizations/{id}/potentials/carpooling',
            path: {
                'id': id,
            },
        });
    }

}
