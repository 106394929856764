import React, { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type RemoveDialogProps = {
  title: string;
  children: ReactNode;
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

const RemoveDialog = ({ title, children, open, onCancel, onSubmit }: RemoveDialogProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="remove-dialog-title"
    aria-describedby="remove-dialog-description"
  >
    <DialogTitle id="remove-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="remove-dialog-description">{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Abbrechen</Button>
      <Button color="error" variant="contained" onClick={onSubmit} autoFocus>
        Löschen
      </Button>
    </DialogActions>
  </Dialog>
);

export default RemoveDialog;
