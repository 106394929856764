import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useStore from '../setup/global-state';

const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setUser = useStore((state) => state.setUser);

  return () => {
    queryClient.clear();
    navigate('/', { replace: true });
    setUser(undefined);
  };
};

export default useLogout;
