import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { Box, styled } from '@mui/material';
import { css } from '@emotion/react';
import useQueryAuthenticatedUser from '../../hooks/queries/useQueryUser';
import useMapRoute from '../../hooks/useMapRoute';
import IconWalk from '../../assets/icons/markers/interchanges/walk.svg';
import IconBicycle from '../../assets/icons/markers/interchanges/bicycle.svg';
import IconPublicTransport from '../../assets/icons/markers/interchanges/public-transport.svg';
import IconSelfDriveCar from '../../assets/icons/markers/interchanges/car.svg';
import { ContinuousServiceStructure } from '../../services/api';

const StyledMap = styled(Box)(
  css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
);

const MobilityOptionsMap = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();
  const [mapIsLoading, setMapIsLoading] = useState(true);
  const [zoom] = useState(14);
  const { data: { routes, addressPoint } = { routes: undefined } } = useQueryAuthenticatedUser();

  useEffect(() => {
    // Initialize map only once
    if (!mapboxgl.supported() || !mapContainer.current || map.current || !addressPoint) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/trafficon/ckt2mzei625ic18nzjxrv5u76',
      center: addressPoint.coordinates as LngLatLike,
      zoom,
      touchPitch: false,
      pitchWithRotate: false,
      dragRotate: false,
      preserveDrawingBuffer: true,
    });
    map.current.touchZoomRotate.disableRotation();

    [
      { name: ContinuousServiceStructure.individualMode.WALK, icon: IconWalk },
      { name: ContinuousServiceStructure.individualMode.CYCLE, icon: IconBicycle },
      { name: 'SELF_DRIVE_CAR', icon: IconSelfDriveCar },
      { name: 'PUBLIC_TRANSPORT', icon: IconPublicTransport },
    ].forEach(({ name, icon }) => {
      const img = new Image(64, 64);
      img.onload = () => map.current?.addImage(name, img);
      img.src = icon;
    });

    map.current.on('load', () => {
      setMapIsLoading(false);
    });
  });

  useMapRoute(map.current, mapIsLoading, routes);

  return mapboxgl.supported() ? <StyledMap ref={mapContainer} /> : null;
};

export default MobilityOptionsMap;
