import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AuthHeading from '../../components/AuthHeading';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import FormWrapper from '../../components/FormWrapper';
import step2Image from '../../assets/images/commuter/sign-up/2-step.jpg';
import { ReactComponent as WaypointsIcon } from '../../assets/icons/split-page/waypoints.svg';
import { GeocodingSearchInputFormData } from '../../components/forms/GeocodingSearchInput';
import FormStepper from '../../components/forms/FormStepper';
import useStore from '../../setup/global-state';
import { CreateUserDto } from '../../services/api';
import CommuterSignUpStep2TextContent from './CommuterSignUpStep2TextContent';
import CommuterSignUpStep2Form from './CommuterSignUpStep2Form';

type FormData = Pick<CreateUserDto, 'address' | 'interestCarpooling' | 'interestChildrenCarpooling'> &
  GeocodingSearchInputFormData<'address'> & {
    childcareFacilities: GeocodingSearchInputFormData<'address'>[];
  };

const CommuterSignUpStep2 = () => {
  const setCommuterSignUpFormData = useStore((state) => state.setCommuterSignUpFormData);

  const methods = useForm<FormData>({
    defaultValues: {
      childcareFacilities: [],
    },
  });
  const { handleSubmit } = methods;

  const navigate = useNavigate();

  return (
    <SplitPage
      image={step2Image}
      left={
        <SplitPageTextContent
          Icon={WaypointsIcon}
          title={
            <>
              Ihr Weg,
              <br />
              Ihre Möglichkeiten.
            </>
          }
        >
          <CommuterSignUpStep2TextContent />
        </SplitPageTextContent>
      }
      right={
        <FormWrapper>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(({ addressFeature, ...formData }) => {
                setCommuterSignUpFormData({
                  ...formData,
                  childcareFacilities: formData.childcareFacilities.map(
                    ({ addressFeature: childcareFacilityAddressFeature, ...childcareFacility }) => childcareFacility,
                  ),
                });

                navigate('/pendlerinnen/registrieren/3');
              })}
            >
              <AuthHeading>Route</AuthHeading>

              <CommuterSignUpStep2Form />

              <FormStepper active={0} />
            </form>
          </FormProvider>
        </FormWrapper>
      }
    />
  );
};

export default CommuterSignUpStep2;
