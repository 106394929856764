import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { css } from '@emotion/react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthHeading from '../../components/AuthHeading';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import FormWrapper from '../../components/FormWrapper';
import step1Image from '../../assets/images/commuter/sign-up/1-step.jpg';
import { ReactComponent as WaveIcon } from '../../assets/icons/split-page/wave.svg';
import useStore from '../../setup/global-state';
import { CreateUserDto } from '../../services/api';
import PrivacyCommuter from '../../common/privacy/PrivacyCommuter';
import ButtonLink from '../../components/ButtonLink';
import RouteFactory from '../../services/RouteFactory';
import Audience from '../../types/Audience';
import SignUpToken from '../../components/forms/SignUpToken';

type CommuterSignUpStep1FormData = Pick<CreateUserDto, 'email' | 'signUpToken' | 'password'> & {
  privacy: boolean;
};

const PrivacyPolicy = styled(Box)(
  ({ theme }) =>
    css`
      max-height: ${theme.spacing(17)};
      overflow: scroll;
    `,
);

const CommuterSignUpStep1 = () => {
  const setCommuterSignUpFormData = useStore((state) => state.setCommuterSignUpFormData);
  const signUpToken = useStore((state) => state.signUpToken);
  const setSignUpToken = useStore((state) => state.setSignUpToken);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const methods = useForm<CommuterSignUpStep1FormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = methods;

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      setSignUpToken(token);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams, setSignUpToken]);

  useEffect(() => {
    setValue('signUpToken', signUpToken || '');
  }, [signUpToken, setValue]);

  return (
    <SplitPage
      image={step1Image}
      left={
        <SplitPageTextContent
          Icon={WaveIcon}
          title={
            <>
              Ab jetzt:
              <br />
              Besser zur Arbeit.
            </>
          }
        >
          <Typography>
            Kleine Veränderungen können Großes bewirken! Ihre täglichen Mobilitätsgewohnheiten haben einen größeren
            Einfluss auf Ihren Körper und Ihre Umwelt, als Sie denken. Das Forschungsprojekt ActNow möchte Ihnen und
            Ihrer Organisation dabei helfen, diese Effekte und Potenziale zu erkennen und zu ergreifen. Mit einer
            kostenlosen Registrierung und kleinen Veränderungen in Ihrem Alltag können Sie viel erreichen:
          </Typography>

          <ul>
            <li>Fördern Sie die eigene Gesundheit einfach und effektiv</li>
            <li>Leisten Sie einen Beitrag zum Umweltschutz</li>
            <li>Entdecken Sie Fahrgemeinschaften mit KollegInnen</li>
            <li>Helfen Sie Ihrer Organisation beim Planen von Mobilitätsmaßnahmen</li>
          </ul>

          <Typography>
            <strong>Jetzt mitmachen und Veränderung bewirken ↦</strong>
          </Typography>
        </SplitPageTextContent>
      }
      right={
        <FormWrapper>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(({ privacy, ...formData }) => {
                setSignUpToken(formData.signUpToken);
                setCommuterSignUpFormData(formData);
                navigate('/pendlerinnen/registrieren/2');
              })}
            >
              <AuthHeading>Registrierung für PendlerInnen</AuthHeading>

              <TextField
                label="E-Mail"
                type="email"
                autoComplete="email"
                error={!!errors.email}
                {...register('email', { required: true })}
              />
              <SignUpToken />
              <Controller
                defaultValue=""
                name="password"
                rules={{ required: true, minLength: 8 }}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Passwort"
                    type="password"
                    autoComplete="new-password"
                    error={!!fieldState.error}
                    helperText={(field.value.length || 0) < 8 && 'Mindestens 8 Zeichen'}
                    {...field}
                  />
                )}
              />

              <PrivacyPolicy>
                <PrivacyCommuter full={false} />

                <ButtonLink
                  component={Link}
                  to={`/datenschutz/${RouteFactory.audienceToSlug(Audience.COMMUTER)}`}
                  target="_blank"
                >
                  Weiterlesen ↦
                </ButtonLink>
              </PrivacyPolicy>

              <FormControl error={!!errors.privacy} sx={{ display: 'block', marginTop: 2 }}>
                <FormControlLabel
                  control={<Checkbox {...register('privacy', { required: true })} />}
                  label="Ich möchte am Forschungsprojekt „ActNow – Allianz Aktive Mobilität“ teilnehmen und bin mit der Verarbeitung meiner Daten sowie der Übertragung meiner IP-Adresse an Mapbox Inc. in den USA im Rahmen der Kartendarstellung einverstanden. Darüber hinaus werden keine personenbezogenen Daten in die USA übertragen."
                />
                {errors.privacy && <FormHelperText>Eine Teilnahme ist nur mit Zustimmung möglich</FormHelperText>}
              </FormControl>

              <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 6 }}>
                Start
              </Button>
            </form>
          </FormProvider>
        </FormWrapper>
      }
    />
  );
};

export default CommuterSignUpStep1;
