import React from 'react';
import { Box, styled, css, Tooltip } from '@mui/material';
import transientOptions from '../../../utils/transient-options';
import { PotentialShareDto } from '../../../services/api';
import Format from '../../../services/Format';

const Root = styled(Box)(
  ({ theme }) => css`
    @media print {
      min-height: ${theme.spacing(5.5)};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  `,
);

const Item = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
);

const ChartWrapper = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(12)};
  `,
);

const ChartBar = styled(
  Box,
  transientOptions,
)<{ $color: string; $percent: number }>(
  ({ theme, $color, $percent }) => css`
    height: ${theme.spacing(2)};
    background: ${$color};
    width: ${100 * $percent}%;

    @media screen {
      transition: ${theme.transitions.create('width')};
    }
  `,
);

type PotentialsDataChartProps = { share: PotentialShareDto; onlyCommon?: boolean };

const PotentialsDataChart = ({ share, onlyCommon }: PotentialsDataChartProps) => (
  <Root>
    {[
      { value: share.common, color: '#e9e9e9' },
      ...(onlyCommon ? [] : [{ value: share.recommended, color: '#ffdb91' }]),
    ].map(({ value, color }, index) => (
      <Item key={color}>
        <Box sx={{ width: 44, textAlign: 'right' }}>{Format.percentRounded(value.percent)}</Box>
        <ChartWrapper>
          <Tooltip
            title={`${value.absolute} PendlerIn${value.absolute === 1 ? '' : 'nen'}`}
            placement={(['top', 'bottom'] as const)[index]}
            arrow
          >
            <ChartBar $color={color} $percent={value.percent} />
          </Tooltip>
        </ChartWrapper>
      </Item>
    ))}
  </Root>
);

export default PotentialsDataChart;
