import React, { ReactNode } from 'react';
import { InputBase, InputAdornment, styled, css } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import transientOptions from '../../utils/transient-options';

const StyledInputBase = styled(
  InputBase,
  transientOptions,
)<{ $fullWidth: boolean }>(
  ({ theme, $fullWidth }) =>
    css`
      width: ${$fullWidth ? '100%' : theme.spacing(40)};
      height: ${theme.spacing(7)};
      border: 2px solid ${theme.palette.common.white};
      padding-left: ${theme.spacing(2)};

      .MuiInputBase-input::placeholder {
        color: ${theme.palette.text.primary};
        opacity: 1;
        font-weight: bold;
        font-size: 1.15rem;
        text-transform: uppercase;
      }
    `,
);

type SearchFieldProps = {
  onChange: (value: string) => void;
  fullWidth?: boolean;
  endAdornment?: ReactNode;
};

const SearchField = ({ onChange, fullWidth = false, endAdornment }: SearchFieldProps) => {
  const searchValue = useWatch({ name: 'search' });

  useDebounce(
    () => {
      onChange(searchValue);
    },
    300,
    [searchValue],
  );

  return (
    <Controller
      name="search"
      defaultValue=""
      render={({ field }) => (
        <StyledInputBase
          $fullWidth={fullWidth}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={endAdornment}
          placeholder="Suche"
          {...field}
        />
      )}
    />
  );
};

export default SearchField;
