/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessPathStructure } from './AccessPathStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { SituationFullRefStructure } from './SituationFullRefStructure';
import type { TrackSectionStructure } from './TrackSectionStructure';

export type NavigationSectionStructure = {
    accessPath?: AccessPathStructure;
    bearing?: number;
    directionHint?: InternationalTextStructure;
    manoeuvre?: NavigationSectionStructure.manoeuvre;
    situationFullRef?: Array<SituationFullRefStructure>;
    trackSection?: TrackSectionStructure;
    turnAction?: NavigationSectionStructure.turnAction;
    turnDescription?: InternationalTextStructure;
};

export namespace NavigationSectionStructure {

    export enum manoeuvre {
        ORIGIN = 'ORIGIN',
        DESTINATION = 'DESTINATION',
        CONTINUE = 'CONTINUE',
        KEEP = 'KEEP',
        TURN = 'TURN',
        LEAVE = 'LEAVE',
        ENTER = 'ENTER',
    }

    export enum turnAction {
        SHARP_LEFT = 'SHARP_LEFT',
        LEFT = 'LEFT',
        HALF_LEFT = 'HALF_LEFT',
        STRAIGHT_ON = 'STRAIGHT_ON',
        HALF_RIGHT = 'HALF_RIGHT',
        RIGHT = 'RIGHT',
        SHARP_RIGHT = 'SHARP_RIGHT',
        UTURN = 'UTURN',
    }


}

