import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { FormProvider, useForm } from 'react-hook-form';

import PageContainer from '../components/PageContainer';
import measures from '../i18n/de.measures';
import HelpPopUp from '../components/HelpPopUp';
import MeasureItem from './MeasureItem';
import EmptyTable from '../components/EmptyTable';
import CheckboxesGroup from '../components/forms/CheckboxesGroup';
import category from '../types/MeasuresCategory';
import effect from '../types/MeasuresEffect';
import FilterHeader from '../components/forms/FilterHeader';

const fuse = new Fuse(measures.items, {
  keys: ['title', 'effects', 'category'],
  useExtendedSearch: true,
});

const MeasuresPage = () => {
  const [results, setResults] = useState(measures.items);
  const [filterCount, setFilterCount] = useState(0);

  const methods = useForm<{ search: string; categories: string[]; effects: string[] }>({
    defaultValues: {
      search: '',
      categories: [],
      effects: [],
    },
  });

  const { handleSubmit, watch } = methods;

  const onSubmit = handleSubmit(({ search, categories, effects }) => {
    const expressions = [];

    if (search !== '') {
      expressions.push({ title: search });
    }

    if (effects.length > 0) {
      expressions.push({
        effects: `=${effects.join('|')}`,
      });
    }

    if (categories.length > 0) {
      expressions.push({
        category: `=${categories.join('|')}`,
      });
    }

    const checkedFilters = [...categories, ...effects];
    setFilterCount(checkedFilters.length);

    setResults(expressions.length > 0 ? fuse.search({ $and: expressions }).map(({ item }) => item) : measures.items);
  });

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (['categories', 'effects'].includes(name || '')) {
        onSubmit();
      }
    });

    return () => subscription.unsubscribe();
  }, [onSubmit, watch]);

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <FilterHeader onSubmit={onSubmit} filterCount={filterCount}>
          <CheckboxesGroup
            label="Kategorie"
            name="categories"
            i18nKey="MeasuresCategory"
            options={Object.values(category)}
          />
          <CheckboxesGroup label="Wirkung" name="effects" i18nKey="MeasuresEffect" options={Object.values(effect)} />
        </FilterHeader>
      </FormProvider>

      {!results.length ? (
        <EmptyTable>Keine Maßnahme gefunden</EmptyTable>
      ) : (
        results.map((listItem) => <MeasureItem key={listItem.id} item={listItem} />)
      )}
      <HelpPopUp>
        <Typography>
          Auf dieser Seite finden Sie Ideen und Maßnahmen zur Förderung aktiver und nachhaltiger Mobilität. Für viele
          bestehen attraktive Fördermöglichkeiten! Diskutieren Sie auch mit ArbeitnehmervertreterInnen über passende
          Ideen für Ihr Unternehmen. Im gemeinsamen Dialog findet man oft die besten Lösungen!
        </Typography>
      </HelpPopUp>
    </PageContainer>
  );
};

export default MeasuresPage;
