/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';
import type { LineDirectionFilterStructure } from './LineDirectionFilterStructure';
import type { OperatorFilterStructure } from './OperatorFilterStructure';
import type { PtModeFilterStructure } from './PtModeFilterStructure';

export type StopEventParamStructure = {
    includeOnwardCalls?: boolean;
    includeOperatingDays?: boolean;
    includePreviousCalls?: boolean;
    includeRealtimeData?: boolean;
    lineFilter?: LineDirectionFilterStructure;
    numberOfResults?: number;
    operatorFilter?: OperatorFilterStructure;
    ptModeFilter?: PtModeFilterStructure;
    stopEventType?: StopEventParamStructure.stopEventType;
    timeWindow?: Duration;
};

export namespace StopEventParamStructure {

    export enum stopEventType {
        DEPARTURE = 'DEPARTURE',
        ARRIVAL = 'ARRIVAL',
        BOTH = 'BOTH',
    }


}

