/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Globale Nutzerrolle
 */
export enum UserRole {
    ADMIN = 'ADMIN',
    CONSULTANT = 'CONSULTANT',
    USER = 'USER',
}
