import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Link as MuiLink } from '@mui/material';
import { useQuery } from 'react-query';
import HelpPopUp from '../components/HelpPopUp';
import useQueryOrganization from '../hooks/queries/useQueryOrganization';
import QueryKeys from '../services/QueryKeys';
import { ApiError, OrganizationsService, PotentialsRoutingDto } from '../services/api';
import ButtonLink from '../components/ButtonLink';
import PotentialsRoutingPage from '../common/potentials/routing/PotentialsRoutingPage';

const OrganizationPotentialsRoutingPage = () => {
  const { organizationId } = useParams();

  const { data: organization } = useQueryOrganization();
  const { data: potentials, error } = useQuery<PotentialsRoutingDto, ApiError, PotentialsRoutingDto>(
    QueryKeys.organizations.potentialsRouting(organizationId as string),
    () => OrganizationsService.getPotentialsRouting({ id: organizationId as string }),
    { retry: false },
  );

  return (
    <PotentialsRoutingPage
      potentials={potentials}
      error={error}
      privacyFallback={
        <>
          Momentan sind {organization?.usersCount} von {organization?.size} MitarbeiterInnen registriert. Ab zwei
          registrierten PendlerInnen zeigen wir Ihnen hier den Status und die Potenziale der Arbeitsweg-Mobilität in
          Ihrem Unternehmen.
          <br />
          <MuiLink component={Link} to="../status">
            MitarbeiterInnen einladen
          </MuiLink>
        </>
      }
      printHeading={`Potenziale | Unternehmen: ${organization?.name}`}
    >
      {(setOpenDialog) => (
        <HelpPopUp>
          Hier sehen Sie die Kennwerte über alle registrierten MitarbeiterInnen.
          <Box component="span" sx={{ displayPrint: 'none' }}>
            {' '}
            Mehr Infos zur Berechnung{' '}
            <ButtonLink sx={{ textDecoration: 'underline' }} onClick={() => setOpenDialog(true)}>
              hier
            </ButtonLink>
            .
          </Box>{' '}
          Momentan sind {organization?.usersCount} von {organization?.size} MitarbeiterInnen registriert, davon konnte
          für {potentials?.potentialUsersCount} PendlerInnen erfolgreich eine Route berechnet werden. Der obere Balken
          zeigt Ihnen an, welcher Prozentsatz Ihrer PendlerInnen zum Zeitpunkt der Registrierung den jeweiligen
          Mobilitätsmodus nutzt. Der untere Balken gibt an, für wie viele dieser Modus die empfohlene
          gesundheitseffektive Option ist. Aus dieser Übersicht können Sie einen Überblick über den Status und die
          Potenziale der Arbeitsweg-Mobilität in Ihrer Organisation gewinnen. Erkennen Sie bereits, wo Sie vielleicht
          Maßnahmen ergreifen sollten? Gerne hilft Ihnen Ihr/e BeraterIn bei der Interpretation der Zahlen und bespricht
          mögliche nächste Schritte mit Ihnen.
        </HelpPopUp>
      )}
    </PotentialsRoutingPage>
  );
};

export default OrganizationPotentialsRoutingPage;
