import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { Municipality } from '../../services/api';
import StyledTableLink from '../../components/table/StyledTableLink';

type MunicipalitiesRowProps = { municipality: Municipality };

const MunicipalitiesRow = ({
  municipality: { iso, name, organizations, organizationsUsersCount, organizationsSize },
}: MunicipalitiesRowProps) => (
  <TableRow>
    <TableCell component="th" scope="row">
      <StyledTableLink component={Link} to={`/beratung/gemeinden/${iso}`}>
        {name}
      </StyledTableLink>
    </TableCell>
    <TableCell align="right">{organizations.length}</TableCell>
    <TableCell align="right">
      {organizationsUsersCount}/{organizationsSize}
    </TableCell>
  </TableRow>
);

export default MunicipalitiesRow;
