/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TimetableInfoType = {
    date?: string;
    time?: string;
    ident?: string;
    type?: TimetableInfoType.type;
    begin?: string;
    end?: string;
};

export namespace TimetableInfoType {

    export enum type {
        U = 'U',
        ST = 'ST',
        ADR = 'ADR',
        POI = 'POI',
    }


}

