import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import AuthHeading from '../../components/AuthHeading';
import SplitPage from '../../components/SplitPage';
import FormWrapper from '../../components/FormWrapper';
import { CreateOrganizationDto, OrganizationsService } from '../../services/api';
import GeocodingSearchInput, { GeocodingSearchInputFormData } from '../../components/forms/GeocodingSearchInput';
import PrivacyOrganization from '../../common/privacy/PrivacyOrganization';
import ButtonLink from '../../components/ButtonLink';
import RouteFactory from '../../services/RouteFactory';
import Audience from '../../types/Audience';

const PrivacyPolicy = styled(Box)(
  ({ theme }) =>
    css`
      max-height: ${theme.spacing(40)};
      overflow: scroll;
    `,
);

const OrganizationSignUpStep2 = () => {
  const navigate = useNavigate();

  const methods = useForm<CreateOrganizationDto & { privacy: true } & GeocodingSearchInputFormData<'address'>>();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const { mutate, error } = useMutation(
    (formData: CreateOrganizationDto) => OrganizationsService.create({ requestBody: formData }),
    {
      onSuccess: () => {
        navigate('/unternehmen/registrieren/fertig');
      },
    },
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(({ privacy, addressFeature, ...data }) => {
          mutate(data);
        })}
        style={{ flex: '1 0 auto', display: 'flex' }}
      >
        <SplitPage
          left={
            <>
              <AuthHeading>Registrierung für Unternehmen</AuthHeading>

              <FormWrapper>
                <TextField
                  label="Unternehmen"
                  autoComplete="organization"
                  error={!!errors.name}
                  {...register('name', { required: true })}
                />
                <GeocodingSearchInput label="Adresse" name="address" />
                <TextField
                  label="Anzahl der MitarbeiterInnen"
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.size}
                  {...register('size', { required: true })}
                />

                <Grid container spacing={2} className="form-item">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Vorname Ansprechperson"
                      autoComplete="given-name"
                      error={!!errors.contactFirstName}
                      {...register('contactFirstName', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Nachname Ansprechperson"
                      autoComplete="family-name"
                      error={!!errors.contactLastName}
                      {...register('contactLastName', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Telefon"
                      type="tel"
                      autoComplete="tel"
                      error={!!errors.contactTel}
                      {...register('contactTel', {
                        required: true,
                        validate: (value) => {
                          try {
                            return parsePhoneNumber(value, 'AT').isValid();
                          } catch {
                            return false;
                          }
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="E-Mail"
                      type="email"
                      autoComplete="email"
                      error={!!errors.email}
                      {...register('email', { required: true })}
                    />
                  </Grid>
                </Grid>

                <Controller
                  defaultValue=""
                  name="password"
                  rules={{ required: true, minLength: 8 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Passwort"
                      type="password"
                      autoComplete="new-password"
                      error={!!fieldState.error}
                      helperText={(field.value.length || 0) < 8 && 'Mindestens 8 Zeichen'}
                      {...field}
                    />
                  )}
                />
              </FormWrapper>
            </>
          }
          right={
            <>
              <PrivacyPolicy>
                <PrivacyOrganization full={false} />

                <ButtonLink
                  component={Link}
                  to={`/datenschutz/${RouteFactory.audienceToSlug(Audience.ORGANIZATION)}`}
                  target="_blank"
                >
                  Weiterlesen ↦
                </ButtonLink>
              </PrivacyPolicy>

              <FormControl error={!!errors.privacy} sx={{ display: 'block', marginTop: 2 }}>
                <FormControlLabel
                  control={<Checkbox {...register('privacy', { required: true })} />}
                  label="Ich möchte am Forschungsprojekt „ActNow – Allianz Aktive Mobilität“ teilnehmen und bin mit der Verarbeitung meiner Daten sowie der Übertragung meiner IP-Adresse an Mapbox Inc. in den USA im Rahmen der Kartendarstellung einverstanden."
                />
                {errors.privacy && <FormHelperText>Eine Teilnahme ist nur mit Zustimmung möglich</FormHelperText>}
              </FormControl>
              {error && <FormHelperText error>Es ist ein Fehler aufgetreten.</FormHelperText>}

              <Box sx={{ textAlign: 'center', marginTop: 8 }}>
                <Button type="submit" size="large" variant="contained">
                  Registrieren
                </Button>
              </Box>
            </>
          }
        />
      </form>
    </FormProvider>
  );
};

export default OrganizationSignUpStep2;
