import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { User } from '../services/api';

const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT as string;
const release =
  process.env.REACT_APP_SENTRY_RELEASE_VERSION && `actnow-frontend@${process.env.REACT_APP_SENTRY_RELEASE_VERSION}`;

Sentry.init({
  dsn: 'https://d57a0e712f554fd189f2af99e6fc0e10@sentry.trafficon.eu/3',
  enabled: environment !== 'development',
  environment,
  ...(release && { release }),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
});

export const sentrySetUser = (user?: User) => {
  if (user) {
    Sentry.setUser({ id: user.id });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
