import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import PageContainer from '../../components/PageContainer';
import { AggregationsService } from '../../services/api';
import QueryKeys from '../../services/QueryKeys';
import AggregationForm from './AggregationForm';

const AggregationPage = () => {
  const { aggregationId } = useParams();

  const create = aggregationId === 'hinzufuegen';

  const { data: aggregation } = useQuery(
    QueryKeys.aggregations.id(aggregationId as string),
    () => AggregationsService.findOne({ id: aggregationId as string }),
    { enabled: !create },
  );

  return <PageContainer>{(create || aggregation) && <AggregationForm aggregation={aggregation} />}</PageContainer>;
};

export default AggregationPage;
