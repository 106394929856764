import React from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import QueryKeys from '../../services/QueryKeys';
import { ApiError, MunicipalitiesService, PotentialsRoutingDto } from '../../services/api';
import PotentialsRoutingPage from '../../common/potentials/routing/PotentialsRoutingPage';
import useQueryMunicipality from '../../hooks/queries/useQueryMunicipality';
import HelpPopUp from '../../components/HelpPopUp';
import ButtonLink from '../../components/ButtonLink';

const MunicipalityPotentialsRoutingPage = () => {
  const { municipalityIso, data: municipality } = useQueryMunicipality();
  const { data: potentials, error } = useQuery<PotentialsRoutingDto, ApiError, PotentialsRoutingDto>(
    QueryKeys.municipalities.potentialsRouting(municipalityIso),
    () => MunicipalitiesService.getPotentialsRouting({ iso: municipalityIso }),
    { retry: false },
  );

  return (
    <PotentialsRoutingPage
      potentials={potentials}
      error={error}
      privacyFallback={
        <>
          Momentan sind {municipality?.organizationsUsersCount} von {municipality?.organizationsSize} MitarbeiterInnen
          in den Unternehmen dieser Gemeinde registriert. Ab zwei registrierten PendlerInnen zeigen wir Ihnen hier den
          Status und die Potenziale der Arbeitsweg-Mobilität in allen Unternehmen dieser Gemeinde.
        </>
      }
      printHeading={`Potenziale | Gemeinde: ${municipality?.name}`}
    >
      {(setOpenDialog) => (
        <HelpPopUp>
          Hier sehen Sie die Kennwerte über alle registrierten MitarbeiterInnen in der Gemeinde.
          <Box component="span" sx={{ displayPrint: 'none' }}>
            {' '}
            Mehr Infos zur Berechnung{' '}
            <ButtonLink sx={{ textDecoration: 'underline' }} onClick={() => setOpenDialog(true)}>
              hier
            </ButtonLink>
            .
          </Box>{' '}
          Momentan sind {municipality?.organizationsUsersCount} von {municipality?.organizationsSize} MitarbeiterInnen
          registriert, davon konnte für {potentials?.potentialUsersCount} PendlerInnen erfolgreich eine Route berechnet
          werden. Der obere Balken zeigt Ihnen an, welcher Prozentsatz der PendlerInnen zum Zeitpunkt der Registrierung
          den jeweiligen Mobilitätsmodus nutzt. Der untere Balken gibt an, für wie viele dieser Modus die empfohlene
          gesundheitseffektive Option ist. Aus dieser Übersicht können Sie einen Überblick über den Status und die
          Potenziale der Arbeitsweg-Mobilität in der Gemeinde gewinnen.
        </HelpPopUp>
      )}
    </PotentialsRoutingPage>
  );
};

export default MunicipalityPotentialsRoutingPage;
