/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Polyline = {
    readonly crd?: Array<number>;
    name?: string;
    delta: boolean;
    dim?: number;
    type?: Polyline.type;
    crdEncYX?: string;
    crdEncZ?: string;
    crdEncS?: string;
};

export namespace Polyline {

    export enum type {
        WGS_84 = 'WGS_84',
        PLANAR = 'PLANAR',
        HAFASGEO = 'HAFASGEO',
    }


}

