import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QueryKey } from 'react-query/types/core/types';
import { Organization, OrganizationsService } from '../../services/api';
import QueryKeys from '../../services/QueryKeys';

type ConsultingStatusMutateProps<T extends string> = {
  organization: Organization;
  searchQueryKey: QueryKey;
  label: string;
  name: keyof Organization;
  i18nKey: string;
  options: T[];
};

const ConsultingStatusMutate = <T extends string>({
  organization,
  searchQueryKey,
  label,
  name,
  i18nKey,
  options,
}: ConsultingStatusMutateProps<T>) => {
  const { t } = useTranslation();
  const { id } = organization;

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (newValue: T) => OrganizationsService.update({ id, requestBody: { [name]: newValue } }),
    {
      onSuccess: (updatedOrganization) => {
        queryClient.setQueryData(QueryKeys.organizations.id(id), updatedOrganization);
        queryClient.setQueryData<Organization[]>(searchQueryKey, (organizations = []) =>
          organizations.map((organizationItem) =>
            organizationItem.id === updatedOrganization.id ? updatedOrganization : organizationItem,
          ),
        );
      },
    },
  );

  return (
    <TextField
      variant="outlined"
      value={organization[name]}
      select
      title={label}
      inputProps={{ 'aria-label': label }}
      onChange={(event) => mutate(event.target.value as T)}
      sx={{ width: 200 }}
      size="small"
    >
      {options.map((value) => (
        <MenuItem key={value} value={value}>
          {t(`${i18nKey}.${value}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ConsultingStatusMutate;
