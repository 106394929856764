import React from 'react';
import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import Audience from '../../types/Audience';
import NavigationWrapper from './NavigationWrapper';
import ActNowLogo from '../ActNowLogo';
import NavigationLinks from './NavigationLinks';
import RouteFactory from '../../services/RouteFactory';

const PublicNavigation = () => {
  const navigate = useNavigate();
  const { params: { audience: audienceSlug = '', action = '' } = {} } = useMatch('/:audience/:action/*') || {};

  const audience = RouteFactory.slugToAudience(audienceSlug);
  const exactAudience = RouteFactory.isExactAudienceSlug(audienceSlug);

  return (
    <NavigationWrapper sections={3}>
      <Box>
        <FormControl>
          <Select
            size="small"
            value={exactAudience ? audience : 'CHOOSE'}
            title="Zielgruppe"
            onChange={(event) => {
              const newAudience = event.target.value as Audience;

              if (exactAudience) {
                if (newAudience !== audience) {
                  navigate(`/${RouteFactory.audienceToSlug(newAudience)}/${action}`);
                }
              } else {
                navigate(RouteFactory.audienceRoot(newAudience));
              }
            }}
          >
            {!exactAudience && <MenuItem value="CHOOSE">Auswählen</MenuItem>}
            <MenuItem value={Audience.COMMUTER}>Für PendlerInnen</MenuItem>
            <MenuItem value={Audience.ORGANIZATION}>Für Unternehmen</MenuItem>
            {audience === Audience.CONSULTING && <MenuItem value={Audience.CONSULTING}>Für BeraterInnen</MenuItem>}
          </Select>
        </FormControl>
      </Box>

      <Box textAlign="center">
        <Link to={exactAudience ? RouteFactory.audienceRoot(audience) : '/'} style={{ display: 'inline-block' }}>
          <ActNowLogo />
        </Link>
      </Box>

      {exactAudience && (
        <NavigationLinks
          links={[{ label: 'Registrieren', to: `/${RouteFactory.audienceToSlug(audience)}/registrieren/1` }]}
          after={
            <Button color="primary" component={Link} to={`/${RouteFactory.audienceToSlug(audience)}/login`}>
              Login
            </Button>
          }
        />
      )}
    </NavigationWrapper>
  );
};

export default PublicNavigation;
