import React, { ReactNode } from 'react';
import PageContainer from '../../../components/PageContainer';
import { PotentialsCarpoolingDto } from '../../../services/api';
import PotentialsCarpooling from './PotentialsCarpooling';

type PotentialsCarpoolingPageProps = {
  potentials?: PotentialsCarpoolingDto;
  distanceCircles: boolean;
  children?: ReactNode;
  printHeading: string;
};

const PotentialsCarpoolingPage = ({
  potentials,
  distanceCircles,
  children,
  printHeading,
}: PotentialsCarpoolingPageProps) => (
  <PageContainer sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
    <PotentialsCarpooling potentials={potentials} distanceCircles={distanceCircles} printHeading={printHeading} />

    {children}
  </PageContainer>
);

export default PotentialsCarpoolingPage;
