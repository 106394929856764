import React from 'react';
import DialogContainer, { DialogContainerProps } from './DialogContainer';
import DialogTypography from './DialogTypography';

type CarpoolingDialogProps = Omit<DialogContainerProps, 'children'>;

const CarpoolingDialog = ({ open, onClose }: CarpoolingDialogProps) => (
  <DialogContainer open={open} onClose={onClose}>
    <DialogTypography fontWeight="bold">Fahrgemeinschaft</DialogTypography>
    <DialogTypography>
      Fahrgemeinschaften sparen Kosten, Zeit und Emissionen. Wir unterstützen Sie gerne dabei, passende Personen in
      Ihrem Umkreis zu finden.
    </DialogTypography>{' '}
    <DialogTypography>
      Wenn Sie bei der Registrierung ein Interesse an Fahrgemeinschaften bekunden, suchen wir für Sie nach NutzerInnen
      des ActNow-Tools mit potentiell ähnlichen Arbeitswegen. Ein Match kommt nur zustande, wenn beide das entsprechende
      Häkchen bei der Registrierung gesetzt haben und wenn mindestens eines der folgenden räumlichen Kriterien zutrifft:
    </DialogTypography>
    <DialogTypography component="ul">
      <DialogTypography component="li">
        Sie wohnen beide in derselben Gemeinde und pendeln in dieselbe Ortschaft.
        <DialogTypography fontStyle="italic" paddingLeft={12} marginTop={2}>
          Beispiel für ein Match: Dominique und Alex wohnen beide in Bad Ischl und pendeln täglich nach Fuschl am See.
        </DialogTypography>
      </DialogTypography>

      <DialogTypography component="li">
        Sie wohnen beide in derselben Gemeinde und Ihre Arbeitsorte befinden sich innerhalb einer Distanz von fünf
        Kilometer zueinander.
        <DialogTypography fontStyle="italic" paddingLeft={12} marginTop={2}>
          Beispiel für ein Match: René und Daniele wohnen beide in Breitenfurt. René arbeitet in Steyr und Daniele im
          kleinen Nachbarort Dippel. Die Arbeitsstätte von René ist weniger als 5 Kilometer Luftlinie von Danieles
          Arbeitsstätte entfernt.
        </DialogTypography>
      </DialogTypography>

      <DialogTypography component="li">
        Sie arbeiten beide in derselben Gemeinde und die Wohnorte liegen innerhalb einer Distanz von fünf Kilometer
        zueinander.
        <DialogTypography fontStyle="italic" paddingLeft={12} marginTop={2}>
          Beispiel für ein Match: Maxime und Michele arbeiten beide in Sankt Pölten und wohnen in unterschiedlichen
          Umlandgemeinden. Ihre Wohnorte sind aber weniger als fünf Kilometer Luftlinie voneinander entfernt.
        </DialogTypography>
      </DialogTypography>
    </DialogTypography>
    <DialogTypography fontWeight="bold">Kinderbegleitwege</DialogTypography>
    <DialogTypography>
      Unter Kinderbegleitwegen werden jene (Um-)Wege auf dem Arbeitsweg verstanden, die notwendig sind, um Kinder bei
      Betreuungsstätten vorbeizubringen. Wir können Sie dabei unterstützen, diese Umwege und den damit verbundenen Zeit-
      und Kostenaufwand zu reduzieren. Wenn Sie Interesse daran haben, sich Ihren Kinderbegleitweg mit einer oder mit
      mehreren Personen zu teilen, setzen Sie bei der Registrierung ein entsprechendes Häkchen.
    </DialogTypography>
    <DialogTypography>
      Ein Match ergibt sich, sobald eine im ActNow-Tool registrierte Person mit einem Kinderbegleitweg im räumlichen
      Umfeld wohnt (innerhalb von 2,5 Kilometer zur eigenen Wohnadresse) und wenn sich die jeweiligen
      Kinderbetreuungsstätten in direkter Nähe zueinander befinden (im Umkreis von 500m).
    </DialogTypography>
    <DialogTypography fontStyle="italic" paddingLeft={12}>
      Beispiel für ein Match: Arin macht auf seinem Arbeitsweg jeden Tag einen Umweg, da er sein Kind in den
      Kindergarten bringt. In seinem Umfeld, nur 10 Minuten von ihm entfernt, wohnt Luca. Täglich vor der Arbeit
      begleitet sie ihr Kind in die Volksschule, die direkt neben dem Kindergarten liegt. Arin und Luca werden per
      E-Mail über das Match informiert.
    </DialogTypography>
    <DialogTypography fontWeight="bold">Anmerkung</DialogTypography>
    <DialogTypography>
      Das Matching und die daraus resultierenden Personenvorschläge beruhen ausschließlich auf dem Abgleich zwischen
      räumlichen Informationen von Wohn- und Arbeitsort bzw. Standort der Kinderbetreuungsstätte. Da wir im ActNow-Tool
      bewusst darauf verzichten, persönliche Informationen von unseren NutzerInnen abzufragen, bieten wir Ihnen die
      Möglichkeit, sich mit den Matches in Verbindung zu setzen und selbst zu entscheiden, ob es „passt“. Schließlich
      erfolgt die Auswahl der Personen, die für eine Fahrgemeinschaft bzw. für das Begleiten der Kinder zu den
      Betreuungseinrichtungen in Frage kommen, auf Basis von subjektiver Einschätzung (Sympathie, Vertrauen,
      Verlässlichkeit etc.).
    </DialogTypography>
  </DialogContainer>
);

export default CarpoolingDialog;
