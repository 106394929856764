import React, { ReactNode } from 'react';
import { Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export type DialogContainerProps = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
};

const DialogContainer = ({ children, open, onClose }: DialogContainerProps) => (
  <Dialog open={open} maxWidth="xl" onClose={onClose}>
    <DialogContent sx={{ padding: 12 }}>
      <IconButton
        title="Dialog schließen"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(8),
          top: (theme) => theme.spacing(8),
          backgroundColor: 'common.white',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContentText component="div">{children}</DialogContentText>
    </DialogContent>
  </Dialog>
);

export default DialogContainer;
