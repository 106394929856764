import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, styled, css } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import transientOptions from '../../utils/transient-options';

const StyledTextField = styled(
  TextField,
  transientOptions,
)<{ $monospace: boolean }>(
  ({ theme, $monospace }) =>
    css`
      .MuiFilledInput-root {
        border: 2px solid ${theme.palette.common.white};
        background-color: ${theme.palette.secondary.main};

        &::after {
          content: none;
        }
      }

      ${$monospace &&
      css`
        .MuiInputBase-input {
          font-family: 'Monaco', 'Consolas', monospace;
        }
      `}
    `,
);

type CopyTextFieldProps = { label: string; value?: string; multiline?: boolean; monospace?: boolean };

const CopyTextField = ({ label, value = '', multiline = false, monospace = false }: CopyTextFieldProps) => {
  const [copyStatus, setCopyStatus] = useState<'copy' | 'success' | 'error'>('copy');

  return (
    <StyledTextField
      $monospace={monospace}
      label={label}
      value={value}
      multiline={multiline}
      onFocusCapture={(event) => {
        if (multiline) {
          event.currentTarget.querySelector('textarea')?.select();
        } else {
          event.currentTarget.querySelector('input')?.select();
        }
      }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              title={`${label} kopieren`}
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(value);
                  setCopyStatus('success');
                } catch {
                  setCopyStatus('error');
                }
                setTimeout(() => {
                  setCopyStatus('copy');
                }, 5000);
              }}
            >
              {
                {
                  copy: <CopyIcon />,
                  success: <CheckIcon />,
                  error: <ErrorIcon />,
                }[copyStatus]
              }
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default CopyTextField;
