import React, { ReactNode, useState } from 'react';
import { Box, Button } from '@mui/material';
import RemoveDialog from './RemoveDialog';

type RemoveButtonProps = {
  entityLabel: string;
  children: ReactNode;
  onRemove: () => void;
};

const RemoveButton = ({ entityLabel, children, onRemove }: RemoveButtonProps) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const title = `${entityLabel} löschen`;

  return (
    <Box textAlign="center" sx={{ marginTop: 8 }}>
      <Button color="error" onClick={() => setRemoveDialogOpen(true)}>
        {title}
      </Button>
      <RemoveDialog
        title={title}
        open={removeDialogOpen}
        onCancel={() => setRemoveDialogOpen(false)}
        onSubmit={onRemove}
      >
        {children}
      </RemoveDialog>
    </Box>
  );
};

export default RemoveButton;
