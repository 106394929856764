import React from 'react';
import { css, Grid, styled, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PotentialsDataChart from './PotentialsDataChart';
import IconContainer from './IconContainer';
import StyledTableCellComponent from '../../../components/table/StyledTableCell';
import StyledTableCellGroupComponent from '../../../components/table/StyledTableCellGroup';
import StyledTableCellFooter from '../../../components/table/StyledTableCellFooter';
import StyledHeading from '../../../components/table/StyledTableCellHeading';
import {
  HealthEffectType,
  HealthyRoutingUseCase,
  PotentialShareDto,
  PotentialsRoutingDto,
} from '../../../services/api';
import HealthyRoutingUseCaseService from '../../../services/HealthyRoutingUseCaseService';
import transientOptions from '../../../utils/transient-options';
import HealthEffectsHelper from '../../../services/HealthEffectsHelper';
import Format from '../../../services/Format';
import PrintHeading from '../../../components/PrintHeading';
import PotentialsFootnote1 from './PotentialsFootnote1';
import PotentialsFootnote2 from './PotentialsFootnote2';
import PotentialsFootnote3 from './PotentialsFootnote3';

const StyledTableRow = styled(TableRow)(
  () => css`
    white-space: nowrap;
  `,
);

const StyledTableCell = styled(
  StyledTableCellComponent,
  transientOptions,
)<{ $border?: boolean }>(
  ({ theme, $border }) => css`
    border-bottom: none;

    @media screen {
      border-right: ${$border ? `${theme.palette.common.white} solid 2px` : 'none'};

      .MuiTableBody-root & {
        background-color: ${theme.palette.secondary.main};
      }
    }

    @media print {
      border-right: ${$border ? `${theme.palette.text.primary} solid 2px` : 'none'};
      padding-top: ${theme.spacing(0.5)};
      padding-bottom: ${theme.spacing(0.5)};
    }
  `,
);

const StyledTableCellHeading = styled(
  StyledHeading,
  transientOptions,
)<{ $border?: boolean }>(
  ({ theme, $border }) => css`
    font-size: 0.75rem;

    ${theme.breakpoints.up('md')} {
      width: ${theme.spacing(12)};
    }

    @media screen {
      border-right: ${$border ? `${theme.palette.common.white} solid 2px` : 'none'};
    }

    @media print {
      border-right: ${$border ? `${theme.palette.text.primary} solid 2px` : 'none'};
      padding-top: ${theme.spacing(0)};
      padding-bottom: ${theme.spacing(0.25)};
      font-size: 0.5rem;
    }
  `,
);

const StyledTableCellSum = styled(StyledTableCell)(
  ({ theme }) => css`
    font-weight: bold;

    @media screen {
      font-size: 1.25rem;
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};
    }
  `,
);

const StyledTableCellGroup = styled(
  StyledTableCellGroupComponent,
  transientOptions,
)<{ $border?: boolean }>(
  ({ theme, $border }) => css`
    @media screen {
      border-right: ${$border ? `${theme.palette.common.white} solid 2px` : 'none'};
    }

    @media print {
      border-right: ${$border ? `${theme.palette.text.primary} solid 2px` : 'none'};
    }
  `,
);

type PotentialsTableProps = { potentials: PotentialsRoutingDto; printHeading: string };

const PotentialsTable = ({ potentials, printHeading }: PotentialsTableProps) => {
  const { t } = useTranslation();
  const MiscIcon = HealthyRoutingUseCaseService.getIcon(HealthyRoutingUseCase.MISC);

  return (
    <>
      <PrintHeading>{printHeading}</PrintHeading>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCellGroup $border colSpan={2}>
                Übersicht PendlerInnen
              </StyledTableCellGroup>
              <StyledTableCellGroup $border colSpan={5}>
                Route
              </StyledTableCellGroup>
              <StyledTableCellGroup colSpan={4}>Veränderung Gesundheitsrisiko</StyledTableCellGroup>
            </TableRow>
            <TableRow sx={{ fontSize: '0.75rem' }}>
              <StyledTableCellHeading>Mobilitäts&shy;modus</StyledTableCellHeading>
              <StyledTableCellHeading $border sx={{ textAlign: 'left' }}>
                Anteil zur Registrierung <br />
                Anteil Routenpotenzial
              </StyledTableCellHeading>
              <StyledTableCellHeading>∅ einfache Reisezeit</StyledTableCellHeading>
              <StyledTableCellHeading>
                ∑ Erzeugtes CO<sub>2</sub>
                <sup>1</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading>
                CO<sub>2</sub>-Ersparnis beim Wechsel vom Auto<sup>2</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading>
                CO<sub>2</sub>-Kostenersparnis beim Wechsel vom Auto<sup>2</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading $border>
                ∅ Anteil an wöchentlicher Bewegungs&shy;empfehlung<sup>3</sup>
              </StyledTableCellHeading>

              <StyledTableCellHeading>
                ∅ Darmkrebs<sup>1</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading>
                ∅ Diabetes<sup>1</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading>
                ∅ Herz&shy;erkrankung<sup>1</sup>
              </StyledTableCellHeading>
              <StyledTableCellHeading>
                ∅ Schlaganfall&shy;risiko<sup>1</sup>
              </StyledTableCellHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {potentials.useCasePotentials
              .filter(({ useCase }) => useCase !== HealthyRoutingUseCase.MISC)
              .map((potential) => {
                const { useCase } = potential;
                const Icon = HealthyRoutingUseCaseService.getIcon(useCase);
                const averageDuration = dayjs.duration(potential.averageDuration);
                const hideValues = potential.share.recommended.absolute < 1;

                return (
                  <StyledTableRow key={useCase}>
                    <StyledTableCell component="th" scope="row">
                      <IconContainer
                        icon={<Icon title={t(`HealthyRoutingUseCase.${useCase}`)} />}
                        substring={t(`HealthyRoutingUseCaseDistance.${useCase}`)}
                      />
                    </StyledTableCell>
                    <StyledTableCell $border>
                      <PotentialsDataChart share={potential.share} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {Format.undefinedFallback(
                        averageDuration,
                        (value) => Format.duration(value),
                        hideValues || averageDuration.asMilliseconds() < 1,
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Format.undefinedFallback(potential.routeHealthEffects.co2.ton, Format.ton, hideValues)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Format.undefinedFallback(potential.co2Reduction.ton, Format.ton, hideValues)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Format.undefinedFallback(potential.co2Reduction.euro, Format.euro, hideValues)}
                    </StyledTableCell>
                    <StyledTableCell $border>
                      {Format.undefinedFallback(
                        potential.routeHealthEffects.metQuotaWeekly.percent,
                        Format.percentRounded,
                        hideValues,
                      )}
                    </StyledTableCell>

                    {Object.values(HealthEffectType).map((type) => (
                      <StyledTableCell key={type}>
                        {HealthEffectsHelper.formatRiskChange(potential.routeHealthEffects, type, hideValues)}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                );
              })}

            <StyledTableRow>
              <StyledTableCell>
                <IconContainer
                  icon={<MiscIcon title={t(`HealthyRoutingUseCase.${HealthyRoutingUseCase.MISC}`)} />}
                  substring={t(`HealthyRoutingUseCaseDistance.${HealthyRoutingUseCase.MISC}`)}
                />
              </StyledTableCell>
              <StyledTableCell>
                <PotentialsDataChart
                  onlyCommon
                  share={
                    potentials.useCasePotentials.find(({ useCase }) => useCase === HealthyRoutingUseCase.MISC)
                      ?.share as PotentialShareDto
                  }
                />
              </StyledTableCell>

              <StyledTableCell colSpan={5} />

              <StyledTableCellFooter
                sx={{ displayPrint: 'none', whiteSpace: 'normal', background: 'transparent' }}
                rowSpan={2}
                colSpan={4}
              >
                <PotentialsFootnote1 />
                <br />
                <PotentialsFootnote2 />
                <br />
                <PotentialsFootnote3 />
              </StyledTableCellFooter>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCellSum component="th" scope="row">
                ∑
              </StyledTableCellSum>
              <StyledTableCellSum>
                {potentials.potentialUsersCount.toLocaleString('de-AT')} PendlerIn
                {potentials.potentialUsersCount === 1 ? '' : 'nen'}
              </StyledTableCellSum>
              <StyledTableCell />
              <StyledTableCellSum>{Format.undefinedFallback(potentials.sumCo2.ton, Format.ton)}</StyledTableCellSum>
              <StyledTableCellSum>
                {Format.undefinedFallback(potentials.sumCo2Reduction.ton, Format.ton)}
              </StyledTableCellSum>
              <StyledTableCellSum>
                {Format.undefinedFallback(potentials.sumCo2Reduction.euro, Format.euro)}
              </StyledTableCellSum>
            </StyledTableRow>
          </TableBody>
          <TableFooter sx={{ display: 'none', displayPrint: 'table-row-group' }}>
            <TableRow>
              <StyledTableCellFooter colSpan={11}>
                <Grid container columnSpacing={2}>
                  <Grid item sm={3}>
                    <PotentialsFootnote1 />
                  </Grid>
                  <Grid item sm={6}>
                    <PotentialsFootnote2 />
                  </Grid>
                  <Grid item sm={3}>
                    <PotentialsFootnote3 />
                  </Grid>
                </Grid>
              </StyledTableCellFooter>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default PotentialsTable;
