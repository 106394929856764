/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Municipality } from '../models/Municipality';
import type { PotentialsCarpoolingDto } from '../models/PotentialsCarpoolingDto';
import type { PotentialsRoutingDto } from '../models/PotentialsRoutingDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MunicipalitiesService {

    /**
     * @returns Municipality
     * @throws ApiError
     */
    public static findAll({
        search,
    }: {
        search?: string,
    }): CancelablePromise<Array<Municipality>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/municipalities',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @returns Municipality
     * @throws ApiError
     */
    public static findOne({
        iso,
    }: {
        iso: number,
    }): CancelablePromise<Municipality> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/municipalities/{iso}',
            path: {
                'iso': iso,
            },
        });
    }

    /**
     * @returns PotentialsRoutingDto
     * @throws ApiError
     */
    public static getPotentialsRouting({
        iso,
    }: {
        iso: number,
    }): CancelablePromise<PotentialsRoutingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/municipalities/{iso}/potentials/routing',
            path: {
                'iso': iso,
            },
        });
    }

    /**
     * @returns PotentialsCarpoolingDto
     * @throws ApiError
     */
    public static getPotentialsCarpooling({
        iso,
    }: {
        iso: number,
    }): CancelablePromise<PotentialsCarpoolingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/municipalities/{iso}/potentials/carpooling',
            path: {
                'iso': iso,
            },
        });
    }

}
