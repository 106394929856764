/* eslint-disable no-console */

import { AuthenticationService } from '../services/api';

const initDownload = (content: any, fileName: string) => {
  const a = document.createElement('a');
  const file = new Blob([JSON.stringify(content, undefined, 2)], { type: 'application/json' });
  a.href = URL.createObjectURL(file);
  a.download = `${fileName}.json`;
  a.click();
};

// @ts-ignore
window.downloadRoutes = async () => {
  try {
    const user = await AuthenticationService.getAuthenticatedUser();
    const fileName = `${user.id}_${user.routesTimestamp}_${user.routesRequest?.serviceRequest?.requestPayload?.tripRequest?.origin?.[0]?.locationRef?.locationName?.text}_${user.routesRequest?.serviceRequest?.requestPayload?.tripRequest?.destination?.[0]?.locationRef?.locationName?.text}`;

    initDownload(user.routesRequest, `${fileName}_request`);
    initDownload(user.routes, `${fileName}_response`);

    console.log('Download initialized');
  } catch (error) {
    console.error('Something went wrong');
    console.error(error);
  }
};
