import React from 'react';
import { useQuery } from 'react-query';
import QueryKeys from '../services/QueryKeys';
import { OrganizationsService } from '../services/api';
import HelpPopUp from '../components/HelpPopUp';
import PotentialsCarpoolingPage from '../common/potentials/carpooling/PotentialsCarpoolingPage';
import useQueryOrganization from '../hooks/queries/useQueryOrganization';

const OrganizationPotentialsCarpoolingPage = () => {
  const { organizationId, data: organization } = useQueryOrganization();

  const { data: potentials } = useQuery(QueryKeys.organizations.potentialsCarpooling(organizationId as string), () =>
    OrganizationsService.getPotentialsCarpooling({ id: organizationId as string }),
  );

  return (
    <PotentialsCarpoolingPage
      potentials={potentials}
      distanceCircles
      printHeading={`Fahrgemeinschaften | Unternehmen: ${organization?.name}`}
    >
      <HelpPopUp>
        Hier sehen Sie eine Übersicht über die Fahrgemeinschaftspotenziale in Ihrem Unternehmen. Spannend, woher Ihre
        PendlerInnen täglich einreisen oder? Nutzen Sie eine der vorgeschlagenen Maßnahmen und sprechen Sie mit Ihrer
        Beraterin/Ihrem Berater, um Fahrgemeinschaften zu fördern. Aus Datenschutzgründen werden die Wohnstandorte nur
        angezeigt, wenn mindestens fünf PendlerInnen in einer Gemeinde wohnen.
      </HelpPopUp>
    </PotentialsCarpoolingPage>
  );
};

export default OrganizationPotentialsCarpoolingPage;
