import React from 'react';
import { Box } from '@mui/material';
import PageContainer from '../components/PageContainer';
import useQueryAuthenticatedUser from '../hooks/queries/useQueryUser';

import ProfileForm from './ProfileForm';

const ProfilePage = () => {
  const { data: user } = useQueryAuthenticatedUser();

  return (
    <PageContainer maxWidth="md">
      <Box sx={{ width: '100%' }}>{user && <ProfileForm user={user} />}</Box>
    </PageContainer>
  );
};

export default ProfilePage;
