import React from 'react';
import { Box, Button } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

type SubmitButtonProps = { label: string; isSuccess: boolean };

const SubmitButton = ({ label, isSuccess }: SubmitButtonProps) => (
  <Box sx={{ marginTop: 5, textAlign: 'center' }}>
    <Button endIcon={isSuccess && <CheckIcon />} type="submit" variant="contained" size="large">
      {label}
    </Button>
  </Box>
);

export default SubmitButton;
