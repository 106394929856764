import { styled, css } from '@mui/material';
import StyledTableCell from './StyledTableCell';

const StyledTableCellGroup = styled(StyledTableCell)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.text.primary};

    @media screen {
      padding-bottom: ${theme.spacing(4)};
    }
  `,
);

export default StyledTableCellGroup;
