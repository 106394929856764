/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Berater
 */
export enum Consultant {
    NOT_ASSIGNED = 'NOT_ASSIGNED',
    IBG = 'IBG',
    KOMOBILE = 'KOMOBILE',
}
