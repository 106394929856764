import { styled, css } from '@mui/material';
import StyledTableCell from './StyledTableCell';

const StyledTableCellFooter = styled(StyledTableCell)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    text-align: left;
    vertical-align: text-top;
    font-size: 0.75rem;
    line-height: 1.2;
    border: none;
  `,
);

export default StyledTableCellFooter;
