import React from 'react';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Consultant } from '../services/api';

type ConsultantLinkProps = { consultant: Consultant };

const ConsultantLink = ({ consultant }: ConsultantLinkProps) => {
  const { t } = useTranslation();

  return consultant === Consultant.NOT_ASSIGNED ? null : (
    <Link
      href={
        {
          [Consultant.IBG]: 'https://www.ibg.at/',
          [Consultant.KOMOBILE]: 'https://www.komobile.at/',
        }[consultant]
      }
      target="_blank"
      rel="noopener"
    >
      {t(`Consultant.${consultant}`)}
    </Link>
  );
};

export default ConsultantLink;
