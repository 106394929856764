import React, { useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import OrganizationsTable from '../organizations/OrganizationsTable';
import SubmitButton from '../../components/forms/SubmitButton';
import QueryKeys from '../../services/QueryKeys';
import {
  Aggregation,
  AggregationsService,
  CreateAggregationDto,
  Organization,
  OrganizationsService,
  UpdateAggregationDto,
} from '../../services/api';
import { ReactComponent as IconClose } from '../../assets/icons/close.svg';
import RemoveButton from '../../components/forms/RemoveButton';

type FormData = CreateAggregationDto & UpdateAggregationDto & { organizations: Organization[] };

type AggregationFormProps = {
  aggregation?: Aggregation;
};

const AggregationForm = ({ aggregation }: AggregationFormProps) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    ({ organizations, ...formData }: FormData) => {
      const requestBody = { ...formData, organizationIds: organizations.map(({ id }) => id) };

      if (aggregation) {
        return AggregationsService.update({ id: aggregation.id as string, requestBody });
      }

      return AggregationsService.create({ requestBody });
    },
    {
      onSuccess: async (updatedAggregation) => {
        queryClient.setQueryData(QueryKeys.aggregations.id(updatedAggregation.id), updatedAggregation);
        await queryClient.invalidateQueries(QueryKeys.aggregations.potentialsRouting(updatedAggregation.id));
        await queryClient.resetQueries(QueryKeys.aggregations.potentialsCarpooling(updatedAggregation.id));

        if (!aggregation) {
          navigate(`../${updatedAggregation.id}`);
        }
      },
    },
  );

  const { mutate: mutateRemove } = useMutation(() => AggregationsService.remove({ id: aggregation?.id as string }), {
    onSuccess: async () => {
      await queryClient.removeQueries(QueryKeys.aggregations.id(aggregation?.id as string));
      navigate(`..`);
    },
  });

  const { data: organizations = [] } = useQuery(QueryKeys.organizations.all, () => OrganizationsService.findAll({}));

  const { register, handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      name: aggregation?.name,
      organizations: aggregation?.organizations,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'organizations',
    keyName: 'key',
  });

  const [inputValue, setInputValue] = useState('');
  const organizationsIds = fields.map(({ id }) => id);

  return (
    <form onSubmit={handleSubmit((data) => mutate(data))}>
      <Typography variant="h2" fontSize="1rem" marginBottom={3}>
        Aggregierte Ansicht
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label="Name" {...register('name', { required: true })} />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            noOptionsText={!inputValue ? 'Keine weiteren Unternehmen vorhanden' : 'Kein Unternehmen gefunden'}
            value={undefined}
            options={organizations.filter(({ id }) => !organizationsIds.includes(id))}
            getOptionLabel={({ name, address }) => `${name} ${address}`}
            onChange={(event, value) => {
              if (value) {
                append(value);
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              if (reason === 'reset') {
                setInputValue('');
              } else {
                setInputValue(value);
              }
            }}
            renderOption={(props, organization) => (
              <li {...props}>
                <Box>
                  <Box>{organization.name}</Box>
                  <Typography component="span" color="text.secondary" fontSize="0.75rem" display="block">
                    {organization.address}
                  </Typography>
                </Box>
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Unternehmen hinzufügen" />}
          />
        </Grid>
      </Grid>

      <Typography variant="h2" fontSize="1rem" marginTop={3} marginBottom={3}>
        Unternehmen
      </Typography>
      <OrganizationsTable
        organizations={fields}
        renderActions={(organization, index) => (
          <Box display="flex" alignItems="center">
            <Button
              startIcon={<IconClose />}
              onClick={() => remove(index)}
              sx={{
                marginLeft: 1,
              }}
            >
              Entfernen
            </Button>
          </Box>
        )}
      />

      <SubmitButton label="Speichern" isSuccess={isSuccess} />

      {aggregation && (
        <RemoveButton entityLabel="Aggregierte Ansicht" onRemove={mutateRemove}>
          Wollen Sie die aggregierte Ansicht <strong>{aggregation.name}</strong> wirklich löschen?
        </RemoveButton>
      )}
    </form>
  );
};

export default AggregationForm;
