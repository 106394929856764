/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { PublicCarpoolDto } from '../models/PublicCarpoolDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns User
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateUserDto,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateUserDto,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static retryFetchRoutes({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{id}/retry-fetch-routes',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PublicCarpoolDto
     * @throws ApiError
     */
    public static getCarpools({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<PublicCarpoolDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{id}/carpools',
            path: {
                'id': id,
            },
        });
    }

}
