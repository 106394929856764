/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingInfoStructure } from './BookingInfoStructure';
import type { Duration } from './Duration';
import type { FaresAuthorityRefStructure } from './FaresAuthorityRefStructure';
import type { FareZoneListInAreaStructure } from './FareZoneListInAreaStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { TravellerCardRefStructure } from './TravellerCardRefStructure';
import type { WebLinkStructure } from './WebLinkStructure';

export type TicketStructure = {
    bookingInfo?: Array<BookingInfoStructure>;
    currency?: string;
    extension?: any;
    faresAuthorityRef?: FaresAuthorityRefStructure;
    faresAuthorityText?: string;
    infoUrl?: Array<WebLinkStructure>;
    netPrice?: number;
    price?: number;
    requiredCard?: Array<TravellerCardRefStructure>;
    saleUrl?: Array<WebLinkStructure>;
    ticketId?: string;
    ticketName?: string;
    travelClass?: TicketStructure.travelClass;
    validFor?: Array<'ADULT' | 'CHILD' | 'SENIOR' | 'YOUTH' | 'DISABLED'>;
    validityAreaText?: InternationalTextStructure;
    validityDuration?: Duration;
    validityDurationText?: InternationalTextStructure;
    validityFareZones?: Array<FareZoneListInAreaStructure>;
    vatRate?: TicketStructure.vatRate;
};

export namespace TicketStructure {

    export enum travelClass {
        ALL = 'ALL',
        FIRST = 'FIRST',
        SECOND = 'SECOND',
        THIRD = 'THIRD',
        BUSINESS = 'BUSINESS',
        ECONOMY = 'ECONOMY',
    }

    export enum vatRate {
        NO = 'NO',
        FULL = 'FULL',
        HALF = 'HALF',
        MIXED = 'MIXED',
        UNKNOWN = 'UNKNOWN',
    }


}

