import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import QueryKeys from '../../services/QueryKeys';
import PageContainer from '../../components/PageContainer';
import { MunicipalitiesService } from '../../services/api/services/MunicipalitiesService';
import MunicipalitiesRow from './MunicipalitiesRow';
import MunicipalitiesFilter from './MunicipalitiesFilter';

const MunicipalitiesPage = () => {
  const [search, setSearch] = useState('');

  const { data: municipalities = [] } = useQuery(
    QueryKeys.municipalities.search(search),
    () => MunicipalitiesService.findAll({ search }),
    { keepPreviousData: true },
  );

  return (
    <PageContainer>
      <MunicipalitiesFilter onChange={(searchValue: string) => setSearch(searchValue)} />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gemeinde</TableCell>
              <TableCell align="right" sx={{ width: 0 }}>
                Unternehmen
              </TableCell>
              <TableCell align="right" sx={{ width: 0 }}>
                MitarbeiterInnen
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {municipalities.map((municipality) => (
              <MunicipalitiesRow key={municipality.iso} municipality={municipality} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default MunicipalitiesPage;
