import React, { ReactNode, useEffect, useState } from 'react';
import { IconButton, Box, Fade, styled, css, Stack } from '@mui/material';
import { ReactComponent as HelpIcon } from '../assets/icons/help.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import transientOptions from '../utils/transient-options';

const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{ $position: 'static' | 'absolute'; $size: 'small' | 'medium' }>(
  ({ theme, $position, $size }) => css`
    @media print {
      display: none;
    }

    ${$position === 'absolute' &&
    css`
      ${theme.breakpoints.up('lg')} {
        position: absolute;
        bottom: ${theme.spacing(1)};
        left: ${theme.spacing(1)};
      }
    `}

    ${$size === 'small' &&
    css`
      top: ${theme.spacing(-0.25)};

      svg {
        width: ${theme.spacing(3)};
        height: ${theme.spacing(3)};
      }
    `}
  `,
);

const StyledBox = styled(
  Box,
  transientOptions,
)<{ $position: 'absolute' | 'fixed' }>(
  ({ theme, $position }) => css`
    background-color: ${theme.palette.primary.main};
    padding: ${theme.spacing(2)};
    position: ${$position};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    @media print {
      display: none;
    }
  `,
);

type HelpPopUpProps = {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children: ReactNode;
  buttonPosition?: 'static' | 'absolute';
  buttonSize?: 'small' | 'medium';
  dialogPosition?: 'absolute' | 'fixed';
};

const HelpPopUp = ({
  open: externalOpen,
  onOpen,
  onClose,
  children,
  buttonPosition = 'absolute',
  buttonSize = 'medium',
  dialogPosition = 'absolute',
}: HelpPopUpProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (externalOpen !== undefined) {
      setOpen(externalOpen);
    }
  }, [externalOpen]);

  return (
    <>
      <Fade in={!open}>
        <StyledIconButton
          title="Hilfe öffnen"
          $position={buttonPosition}
          $size={buttonSize}
          onClick={() => {
            setOpen(true);

            if (onOpen) {
              onOpen();
            }
          }}
        >
          <HelpIcon />
        </StyledIconButton>
      </Fade>

      <Fade in={open}>
        <StyledBox $position={dialogPosition}>
          <Stack direction="row" spacing={4}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0 }}>
              <HelpIcon />
            </Box>
            <Box>{children}</Box>

            <Box marginLeft="auto !important">
              <IconButton
                title="Hilfe schließen"
                onClick={() => {
                  setOpen(false);

                  if (onClose) {
                    onClose();
                  }
                }}
                sx={{ color: 'common.white' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </StyledBox>
      </Fade>

      <Box sx={{ display: 'none', displayPrint: 'block', fontSize: '0.75rem' }}>
        <Box sx={{ float: 'left', marginRight: 1, marginBottom: 1 }}>
          <HelpIcon style={{ display: 'block', width: 24, height: 24 }} />
        </Box>
        {children}
      </Box>
    </>
  );
};

export default HelpPopUp;
