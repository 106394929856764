/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RGBAColorType } from './RGBAColorType';

export type IconType = {
    foregroundColor?: RGBAColorType;
    backgroundColor?: RGBAColorType;
    borderColor?: RGBAColorType;
    res?: string;
    txt?: string;
    txtS?: string;
    style?: IconType.style;
    shape?: IconType.shape;
    shapeRes?: string;
};

export namespace IconType {

    export enum style {
        U = 'U',
        N = 'N',
        B = 'B',
        I = 'I',
        BI = 'BI',
    }

    export enum shape {
        U = 'U',
        R = 'R',
        C = 'C',
        RES = 'RES',
    }


}

