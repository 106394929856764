/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Mode = {
    accessMode?: Mode.accessMode;
    airSubmode?: Mode.airSubmode;
    busSubmode?: Mode.busSubmode;
    coachSubmode?: Mode.coachSubmode;
    metroSubmode?: Mode.metroSubmode;
    railSubmode?: Mode.railSubmode;
    tramSubmode?: Mode.tramSubmode;
    vehicleMode?: Mode.vehicleMode;
    waterSubmode?: Mode.waterSubmode;
};

export namespace Mode {

    export enum accessMode {
        FOOT = 'FOOT',
        BICYCLE = 'BICYCLE',
        CAR = 'CAR',
        TAXI = 'TAXI',
        SHUTTLE = 'SHUTTLE',
    }

    export enum airSubmode {
        PTI_8_0 = 'PTI_8_0',
        LOC_15_0 = 'LOC_15_0',
        UNKNOWN = 'UNKNOWN',
        PTI_8_1 = 'PTI_8_1',
        LOC_15_2 = 'LOC_15_2',
        INTERNATIONAL_FLIGHT = 'INTERNATIONAL_FLIGHT',
        PTI_8_2 = 'PTI_8_2',
        DOMESTIC_FLIGHT = 'DOMESTIC_FLIGHT',
        PTI_8_3 = 'PTI_8_3',
        LOC_15_1 = 'LOC_15_1',
        INTERCONTINENTAL_FLIGHT = 'INTERCONTINENTAL_FLIGHT',
        PTI_8_4 = 'PTI_8_4',
        LOC_15_4 = 'LOC_15_4',
        DOMESTIC_SCHEDULED_FLIGHT = 'DOMESTIC_SCHEDULED_FLIGHT',
        PTI_8_5 = 'PTI_8_5',
        LOC_15_9 = 'LOC_15_9',
        SHUTTLE_FLIGHT = 'SHUTTLE_FLIGHT',
        PTI_8_6 = 'PTI_8_6',
        LOC_15_5 = 'LOC_15_5',
        INTERCONTINENTAL_CHARTER_FLIGHT = 'INTERCONTINENTAL_CHARTER_FLIGHT',
        PTI_8_7 = 'PTI_8_7',
        LOC_15_6 = 'LOC_15_6',
        INTERNATIONAL_CHARTER_FLIGHT = 'INTERNATIONAL_CHARTER_FLIGHT',
        PTI_8_8 = 'PTI_8_8',
        ROUND_TRIP_CHARTER_FLIGHT = 'ROUND_TRIP_CHARTER_FLIGHT',
        PTI_8_9 = 'PTI_8_9',
        LOC_15_8 = 'LOC_15_8',
        SIGHTSEEING_FLIGHT = 'SIGHTSEEING_FLIGHT',
        PTI_8_10 = 'PTI_8_10',
        LOC_15_10 = 'LOC_15_10',
        HELICOPTER_SERVICE = 'HELICOPTER_SERVICE',
        PTI_8_11 = 'PTI_8_11',
        LOC_15_7 = 'LOC_15_7',
        DOMESTIC_CHARTER_FLIGHT = 'DOMESTIC_CHARTER_FLIGHT',
        PTI_8_12 = 'PTI_8_12',
        SCHENGEN_AREA_FLIGHT = 'SCHENGEN_AREA_FLIGHT',
        PTI_8_13 = 'PTI_8_13',
        AIRSHIP_SERVICE = 'AIRSHIP_SERVICE',
        PTI_8_14 = 'PTI_8_14',
        ALL_AIR_SERVICES = 'ALL_AIR_SERVICES',
        LOC_14_3 = 'LOC_14_3',
        SHORT_HAUL_INTERNATIONAL_FLIGHT = 'SHORT_HAUL_INTERNATIONAL_FLIGHT',
        PTI_8_255 = 'PTI_8_255',
        LOC_15_255 = 'LOC_15_255',
        UNDEFINED_AIRCRAFT_SERVICE = 'UNDEFINED_AIRCRAFT_SERVICE',
    }

    export enum busSubmode {
        PTI_5_0 = 'PTI_5_0',
        LOC_10_0 = 'LOC_10_0',
        UNKNOWN = 'UNKNOWN',
        PTI_5_1 = 'PTI_5_1',
        LOC_10_6 = 'LOC_10_6',
        REGIONAL_BUS = 'REGIONAL_BUS',
        PTI_5_2 = 'PTI_5_2',
        LOC_10_1 = 'LOC_10_1',
        EXPRESS_BUS = 'EXPRESS_BUS',
        PTI_5_3 = 'PTI_5_3',
        BUS = 'BUS',
        PTI_5_4 = 'PTI_5_4',
        LOC_10_5 = 'LOC_10_5',
        LOCAL_BUS_SERVICE = 'LOCAL_BUS_SERVICE',
        PTI_5_5 = 'PTI_5_5',
        LOC_10_2 = 'LOC_10_2',
        NIGHT_BUS = 'NIGHT_BUS',
        PTI_5_6 = 'PTI_5_6',
        LOC_10_4 = 'LOC_10_4',
        POST_BUS = 'POST_BUS',
        PTI_5_7 = 'PTI_5_7',
        LOC_10_8 = 'LOC_10_8',
        SPECIAL_NEEDS_BUS = 'SPECIAL_NEEDS_BUS',
        PTI_5_8 = 'PTI_5_8',
        MOBILITY_BUS = 'MOBILITY_BUS',
        PTI_5_9 = 'PTI_5_9',
        MOBILITY_BUS_FOR_REGISTERED_DISABLED = 'MOBILITY_BUS_FOR_REGISTERED_DISABLED',
        PTI_5_10 = 'PTI_5_10',
        LOC_10_9 = 'LOC_10_9',
        SIGHTSEEING_BUS = 'SIGHTSEEING_BUS',
        PTI_5_11 = 'PTI_5_11',
        SHUTTLE_BUS = 'SHUTTLE_BUS',
        PTI_5_12 = 'PTI_5_12',
        LOC_10_7 = 'LOC_10_7',
        SCHOOL_BUS = 'SCHOOL_BUS',
        PTI_5_13 = 'PTI_5_13',
        LOC_10_13 = 'LOC_10_13',
        SCHOOL_AND_PUBLIC_SERVICE_BUS = 'SCHOOL_AND_PUBLIC_SERVICE_BUS',
        PTI_5_14 = 'PTI_5_14',
        RAIL_REPLACEMENT_BUS = 'RAIL_REPLACEMENT_BUS',
        PTI_5_15 = 'PTI_5_15',
        DEMAND_AND_RESPONSE_BUS = 'DEMAND_AND_RESPONSE_BUS',
        PTI_5_16 = 'PTI_5_16',
        ALL_BUS_SERVICES = 'ALL_BUS_SERVICES',
        LOC_10 = 'LOC_10',
        AIRPORT_LINK_BUS = 'AIRPORT_LINK_BUS',
        PTI_5_255 = 'PTI_5_255',
        LOC_10_255 = 'LOC_10_255',
        UNDEFINED = 'UNDEFINED',
    }

    export enum coachSubmode {
        PTI_3_0 = 'PTI_3_0',
        UNKNOWN = 'UNKNOWN',
        PTI_3_1 = 'PTI_3_1',
        INTERNATIONAL_COACH_SERVICE = 'INTERNATIONAL_COACH_SERVICE',
        PTI_3_2 = 'PTI_3_2',
        NATIONAL_COACH_SERVICE = 'NATIONAL_COACH_SERVICE',
        PTI_3_3 = 'PTI_3_3',
        SHUTTLE_COACH_SERVICE = 'SHUTTLE_COACH_SERVICE',
        PTI_3_4 = 'PTI_3_4',
        REGIONAL_COACH_SERVICE = 'REGIONAL_COACH_SERVICE',
        PTI_3_5 = 'PTI_3_5',
        SPECIAL_COACH_SERVICE = 'SPECIAL_COACH_SERVICE',
        PTI_3_6 = 'PTI_3_6',
        SIGHTSEEING_COACH_SERVICE = 'SIGHTSEEING_COACH_SERVICE',
        PTI_3_7 = 'PTI_3_7',
        TOURIST_COACH_SERVICE = 'TOURIST_COACH_SERVICE',
        PTI_3_8 = 'PTI_3_8',
        COMMUTER_COACH_SERVICE = 'COMMUTER_COACH_SERVICE',
        PTI_3_9 = 'PTI_3_9',
        ALL_COACH_SERVICES = 'ALL_COACH_SERVICES',
        PTI_3_255 = 'PTI_3_255',
        UNDEFINED = 'UNDEFINED',
    }

    export enum metroSubmode {
        PTI_4_0 = 'PTI_4_0',
        UNKNOWN = 'UNKNOWN',
        PTI_4_1 = 'PTI_4_1',
        METRO = 'METRO',
        PTI_4_2 = 'PTI_4_2',
        TUBE = 'TUBE',
        PTI_4_3 = 'PTI_4_3',
        URBAN_RAILWAY = 'URBAN_RAILWAY',
        PTI_4_4 = 'PTI_4_4',
        ALL_RAIL_SERVICES = 'ALL_RAIL_SERVICES',
        PTI_4_255 = 'PTI_4_255',
        UNDEFINED = 'UNDEFINED',
    }

    export enum railSubmode {
        PTI_2_0 = 'PTI_2_0',
        LOC_13_0 = 'LOC_13_0',
        UNKNOWN = 'UNKNOWN',
        PTI_2_1 = 'PTI_2_1',
        HIGH_SPEED_RAIL_SERVICE = 'HIGH_SPEED_RAIL_SERVICE',
        PTI_2_2 = 'PTI_2_2',
        LOC_13_3 = 'LOC_13_3',
        LONG_DISTANCE_TRAIN = 'LONG_DISTANCE_TRAIN',
        PTI_2_3 = 'PTI_2_3',
        LOC_13_2 = 'LOC_13_2',
        INTER_REGIONAL_RAIL_SERVICE = 'INTER_REGIONAL_RAIL_SERVICE',
        PTI_2_4 = 'PTI_2_4',
        CAR_TRANSPORT_RAIL_SERVICE = 'CAR_TRANSPORT_RAIL_SERVICE',
        PTI_2_5 = 'PTI_2_5',
        SLEEPER_RAIL_SERVICE = 'SLEEPER_RAIL_SERVICE',
        PTI_2_6 = 'PTI_2_6',
        LOC_13_4 = 'LOC_13_4',
        REGIONAL_RAIL = 'REGIONAL_RAIL',
        PTI_2_7 = 'PTI_2_7',
        LOC_13_7 = 'LOC_13_7',
        TOURIST_RAILWAY = 'TOURIST_RAILWAY',
        PTI_2_8 = 'PTI_2_8',
        RAIL_SHUTTLE = 'RAIL_SHUTTLE',
        PTI_2_9 = 'PTI_2_9',
        LOC_13_5 = 'LOC_13_5',
        SUBURBAN_RAILWAY = 'SUBURBAN_RAILWAY',
        PTI_2_10 = 'PTI_2_10',
        REPLACEMENT_RAIL_SERVICE = 'REPLACEMENT_RAIL_SERVICE',
        PTI_2_11 = 'PTI_2_11',
        SPECIAL_TRAIN_SERVICE = 'SPECIAL_TRAIN_SERVICE',
        PTI_2_12 = 'PTI_2_12',
        LORRY_TRANSPORT_RAIL_SERVICE = 'LORRY_TRANSPORT_RAIL_SERVICE',
        PTI_2_13 = 'PTI_2_13',
        ALL_RAIL_SERVICES = 'ALL_RAIL_SERVICES',
        PTI_2_14 = 'PTI_2_14',
        CROSS_COUNTRY_RAIL_SERVICE = 'CROSS_COUNTRY_RAIL_SERVICE',
        PTI_2_15 = 'PTI_2_15',
        VEHICLE_RAIL_TRANSPORT_SERVICE = 'VEHICLE_RAIL_TRANSPORT_SERVICE',
        PTI_2_16 = 'PTI_2_16',
        LOC_13_8 = 'LOC_13_8',
        RACK_AND_PINION_RAILWAY = 'RACK_AND_PINION_RAILWAY',
        PTI_2_17 = 'PTI_2_17',
        ADDITIONAL_TRAIN_SERVICE = 'ADDITIONAL_TRAIN_SERVICE',
        PTI_2_255 = 'PTI_2_255',
        UNDEFINED = 'UNDEFINED',
        LOC_13_6 = 'LOC_13_6',
        LOCAL = 'LOCAL',
        LOC_13_1 = 'LOC_13_1',
        INTERBATIONAL = 'INTERBATIONAL',
    }

    export enum tramSubmode {
        PTI_6_0 = 'PTI_6_0',
        LOC_12_0 = 'LOC_12_0',
        UNKNOWN = 'UNKNOWN',
        PTI_6_1 = 'PTI_6_1',
        LOC_12_1 = 'LOC_12_1',
        CITY_TRAM = 'CITY_TRAM',
        PTI_6_2 = 'PTI_6_2',
        LOCAL_TRAM_SERVICE = 'LOCAL_TRAM_SERVICE',
        PTI_6_3 = 'PTI_6_3',
        REGIONAL_TRAM = 'REGIONAL_TRAM',
        PTI_6_4 = 'PTI_6_4',
        LOC_12_2 = 'LOC_12_2',
        SIGHTSEEING_TRAM = 'SIGHTSEEING_TRAM',
        PTI_6_5 = 'PTI_6_5',
        SHUTTLE_TRAM = 'SHUTTLE_TRAM',
        PTI_6_6 = 'PTI_6_6',
        ALL_TRAM_SERVICES = 'ALL_TRAM_SERVICES',
        PTI_6_255 = 'PTI_6_255',
        LOC_12_255 = 'LOC_12_255',
        UNDEFINED_TRAM_SERVICE = 'UNDEFINED_TRAM_SERVICE',
    }

    export enum vehicleMode {
        PTI_1_0 = 'PTI_1_0',
        UNKNOWN = 'UNKNOWN',
        PTI_1_1 = 'PTI_1_1',
        RAILWAY_SERVICE = 'RAILWAY_SERVICE',
        RAIL = 'RAIL',
        PTI_1_2 = 'PTI_1_2',
        COACH_SERVICE = 'COACH_SERVICE',
        COACH = 'COACH',
        PTI_1_3 = 'PTI_1_3',
        SUBURBAN_RAILWAY_SERVICE = 'SUBURBAN_RAILWAY_SERVICE',
        SUBURBAN_RAIL = 'SUBURBAN_RAIL',
        PTI_1_4 = 'PTI_1_4',
        URBAN_RAILWAY_SERVICE = 'URBAN_RAILWAY_SERVICE',
        URBAN_RAIL = 'URBAN_RAIL',
        PTI_1_5 = 'PTI_1_5',
        METRO_SERVICE = 'METRO_SERVICE',
        METRO = 'METRO',
        PTI_1_6 = 'PTI_1_6',
        UNDERGROUND_SERVICE = 'UNDERGROUND_SERVICE',
        UNDERGROUND = 'UNDERGROUND',
        PTI_1_7 = 'PTI_1_7',
        BUS_SERVICE = 'BUS_SERVICE',
        BUS = 'BUS',
        PTI_1_8 = 'PTI_1_8',
        TROLLEY_BUS_SERVICE = 'TROLLEY_BUS_SERVICE',
        TROLLEY_BUS = 'TROLLEY_BUS',
        PTI_1_9 = 'PTI_1_9',
        TRAM_SERVICE = 'TRAM_SERVICE',
        TRAM = 'TRAM',
        PTI_1_10 = 'PTI_1_10',
        WATER_TRANSPORT_SERVICE = 'WATER_TRANSPORT_SERVICE',
        WATER_TRANSPORT = 'WATER_TRANSPORT',
        PTI_1_11 = 'PTI_1_11',
        AIR_SERVICE = 'AIR_SERVICE',
        AIR = 'AIR',
        PTI_1_12 = 'PTI_1_12',
        FERRY_SERVICE = 'FERRY_SERVICE',
        WATER = 'WATER',
        PTI_1_13 = 'PTI_1_13',
        TELECABIN_SERVICE = 'TELECABIN_SERVICE',
        TELECABIN = 'TELECABIN',
        PTI_1_14 = 'PTI_1_14',
        FUNICULAR_SERVICE = 'FUNICULAR_SERVICE',
        FUNICULAR = 'FUNICULAR',
        PTI_1_15 = 'PTI_1_15',
        TAXI_SERVICE = 'TAXI_SERVICE',
        TAXI = 'TAXI',
        PTI_1_16 = 'PTI_1_16',
        SELF_DRIVE = 'SELF_DRIVE',
        PTI_1_17 = 'PTI_1_17',
        ALL_SERVICES = 'ALL_SERVICES',
        ALL = 'ALL',
        PTI_1_18 = 'PTI_1_18',
        ALL_SERVICES_EXCEPT = 'ALL_SERVICES_EXCEPT',
    }

    export enum waterSubmode {
        PTI_7_0 = 'PTI_7_0',
        UNKNOWN = 'UNKNOWN',
        PTI_7_1 = 'PTI_7_1',
        INTERNATIONAL_CAR_FERRY_SERVICE = 'INTERNATIONAL_CAR_FERRY_SERVICE',
        PTI_7_2 = 'PTI_7_2',
        NATIONAL_CAR_FERRY_SERVICE = 'NATIONAL_CAR_FERRY_SERVICE',
        PTI_7_3 = 'PTI_7_3',
        REGIONAL_CAR_FERRY_SERVICE = 'REGIONAL_CAR_FERRY_SERVICE',
        PTI_7_4 = 'PTI_7_4',
        LOCAL_CAR_FERRY_SERVICE = 'LOCAL_CAR_FERRY_SERVICE',
        PTI_7_5 = 'PTI_7_5',
        INTERNATIONAL_PASSENGER_FERRY = 'INTERNATIONAL_PASSENGER_FERRY',
        PTI_7_6 = 'PTI_7_6',
        NATIONAL_PASSENGER_FERRY = 'NATIONAL_PASSENGER_FERRY',
        PTI_7_7 = 'PTI_7_7',
        REGIONAL_PASSENGER_FERRY = 'REGIONAL_PASSENGER_FERRY',
        PTI_7_8 = 'PTI_7_8',
        LOCAL_PASSENGER_FERRY = 'LOCAL_PASSENGER_FERRY',
        PTI_7_9 = 'PTI_7_9',
        POST_BOAT = 'POST_BOAT',
        PTI_7_10 = 'PTI_7_10',
        TRAIN_FERRY = 'TRAIN_FERRY',
        PTI_7_11 = 'PTI_7_11',
        ROAD_FERRY_LINK = 'ROAD_FERRY_LINK',
        PTI_7_12 = 'PTI_7_12',
        AIRPORT_BOAT_LINK = 'AIRPORT_BOAT_LINK',
        PTI_7_13 = 'PTI_7_13',
        HIGH_SPEED_VEHICLE_SERVICE = 'HIGH_SPEED_VEHICLE_SERVICE',
        PTI_7_14 = 'PTI_7_14',
        HIGH_SPEED_PASSENGER_SERVICE = 'HIGH_SPEED_PASSENGER_SERVICE',
        PTI_7_15 = 'PTI_7_15',
        SIGHTSEEING_SERVICE = 'SIGHTSEEING_SERVICE',
        PTI_7_16 = 'PTI_7_16',
        SCHOOL_BOAT = 'SCHOOL_BOAT',
        PTI_7_17 = 'PTI_7_17',
        CABLE_FERRY = 'CABLE_FERRY',
        PTI_7_18 = 'PTI_7_18',
        RIVER_BUS = 'RIVER_BUS',
        PTI_7_19 = 'PTI_7_19',
        SCHEDULED_FERRY = 'SCHEDULED_FERRY',
        PTI_7_20 = 'PTI_7_20',
        SHUTTLE_FERRY_SERVICE = 'SHUTTLE_FERRY_SERVICE',
        PTI_7_21 = 'PTI_7_21',
        ALL_WATER_TRANSPORT_SERVICES = 'ALL_WATER_TRANSPORT_SERVICES',
        PTI_7_255 = 'PTI_7_255',
        UNDEFINED_WATER_TRANSPORT = 'UNDEFINED_WATER_TRANSPORT',
    }


}

