import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import SearchField from '../../components/forms/SearchField';

type MunicipalitiesFilterProps = { onChange: (search: string) => void };

const MunicipalitiesFilter = ({ onChange }: MunicipalitiesFilterProps) => {
  const methods = useForm<{ search: string }>();
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(({ search: searchValue }) => {
    onChange(searchValue);
  });

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SearchField onChange={() => onSubmit()} />
      </Box>
    </FormProvider>
  );
};

export default MunicipalitiesFilter;
