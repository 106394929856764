import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import QueryKeys from '../../services/QueryKeys';
import { AggregationsService } from '../../services/api';

const useQueryAggregation = () => {
  const { aggregationId } = useParams();

  return {
    aggregationId,
    ...useQuery(QueryKeys.aggregations.id(aggregationId as string), () =>
      AggregationsService.findOne({ id: aggregationId as string }),
    ),
  };
};

export default useQueryAggregation;
