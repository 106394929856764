import React, { useEffect } from 'react';
import { Collapse, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const PasswordForm = () => {
  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const newPasswordValue = watch('newPassword');

  useEffect(() => {
    if (newPasswordValue === '') {
      setValue('oldPassword', '');
    }
  });

  return (
    <>
      <Controller
        defaultValue=""
        name="newPassword"
        rules={{ minLength: 8, validate: (value) => !(value && !getValues('oldPassword')) }}
        render={({ field, fieldState }) => (
          <TextField
            label="Neues Passwort"
            type="password"
            autoComplete="new-password"
            error={!!fieldState.error}
            helperText={(field.value.length || 0) < 8 && 'Mindestens 8 Zeichen'}
            {...field}
          />
        )}
      />

      <Collapse in={!!newPasswordValue}>
        <TextField
          label="Altes Passwort"
          type="password"
          autoComplete="current-password"
          error={!!errors.oldPassword}
          {...register('oldPassword', {
            validate: (value) => !(getValues('newPassword') && !value),
          })}
        />
      </Collapse>
    </>
  );
};

export default PasswordForm;
