import React from 'react';

import { css, styled, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import StyledTableCellGroup from '../../components/table/StyledTableCellGroup';
import StyledTableCellFooter from '../../components/table/StyledTableCellFooter';
import StyledHeading from '../../components/table/StyledTableCellHeading';

import useQueryAuthenticatedUser from '../../hooks/queries/useQueryUser';
import MobilityOptionsRow from './MobilityOptionsRow';
import ButtonLinkSmall from '../../components/ButtonLinkSmall';
import MobilityOptionsErrors from './MobilityOptionsErrors';

const StyledTableCellHeading = styled(StyledHeading)(
  ({ theme }) => css`
    font-size: 0.625rem;

    ${theme.breakpoints.up('lg')} {
      width: ${theme.spacing(11.75)};
    }
  `,
);

const MobilityOptionsTable = () => {
  const { data: user } = useQueryAuthenticatedUser();

  return user ? (
    <>
      {(user.routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.length || 0) > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCellGroup colSpan={4}>Route</StyledTableCellGroup>
                <StyledTableCellGroup colSpan={4}>Veränderung Gesundheitsrisiko</StyledTableCellGroup>
              </TableRow>
              <TableRow>
                <StyledTableCellHeading>Mobilitäts&shy;modus</StyledTableCellHeading>
                <StyledTableCellHeading>Einfache Reisezeit</StyledTableCellHeading>
                <StyledTableCellHeading>
                  Anteil an wöchentlicher Bewegungs&shy;empfehlung<sup>1</sup>
                </StyledTableCellHeading>
                <StyledTableCellHeading>
                  CO<sub>2</sub> Ausstoß pro Jahr<sup>2</sup>
                </StyledTableCellHeading>

                <StyledTableCellHeading>
                  Darmkrebs<sup>2</sup>
                </StyledTableCellHeading>
                <StyledTableCellHeading>
                  Diabetes<sup>2</sup>
                </StyledTableCellHeading>
                <StyledTableCellHeading>
                  Herzerkrankung<sup>2</sup>
                </StyledTableCellHeading>
                <StyledTableCellHeading>
                  Schlaganfall<sup>2</sup>
                </StyledTableCellHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.map((tripResult) => (
                <MobilityOptionsRow
                  key={tripResult.resultId}
                  routesHealthEffects={user.routesHealthEffects || []}
                  tripResult={tripResult}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTableCellFooter colSpan={3}>
                  <sup>1</sup> Gilt für einen Arbeitstag (Hin- und Rückweg) mit jeweiliger Route laut WHO-Empfehlung.
                </StyledTableCellFooter>
                <StyledTableCellFooter colSpan={5}>
                  <sup>2</sup> Wenn Sie an 80% der Arbeitstage im Jahr die jeweilige Route wählen.
                  <br />
                  Basierend auf {user.activeMinutesPerWeek} Minuten körperlicher Aktivität pro Woche. |{' '}
                  <ButtonLinkSmall component={Link} to="/pendlerinnen/profil">
                    ändern
                  </ButtonLinkSmall>
                </StyledTableCellFooter>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      <MobilityOptionsErrors user={user} />
    </>
  ) : null;
};

export default MobilityOptionsTable;
