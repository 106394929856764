/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HealthyRoutingUseCase {
    PEDESTRIAN = 'PEDESTRIAN',
    BICYCLE = 'BICYCLE',
    WALK_PUBLICTRANSPORT_WALK = 'WALK_PUBLICTRANSPORT_WALK',
    BIKE_PUBLICTRANSPORT_BIKE = 'BIKE_PUBLICTRANSPORT_BIKE',
    WALK_PUBLICTRANSPORT_BIKE = 'WALK_PUBLICTRANSPORT_BIKE',
    BIKE_PUBLICTRANSPORT_WALK = 'BIKE_PUBLICTRANSPORT_WALK',
    CAR_PUBLICTRANSPORT_WALK = 'CAR_PUBLICTRANSPORT_WALK',
    CAR = 'CAR',
    MISC = 'MISC',
}
