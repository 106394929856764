import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import QueryKeys from '../../services/QueryKeys';
import { OrganizationsService } from '../../services/api';

const useQueryOrganization = () => {
  const { organizationId } = useParams();
  return {
    organizationId,
    ...useQuery(QueryKeys.organizations.id(organizationId as string), () =>
      OrganizationsService.findOne({ id: organizationId as string }),
    ),
  };
};

export default useQueryOrganization;
