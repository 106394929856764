/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationList } from '../models/LocationList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationNameService {

    /**
     * Location search by name
     * @returns LocationList success
     * @throws ApiError
     */
    public static verb1({
        input,
        requestId,
        format,
        jsonpCallback,
        lang = 'de',
        maxNo = 10,
        type = 'ALL',
        locationSelectionMode,
        products,
        coordLat,
        coordLong,
        r = 1000,
        refineId,
        meta,
        stations,
        sattributes,
        sinfotexts,
        filterMode = 'DIST_PERI',
    }: {
        /**
         * Search for that token. String not longer than 512.
         *
         * Since version: 1.0
         */
        input: string,
        /**
         * Request ID for identifying the request.
         */
        requestId?: string,
        /**
         * Requested response format. If not set, the Accept-Header is used. If both are missing application/xml is used
         */
        format?: 'xml' | 'json' | 'jsonp',
        /**
         * Requests the JSON response data is wrapped into a JavaScript function with that name.
         */
        jsonpCallback?: string,
        /**
         * The language of the journey planer.
         */
        lang?: string,
        /**
         * Maximum number of returned stops. Range: 1-1000
         *
         * Since version: 1.0
         */
        maxNo?: number,
        /**
         * Type filter for location types.
         *
         * Since version: 1.20
         */
        type?: 'A' | 'ALL' | 'AP' | 'P' | 'S' | 'SA' | 'SP',
        /**
         * Selection mode for locations.
         *
         * Since version: 1.20
         */
        locationSelectionMode?: 'SLCT_A' | 'SLCT_N',
        /**
         * Decimal value defining the product classes to be included in the search. It represents a bitmask combining bit number of a product as defined in the HAFAS raw data. Values are retrievable by <<service_datainfo,Data Information service>>.
         *
         * Since version: 1.15
         */
        products?: number,
        /**
         * Latitude of centre coordinate.
         *
         * Since version: 1.20
         */
        coordLat?: number,
        /**
         * Longitude of centre coordinate.
         *
         * Since version: 1.20
         */
        coordLong?: number,
        /**
         * Search radius in meter around the given coordinate if any.
         *
         * Since version: 1.20
         */
        r?: number,
        /**
         * In case of an refinable location, this value takes the ID of the refinable one of a previous result. String not longer than 512.
         *
         * Since version: 1.0
         */
        refineId?: string,
        /**
         * Filter by a predefined meta filter. If the rules of the predefined filter should not be negated, put ! in front of it. String not longer than 512.
         *
         * Since version: 1.0
         */
        meta?: string,
        /**
         * Filter for stations. Matches if the given value is prefix of any station ID. Multiple values are separated by comma. String not longer than 4096.
         *
         * Since version: 1.0
         */
        stations?: string,
        /**
         * Filter locations by one or more attribute codes. Multiple attribute codes are separated by comma. If the attribute should not be part of the be location data, negate it by putting ! in front of it. String not longer than 1024.
         *
         * Since version: 1.0
         */
        sattributes?: string,
        /**
         * Filter locations by one or more station infotext codes and values. Multiple attribute codes are separated by comma the value by pipe |. String not longer than 1024.
         *
         * Since version: 2.24
         */
        sinfotexts?: string,
        /**
         * Filter modes for nearby searches.
         *
         * Since version: 2.7
         */
        filterMode?: 'DIST_PERI' | 'EXCL_PERI' | 'SLCT_PERI',
    }): CancelablePromise<LocationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location.name',
            query: {
                'requestId': requestId,
                'format': format,
                'jsonpCallback': jsonpCallback,
                'lang': lang,
                'input': input,
                'maxNo': maxNo,
                'type': type,
                'locationSelectionMode': locationSelectionMode,
                'products': products,
                'coordLat': coordLat,
                'coordLong': coordLong,
                'r': r,
                'refineId': refineId,
                'meta': meta,
                'stations': stations,
                'sattributes': sattributes,
                'sinfotexts': sinfotexts,
                'filterMode': filterMode,
            },
        });
    }

}
