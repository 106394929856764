import MeasuresCategory from '../types/MeasuresCategory';
import MeasuresEffect from '../types/MeasuresEffect';

const measures = {
  items: [
    {
      id: 1,
      title: 'Persönliche Beratung und digitale Kommunikation',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.MOTIVATION,
      summary:
        'Durch persönliche Beratung den Umstieg auf aktive Mobilität erleichtern und die Umsetzung mithilfe digitaler Reminder unterstützen.',
      paragraphs: [
        'Bei einem persönlichen Beratungsgespräch können Mitarbeitende über einen Umstieg auf das Fahrrad als tägliches Verkehrsmittel informiert und bei der Planung unterstützt werden. Die Beratung kann durch Mitarbeitende im BGM oder „Fahrradbeauftragte“ erfolgen. Verhaltensänderungen haben die größten Erfolgschancen, wenn sie mit Lebensveränderungen zusammenfallen wie z.B. dem Wechsel des Arbeitsplatzes. Von daher sollten Unternehmen neue Mitarbeitende direkt auf das ActNow-Tool aufmerksam machen und ein Beratungsgespräch anbieten.',
        'Während sich ein persönliches Gespräch vor allem als Auftakt eignet, können Mitarbeitende bei der Aufrechterhaltung neuer Mobilitätsgewohnheiten auf digitalem Wege unterstützt werden. Erinnerungen, positives Feedback, Tipps z.B. zum Radfahren bei Schlechtwetter oder zur Etablierung neuer Routinen (z.B. Fahrradhelm in der Nähe der Wohnungstür) können z.B. über einen personalisierten Newsletter verbreitet werden.',
      ],
      conditions:
        'Das Unternehmen muss über die Personalkapazitäten (zeitlich, inhaltlich) für eine persönliche Beratung und für die Aktualisierung der digitalen Kanäle verfügen.',

      associatedMeasures: undefined,
      support: undefined,
      keywords: ['Persönliche Beratung', 'neue Mitarbeitende', 'digitale Kommunikation'],
    },
    {
      id: 2,
      title: 'Finanzieller Bonus bei Fahrradnutzung',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Wer seinen Arbeitsweg mit dem Fahrrad zurücklegt, bekommt einen finanziellen Bonus – entweder als Barleistung oder als Gutschein.',
      paragraphs: [
        'Obwohl Fahrradfahren sowohl umweltfreundlich als auch gesundheitsfördernd ist, reicht das Wissen darüber oft nicht aus, um das eigene Mobilitätsverhalten zu ändern. Das liegt u.a. daran, dass z.B. der gegenwärtige Komfort des Autofahrens stärker gewichtet wird als die langfristigen positiven Effekte für Gesundheit und Umwelt. Indem Unternehmen finanzielle Incentives einsetzen, kann dieser Mechanismus bei MitarbeiterInnen ausgehebelt werden. In Österreich und im europäischen Ausland gibt es bereits Beispiele für diese Art der Unterstützung:',
      ],
      listParagraphs: [
        'Fa. Anton Paar GmbH (Graz/Ö): Finanzieller Bonus bei Fahrradnutzung über 1,70€ pro Tag. Kontrolle erfolgt mit Hilfe der „Ökotaste“ beim Einstempeln im Eingangsbereich des Unternehmens. Gutschrift erfolgt automatisch aufs Gehaltskonto (bis zu 357.- Euro im Jahr „erradeln“).',
        'Ikea Wels (Ö): MitarbeiterInnen bekommen Gutscheine bei Zurücklegen des Arbeitsweges mittels Fahrrad oder zu Fuß (10 € für 10 Wege). ',
        'Acato (NL): Kopplung finanzieller Anreizzahlungen an Nutzungshäufigkeit und Erreichung vereinbarter Ziele, Tracking z.B. über Apps wie „ByCycling“.',
      ],
      conditions:
        'Die finanziellen Anreize müssen mit den relevanten StakeholderInnen innerhalb des Unternehmens, v.a. im Hinblick auf die Finanzierbarkeit, abgestimmt werden. Im Unternehmen müssen die technischen Möglichkeiten zur (elektronischen) Erfassung von Arbeitswegen per Fahrrad vorhanden sein (Nachweis, Fairness).',
      associatedMeasures:
        'Finanzielle Incentives müssen von entsprechenden Kommunikationsmaßnahmen begleitet werden. Firmeninterne Wettbewerbe (Gamification) können den Anreiz noch verstärken (siehe auch: Radfahr-Wettbewerb). ',
      support: undefined,
      keywords: [
        'Finanzielle Incentives',
        'Anfahrt als Teil der Arbeitszeit',
        'Fahrrad-Leasing',
        'Verlosung',
        'Stempelpass',
      ],
    },
    {
      id: 3,
      title: 'Radfahr-Wettbewerb',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INCENTIVES,
      summary: 'Das Gemeinschaftsgefühl und die Selbstverpflichtung durch Wettbewerb führen zu mehr Fahrradmobilität.',
      paragraphs: [
        'Bei Teamwettbewerben, in denen Gruppen innerhalb eines Betriebs gegeneinander antreten, wirkt das Anreizsystem über das Gemeinschaftsgefühl und Belohnungen in Form von Spaß, sozialen Kontakten, sozialem Status, oder Preisen und Gutscheine. Positiver „Peer Pressure“ durch die Gruppenzugehörigkeit und die anfängliche Selbstverpflichtung motivieren zu mehr Fahrradmobilität. Alternativ könnte auch ein individueller Wettbewerb stattfinden. Die GewinnerInnen des Wettbewerbs erhalten soziale Anerkennung durch eine Form der Bekanntmachung oder werden mit Geld- und Sachprämien belohnt. Die ArbeitgeberInnen signalisieren damit, dass ihnen die Gesundheit ihrer MitarbeiterInnen wichtig ist.',
      ],
      conditions:
        'Für Unternehmen bietet sich die Teilnahme an bereits existierenden Fahrradwettbewerben in Österreich (z.B. die Aktion „Österreich Radelt“) an.',
      associatedMeasures:
        'Der Wettbewerb muss entsprechend beworben und der Zeitraum mit einer Auftakt- und Endveranstaltung klar definiert werden. Folgeaktionen oder zusätzliche Anreize verstärken den Effekt, damit neue Mobilitätsgewohnheiten auch nach Ende des Wettbewerbs beibehalten werden.',
      support: undefined,
      keywords: [
        'Wettbewerb',
        'Gemeinschaftsgefühl',
        'Selbstverpflichtung',
        'Anerkennung',
        'Fahrradchallenge',
        'Fun-Faktor',
      ],
    },

    {
      id: 4,
      title: 'Werbung gezielt einsetzen',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.CULTURE,
      summary: 'Werbung und Events helfen, eine Fahrradkultur im Unternehmen zu etablieren.',
      paragraphs: [
        'Durch Werbung in diversen Formaten soll die Option des Radfahrens zur Arbeit bewusstgemacht und Vorteile aufgezeigt werden, um eine neue Mobilitätskultur im Unternehmen zu etablieren. Dabei sollten t Spaß, Naturerlebnisse, körperliches Wohlergehen und Kosteneinsparungen im Vordergrund stehen und klar kommuniziert werden. Auch können z.B. Vor- und Nachteile von Auto & Rad nebeneinander im Vergleich präsentiert werden. Fahrradfahren kann dabei mit positiven Emotionen und positiv konnotierten Begriffen wie Luft, Sonne, Flexibilität, Freiheit etc. verknüpft werden. Bei Werbung in Form von Plakaten spielt die Auswahl des Ortes eine Rolle. Orte, die sich besonders gut eignen, das Fahrrad zu bewerben, sind jene, an denen man sich über das Auto ärgert. Auf dem Betriebsgelände sind das bspw. überfüllte Parkplätze oder Stauorte z.B. vor der Einfahrtsschranke zum Parkhaus. Zusätzlich könnten digitale Kanäle des Unternehmens eingesetzt werden. Alternativ können Betriebsveranstaltungen wie Feiern, Betriebsausflüge mit Fahrrädern oder zusätzliche Events wie z.B. Fahrrad-Aktionstage als Werbe-Plattform eingesetzt werden.',
      ],
      conditions:
        'Die Maßnahmen müssen mit dem Öffentlichkeitsauftritt bzw. der unternehmensinternen Kommunikation mit den Mitarbeitenden abgestimmt werden.',
      associatedMeasures: undefined,
      support: undefined,
      keywords: ['Werbung', 'Werbekampagne', 'Kommunikation', 'Events'],
    },
    {
      id: 5,
      title: 'Betriebliche Leihfahrräder',
      effects: [MeasuresEffect.BYCICLE, MeasuresEffect.PUBLIC_TRANSPORT],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Mit Firmen-Leihfahrrädern können Wege zwischen unterschiedlichen Betriebsstandorten oder zwischen dem Unternehmen und der ÖV-Haltestelle effektiver zurückgelegt werden.',
      paragraphs: [
        'Durch die Bereitstellung von Firmen-Leihfahrrädern können Wege zwischen unterschiedlichen Betriebsstandorten oder zwischen dem Unternehmen und der ÖV-Haltestelle (Fahrrad als Zubringer-Verkehrsmittel) effektiver zurückgelegt werden. Dadurch können MIV-Wege eingespart bzw. der Anreiz den ÖV für den Arbeitsweg zu nutzen erhöht werden (v.a. dann, wenn die nächste ÖV-Haltestelle in nicht-fußläufiger Entfernung zum Unternehmen liegt.',
      ],
      conditions:
        'Sichere (überdachte) Abstellmöglichkeiten der Fahrräder z.B. bei der ÖV-Haltestelle bzw. die Möglichkeit, dass man das Fahrrad auch an der Haltestelle stehen lassen bzw. ausleihen kann.',
      associatedMeasures: 'Jobticket, um den Umstieg auf den ÖV noch attraktiver zu machen.',
      support: undefined,
      keywords: ['Leihfahrrad', 'ÖV-Nutzung', 'Erreichbarkeit', 'ÖV-Haltestelle'],
    },
    {
      id: 6,
      title: 'Fahrradinfrastruktur im Betrieb',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Eine gute Fahrradinfrastruktur am Betriebsstandort dient als Voraussetzung, Arbeitswege vermehrt mit dem Fahrrad zurückzulegen.',
      paragraphs: [
        'Durch die Bereitstellung von Fahrradinfrastruktur im Unternehmen werden MitarbeiterInnen motiviert, mit dem Fahrrad in die Arbeit zu fahren. Zur Fahrradinfrastruktur zählen sichere (überdachte) Fahrradabstellplätze, Fahrradwerkstatt (Fahrradpumpe, Werkzeug, Ersatzschläuche etc.), aber auch Garderoben (ev. auch Duschen).',
      ],
      conditions:
        'Platzangebot am Unternehmensgelände, zur Verfügung stellen von Räumen (für Werkstatt bzw. Garderoben).',
      associatedMeasures:
        'Eventuell vorhandene Firmenparkplätze in Fahrradabstellplätze umwidmen bzw. generell die Parkmöglichkeiten für den MIV reduzieren.',
      support: undefined,
      keywords: ['Fahrradinfrastruktur', 'Fahrradabstellanlagen', 'Fahrradwerkstatt'],
    },
    {
      id: 7,
      title: 'Bepreisung der firmeneigenen Parkplätze ',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Firmeneigene Parkplätze sollten den MitarbeiterInnen nicht gratis zur Verfügung gestellt werden, um den Umstieg vom MIV auf umweltfreundliche Verkehrsmittel zu fördern.',
      paragraphs: [
        'Firmeneigene Parkplätze sollten den MitarbeiterInnen nicht gratis zur Verfügung gestellt werden, um den Umstieg vom MIV auf umweltfreundliche Verkehrsmittel zu fördern. Das Unternehmen könnte im Gegenzug Flächen für Parkplätze reduzieren und für andere Zwecke (Fahrradinfrastruktur, Sporteinrichtung etc.) nutzen.',
        'Es könnte auch eine Staffelung der Parkkosten je nach Entfernung zum Wohnort und Verfügbarkeit von ÖV geben, z.B. MitarbeiterInnen, für die es keine andere Möglichkeit gibt, als mit dem Auto in die Arbeit zu kommen, werden bei der Parkplatzvergabe bevorzugt, jene, die in der Nähe wohnen, haben keinen Anspruch auf einen Parkplatz oder nur zu einem sehr hohen Preis.',
      ],
      conditions:
        'MitarbeiterInnen müssen die Möglichkeit haben, mit alternativen Verkehrsmitteln an den Arbeitsort zu gelangen (Frage des Standorts des Unternehmens).',
      associatedMeasures:
        'Wohnortanalyse der MitarbeiterInnen; MitarbeiterInnen müssen klar informiert werden; freiwerdenden Flächen sollten allen MitarbeiterInnen zugutekommen (Nutzungskonzept).',
      support: undefined,
      keywords: ['Firmeneigene Parkplätze', 'Restriktionen', 'Nutzungskonzept'],
    },
    {
      id: 8,
      title: 'Absperrbare Abstellanlagen',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Sichere, überdachte Abstellanlagen erhöhen die Attraktivität, hochpreisige Fahrräder nicht nur in der Freizeit, sondern auch für den Arbeitsweg nutzen.',
      paragraphs: [
        'Für Freizeitfahrten angeschaffte Fahrräder (Rennrad, Mountain-Bike, o.ä.) können sehr hochpreisig und damit sehr wertvoll sein. Im Unterschied zum Arbeitsalltag, werden Fahrräder bei Freizeitfahrten selten unbeaufsichtigt im öffentlichen Raum verwahrt. Im Alltag sind die BesitzerInnen mit der Herausforderung konfrontiert, einen sicheren, versperrbaren und überdachten Abstellplatz zu finden.',
        'Die Mitnahme des Fahrrads in die Büroräumlichkeiten ist keine adäquate, dauerhafte Lösung für die sichere Verwahrung des Fahrrads während der Arbeitszeit.',
        'Die Errichtung und (kostenlose) Bereitstellung verschließbarer Abstellräume oder -boxen erhöht für jene, meist bereits sehr sportlichen, Personen die Attraktivität enorm, das Fahrrad auch für den Arbeitsweg zu nutzen.',
      ],
      conditions:
        'Abstellanlage muss zentral, leicht erreichbar und sowohl vom öffentlichen Raum als auch den Büroräumlichkeiten einfach zugänglich sein.',
      associatedMeasures: 'PKW-Parkplätze reduzieren, Mobilitätsgarantie bei Schlechtwetter, Duschen, Umkleide, Spinde',
      support: 'Kommunalkredit (KPC): Nachrüstung zum Fahrradparken',
      keywords: ['Infrastruktur', 'Abstellanlage', 'Fahrrad'],
    },
    {
      id: 9,
      title: 'Individualisiertes Mobilitätsmarketing',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.MOTIVATION,
      summary:
        'Individuelle Beratung der MitarbeiterInnen zu ihren Mobilitätsalternativen, um sie zum Umstieg vom PKW auf aktive Verkehrsmodi zu motivieren.',
      paragraphs: [
        '(Mobilitäts-)Gewohnheiten helfen Menschen, ihren Alltag einfach und effizient zu meistern, lassen sich allerdings nur durch passende Anreize umstellen und bedürfen einer Dauer von mehreren Wochen, um nachhaltig verändert zu werden.',
        'Die persönliche Beratung der MitarbeiterInnen kann einen wesentlichen Beitrag zur Entwicklung individueller Lösungen leisten. Es werden Anreize gesetzt, das Testen neuer Verhaltensweisen kann im Change Prozess begleitet werden.',
      ],
      conditions: 'Personelle und finanzielle Ressourcen, Beratungskompetenz, Kenntnisse Mobilitätsmanagement.',
      associatedMeasures: 'Incentives, Gamification, Vereinbarungen, Teambuilding, Infrastruktur',
      support: undefined,
      keywords: ['Persönliche Reisepläne', 'Incentives', 'Kompetenzbildung'],
    },
    {
      id: 10,
      title: 'Mobilitäts-Pool',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
      ],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary: 'Pooling von verschiedenen Mobilitätswerkzeugen: Fahrräder, eScooter, E-Fahrzeuge, ÖV-Zeitkarte.',
      paragraphs: [
        'Das Pooling verschiedener Mobilitätswerkzeuge wie (Lasten-)Fahrräder, eScooter, andere E-Fahrzeuge, ÖV-Zeitkarten etc. bietet ein vielfältiges Mobilitätsangebot – passend zu den jeweiligen Anforderungen an den Weg, aber auch zum Ausprobieren neuer Mobilitätsformen, die bisher nicht genutzt wurden.',
        'Ein Mobilitäts-Pool bietet eine tatsächliche Alternative zum privaten oder individuell zugeordneten Firmen-PKW und kann in Form eines Verleihs für Dienstwege oder für Arbeitsweg nach Hause angeboten werden. Die Bereitstellung von ÖV-Zeitkarten dient in Ergänzung zum Fahrzeugpool als Mobilitätsgarantie.',
        'In Kooperation mit lokalen Wirtschaftspartnern kann ein gemischter Mobilitäts-Pool geleast oder über Mobilitätsdienstleister (Expertise Mobilitätsmanagement) angeboten werden.',
      ],
      conditions:
        'Zusätzliches Zubehör wie Helme, Fahrradtasche, Laptop-Rucksack als Teil des Verleih-Systems oder in Kooperation mit lokalen Wirtschaftspartnern (Angebote für Firmen-Mitarbeitende).',
      associatedMeasures:
        'Starke Kommunikation innerhalb des Betriebes, Restriktionen im Bereich Stellplätze und Pool-PKW ',
      support: undefined,
      keywords: ['Multimodaler Fahrzeugpool', 'Mobilitätsgarantie', 'ÖV'],
    },
    {
      id: 11,
      title: 'Betriebliche Mobilitätsberatung',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.MOTIVATION,
      summary:
        'Betriebliche Mobilitätsberatung beinhaltet die Entwicklung individueller Konzepte für den Pendelverkehr der MitarbeiterInnen von Unternehmen.',
      paragraphs: [
        'Betriebliche Mobilitätsberatung beinhaltet die Entwicklung individueller Konzepte für den Pendelverkehr der MitarbeiterInnen von Unternehmen (Betriebliches Mobilitätsmanagement), innerbetriebliches Parkraummanagement, Optimierung betrieblicher Mobilitätserfordernisse, Fuhrparkoptimierung, Information und Bewusstseinsbildung sowie Motivationskampagnen für MitarbeiterInnen umweltfreundliche Verkehrsmittel zu nutzen (Mobilitätslotto), Errichtung von Radabstellmöglichkeiten, Einführung von kostenlosen ÖV-Testtickets, ÖV-Betriebstickets etc.',
        'Die Mobilitätsberatung wird von geschulten Mobilitätsberatern durchgeführt, die entweder an das Unternehmen im Auftrag einer Gemeinde oder Organisation (Verbund, Verkehrsbetrieb, Mobilitätszentrale etc.) mit dem Vorschlag der betrieblichen Mobilitätsberatung herantreten oder von Unternehmen selbst kontaktiert werden.',
      ],
      conditions:
        'Parkraumbewirtschaftung am Standort bzw. ggf. in Absprache mit z.B. der Gemeinde in der Umgebung des Betriebes.',
      associatedMeasures:
        'Fahrgemeinschaftsförderung durch die öffentliche Hand, gutes ÖV-System, gute ÖV-Erreichbarkeit, auf den Betriebsstandort abgestimmte ÖV-Routen und -Betriebszeiten',
      support: 'Umweltförderung Mobilitätsmanagement',
      keywords: ['Mobilitätsberatung', 'Fahrgemeinschaftsförderung', 'individuelle Mobilitätskonzepte'],
    },
    {
      id: 12,
      title: 'Mobilitätskonsequenztest',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.E_CAR,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Bewusstmachung der Auswirkungen in allen Nachhaltigkeitsdimensionen der Nutzung  PKW im Vergleich alternativer Verkehrsmittel.',
      paragraphs: [
        'Ein Mobilitätskonsequenztest im Internet soll es allen VerkehrsteilnehmerInnen ermöglichen, die jeweiligen Wege in Hinblick auf Konsequenzen in allen möglichen Dimensionen (z.B. ökologisch, ökonomisch, sozial) zu testen und zu vergleichen. In einer Maske wird die Weglänge [km], das benutzte Verkehrsmittel [zu Fuß, Inline-Skates, Fahrrad, ÖV, Auto (Pkw – als FahrerIn, als MitfahrerIn), Motorrad, Taxi], sowie Quelle und Ziel des Weges eingegeben. Der Output kann je nach Programmierung & Konzeption unterschiedlich aussehen.',
        'Interessant wird das Ergebnis im Vergleich mit anderen Verkehrsmitteln. Außerdem sollte es möglich sein, die Veränderungen zu verfolgen, die sich bei theoretischer Änderung der Verkehrsmittelwahl ergeben. Der Mobilitätskonsequenztest ist ein Ansatz zur Bewusstseinsbildung für eine umweltfreundliche Mobilität.',
      ],
      conditions: [
        'Beauftragung von ExpertInnen mit der Konzipierung der Inhalte des Mobilitätskonsequenztests',
        'Gewinnung von PartnerInnen (Mobilitätsvereine, Betreiber einer Mobilitätszentrale, Verkehrsbetriebe, Verkehrsverbünde, Unternehmen etc.) und ggf. Sponsoren für die Umsetzung der Idee',
        'Einrichtung einer Website zum individuellen Mobilitätskonsequenztest im Internet.',
        'Organisation, Planung der Verbreitung',
      ],
      associatedMeasures: 'Sämtliche Begleitmaßnahmen zur Förderung des Umweltverbunds bzw. der aktiven Mobilität.',
      support: undefined,
      keywords: ['Umweltauswirkungen', 'soziale Wirkungen', 'ökonomische Wirkungen', 'Bewusstseinsbildung'],
    },
    {
      id: 13,
      title: 'Mobilitätslotto',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.E_CAR,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
      ],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Mobilitätslotto ist eine spezielle Maßnahme innerhalb eines Unternehmens, um umweltfreundliche Pendelmobilität zu fördern.',
      paragraphs: [
        'Mobilitätslotto ist eine spezielle Maßnahme des betrieblichen Mobilitätsmanagements. Dabei setzt das Unternehmen verschiedene Anreize – konkret werden Preise in Aussicht gestellt – für diejenigen MitarbeiterInnen, die umweltfreundlich zur Arbeit kommen. Zum Beispiel findet wöchentlich die Auslosung eines Arbeitstages statt, in der MitarbeiterInnen belohnt werden, die am Stichtag zu Fuß, mit dem Fahrrad oder öffentlich zur Arbeit gelangt sind. Der „Lottogewinn“ kann eine finanzielle Belohnung, ein Gutschein für ein Gratis-Fahrradservice, eine kostenlose Monatskarte etc. sein.',
      ],
      conditions:
        'Die Umsetzung dieser Maßnahme obliegt der Unternehmensführung. Eventuell können Sponsoren bzw. Partner für die Spende der Sachpreise gewonnen werden.',
      associatedMeasures: 'Sämtliche Begleitmaßnahmen zur Förderung des Umweltverbunds bzw. der aktiven Mobilität.',
      support: undefined,
      keywords: ['Mobilitätslotto', 'Mobilitätsmanagement', 'Anreizsystem', 'Verlosung'],
    },
    {
      id: 14,
      title: 'Mobilitätsplan – Green Mobility Plan',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Verbindliche Festlegung von Strategien zur erfolgreichen Umsetzung von Maßnahmen des Mobilitätsmanagements innerhalb eines Unternehmens.',
      paragraphs: [
        'Der Mobilitätsplan (Green Mobility Plan) ist ein Instrument des standortbezogenen Mobilitätsmanagements. Er betrifft Verkehrserreger wie Groß- und Mittelbetriebe, Schulen, Einkaufszentren, Krankenhäuser etc. und ist ein Plan für die Umsetzung von Mobilitätsmaßnahmen. Er wird z.B. im Zuge von betrieblichem Mobilitätsmanagement mit Unterstützung einer Mobilitätsberaterin oder einem internen Mobilitätskoordinator erstellt. Er beschreibt Umsetzungsstrategien bzw. die Vorgehensweise bei der Einführung von Maßnahmen des Mobilitätsmanagements für einen bestimmten Standort, detailliert die entsprechenden Maßnahmen und legt Ziele (wichtig für eine spätere Bewertung und Fortschreibung des Mobilitätsplans), Verantwortlichkeiten und einen Zeitplan für die Umsetzung fest. Abgesehen von einem standortbezogenen Mobilitätsplan kann letzterer auch für bestimmte NutzerInnengruppen (z.B. Jugendliche, Personen mit Mobilitätseinschränkungen) oder z.B. für ein bestimmtes Stadtviertel erstellt werden.',
      ],
      conditions: [
        'Bekanntmachung, Propagierung und Informationsbereitstellung durch die Gemeinde bzw. Organisationen, die Mobilitätsdienstleistungen anbieten (Mobilitätszentrale, Mobilitätsberater etc.)',
        'Gemeinde als Schnittstelle zwischen Unternehmen, die daran interessiert sind, einen Mobilitätsplan zu erstellen, und Organisationen, die Mobilitätsdienstleistungen anbieten.',
        'Finanzielle Anreize und Unterstützung durch das Land oder die Gemeinde',
        'Generelle Unterstützung durch Politik, Verwaltung etc.',
      ],
      associatedMeasures: 'Sämtliche Begleitmaßnahmen zur Förderung des Umweltverbunds bzw. der aktiven Mobilität.',
      support: 'Umweltförderung Mobilitätsmanagement',
      keywords: ['Mobilitätsplan', 'Mobilitätsmaßnahmen', 'Umsetzung von Maßnahmen'],
    },
    {
      id: 15,
      title: 'Verankerung Betriebliches Mobilitätsmanagement',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
        MeasuresEffect.MIKRO_PUBLIC_TRANSPORT,
      ],
      category: MeasuresCategory.MOTIVATION,
      summary:
        'Im Rahmen eines betrieblichen Mobilitätsmanagements werden im Unternehmen umfassende Maßnahmen und Angebote in einem Gesamtkonzept zusammengeführt um die Beschäftigten beim Umstieg auf nachhaltige Mobilitätsformen zu unterstützen.',
      paragraphs: [
        'In einem Betrieblichen Mobilitätsmanagement sind umfassende Angebote und Maßnahmen zu einem konsistenten Gesamtkonzept zusammengefasst. Angebote können motivierenden Charakter und finanzielle Anreize (z.B. Job Ticket), organisatorische Anreize (Mitfahrpool, individuelle Beratung, Information und Event-Kampagnen) oder infrastrukturelle Angebote (z. B. Optimierung der Anbindung zum nächstgelegenen ÖV- Knotenpunkt, Werksshuttle / Rufsammeltaxi / Gepäcktransport etc. bei Schlechtwetter oder nach Bedarf, Leihräder, Fahrradabstellraum, Fahrradreparaturstätte, E-Ladestation f. E-Räder) zum Thema haben. Darüber hinaus ist durch Restriktionen gegenüber weniger nachhaltigerem Mobilitätsverhalten ein Lenkungseffekt zu erzielen (z.B. Kosten und entfernt liegende Parkplätze). Die Verknüpfung von Mobilitätsmanagement mit Zielen der Gesundheitsförderung kann Synergien heben und zusätzliche Effekte erzielen. Überbetriebliche Vernetzung mit weiteren Betrieben der Region oder mit anderen Sektoren ermöglichen.',
      ],
      conditions: [
        'Abstimmung auf Bedürfnisse der MitarbeiterInnen und der vorhandenen Mobilitäts- und Verkehrsinfrastruktur.',
        'Mobilitätserhebung im Vorfeld',
        'Abstimmung mit regionalen Mobilitätsanbietern und -dienstleistern',
      ],
      associatedMeasures:
        'Das betriebliche Mobilitätsmanagement sollte innerhalb des Unternehmens gut kommuniziert und laufend an (veränderte) Bedürfnisse angepasst und erweitert werden. Der Zusammenschluss mehrere Unternehmen zu einem unternehmensübergreifenden Netzwerk ermöglicht einen flächendeckenderen und dichteren Ausbau der geplanten Maßnahmen.',
      support: undefined,
      keywords: [
        'Betriebliches Mobilitätsmanagement',
        'verpflichtendes Mobilitätsmanagement',
        'Nachhaltige und aktive Mobilität',
        'Mobilitätsverhalten',
      ],
    },
    {
      id: 16,
      title: 'Teleworking / Homeoffice',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.TRANSPORT_AVOIDANCE,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Im Bereich der Pendlermobilität ist Teleworking / Homeoffice über Verkehrsvermeidung die vermutlich effizienteste Maßnahme zur Erreichung von Mobilitäts- und Klimaziele auf nationaler und internationaler Ebene.',
      paragraphs: [
        'Telearbeit / Homeoffice ist das Ausüben von beruflichen Tätigkeiten an einem räumlich von der Beschäftigungsfirma zu einem in oder in der Nähe der Wohnung ausgelagerten Ort, der über Informations- und Kommunikationssystem mit einer Zentrale verbunden ist. Der Arbeitsplatz kann zu Hause, in einem Telearbeitszentrum, Satellitenbüro, aber auch bei Kunden sein (mobile Arbeit). Wichtigstes Kriterium ist die räumliche Trennung von der Zentrale und die Nähe zur Wohnung.',
        'Telearbeit / Homeoffice kann seitens des Unternehmens ermöglicht bzw. gefördert werden – dies umfasst zeitliche, finanzielle bzw. organisatorische Aspekte. Ebenso kann technische Infrastruktur, ggf. sogar räumliche Infrastruktur (Coworking Space) zur Verfügung gestellt werden.',
      ],
      conditions: [
        'Erhöhung der Datensicherheit bei der Datenübertragung',
        'Nutzung steuerlicher Vorteile für Arbeitgeber & Arbeitnehmer',
        'Aufklärung über positive Konsequenzen von Telearbeit / Homeoffice auf allen Ebenen',
      ],
      associatedMeasures: [
        'Arbeit an der Unternehmenskultur in den jeweiligen Unternehmen i.S. Bewusstseinsbildung für diese Maßnahme, offener Diskurs über Vor- und Nachteilt, nötige Rahmenbedingungen etc.',
        'Vertrauensbildende Maßnahmen zwischen Arbeitgeber und Arbeitnehmer',
      ],
      support: undefined,
      keywords: ['Homeoffice', 'Telearbeit', 'Verkehrsvermeidung'],
    },
    {
      id: 17,
      title: 'Autofreier Tag',
      effects: [MeasuresEffect.WALK, MeasuresEffect.BYCICLE, MeasuresEffect.PUBLIC_TRANSPORT],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Nach dem Vorbild von erfolgreichen Einzelinitiativen rief die Europäische Kommission am 22.09.2000 zum ersten „Europaweiten Autofreien Tag“ auf, der seitdem jährlich wiederholt wird. Jede Gemeinde, Stadt oder Region kann daran teilzunehmen – dies kann auch auf Unternehmen übertragen werden.',
      paragraphs: [
        'Im öffentlichen Bereich müssen die Teilnehmenden die von der EU erarbeitete „Europäische Charta zum Europaweiten Autofreien Tag ‚Mobil ohne eigenes Auto!‘“ unterzeichnen. Zur Vereinbarung gehört die Berücksichtigung des gemeinsamen Datums (22. September) und die Sperrung von innerstädtischen Straßenzügen oder Plätzen, ausgewählten Arealen oder des gesamten Stadtgebietes für den Autoverkehr, um diese ausschließlich dem nichtmotorisierten Verkehr, dem öffentlichen Verkehr und alternativ betriebenen Fahrzeugen zur Verfügung zu stellen. Die Maßnahme sollte von entsprechenden Aktionen begleitet werden, um sie bekannt zu machen und die Bürger/innen zum Mitmachen zu motivieren.',
        'Der Ansatz des Autofreien Tags kann leicht auf Unternehmen übertragen werden und in der Frequenz erhöht werden. Z.B. verpflichten sich die Mitarbeitende jeden ersten Freitag im Monat das Auto stehen zu lassen und auf Alternativen umzusteigen.',
      ],
      conditions: [
        'Idealer Weise akkordierte Vorgehensweise zwischen Unternehmen und der öffentlichen Hand / Gemeinden',
        'Verbindliche Vereinbarungen innerhalb eines Unternehmens',
      ],
      associatedMeasures: 'Anreize bei Einhaltung, Belohnung seitens des Unternehmens',
      support: undefined,
      keywords: ['Autofreier Tag', 'Verkehrssparen', 'Aktive Mobilität'],
    },
    {
      id: 18,
      title: 'JobRad',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Für die MitarbeiterInnen von Unternehmen aller Artwerden vom Arbeitgeber Dienstfahrräder für dienstliche aber auch private Fahrten zur Verfügung gestellt.',
      paragraphs: [
        'Die Fahrräder stehen den MitarbeiterInnen kostenlos aber eigenverantwortlich oder in einem Modell mit monatlichen Raten zur Rückzahlung zur Verfügung. Am Arbeitsplatz werden diese Fahrräder an günstigen Stellen (witterungsgeschützt, unmittelbar bei Eingängen, Aufzügen etc.) in Abstellanlagen verwahrt. Eine Aufsicht und Reparaturmöglichkeiten der Fahrräder (Pressluft, Service etc.) sind notwendig. Umkleide- und Duschmöglichkeiten für die Radfahrenden sind günstig. Die Unternehmen können eine Förderung für die (E-) Fahrräder in Anspruch nehmen.',
      ],
      conditions: [
        'Reduktion der betrieblichen Stellplätze vor Ort im Unternehmen',
        'Reduktion der Firmenfahrzeuge (PKW)',
        'Damit das JobRad den gewünschten Effekt erzielt, muss die Steuerbegünstigung (keine USt-pflicht) an die MitarbeiterInnen weitergegeben werden können.',
      ],
      associatedMeasures:
        'MitarbeiterInnen, welche für die Fahrt zur Arbeit vom Kfz auf ein Dienstfahrrad umsteigen, können als Anreiz eine Vergünstigung erhalten. Notwendige Infrastruktur muss vorhanden sein. Das heißt, es muss eine moderne, sichere Fahrradabstellanlage, Duschmöglichkeiten, Ladeinfrastruktur und Kästen, in denen das ggf. feuchte Gewand aufgehängt und getrocknet werden kann, vorhanden sein.',
      support: 'klimaaktiv Jobrad',
      keywords: ['Dienstfahrradaktion', 'Dienstrad', 'Aktive Mobilität'],
    },
    {
      id: 19,
      title: 'ÖV-Betriebsticket („Jobticket“)',
      effects: [MeasuresEffect.WALK, MeasuresEffect.PUBLIC_TRANSPORT],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Unternehmen oder Behörden erwerben zu attraktiven Preisen pauschal für ihre MitarbeiterInnen Zeitkarten für den öffentlichen Verkehr.',
      paragraphs: [
        'Unternehmen oder Behörden erwerben zu attraktiven Preisen pauschal für ihre MitarbeiterInnen Zeitkarten für den öffentlichen Verkehr. Da Betriebstickets zu einem stark reduzierten Preis angeboten werden, würden bei geringen Abnahmemengen Mindereinnahmen zu verzeichnen sein. Um eine wirtschaftliche Verschlechterung zu verhindern, werden alle MitarbeiterInnen einbezogen, wobei nicht von Bedeutung ist, ob die Tickets genutzt werden oder nicht. In welcher Form und Höhe die MitarbeiterInnen für die ÖV-Betriebstickets zu zahlen haben, liegt an den einzelnen Betrieben. Erfahrungsgemäß liegt der Preis eines Betriebstickets bis zu 50% unter dem einer herkömmlichen Monatskarte.',
      ],
      conditions: [
        'Klärung der notwendigen Mindestabnahmemenge durch Verkehrsunternehmen',
        'Klärung der steuerlichen Aspekte',
        'Beauftragung einer Agentur mit der systematischen Beratung der relevanten Großfirmen',
      ],
      associatedMeasures: 'Bewusstseinsbildungsmaßnahmen auf einer breiten Ebene im Unternehmen.',
      support: undefined,
      keywords: ['ÖV-Betriebsticket', 'Jobticket', 'Öffentlicher Verkehr', 'Umweltverbund'],
    },
    {
      id: 20,
      title: 'Innerbetriebliche Fahrgemeinschaften und Vernetzung für aktive Mobilität',
      effects: [MeasuresEffect.CARPOOLING],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary: 'Vermittlung von potenziellen Fahrgemeinschaftsteilnehmern innerhalb eines großen Unternehmens.',
      paragraphs: [
        'Im Zuge eines betrieblichen Mobilitätsmanagements ist die Möglichkeit der Vermittlung von Fahrgemeinschaftsteilnehmern oder der gemeinsam zurückgelegten Pendelstrecke vorzusehen. Aus der Information der Wohnadressen und des Dienstplanes sowie unter Berücksichtigung des durchschnittlichen Wegeverhaltens (z.B. regelmäßige Servicewege in der Früh) wird für jeden Mitarbeiter ein individueller Fahrtenvorschlag, abgestimmt auf eine Fahrgemeinschaft mit anderen Arbeitskollegen, erstellt. Dies kann einerseits händisch anhand von Planunterlagen (Straßenkarten, Fahrpläne etc.) geschehen oder – bei größer Zahl von Mitarbeitern – mittels speziell entwickelter Software. Dazu können vom Unternehmen eigene Online-Plattformen entwickelt oder bereits auf den Markt befindliche Angebote adaptiert werden.  Der Vorteil der innerbetrieblichen Vermittlung besteht darin, dass die Teilnehmer nicht mit „Fremden“ ihr Auto teilen müssen, sondern durch den gemeinsamen Arbeitsplatz einen Bezug zueinander haben und so die Hemmschwelle für eine Teilnahme leichter zu überwinden ist. MitarbeiterInnenvernetzung kann aber auch für gemeinsam aktiv zurückgelegter Arbeitsweg organisiert werden, z.B. gemeinsam zu Fuß gehen oder mit dem Rad fahren. ',
      ],
      conditions: [
        'Einrichtung eines innerbetrieblichen Mobilitätsbüros mit entsprechend eingeschultem Personal und gegebenenfalls Installierung der notwendigen Vermittlungssoftware',
        'Gezielte Information der MitarbeiterInnen',
        'Integration der Maßnahme im Rahmen eines umfassenden betrieblichen Mobilitätsmanagements',
        'Um Mitfahrpooling zu ermöglichen, sollten Arbeitszeiten (Arbeitsbeginn, -ende) möglichst flexibel regelbar oder adaptierbar sein.',
      ],
      associatedMeasures:
        'Reduktion der PKW-Stellplätze im Unternehmen. Begleitend könnten besondere (Belohnungs-)Angebote die Bildung von Mitfahrpools unterstützen und attraktivieren (z.B. kostenloser Parkplatz für Fahrgemeinschaften). Für gemeinsam aktiv zurückgelegte Arbeitswege wäre eine Anerkennung als Arbeitszeit, z.B. „Besprechungszeit“, möglich.',
      support: undefined,
      keywords: ['Innerbetriebliche Fahrgemeinschaften', 'Carpooling', 'MitarbeiterInnen-Vernetzung'],
    },
    {
      id: 21,
      title: 'Shuttlebus',
      effects: [MeasuresEffect.CARPOOLING],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Ein Unternehmen stellt einen Shuttlebus zur Verfügung, holt MitarbeiterInnen von Zuhause ab und bringt sie am Ende des Arbeitstages wieder nach Hause.',
      paragraphs: [
        'Das Unternehmen macht eine Wohnorterhebung der MitarbeiterInnen und stellt eine Route zusammen, um die MitarbeiterInnen zur Arbeit und wieder nachhause bzw. zu fußläufig erreichbaren Haltestellen zu bringen. Die MitarbeiterInnen können sich auf diese Weise im Optimalfall ein Zweit- bzw. Drittauto ersparen.',
      ],
      conditions: 'Die MitarbeiterInnen müssen gewisse Flexibilität bei der Arbeitszeit haben (Arbeitszeitanpassung).',
      associatedMeasures: 'Das Shuttle-Angebot muss kostenlos für die MitarbeiterInnen sein.',
      support: undefined,
      keywords: ['Bus', 'Shuttle', 'Fahrgemeinschaft'],
    },
    {
      id: 22,
      title: 'Carsharing für MitarbeiterInnen',
      effects: [MeasuresEffect.CARPOOLING],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary:
        'Das Unternehmen stellt MitarbeiterInnen ein Firmenfahrzeug für den Arbeitsweg kostenlos zur Verfügung, wenn Fahrgemeinschaften gebildet werden.',
      paragraphs: [
        'Ein Firmenfahrzeug wird den MitarbeiterInnen kostenlos für die Arbeitswege zur Verfügung gestellt, wenn zumindest 4 Personen mit diesem Fahrzeug eine Fahrgemeinschaft bilden.',
      ],
      conditions:
        'Diese Nutzung muss für die MitarbeiterInnen ohne „Lohneinbußen“ (kein Sachkostenbezug usw.) möglich sein. Es dürfen für die Nutzung bei den MitarbeiterInnen keine Kosten anfallen.',
      associatedMeasures: 'Das Angebot muss gut beworben werden.',
      support: undefined,
      keywords: ['Fahrgemeinschaft', 'Mitfahrgelegenheit'],
    },
    {
      id: 23,
      title: 'Radfahrwege zum Unternehmen',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INFRASTRUCTURE,
      summary: 'Errichtung von guten und sicheren Radwegen motivieren die MitarbeiterInnen aufs Fahrrad umzusteigen.',
      paragraphs: ['Das Unternehmen errichtet mit der Gemeinde gemeinsam einen attraktiven Radweg.'],
      conditions: 'Die Gemeinde muss zur Kooperation bereit sein und auch Kosten übernehmen.',
      associatedMeasures: 'Bewerbung des Radweges im Unternehmen.',
      support: 'Umweltförderung für Gemeinden',
      keywords: ['Fahrrad', 'Radweg', 'Gesundheit'],
    },
    {
      id: 24,
      title: 'Ermäßigungen bzw. Zuschuss zu Alternativen zum MIV',
      effects: [
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
      ],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Durch einen zweckgebundenen Zuschuss seitens des Arbeitgebers oder Ermäßigungen, die sich durch eine Nutzung mehrerer Personen ergeben, werden MitarbeiterInnen zur Nutzung von Sharinglösungen, ÖV und weiteren Alternativen zum MIV motiviert.',
      paragraphs: [
        'Um den Anteil der ArbeitnehmerInnen, die nicht mit dem eigenen Auto zur Arbeit kommen, zu erhöhen und einen positiven gesundheitlichen Effekt zu erzielen, kann ein Unternehmen Zuschüsse zu ÖV-Tickets oder spezielle Angebote für Car-/Bike-Sharing bereitstellen. Dadurch könnten auch Parkplätze eingespart werden.',
      ],
      conditions: 'Vor Start der Maßnahme kommen viele ArbeitnehmerInnen mit dem Auto zur Arbeit.',
      associatedMeasures:
        'Ermäßigungen/Zuschüsse werden nur ausgegeben, wenn eine Mindestnutzung garantiert wird oder der Verzicht auf einen Parkplatz erklärt wird.',
      support: undefined,
      keywords: ['Ermäßigung', 'Zuschuss', 'ÖV', 'Carsharing', 'Bikesharing'],
    },
    {
      id: 25,
      title: 'Radlkampagne',
      effects: [MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INCENTIVES,
      summary: 'Erhöhung der Radnutzung für den Weg zur Arbeit durch Bewusstseinsbildung und Motivation.',
      paragraphs: [
        'Durch eigene Radlkampagne im Unternehmen (oder zusammen mit mehreren Unternehmen) werden die MitarbeiterInnen zu einer verstärkten Fahrradnutzung für den Arbeitsweg motiviert. Von einer Verbesserung der Radinfrastruktur am Standort (z.B. Abstellmöglichkeiten), einem Event mit der Möglichkeit sein Rad mit der Hilfe eines Fachmanns zu reparieren, Verteilung von Infomaterial bis hin zu Wettkämpfen, Punktesammeln für gefahrene Kilometer und Eintauschen dieser Punkte gegen Belohnungen (z.B. Gutscheine) etc. ist vieles möglich.',
      ],
      conditions: 'Der Arbeitsweg der MitarbeiterInnen sollte grundsätzlich zur Fahrradnutzung geeignet sein.',
      associatedMeasures: 'Belohnung für gefahrene Kilometer, regelmäßige Events',
      support: undefined,
      keywords: ['Ermäßigung', 'Zuschuss', 'ÖV', 'Carsharing', 'Bikesharing'],
    },
    {
      id: 26,
      title: 'Verzicht auf Parkplatz',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.INCENTIVES,
      summary: 'Für den Verzicht auf einen Parkplatz am Unternehmensstandort werden MitarbeiterInnen belohnt.',
      paragraphs: [
        'Viele Arbeitsstätten bieten ihren MitarbeiterInnen Gratisparkplätze oder Parkplätze gegen geringe Gebühr an, was dazu führt, dass oft das Auto auf dem Weg zur Arbeit benutzt wird. Der Verzicht auf einen Parkplatz am Unternehmensstandort wird deshalb belohnt. Die MitarbeiterInnen haben die Wahl zwischen einem freien Parkplatz und einer Auszahlung der Parkplatzkosten, einem ÖV-Tickets oder Ähnlichem.  Auf diese Weise werden der Bedarf an Parkplätzen gesenkt, Kosten für Flächenmiete und Wartung gespart sowie nachhaltige Fortbewegungsweisen gefördert.',
      ],
      conditions: 'Vor Start der Maßnahme kommen viele MitarbeiterInnen mit dem Auto zur Arbeit.',
      associatedMeasures: 'alle anderen Maßnahmen, die eine Alternative zum eigenen PKW / Parkplatz bieten',
      support: undefined,
      keywords: ['Parkplatzverzicht'],
    },
    {
      id: 27,
      title: 'Gamification-App',
      effects: [MeasuresEffect.WALK, MeasuresEffect.BYCICLE],
      category: MeasuresCategory.INCENTIVES,
      summary: 'MitarbeiterInnen sammeln Punkte durch aktive Mobilität am Arbeitsweg und werden dafür belohnt.',
      paragraphs: [
        'Im Sinne eines Gamification Ansatzes nehmen MitarbeiterInnen freiwillig an einem Wettbewerb teil, bei dem es gilt, Punkte für aktive Mobilität zu sammeln (z.B. pro geradeltem Kilometer zur Arbeit, Schrittechallenge). Wer die meisten bzw. ausreichend Punkte über einen Zeitraum sammelt, wird beispielsweise mit Gutscheinen für Restaurants, (Sport-)Geschäfte, Radreparatur oder Goodies für aktive Mobilität. belohnt. Eine App trackt dabei die zurückgelegten Wege und erlaubt es, sich mit anderen TeilnehmerInnen zu messen, um einen Wettbewerb entstehen zu lassen. Mit Hilfe einer weiteren Funktion der App können die NutzerInnen zusätzlich z.B. persönliche Ziele definieren und tracken.',
      ],
      conditions: 'Der Arbeitsort sollte gut an das Fuß- und Radwegenetz angeschlossen sein.',
      associatedMeasures:
        'Es sollten regelmäßige Updates erfolgen, z.B. durch weitere/andere Belohnungen oder Zusatzfunktionen in der App, damit dieser Ansatz interessant bleibt.',
      support: undefined,
      keywords: ['Gamification', 'App'],
    },
    {
      id: 28,
      title: 'Incentives und motivierende Maßnahmen für aktive Arbeitswege',
      effects: [MeasuresEffect.WALK, MeasuresEffect.BYCICLE, MeasuresEffect.PUBLIC_TRANSPORT],
      category: MeasuresCategory.INCENTIVES,
      summary:
        'Angebote und Anreize motivieren die Beschäftigten ihr Mobilitätsverhalten zu überdenken und Alternativen zu erproben.',
      paragraphs: [
        'Durch gezielt auf die Möglichkeiten des Unternehmens abgestimmte Anreize und Angebote werden die Beschäftigten angeregt ihr Mobilitätsverhalten zu überdenken und Alternativen zu erproben. Dazu ist es wichtig einen Maßnahmenmix anzubieten um (1) die sehr unterschiedlichen Lebensrealitäten (Lehrling, Jungfamilie, Single, Alleinlebend mit Kind-/ern, Alter, soziokultureller Hintergrund, Geschlecht etc.), (2) Interessen (Freizeitsport, Kultur, Gemeinschaft etc.) und (3) Tätigkeiten im Unternehmen (Bürotätigkeit, körperliche Tätigkeit, Arbeitszeiten etc.) der beschäftigten Personen zu berücksichtigen. Ideen und Maßnahmen dazu sollten daher unter einer breiten Beteiligung von Personen aus unterschiedlichen Bereichen des Betriebs entwickelt werden. Mögliche Maßnahmen könnten sein: individuelle Informations- und Beratungsangebote, Informations- und Event-Kampagnen, Belohnungsangebote (z.B. Zeitguthaben, Gutscheine etc.)',
      ],
      conditions:
        'Vorausgesetzt werden das Vorhandensein der adäquaten betrieblichen Infrastruktur (z.B. gesicherte Fahrradabstellplätze, Leihräder, kontenpflichtige PKW-Parkplätze etc.) und eine konsequente Umsetzung in allen Betriebs- und Hierarchieebenen.',
      associatedMeasures:
        'Erfolge der Maßnahmen sollten im Unternehmen anhaltend gut kommuniziert, gegebenenfalls adaptiert und auch mit neuen Angeboten laufend attraktiviert werden.',
      support: undefined,
      keywords: ['Aktive Mobilität', 'individuelles Mobilitätsverhalten', 'Informations- und Event-Kampagnen'],
    },
    {
      id: 29,
      title: 'MultiplikatorInnen, Role Models & Peergroups',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.CULTURE,
      summary: 'MentorInnen, Role Models & Peergroups unterstützen die Verbreitung der Ideen und Akzeptanz der Ziele',
      paragraphs: [
        'Um die Beschäftigten zur Änderung ihres Mobilitätsverhaltens zu motivieren, ist die Etablierung von MultiplikatorInnen, Role Models oder die Bildung von Peergroups (z.B. Lehrlinge) eine effiziente Maßnahme. Dazu können im Rahmen einer ersten Informationsveranstaltung gezielt Personen angesprochen werden, welche bereits häufig das Fahrrad für den Arbeitsweg nutzen oder zu Fuß unterwegs sind, um für andere als MultiplikatorInnen zu fungieren. MultiplikatorInnen sollten möglichst aus allen Beschäftigungs- und Altersgruppen kommen. Die MultiplikatorInnen erhalten durch das Unternehmen gezielte Unterstützung und gegebenenfalls Zugang zu spezifischen Fort- und Weiterbildungsangeboten.',
      ],
      conditions:
        'Vorausgesetzt wird die Unterstützung, Akzeptanz und Wertschätzung der MultiplikatorInnen seitens der Unternehmensleitung sowie deren kongruentes Verhalten. Engagement und zusätzliche Arbeitszeit finanziell (Gutscheine, Bonus) oder organisatorisch (Zeitausgleich) abzugelten kann darüber hinaus Wertschätzung ausdrücken.',
      associatedMeasures:
        'MultiplikatorInnen oder Role Models sollen in die Unternehmenskommunikation eingebunden sein und Möglichkeiten für regelmäßige „Verlautbarungen“ eingeräumt bekommen (z.B. Artikel in Mitarbeiterzeitschriften).',
      support: undefined,
      keywords: ['MultiplikatorInnen', 'Peergroups', 'Mobilitätsverhalten'],
    },
    {
      id: 30,
      title: 'Überbetriebliche „Mobilitätsplattform aktive Mobilität“',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.E_CAR,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
        MeasuresEffect.MIKRO_PUBLIC_TRANSPORT,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Der Aufbau eines unternehmensübergreifenden Netzwerks zur Förderung aktiver Mobilität von Unternehmen, lokaler Wirtschaft und Verwaltung ermöglicht umfassende Mobilitätskonzepte und stärkt die lokale Wirtschaft.',
      paragraphs: [
        'Der Aufbau einer überbetrieblichen “Mobilitätsplattform aktive Mobilität” könnte über Kooperation mit lokalen Initiativen und Beratungsangeboten angeregt werden (z.B. Klimabündnis) und Unternehmen könnten sich dazu aktiv einbringen. Die Vernetzung von AkteurInnen unterschiedlicher Betriebe mit der lokalen Wirtschaft und der Verwaltung ermöglicht eine über den Betriebsstandort hinausreichende Angebotsvielfalt. Dadurch sind zusätzliche Angebote möglich um zu Fuß gehen oder Fahrradfahren attraktiver zu machen und Einschränkungen in der Entscheidung zu aktiver Mobilität zu beseitigen (z. B. Transport v. Gütern oder Geräten, Zusatzwege wie Einkauf, Hol- und Bringdienste am Arbeitsweg). In einer überbetrieblichen Mobilitätsplattform könnten Einkaufs- und Paketlieferungen zum Betrieb, Kooperation mit Transport- und Lieferservices, Mikro-ÖV Angebote privater Dienstleister etc. organisiert und verwaltet werden.',
      ],
      conditions:
        'Die Angebote des überbetrieblichen Netzwerks sollten über eine Online-Plattform verwaltet und kommuniziert werden. Darüber hinaus sollte laufendes Feedback und Adaptierungen eingeplant werden. Ebenso sollte das Netzwerk selbst regelmäßige Treffen und laufende Abstimmungen vereinbaren. Diese Plattform und die Koordination des Netzwerks könnte von einzelnen Unternehmen entwickelt und bereitgestellt oder über eine kommunale Einrichtung angeregt werden.',
      associatedMeasures:
        'Laufende Kommunikation und Information, Berichte und Artikel in lokalen Medien und bei regionalen Veranstaltungen etc. gewährleisten die nötige Breitenwirksamkeit.',
      support: undefined,
      keywords: [
        'Überbetriebliche Vernetzung',
        'lokales Mobilitätsangebot',
        'Mobilitätsverhalten',
        'lokale Wertschöpfungsketten',
      ],
    },
    {
      id: 31,
      title: 'Betriebliches Organisationskonzept, Leitlinien für „aktive Arbeitswege, Gesundheit und Klimaschutz“',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.E_CAR,
        MeasuresEffect.CARSHARING,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
        MeasuresEffect.CARPOOLING,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Durch die Ausarbeitung betrieblicher Leitlinien und einem Regelwerk zur Erreichung der Leitziele werden alle Beschäftigten eines Unternehmens für das Thema sensibilisiert.',
      paragraphs: [
        'Damit betriebliche Maßnahmen für den „aktiven Arbeitsweg“ von den Beschäftigten auch angenommen und umgesetzt werden können, braucht es einerseits die adäquate betriebliche Infrastruktur (z.B. gesicherte Fahrradabstellplätze, Leihräder) und umgekehrt müssen auch einschränkende Maßnahmen für die Nutzung des individuellen PKWs im Maßnahmen-Portfolio verankert sein (z.B. Kostenpflicht für Firmenparkplätze). Darüber ist eine konsequente Umsetzung in allen Betriebsebenen (auch Leitungsebene) wesentlich für den Erfolg.',
      ],
      conditions: undefined,
      associatedMeasures:
        'Als Begleitmaßnahmen können aktivierende und unterstützende Maßnahmen auf betrieblicher Ebene (z. B. individuelle Beratung, Event-Kampagnen etc.) oder finanzielle Anreize (Jobticket, Zeitguthaben) die Maßnahmen unterstützen.',
      support: undefined,
      keywords: [
        'Organisationsentwicklung',
        'Leitlinien für „Aktive Mobilität“ im Unternehmen',
        'Organisationskultur',
        'Sensibilisierung',
      ],
    },
    {
      id: 32,
      title: 'Betriebliche Gesundheitsförderung und aktive Mobilität',
      effects: [
        MeasuresEffect.WALK,
        MeasuresEffect.BYCICLE,
        MeasuresEffect.PUBLIC_TRANSPORT,
        MeasuresEffect.BIKE_SCOOTER_SHARE,
      ],
      category: MeasuresCategory.CULTURE,
      summary:
        'Im Unternehmen werden aktiv zurückgelegte Arbeitswege als gesundheitsfördernde Maßnahme im Rahmen der Betrieblichen Gesundheitsförderung (BGF) eingeführt.',
      paragraphs: [
        'Aktive Mobilitätsformen wie zu Fuß gehen oder mit dem Fahrrad fahren werden im Unternehmen bewusst gefördert und sind Teil der Unternehmenskultur für eine gesündere Belegschaft. Im Rahmen der Betrieblichen Gesundheitsförderung (BGF) können durch Zusammenführung mit dem Betrieblichen Mobilitätsmanagement (BMM) vorhandene Ressourcen und Potenziale utilisiert werden. Dazu werden Ziele und Maßnahmen gemeinsam definiert und umgesetzt. Zusätzliche Angebote, welche über ein reines Mobilitätsmanagement hinausreichen und vorrangig die Gesundheitsförderung zum Ziel haben, werden im Unternehmen implementiert. Konkrete Angebote dazu wären z.B. eine individuelle Gesundheitsberatung mit regelmäßigen Gesundheitschecks oder die Kommunikation von gesundheitlichen Aspekten durch aktive Mobilität (z. B. Vortrags- und Infokampagne).',
      ],
      conditions:
        'Voraussetzung dazu ist die Verankerung in den betrieblichen Leitlinien bis hin zur Organisationskultur und einem klaren Bekenntnis der Unternehmensführung. Die Maßnahmen sollten über einen längeren Zeithorizont hinweg laufen, um den Gewöhnungseffekt anzusteuern.',
      associatedMeasures:
        'Der Prozess sollte von vielfältigen Maßnahmen zur Sensibilisierung, Information, Aktivierung und Unterstützung der Beschäftigten begleitet sein.',
      support: undefined,
      keywords: ['Betriebliches Gesundheitsmanagement (BGM)', 'Aktive Mobilität', 'Betriebliche Organisationskultur'],
    },
    {
      id: 33,
      title: 'active2work',
      effects: [MeasuresEffect.WALK, MeasuresEffect.BYCICLE, MeasuresEffect.PUBLIC_TRANSPORT],
      category: MeasuresCategory.CULTURE,
      summary:
        'Die zentrale Idee von „active2work“ ist, dass ein Teil der Mobilitätszeit, wenn der Arbeitsweg zu Fuß oder mit dem Fahrrad zurückgelegt wird, in die Arbeitszeit eingerechnet wird. Denn mangelnde Zeit ist einer der wichtigsten Gründe, Arbeitswege nicht mit aktiven Modi zurückzulegen.',
      paragraphs: [
        'Die zentrale Idee von „active2work“ ist, dass ein Teil der Mobilitätszeit, wenn der Arbeitsweg zu Fuß oder mit dem Fahrrad (bzw. allgemein aktiv) zurückgelegt wird, in die Arbeitszeit eingerechnet wird. Denn mangelnde Zeit ist einer der wichtigsten Gründe, Arbeitswege nicht mit aktiven Modi (eventuell in Kombination mit dem Öffentlichen Verkehr (ÖV)), sondern mit dem motorisierten Individualverkehr (MIV) zu bewältigen. Daher soll die Summe aus Arbeits- und Mobilitätszeit über alle Modi in etwa gleich sein, um modale Chancengleichheit zu ermöglichen und aktive Verkehrsmodi aufzuwerten. Eine Entschleunigung der Alltagsmobilität soll nicht ausschließlich zu Lasten der Freizeit der ArbeitnehmerInnen gehen, um die zentralen Ziele der Mobilitätswende im Verkehr zu erreichen. Mit einem derartigen Paradigmenwechsel wird erwartet, Akzeptanz und Inanspruchnahme aktiver Verkehrsmodi wesentlich und nachhaltig zu verbessern.',
      ],
      conditions:
        'Basiserhebung im Sinne einer Eignungsprüfung für Unternehmen (Lage, Arbeitszeiten etc.) und Mitarbeitende (Wohnort, Mobilitätsverhalten etc.), Bereitschaft auf Führungsebene zur Erprobung eines neuartigen Ansatzes, personelle Ressourcen und Kompetenzen zur Information im Unternehmen, Aufbau einer allfälligen “Kontrollstruktur” (Fairness)',
      associatedMeasures:
        'Review von Good/Best Practice Beispiele, (unternehmens-)öffentlicher Diskurs zu Arbeitszeiten, Betriebliches Mobilitätsmanagement, Arbeitszeitflexibilisierung, Entwicklung von Incentives, Möglichkeit einer Testphase',
      support: undefined,
      keywords: ['aktive Mobilität', 'Arbeitszeit', 'Mobilitätszeit', 'new deal'],
    },
  ],
};

export default measures;
