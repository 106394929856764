import React, { useEffect, useState } from 'react';
import { css, styled, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateUserDto, OrganizationsService } from '../../services/api';
import SignUpTokenMask from './SignUpTokenMask';

const StyledTextField = styled(TextField)(
  css`
    .MuiInputBase-input {
      font-family: 'Monaco', 'Consolas', monospace;
    }
  `,
);

const SignUpToken = () => {
  const { trigger, watch } = useFormContext<Pick<CreateUserDto, 'signUpToken'>>();
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'signUpToken') {
        if (data.signUpToken?.length === 19) {
          trigger('signUpToken');
        } else {
          setOrganizationName('');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [trigger, watch]);

  return (
    <Controller
      name="signUpToken"
      rules={{
        required: true,
        validate: async (signUpToken) => {
          try {
            const { name, address } = await OrganizationsService.validateSignUpToken({ signUpToken });
            setOrganizationName(`${name}, ${address}`);

            return true;
          } catch {
            setOrganizationName('');

            return 'Unternehmenscode ungültig';
          }
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          fullWidth
          label="Unternehmenscode"
          InputProps={{
            inputComponent: SignUpTokenMask as any,
          }}
          autoComplete="off"
          error={!!error}
          helperText={error?.message || organizationName}
          {...field}
        />
      )}
    />
  );
};
export default SignUpToken;
