/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Advice } from './Advice';
import type { Cause } from './Cause';
import type { ComplexComment } from './ComplexComment';
import type { DateTime } from './DateTime';
import type { ExtensionType } from './ExtensionType';
import type { GroupOfLocations } from './GroupOfLocations';
import type { Impact } from './Impact';
import type { Management } from './Management';
import type { SourceInformation } from './SourceInformation';
import type { Validity } from './Validity';

export type SituationRecord = {
    advice?: Array<Advice>;
    cause?: Cause;
    generalPublicComment?: Array<ComplexComment>;
    groupOfLocations?: GroupOfLocations;
    id?: string;
    impact?: Impact;
    informationUsageOverride?: SituationRecord.informationUsageOverride;
    management?: Management;
    nonGeneralPublicComment?: Array<ComplexComment>;
    probabilityOfOccurrence?: SituationRecord.probabilityOfOccurrence;
    situationRecordCreationReference?: string;
    situationRecordCreationTime?: DateTime;
    situationRecordExtension?: ExtensionType;
    situationRecordFirstSupplierVersionTime?: DateTime;
    situationRecordObservationTime?: DateTime;
    situationRecordVersion?: number;
    situationRecordVersionTime?: DateTime;
    sourceInformation?: SourceInformation;
    validity?: Validity;
};

export namespace SituationRecord {

    export enum informationUsageOverride {
        BROADCAST = 'BROADCAST',
        INTERNAL = 'INTERNAL',
        INTERNET = 'INTERNET',
        VARIABLE_MESSAGE_SIGN = 'VARIABLE_MESSAGE_SIGN',
    }

    export enum probabilityOfOccurrence {
        CERTAIN = 'CERTAIN',
        PROBABLE = 'PROBABLE',
        RISK_OF = 'RISK_OF',
        IMPROBABLE = 'IMPROBABLE',
    }


}

