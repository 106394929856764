/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MunicipalityFeatureDto } from './MunicipalityFeatureDto';

export type MunicipalitiesCountDto = {
    type: MunicipalitiesCountDto.type;
    features: Array<MunicipalityFeatureDto>;
};

export namespace MunicipalitiesCountDto {

    export enum type {
        FEATURE_COLLECTION = 'FeatureCollection',
    }


}

