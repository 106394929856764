import React, { useState } from 'react';
import DialogTypography from './DialogTypography';
import DialogTypographySource from './DialogTypographySource';
import ButtonLink from '../ButtonLink';
import DialogContainer, { DialogContainerProps } from './DialogContainer';
import UserHealthEffectsDialog from './UserHealthEffectsDialog';

type OrganizationHealthDialogProps = Omit<DialogContainerProps, 'children'>;

const OrganizationHealthDialog = ({ open, onClose }: OrganizationHealthDialogProps) => {
  const [openEffect, setOpenEffect] = useState(false);

  return (
    <>
      <DialogContainer open={open} onClose={onClose}>
        <DialogTypography>
          Die Wichtigkeit von körperlicher Aktivität für die Gesundheit ist immens. Mehr frühzeitige Todesfälle sind auf
          mangelnde körperliche Aktivität als auf Rauchen Zurückzuführen (Lee et al. 2012). „Sitzen ist das neue
          Rauchen“, ist eine häufig zitierte Zusammenfassung der wissenschaftlichen Studienlage.
        </DialogTypography>
        <DialogTypography>
          Aktive, also muskelbetriebene Mobilität leistet einen wichtigen Beitrag zur Erhöhung der körperlichen
          Aktivität und damit zur Verbesserung der Gesundheit. Der tägliche Arbeitsweg bietet enormes Potenzial, um den
          negativen Folgen eines sitzenden Lebensstils entgegenzuwirken. Neben dem gesundheitlichen Nutzen aktiver
          Mobilität, gilt es die positiven betriebs- und volkswirtschaftlichen Effekte als Grund für die Förderung
          aktiver Mitarbeitermobilität ins Treffen zu führen. MitarbeiterInnen, die aktiv pendeln, sind im Durchschnitt
          nachweislich zufriedener, was sich auch auf das Wohlbefinden und die Produktivität am Arbeitsplatz überträgt
          (Chatterjee et al. 2020). Der Gesamtgesundheitszustand und die gesundheitsbedingte Lebensqualität
          („healthrelated quality of life“, HRQoL) ist bei Personen, die aktiv pendeln signifikant höher, als bei
          MitarbeiterInnen, die mit dem Auto anreisen (Neumeier et al. 2020). Eine Studie aus Cambridge, UK wies nach,
          dass Personen, die mit Fahrrad zur Arbeit pendeln, insgesamt einen Tag weniger im Krankenstand waren als die
          Vergleichsgruppe (Mytton et al. 2016). Eine Reduktion der vorgehaltenen MitarbeiterInnenparkplätze gilt als
          weiteres betriebswirtschaftliches Argument für die Förderung aktiver Pendelmobilität. Es zahlt sich also in
          mehrerlei Hinsicht aus, in die aktive Mobilität der MitarbeiterInnen zu investieren.
        </DialogTypography>
        <DialogTypography>
          Das ActNow-Tool unterscheidet bei der Berechnung des Gesundheitsnutzen aktiver Pendelmobilität zwischen
          Zielerreichungswerten und prognostischen Effekten. Ersteres gibt an, welcher Anteil der offiziellen
          Bewegungsempfehlungen durch einen aktiv zurückgelegten Arbeitsweg erreicht werden kann. Für die prognostischen
          Effekte greift das ActNow-Tool auf die durchschnittliche Aktivität der MitarbeiterInnen zurück. Basierend
          darauf werden die Gesundheitseffekte der aktiven Pendelroute dann berechnet werden. In der Zusammenfassung
          aller teilnehmenden MitarbeiterInnen werden die prognostischen Werte und die reisezeiteffizienteste
          Routenoption als Berechnungsgrundlage herangezogen. Die kumulierten Werte vermitteln den Unternehmen einen
          Eindruck vom Effekt, den eine aktive Anreise der MitarbeiterInnen erzeugt. Ein unmittelbarer Rückschluss auf
          einzelne Personen kann daraus nicht gezogen werden.
        </DialogTypography>
        <DialogTypography>
          Weitere Details zur Berechnung der Gesundheitseffekte finden Sie{' '}
          <ButtonLink
            onClick={() => {
              onClose();
              setOpenEffect(true);
            }}
            sx={{ color: 'primary.main' }}
          >
            hier
          </ButtonLink>
          .
        </DialogTypography>
        <DialogTypographySource>
          LEE, I. M., SHIROMA, E. J., LOBELO, F., PUSKA, P., BLAIR, S. N. & KATZMARZYK, P. T. 2012. Effect of physical
          inactivity on major non-communicable diseases worldwide: an analysis of burden of disease and life expectancy.
          The Lancet, 380, 219-229.
        </DialogTypographySource>
        <DialogTypographySource>
          CHATTERJEE, K., CHNG, S., CLARK, B., DAVIS, A., DE VOS, J., ETTEMA, D., HANDY, S., MARTIN, A. & REARDON, L.
          2020. Commuting and wellbeing: a critical overview of the literature with implications for policy and future
          research. Transport Reviews, 40, 5-34.
        </DialogTypographySource>
        <DialogTypographySource>
          NEUMEIER, L. M., LOIDL, M., REICH, B., FERNANDEZ LA PUENTE DE BATTRE, M. D., KISSEL, C. K., TEMPLIN, C.,
          SCHMIED, C., NIEBAUER, J. & NIEDERSEER, D. 2020. Effects of active commuting on health-related quality of life
          and sickness-related absence. Scandinavian Journal of Medicine & Science in Sports, 30, 31-40.
        </DialogTypographySource>
        <DialogTypographySource>
          MYTTON, O. T., PANTER, J. & OGILVIE, D. 2016. Longitudinal associations of active commuting with wellbeing and
          sickness absence. Preventive Medicine, 84, 19-26.
        </DialogTypographySource>
      </DialogContainer>
      <UserHealthEffectsDialog open={openEffect} onClose={() => setOpenEffect(false)} />
    </>
  );
};

export default OrganizationHealthDialog;
