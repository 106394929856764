/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PointDto } from './PointDto';

export type MunicipalityFeatureDto = {
    type: MunicipalityFeatureDto.type;
    properties: {
        iso?: number;
        count?: number;
    };
    geometry: PointDto;
};

export namespace MunicipalityFeatureDto {

    export enum type {
        FEATURE = 'Feature',
    }


}

