import React, { useState } from 'react';
import { Alert, Box, Button, Fade, LinearProgress, Link as MuiLink, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { HealthyRoutingUseCase, RoutesStatus, User, UsersService } from '../../services/api';
import HealthyRoutingUseCaseService from '../../services/HealthyRoutingUseCaseService';
import useQueryAuthenticatedUser from '../../hooks/queries/useQueryUser';

const checkRetry = (timestamp?: string) => dayjs(timestamp).isSameOrBefore(dayjs().subtract(1, 'hour'));

type MobilityOptionsErrorsProps = { user: User };

const MobilityOptionsErrors = ({ user }: MobilityOptionsErrorsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showRoutesErrors, setShowRoutesErrors] = useState(false);

  let error;
  let autoRefetch = false;
  let retryAvailable = false;
  let checkAddress = false;

  switch (user.routesStatus) {
    case RoutesStatus.FAILED_ROUTING:
      error = 'Aufgrund eines technischen Fehlers konnten wir leider Ihre Routen nicht berechnen.';
      retryAvailable = checkRetry(user.routesTimestamp);

      break;
    case RoutesStatus.FAILED_POSTPROCESSING:
      error = 'Aufgrund eines technischen Fehlers konnten wir leider Ihre Gesundheitseffekte nicht berechnen.';
      retryAvailable = checkRetry(user.routesTimestamp);

      break;
    case RoutesStatus.SUCCESS:
    default:
      if (!user.routesTimestamp) {
        error = 'Es wurde noch keine Route für Sie berechnet.';
        retryAvailable = true;
      } else if (!user.routesStatus) {
        error = 'Die Berechnung Ihrer Routen dauert noch an, bitte haben Sie noch einen kurzen Moment Geduld.';
        autoRefetch = true;
      } else if (!user.routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult) {
        error = 'Leider konnten wir keine Route für Sie finden.';
        checkAddress = true;
      } else if (user.routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.[0]) {
        const tripResult = user.routes.serviceDelivery.deliveryPayload.tripResponse.tripResult[0];
        if (
          HealthyRoutingUseCaseService.getUseCaseFromResultId(tripResult.resultId) !== HealthyRoutingUseCase.CAR &&
          user.routesHealthEffects?.find(({ resultId }) => resultId === tripResult.resultId)?.metQuotaWeekly.percent ===
            0
        ) {
          error =
            'Leider konnten wir für Ihre schnellste aktive Route keine Gesundheitseffekte berechnen. Aber der schnellste Weg ist nicht immer der Beste! Vielleicht können Sie Ihren Arbeitsweg ja mit einem kurzen Abstecher zum nächsten Park noch aktiver gestalten? Oder gestalten Sie Ihre Freizeit möglichst bewegt – Sie tun Ihrer Gesundheit damit etwas Gutes!';
        } else if (
          HealthyRoutingUseCaseService.getUseCaseFromResultId(tripResult.resultId) === HealthyRoutingUseCase.CAR
        ) {
          error = (
            <>
              Leider konnten wir keine gesundheitseffektive Route für Sie berechnen. Gestalten Sie Ihre Freizeit
              möglichst bewegt – Sie tun Ihrer Gesundheit damit etwas Gutes! Vielleicht können Sie ja eine
              Fahrgemeinschaft mit anderen PendlerInnen bilden?
              <br />
              {user.interestCarpooling ? (
                <MuiLink component={Link} to="/pendlerinnen/fahrgemeinschaften">
                  Fahrgemeinschaften ansehen
                </MuiLink>
              ) : (
                <MuiLink component={Link} to="/pendlerinnen/profil">
                  Interesse an Fahrgemeinschaften ändern
                </MuiLink>
              )}
            </>
          );
        }
      }
  }

  useQueryAuthenticatedUser({ ...(autoRefetch && { refetchInterval: 1000 }) });

  const routesErrors = (user.routes?.serviceDelivery?.deliveryPayload?.tripResponse?.errorMessage || []).filter(
    ({ code, text: { text } = { text: '' } }) =>
      code !== 'OK' && text !== 'Anfrage erfolgreich, Ergebnis war jedoch außerhalb der Healthy Routing-Kriterien.',
  );

  return error || routesErrors.length > 0 ? (
    <Alert icon={false} severity="info" variant="outlined" sx={{ marginTop: 2 }}>
      {error}

      {checkAddress && (
        <Box sx={{ marginTop: 2 }}>
          Bitte gehen Sie in Ihr{' '}
          <MuiLink component={Link} to="/pendlerinnen/profil">
            Profil
          </MuiLink>{' '}
          und überprüfen Sie Ihre Adresse.
        </Box>
      )}

      {(autoRefetch || retryAvailable) && (
        <Box sx={{ marginTop: 2 }}>
          {autoRefetch && <LinearProgress />}

          {retryAvailable && (
            <Button
              onClick={async () => {
                await UsersService.retryFetchRoutes({
                  id: user.id,
                });

                navigate('/pendlerinnen/mobilitaetsoptionen-berechnen?title=Wir versuchen es erneut...');
              }}
            >
              Erneut versuchen
            </Button>
          )}
        </Box>
      )}

      {routesErrors.length > 0 && (
        <Box sx={{ marginTop: 2 }}>
          {!error && (
            <>
              Es konnten nicht alle Routenoptionen berechnet werden.
              <br />
            </>
          )}
          <Button onClick={() => setShowRoutesErrors((prevState) => !prevState)}>
            Fehler {showRoutesErrors ? 'ausblenden' : 'anzeigen'}
          </Button>
          <Fade in={showRoutesErrors} mountOnEnter unmountOnExit>
            <Typography component="ul" sx={{ textAlign: 'left' }}>
              {routesErrors.map(({ code, text: { textId, text } = { text: '' } }) => (
                <Typography key={`${textId}-${code}`} component="li">
                  {textId && (
                    <>
                      <strong>{t(`HealthyRoutingUseCase.${textId}`)}</strong>:{' '}
                    </>
                  )}
                  {text}
                </Typography>
              ))}
            </Typography>
          </Fade>
        </Box>
      )}
    </Alert>
  ) : null;
};

export default MobilityOptionsErrors;
