import React, { useEffect } from 'react';
import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CreateUserDto } from '../../services/api';
import ActivitySlider from './ActivitySlider';

type FormData = Pick<CreateUserDto, 'activeMinutesPerWeek'>;

type ActiveMinutesPerWeekProps = {
  labelledBy: string;
};

const ActiveMinutesPerWeek = ({ labelledBy }: ActiveMinutesPerWeekProps) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  useEffect(() => {
    register('activeMinutesPerWeek', { required: true });
  }, [register]);

  const activeMinutesPerWeek = watch('activeMinutesPerWeek');

  return (
    <>
      <ActivitySlider
        value={activeMinutesPerWeek}
        min={30}
        max={360}
        step={5}
        onChange={(value) => setValue('activeMinutesPerWeek', value)}
        labelledBy={labelledBy}
      />

      {errors.activeMinutesPerWeek && <FormHelperText error>Bitte geben Sie einen Wert an</FormHelperText>}
    </>
  );
};

export default ActiveMinutesPerWeek;
