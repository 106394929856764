/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LocationNote = {
    value?: string;
    key?: string;
    type?: LocationNote.type;
    txtN?: string;
    txtL?: string;
    txtS?: string;
};

export namespace LocationNote {

    export enum type {
        U = 'U',
        A = 'A',
        I = 'I',
        R = 'R',
        H = 'H',
        M = 'M',
        C = 'C',
        D = 'D',
        B = 'B',
        Q = 'Q',
        L = 'L',
        N = 'N',
        O = 'O',
        P = 'P',
        S = 'S',
        V = 'V',
        X = 'X',
        Z = 'Z',
        Y = 'Y',
        K = 'K',
        G = 'G',
        W = 'W',
        ED = 'ED',
        TAR = 'TAR',
        FN = 'FN',
        TLN = 'TLN',
        LNC = 'LNC',
    }


}

