import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Alert } from '@mui/material';
import { ApiError, HealthyRoutingUseCase, PotentialsRoutingDto } from '../../../services/api';
import PageContainer from '../../../components/PageContainer';
import PotentialsTable from './PotentialsTable';
import OrganizationHealthDialog from '../../../components/dialogs/OrganizationHealthDialog';

const potentialsFallback: PotentialsRoutingDto = {
  potentialUsersCount: 0,
  sumCo2: { ton: 0 },
  sumCo2Reduction: { ton: 0, euro: 0 },
  useCasePotentials: Object.values(HealthyRoutingUseCase).map((useCase) => ({
    useCase,
    share: { common: { percent: 0, absolute: 0 }, recommended: { percent: 0, absolute: 0 } },

    averageDuration: '',
    co2Reduction: { ton: 0, euro: 0 },
    routeHealthEffects: { co2: {}, met: {}, metQuotaWeekly: {}, riskReduction: {} },
  })),
};

type PotentialsRoutingPageProps = {
  potentials?: PotentialsRoutingDto;
  error: ApiError | null;
  privacyFallback: ReactNode;
  children?: (setOpenDialog: Dispatch<SetStateAction<boolean>>) => ReactNode;
  printHeading: string;
};

const PotentialsRoutingPage = ({
  potentials = potentialsFallback,
  error,
  privacyFallback,
  children,
  printHeading,
}: PotentialsRoutingPageProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <PageContainer>
      {error?.status === 403 ? (
        <Alert icon={false} severity="info" variant="outlined">
          {privacyFallback}
        </Alert>
      ) : (
        <>
          <PotentialsTable potentials={potentials} printHeading={printHeading} />

          {children && children(setOpenDialog)}

          <OrganizationHealthDialog open={openDialog} onClose={() => setOpenDialog(false)} />
        </>
      )}
    </PageContainer>
  );
};

export default PotentialsRoutingPage;
