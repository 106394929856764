/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PointDto = {
    type: PointDto.type;
    coordinates: Array<number>;
};

export namespace PointDto {

    export enum type {
        POINT = 'Point',
    }


}

