import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, css, styled, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';

import { parsePhoneNumber } from 'libphonenumber-js';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { Organization, OrganizationsService, UpdateOrganizationDto, UserRole } from '../../services/api';
import FormWrapper from '../../components/FormWrapper';
import QueryKeys from '../../services/QueryKeys';
import useStore from '../../setup/global-state';
import GeocodingSearchInput, { GeocodingSearchInputFormData } from '../../components/forms/GeocodingSearchInput';

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    font-size: 1rem;
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
);
StyledTypography.defaultProps = { variant: 'h2' };

type FormData = Pick<UpdateOrganizationDto, 'name' | 'size' | 'contactFirstName' | 'contactLastName' | 'contactTel'> &
  GeocodingSearchInputFormData<'address'>;

type OrganizationProfileFormProps = { organization: Organization };

const OrganizationProfileForm = ({ organization }: OrganizationProfileFormProps) => {
  const user = useStore((state) => state.user);

  const methods = useForm<FormData>({
    defaultValues: {
      name: organization.name,
      address: organization.address,
      addressPoint: organization.addressPoint,
      size: organization.size,
      contactFirstName: organization.contactFirstName,
      contactLastName: organization.contactLastName,
      contactTel: organization.contactTel,
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMutation(
    (requestBody: Partial<UpdateOrganizationDto>) => OrganizationsService.update({ id: organization.id, requestBody }),
    {
      onSuccess: (updatedOrganization) => {
        queryClient.setQueryData(QueryKeys.organizations.id(updatedOrganization.id), updatedOrganization);
      },
    },
  );

  const canUpdateAddress = user?.role === UserRole.ADMIN || user?.role === UserRole.CONSULTANT;

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(({ addressFeature, address, addressPoint, ...formData }) => {
            mutate({ ...formData, ...(canUpdateAddress && { address, addressPoint }) });
          })}
        >
          <StyledTypography>Unternehmen</StyledTypography>
          <TextField {...register('name', { required: true })} error={!!errors.name} label="Unternehmen" />

          {canUpdateAddress ? (
            <GeocodingSearchInput label="Adresse" name="address" />
          ) : (
            <TextField
              label="Adresse"
              value={organization.address}
              disabled
              InputProps={{
                readOnly: true,
              }}
              helperText="Bitte kontaktieren Sie Ihre/n BeraterIn zur Änderung der Adresse"
            />
          )}

          <TextField
            label="Anzahl der MitarbeiterInnen"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            error={!!errors.size}
            {...register('size', { required: true })}
          />

          <StyledTypography>Ansprechperson</StyledTypography>
          <TextField
            label="Vorname Ansprechperson"
            error={!!errors.contactFirstName}
            {...register('contactFirstName', { required: true })}
          />
          <TextField
            label="Nachname Ansprechperson"
            error={!!errors.contactLastName}
            {...register('contactLastName', { required: true })}
          />
          <TextField
            label="Telefon"
            type="tel"
            error={!!errors.contactTel}
            {...register('contactTel', {
              required: true,
              validate: (value) => {
                try {
                  return parsePhoneNumber(value as string, 'AT').isValid();
                } catch {
                  return false;
                }
              },
            })}
          />

          <Box sx={{ marginTop: 5, textAlign: 'center' }}>
            <Button endIcon={isSuccess && <CheckIcon />} type="submit" variant="contained" size="large">
              Speichern
            </Button>
          </Box>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default OrganizationProfileForm;
