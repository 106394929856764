import React, { ReactNode, useState } from 'react';
import { Box, styled, css, Fade } from '@mui/material';
import transientOptions from '../utils/transient-options';
import MapButton from '../commuter/mobility-options/MapButton';

const Root = styled(Box)(
  ({ theme }) =>
    css`
      ${theme.breakpoints.up('md')} {
        flex: 1 0 auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: stretch;
        width: 100%;
      }
    `,
);

const Item = styled(
  Box,
  transientOptions,
)<{ $image?: string }>(
  ({ $image }) =>
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ${$image &&
      css`
        background-image: url(${$image});
        background-size: cover;
        background-position: center;
      `}
    `,
);

const ItemContent = styled(
  Box,
  transientOptions,
)<{ $paddingDense?: boolean; $map?: boolean }>(
  ({ theme, $paddingDense, $map }) =>
    css`
      width: 100%;
      padding: ${theme.spacing($paddingDense ? 1 : 2)};
      max-width: ${theme.spacing(100)};
      z-index: 1;

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing($paddingDense ? 2 : 4)};
      }

      ${theme.breakpoints.up('lg')} {
        padding: ${theme.spacing($paddingDense ? 3 : 15)};
      }

      ${$map &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: #ffb800;
          opacity: 0.7;
        }
      `}
    `,
);

const MapWrapper = styled(
  Box,
  transientOptions,
)<{ $showMap: boolean }>(
  ({ theme, $showMap }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.palette.common.white};

    .mapboxgl-map {
      width: 100%;
      height: 100%;
      transition: ${theme.transitions.create('opacity')};

      ${!$showMap &&
      css`
        filter: saturate(0) contrast(1) brightness(1.1);
      `}
    }
  `,
);

const StyledMapButton = styled(MapButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(5)};
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
  `,
);

type SplitPageProps = { right: ReactNode; rightPaddingDense?: boolean } & (
  | { left: ReactNode; image?: undefined; map?: undefined }
  | { left: (onShowMap: () => void) => ReactNode; image?: undefined; map: ReactNode }
  | { left: ReactNode; image: string; map?: undefined }
);

const SplitPage = ({ left, right, image, map, rightPaddingDense }: SplitPageProps) => {
  const [showMap, setShowMap] = useState(false);

  return (
    <Root>
      <Item $image={image}>
        {map && (
          <MapWrapper $showMap={showMap}>
            <Fade in={showMap}>
              <StyledMapButton onClick={() => setShowMap(false)}>▾ Text einblenden</StyledMapButton>
            </Fade>

            {map}
          </MapWrapper>
        )}
        <Fade in={!showMap}>
          <ItemContent $map={!!map}>
            {typeof left === 'function'
              ? left(() => {
                  setShowMap(true);
                })
              : left}
          </ItemContent>
        </Fade>
      </Item>

      <Item>
        <ItemContent $paddingDense={rightPaddingDense}>{right}</ItemContent>
      </Item>
    </Root>
  );
};

export default SplitPage;
