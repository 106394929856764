import { TableCell, styled, css } from '@mui/material';

const StyledTableCellComponent = styled(TableCell)(
  ({ theme }) => css`
    text-align: center;
    padding-left: ${theme.spacing(1.5)};
    padding-right: ${theme.spacing(1.5)};
  `,
);

export default StyledTableCellComponent;
