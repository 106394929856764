import React, { ReactNode, useState } from 'react';
import { IconButton, Box, Popover, Stack, Button, Typography, Chip, styled, css } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';

const ResetButton = styled(Button)(
  ({ theme }) =>
    css`
      color: ${theme.palette.text.primary};
      &:hover {
        color: ${theme.palette.text.secondary};
      }
    `,
);

const MainButton = styled(Button)(
  ({ theme }) =>
    css`
      border-radius: 0;
      border: 2px ${theme.palette.common.white} solid;
      height: ${theme.spacing(7)};
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};

      .MuiButton-startIcon {
        margin-left: 0;
        margin-right: ${theme.spacing(2)};
      }
    `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) =>
    css`
      color: ${theme.palette.text.primary};
      font-weight: bold;
      font-size: 1.15rem;
      text-transform: uppercase;
    `,
);

type FilterButtonProps = { children: ReactNode; filterCount: number };

const FilterButton = ({ children, filterCount }: FilterButtonProps) => {
  const { reset } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const filterEnabled = filterCount > 0;

  return (
    <Box sx={{ position: 'relative' }}>
      <MainButton onClick={(event) => setAnchorEl(event.currentTarget)} startIcon={<FilterIcon />}>
        {filterCount > 0 && <Chip label={filterCount} color="primary" size="small" sx={{ marginRight: 1.5 }} />}
        <StyledTypography sx={{ marginRight: (theme) => theme.spacing(filterEnabled ? 4 : 0) }}>
          Filter
        </StyledTypography>
      </MainButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box sx={{ padding: 3 }}>
          <Stack direction="row">{children}</Stack>

          <Box>
            <ResetButton form="filter-header" type="submit" onClick={() => reset()}>
              Zurücksetzen
            </ResetButton>
          </Box>
        </Box>
      </Popover>
      {filterEnabled && (
        <IconButton
          form="filter-header"
          type="submit"
          onClick={() => reset()}
          sx={{
            position: 'absolute',
            top: (theme) => `${theme.spacing(1.5)}`,
            right: (theme) => `${theme.spacing(2)}`,
            width: (theme) => `${theme.spacing(4)}`,
            height: (theme) => `${theme.spacing(4)}`,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default FilterButton;
