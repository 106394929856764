import React from 'react';

const PotentialsFootnote2 = () => (
  <>
    <sup>2</sup> Wenn alle PendlerInnen, die bisher mit dem Auto fahren, in Zukunft an 80% der Arbeitstage im Jahr statt
    dem Auto ihre empfohlene Route wählen. Bei einem{' '}
    <span style={{ whiteSpace: 'nowrap' }}>
      CO<sub>2</sub>-Preis
    </span>{' '}
    von 83€/t (Mittelwert Q1/2022 nach EU Emissionshandel).
  </>
);

export default PotentialsFootnote2;
