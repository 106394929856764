/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Nutzerrolle innerhalb des Arbeitgebers
 */
export enum OrganizationUserRole {
    MANAGER = 'MANAGER',
    COMMUTER = 'COMMUTER',
}
