import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { deDE } from '@mui/material/locale';
import '@fontsource/source-sans-pro/latin-400.css';
import '@fontsource/source-sans-pro/latin-700.css';
import '@fontsource/montserrat/latin-700.css';
import { css } from '@emotion/react';
import { GlobalStylesProps } from '@mui/material';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#ffab00',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#fef6e5',
      },
      text: {
        primary: '#3a3a3a',
        secondary: '#86888c',
        disabled: '#9c9890',
      },

      success: {
        main: '#87bb2c',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },

      h1: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: '1.5rem',
      },
      h2: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
      h3: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
      h4: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
      h5: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
      h6: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      '0 0.2px 0.4px rgba(0, 0, 0, 0.017), 0 0.5px 1.1px rgba(0, 0, 0, 0.024), 0 1px 2px rgba(0, 0, 0, 0.03), 0 1.8px 3.6px rgba(0, 0, 0, 0.036), 0 3.3px 6.7px rgba(0, 0, 0, 0.043), 0 8px 16px rgba(0, 0, 0, 0.06)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  deDE,
);

theme = createTheme(theme, {
  breakpoints: {
    values: {
      ...theme.breakpoints.values,
      xl: 1600,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
      styleOverrides: {
        root: css`
          ${theme.breakpoints.up('sm')} {
            padding-left: ${theme.spacing(4)};
            padding-right: ${theme.spacing(4)};
          }

          ${theme.breakpoints.up('lg')} {
            padding-left: ${theme.spacing(15)};
            padding-right: ${theme.spacing(15)};
          }
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: css`
          border-radius: ${theme.spacing(2.5)};
          padding-left: ${theme.spacing(2.5)};
          padding-right: ${theme.spacing(2.5)};

          &.Mui-disabled {
            color: ${theme.palette.text.disabled};
          }
        `,
        text: css`
          font-weight: bold;
        `,
        sizeLarge: css`
          height: ${theme.spacing(7)};
          border-radius: ${theme.spacing(3.5)};
          padding-left: ${theme.spacing(3.5)};
          padding-right: ${theme.spacing(3.5)};
          font-weight: bold;
          line-height: 1.2;
        `,
        contained: css`
          font-weight: bold;
        `,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: css`
          color: ${theme.palette.text.primary};

          &.Mui-disabled {
            color: ${theme.palette.text.disabled};
          }
        `,
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: css`
          margin-bottom: 1em;
        `,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: css`
          color: ${theme.palette.text.primary};
          text-decoration-color: ${theme.palette.text.primary};
          font-weight: bold;
        `,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: css`
          color: ${theme.palette.text.primary};
        `,
        outlinedInfo: css`
          border: 2px solid ${theme.palette.primary.main};
        `,
        message: css`
          width: 100%;
          text-align: center;
        `,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true,
      },
    },
    MuiMenu: {
      defaultProps: { elevation: 1 },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: css`
          box-shadow: none;
          border: 2px solid ${theme.palette.secondary.main};
        `,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: css`
          background-color: ${theme.palette.common.white};

          &:hover,
          &.Mui-focused {
            background-color: ${theme.palette.common.white};
          }

          &.Mui-disabled {
            background-color: ${theme.palette.common.white};
          }
        `,
        underline: css`
          &::before {
            content: none;
          }
        `,
        input: css`
          padding-left: ${theme.spacing(2)};
          padding-right: ${theme.spacing(2)};
        `,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: css`
          border-color: transparent;

          .MuiSelect-select ~ & {
            border-radius: ${theme.spacing(2.5)};
          }
        `,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: css`
          padding-left: ${theme.spacing(4)};
          padding-right: ${theme.spacing(4)};
        `,
        head: css`
          border-bottom: none;
          color: ${theme.palette.text.secondary};
        `,
        stickyHeader: css`
          background-color: ${theme.palette.secondary.main};
        `,
        body: css`
          background-color: ${theme.palette.common.white};
          border-bottom: ${theme.spacing(1)} solid ${theme.palette.secondary.main};
        `,
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: css`
          color: ${theme.palette.text.primary};
        `,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

theme = responsiveFontSizes(theme);

export const globalStyles: GlobalStylesProps['styles'] = (globalTheme) => css`
  .mapboxgl-popup-content {
    height: ${globalTheme.spacing(5)};
    padding: 4px 16px;
    border-radius: ${globalTheme.spacing(2.5)} !important;
    box-shadow: none;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: ${globalTheme.spacing(4)};
    font-weight: 700;
  }

  .mapboxgl-popup-tip {
    display: none;
  }
`;

export default theme;
