import React from 'react';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const PrivacyReceiverTable = () => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Empfänger</TableCell>
          <TableCell>Sitz des Empfängers (Land)</TableCell>
          <TableCell>Grundlage für Übermittlung </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Anbieter von Internet- und Telefonieleistungen</TableCell>
          <TableCell>Österreich</TableCell>
          <TableCell>Innerhalb des EWR</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Mapbox Inc.</TableCell>
          <TableCell>USA</TableCell>
          <TableCell>
            Wir haben auf unserer Webseite den Kartendienst Mapbox der Mapbox Inc., 740 15th Street NW, 5th Floor,
            Washington, District of Columbia 20005, USA, eingebunden. Das führt dazu, dass beim Aufruf unserer Webseite
            Daten wie Ihre IP-Adresse von Mapbox an Server in die USA übertragen und gespeichert werden. Die Stammdaten
            werden nicht an Mapbox übertragen. Nähere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren
            Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte der Website von Mapbox:{' '}
            <Link href="https://www.mapbox.com/legal/privacy" target="_blank" rel="noopener">
              Mapbox Datenschutzerklärung
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Verkehrsauskunft Österreich VAO GmbH</TableCell>
          <TableCell>Österreich</TableCell>
          <TableCell>
            Wir nutzen die Schnittstellen der Verkehrsauskunft Österreich VAO GmbH, Europaplatz 3 / 3, A-1150 Wien.
            Eingegebene Adressdaten bzw. entsprechende Koordinaten werden an die VAO übertragen zur Ortspunktauflösung
            mit Namen sowie zur Routenberechnung.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Hetzner Online GmbH</TableCell>
          <TableCell>Deutschland</TableCell>
          <TableCell>
            Hosting des ActNow-Tools in einem Rechenzentrum der Firma Hetzner Online GmbH, Industriestr. 25, 91710
            Gunzenhausen, Deutschland
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Asana, Inc.</TableCell>
          <TableCell>USA</TableCell>
          <TableCell>
            Bei technischen Problemen mit dem ActNow-Tool können Sie uns eine Supportanfrage über ein Formular senden.
            Für das Formular sowie zur Sammlung und Verwaltung der Supportanfragen nutzen wir das
            Aufgabenverwaltungstool der Asana, Inc., 1550 Bryant Street, Suite 200, San Francisco, CA 94103, USA. Wenn
            Sie uns über das Formular eine Supportanfrage senden, werden daher Daten wie Ihre angegebene E-Mail-Adresse
            sowie Ihre IP-Adresse an Server von Asana übertragen. Die Stammdaten werden nicht an Asana übertragen.
            Nähere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz
            Ihrer Privatsphäre entnehmen Sie bitte der Website von Asana:{' '}
            <Link href="https://asana.com/de/terms#privacy-policy" target="_blank" rel="noopener">
              Asana Datenschutzerklärung
            </Link>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default PrivacyReceiverTable;
