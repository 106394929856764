import { css, styled, Typography } from '@mui/material';

const PrivacyHeading = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)};

    &::before {
      counter-increment: section;
      content: counter(section) '. ';
    }
  `,
);
PrivacyHeading.defaultProps = { variant: 'h2' };

export default PrivacyHeading;
