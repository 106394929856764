import { Button, styled, css } from '@mui/material';
import ButtonLink from './ButtonLink';

const ButtonLinkSmall = styled(ButtonLink)(
  css`
    font-size: 0.75rem;
    text-decoration: underline;
  `,
) as typeof Button;

export default ButtonLinkSmall;
