import { Duration } from 'dayjs/plugin/duration';

class Format {
  public static percentRounded(value: number) {
    return `${Math.round(value * 100)} %`;
  }

  public static kgRounded(value: number) {
    return `${Math.round(value).toLocaleString('de-AT')} kg`;
  }

  public static ton(value: number) {
    return `${value.toLocaleString('de-AT', { maximumFractionDigits: 1, minimumFractionDigits: 1 })} t`;
  }

  public static euro(value: number) {
    return `${Math.round(value).toLocaleString('de-AT')} €`;
  }

  public static undefinedFallback<T extends unknown>(
    value: T | undefined,
    format: (value: T) => string,
    override?: boolean,
  ) {
    return value === undefined || override ? '–' : format(value);
  }

  public static duration(duration: Duration) {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.round(duration.asMinutes() % 60);

    let result = '';

    if (hours) {
      result += `${hours} h`;
    }

    if (minutes) {
      result += ` ${minutes} min`;
    }

    return result.trim();
  }
}

export default Format;
