import React from 'react';
import { Box, Stack, Typography, css, styled } from '@mui/material';
import PotentialItem, { PotentialItemProps } from './PotentialItem';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    width: 1px;
    height: ${theme.spacing(6)};
    background-color: ${theme.palette.divider};
  `,
);

type PotentialsCountProps = { title: string; items: PotentialItemProps[] };

const PotentialsCount = ({ title, items }: PotentialsCountProps) => (
  <Box>
    <Stack spacing={3} direction="row" justifyContent="center" alignItems="center" divider={<StyledBox />}>
      {items.map(({ label, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PotentialItem key={index} label={label} value={value} />
      ))}
    </Stack>
    <Typography>{title}</Typography>
  </Box>
);

export default PotentialsCount;
