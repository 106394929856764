import React from 'react';
import { Box, Button, styled, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import Audience from '../types/Audience';
import AuthHeading from './AuthHeading';
import useStore from '../setup/global-state';
import FormWrapper from './FormWrapper';
import { ApiError, AuthenticationService, LoginUserDto, User } from '../services/api';
import useRedirectedFrom from '../hooks/useRedirectedFrom';
import RouteFactory from '../services/RouteFactory';

const StyledLink = styled(Link)(
  ({ theme }) =>
    css`
      display: inline-block;
      color: ${theme.palette.primary.main};
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-bottom: ${theme.spacing(2)};
      }

      &:hover {
        text-decoration: underline;
      }
    `,
);

type LoginProps = {
  audience: Audience;
};

const Login = ({ audience }: LoginProps) => {
  const navigate = useNavigate();
  const { redirectedFrom } = useRedirectedFrom();

  const setUser = useStore((state) => state.setUser);

  const { mutate, error } = useMutation<User, ApiError, LoginUserDto>(
    (loginUserDto: LoginUserDto) => AuthenticationService.login({ requestBody: loginUserDto }),
    {
      onSuccess: (newUser) => {
        navigate(redirectedFrom || RouteFactory.audienceRoot(audience), { replace: true });
        setUser(newUser);
      },
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginUserDto>();

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit((requestBody) => mutate(requestBody))}>
        <AuthHeading>
          Login für{' '}
          {
            {
              [Audience.COMMUTER]: 'PendlerInnen',
              [Audience.ORGANIZATION]: 'Unternehmen',
              [Audience.CONSULTING]: 'BeraterInnen',
            }[audience]
          }
        </AuthHeading>

        <TextField label="E-Mail" type="email" error={!!errors.email} {...register('email', { required: true })} />
        <TextField
          label="Passwort"
          type="password"
          error={!!errors.password || !!error}
          helperText={
            error && (error?.status === 401 ? 'Bitte überprüfen Sie Ihre Eingabe' : 'Es ist ein Fehler aufgetreten')
          }
          {...register('password', { required: true })}
        />

        <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 3, marginBottom: 5 }}>
          Login
        </Button>

        <Box>
          <StyledLink to="/passwort/vergessen">Passwort vergessen</StyledLink>

          {audience !== Audience.CONSULTING && (
            <>
              <br />
              <StyledLink to="../registrieren/1">Registrieren</StyledLink>
            </>
          )}
        </Box>
      </form>
    </FormWrapper>
  );
};

export default Login;
