import { Location, useLocation } from 'react-router-dom';

const useRedirectedFrom = () => {
  const location = useLocation();
  const from = (location?.state as { from?: Location })?.from;

  return { redirectedFrom: from ? from.pathname + from.search + from.hash : undefined };
};

export default useRedirectedFrom;
