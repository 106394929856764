import React from 'react';
import {
  Box,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  css,
  styled,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import AuthHeading from '../../components/AuthHeading';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import FormWrapper from '../../components/FormWrapper';
import step4Image from '../../assets/images/commuter/sign-up/4-step.jpg';
import { ReactComponent as MindIcon } from '../../assets/icons/split-page/mind.svg';
import FormStepper from '../../components/forms/FormStepper';
import { CreateUserDto, HealthyRoutingUseCase, UsersService } from '../../services/api';
import useStore from '../../setup/global-state';
import HealthyRoutingUseCaseService from '../../services/HealthyRoutingUseCaseService';

const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)};
  `,
);

type FormData = Pick<CreateUserDto, 'commonHealthyRoutingUseCase'>;

const CommuterSignUpStep3 = () => {
  const { t } = useTranslation();
  const setUser = useStore((state) => state.setUser);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const navigate = useNavigate();

  const commuterSignUpFormData = useStore((state) => state.commuterSignUpFormData);
  const resetCommuterSignUpFormData = useStore((state) => state.resetCommuterSignUpFormData);

  const { mutate, error } = useMutation((formData: CreateUserDto) => UsersService.create({ requestBody: formData }), {
    onSuccess: (user) => {
      resetCommuterSignUpFormData();
      navigate('/pendlerinnen/mobilitaetsoptionen-berechnen?title=Danke!');
      setUser(user);
    },
  });

  return (
    <SplitPage
      image={step4Image}
      left={
        <SplitPageTextContent
          Icon={MindIcon}
          title={
            <>
              Ihre Gewohnheit,
              <br />
              Ihr Beitrag.
            </>
          }
        >
          <Typography gutterBottom>
            Wunderbar – nun haben wir alle Informationen, um Ihnen einen aktiveren Arbeitsweg vorzuschlagen! Sollten Sie
            schon jetzt aktiv unterwegs sein, zeigt Ihnen das Tool die positiven Auswirkungen auf Ihre Gesundheit und
            auf Umwelt und Klima.
          </Typography>
          <Typography gutterBottom>
            Wir wollen aber auch Ihrem Unternehmen dabei helfen, Mobilitätspotenziale zu erkennen, und die
            Rahmenbedingungen für aktive Arbeitswege zu verbessern. Geben Sie dafür bitte an, wie Sie Ihren Arbeitsweg
            üblicherweise zurücklegen – dies wird nur für statistische Zwecke verwendet und ohne Personenbezug
            verarbeitet.
          </Typography>
        </SplitPageTextContent>
      }
      right={
        <FormWrapper>
          {/* @ts-ignore */}
          <form
            onSubmit={handleSubmit((formData) => {
              mutate({ ...commuterSignUpFormData, ...formData } as CreateUserDto);
            })}
          >
            <AuthHeading>Aktuelles Mobilitätsverhalten</AuthHeading>

            <Typography gutterBottom>Wie legen Sie aktuell am häufigsten Ihren Arbeitsweg zurück?</Typography>

            <TextField
              label="Bevorzugter Mobilitätsmodus"
              select
              defaultValue=""
              {...register('commonHealthyRoutingUseCase', { required: true })}
              error={!!errors.commonHealthyRoutingUseCase}
            >
              {Object.values(HealthyRoutingUseCase).map((healthyRoutingUseCase) => {
                const Icon = HealthyRoutingUseCaseService.getIcon(healthyRoutingUseCase);

                return (
                  <MenuItem key={healthyRoutingUseCase} value={healthyRoutingUseCase}>
                    <Box display="flex" alignItems="center">
                      <StyledListItemIcon>
                        <Icon aria-hidden />
                      </StyledListItemIcon>

                      <ListItemText>{t(`HealthyRoutingUseCase.${healthyRoutingUseCase}`)}</ListItemText>
                    </Box>
                  </MenuItem>
                );
              })}
            </TextField>

            {error && <FormHelperText error>Es ist ein Fehler aufgetreten.</FormHelperText>}

            <FormStepper active={2} />
          </form>
        </FormWrapper>
      }
    />
  );
};

export default CommuterSignUpStep3;
