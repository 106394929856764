import React, { ReactNode } from 'react';
import { Box, css, styled } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen {
      background-color: ${theme.palette.secondary.main};
    }
  `,
);

type PageLayoutProps = { header: ReactNode; children: ReactNode; footer: ReactNode };

const PageLayout = ({ header, children, footer }: PageLayoutProps) => (
  <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    {header}

    <StyledBox component="main">{children}</StyledBox>

    {footer}
  </Box>
);

export default PageLayout;
