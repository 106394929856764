import React from 'react';
import dayjs from 'dayjs';
import { css, styled, TableRow } from '@mui/material';
import { Duration, HealthEffectType, RouteHealthEffectsDto, TripResultStructure } from '../../services/api';
import HealthyRoutingUseCaseService from '../../services/HealthyRoutingUseCaseService';
import StyledTableCellComponent from '../../components/table/StyledTableCell';
import transientOptions from '../../utils/transient-options';
import HealthEffectsHelper from '../../services/HealthEffectsHelper';
import Format from '../../services/Format';
import useStore from '../../setup/global-state';

const StyledTableRow = styled(TableRow)(
  ({ theme }) => css`
    border-width: ${theme.spacing(1)} 0;
    border-style: solid;
    border-color: ${theme.palette.secondary.main};
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    & .MuiTableCell-root {
      position: relative;

      &::after {
        content: '';
        opacity: 0;
        transition: ${theme.transitions.create('opacity')};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-top: 2px solid ${theme.palette.primary.main};
        border-bottom: 2px solid ${theme.palette.primary.main};
      }
    }

    & th.MuiTableCell-root::after {
      border-left: 2px solid ${theme.palette.primary.main};
    }

    & td.MuiTableCell-root:last-of-type::after {
      border-right: 2px solid ${theme.palette.primary.main};
    }

    &:hover,
    &.selected {
      & .MuiTableCell-root::after {
        opacity: 1;
      }
    }
  `,
);

const StyledTableCell = styled(
  StyledTableCellComponent,
  transientOptions,
)<{ $backgroundTable?: boolean }>(
  ({ theme, $backgroundTable }) => css`
    border: none;

    .MuiTableBody-root & {
      background-color: ${$backgroundTable ? '#FFFCF4' : theme.palette.common.white};
    }
  `,
);

type MobilityOptionsRowProps = {
  routesHealthEffects: RouteHealthEffectsDto[];
  tripResult: TripResultStructure;
};

const MobilityOptionsRow = ({ routesHealthEffects, tripResult }: MobilityOptionsRowProps) => {
  const selectedHealthyRoutingUseCase = useStore((state) => state.selectedHealthyRoutingUseCase);
  const setSelectedHealthyRoutingUseCase = useStore((state) => state.setSelectedHealthyRoutingUseCase);

  const useCase = HealthyRoutingUseCaseService.getUseCaseFromResultId(tripResult.resultId);
  const Icon = useCase && HealthyRoutingUseCaseService.getIcon(useCase);
  const healthEffects = routesHealthEffects?.find(({ resultId }) => resultId === tripResult.resultId);

  return useCase && Icon ? (
    <StyledTableRow
      onClick={() => setSelectedHealthyRoutingUseCase(useCase)}
      className={useCase === selectedHealthyRoutingUseCase ? 'selected' : ''}
    >
      <StyledTableCell component="th" scope="row">
        <Icon style={{ verticalAlign: 'middle' }} />
      </StyledTableCell>
      <StyledTableCell>
        {Format.undefinedFallback(tripResult.trip?.duration, (value: Duration) =>
          Format.duration(dayjs.duration(value)),
        )}
      </StyledTableCell>
      <StyledTableCell>
        {Format.undefinedFallback(healthEffects?.metQuotaWeekly.percent, Format.percentRounded)}
      </StyledTableCell>
      <StyledTableCell>
        {Format.undefinedFallback(healthEffects?.co2?.kg, (value) => Format.kgRounded(value))}
      </StyledTableCell>

      {Object.values(HealthEffectType).map((type) => (
        <StyledTableCell key={type} $backgroundTable>
          {HealthEffectsHelper.formatRiskChange(healthEffects, type)}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ) : null;
};

export default MobilityOptionsRow;
