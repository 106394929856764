import { styled, Toolbar } from '@mui/material';
import { css } from '@emotion/react';

const PaddedToolbar = styled(Toolbar)(
  ({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
    }

    ${theme.breakpoints.up('lg')} {
      padding-left: ${theme.spacing(15)};
      padding-right: ${theme.spacing(15)};
    }
  `,
) as typeof Toolbar;

export default PaddedToolbar;
