import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Box, Button, TextField, Typography } from '@mui/material';
import PageContainer from '../components/PageContainer';
import { ApiError, AuthenticationService, ResetPasswordDto } from '../services/api';
import AuthHeading from '../components/AuthHeading';
import RouteFactory from '../services/RouteFactory';
import Audience from '../types/Audience';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleSubmit, setValue, control } = useForm<ResetPasswordDto>();

  const { mutate, error } = useMutation<any, ApiError, ResetPasswordDto>(
    (requestBody: ResetPasswordDto) => AuthenticationService.resetPassword({ requestBody }),

    {
      onSuccess: () => {
        navigate(RouteFactory.audienceRoot(Audience.COMMUTER));
      },
    },
  );

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      setValue('passwordResetToken', token);
    }
  }, [searchParams, setSearchParams, setValue]);

  return (
    <PageContainer maxWidth="md">
      <form onSubmit={handleSubmit((requestBody) => mutate(requestBody))}>
        <AuthHeading>Passwort zurücksetzen</AuthHeading>

        <Typography gutterBottom>Bitte vergeben Sie ein neues Passwort.</Typography>

        <Controller
          defaultValue=""
          name="newPassword"
          rules={{ required: true, minLength: 8 }}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label="Neues Passwort"
              type="password"
              autoComplete="new-password"
              error={!!fieldState.error || !!error}
              helperText={
                error ? 'Es ist ein Fehler aufgetreten' : (field.value.length || 0) < 8 && 'Mindestens 8 Zeichen'
              }
              {...field}
            />
          )}
        />

        <Box textAlign="center">
          <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 5 }}>
            Passwort ändern
          </Button>
        </Box>
      </form>
    </PageContainer>
  );
};

export default ResetPasswordPage;
