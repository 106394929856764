import React from 'react';
import { Typography } from '@mui/material';
import PageContainer from '../components/PageContainer';

const ImprintPage = () => (
  <PageContainer>
    <Typography gutterBottom variant="h1">
      Impressum
    </Typography>

    <Typography component="address" gutterBottom>
      <strong>Trafficon – Traffic Consultants GmbH</strong>
      <br />
      Strubergasse 26
      <br />
      5020 Salzburg
      <br />
      Österreich
    </Typography>

    <Typography>
      +43 662 46 11 02-0
      <br />
      sbg@trafficon.eu
      <br />
      <br />
      Geschäftsführer: Dr. Stefan Krampe
      <br />
      Firmenbuch-Nr.: Landesgericht Salzburg – FN 327083f
      <br />
      UID: ATU650 71334
    </Typography>
  </PageContainer>
);

export default ImprintPage;
