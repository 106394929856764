import React from 'react';
import { css, LinearProgress, styled } from '@mui/material';
import { useIsFetching } from 'react-query';
import { useThrottle } from 'react-use';

const StyledLinearProgress = styled(LinearProgress)(
  css`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  `,
);

const Loading = () => {
  const isFetching = useIsFetching();
  const throttledIsFetching = useThrottle(isFetching, 750);

  return throttledIsFetching !== 0 ? <StyledLinearProgress /> : null;
};

export default Loading;
