/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type NotifyByEmailActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: NotifyByEmailActionStructure.actionStatus;
    beforeNotices?: BeforeNotices;
    clearNotice?: boolean;
    description?: NaturalLanguageStringStructure;
    email?: string;
};

export namespace NotifyByEmailActionStructure {

    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }


}

