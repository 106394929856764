/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type SituationSourceStructure = {
    agentReference?: string;
    country?: string;
    email?: string;
    externalCode?: string;
    fax?: string;
    name?: NaturalLanguageStringStructure;
    other?: string;
    phone?: string;
    sourceFile?: string;
    sourceMethod?: SituationSourceStructure.sourceMethod;
    sourceRole?: string;
    sourceType?: SituationSourceStructure.sourceType;
    timeOfCommunication?: string;
    web?: string;
};

export namespace SituationSourceStructure {

    export enum sourceMethod {
        AUTOMOBILE_CLUB_PATROL = 'AUTOMOBILE_CLUB_PATROL',
        CAMERA_OBSERVATION = 'CAMERA_OBSERVATION',
        FREIGHT_VEHICLE_OPERATOR = 'FREIGHT_VEHICLE_OPERATOR',
        INDUCTION_LOOP_MONITORING_STATION = 'INDUCTION_LOOP_MONITORING_STATION',
        INFRARED_MONITORING_STATION = 'INFRARED_MONITORING_STATION',
        MICROWAVE_MONITORING_STATION = 'MICROWAVE_MONITORING_STATION',
        MOBILE_TELEPHONE_CALLER = 'MOBILE_TELEPHONE_CALLER',
        NON_POLICE_EMERGENCY_SERVICE_PATROL = 'NON_POLICE_EMERGENCY_SERVICE_PATROL',
        OTHER_INFORMATION = 'OTHER_INFORMATION',
        OTHER_OFFICIAL_VEHICLE = 'OTHER_OFFICIAL_VEHICLE',
        POLICE_PATROL = 'POLICE_PATROL',
        PRIVATE_BREAKDOWN_SERVICE = 'PRIVATE_BREAKDOWN_SERVICE',
        PUBLIC_AND_PRIVATE_UTILITIES = 'PUBLIC_AND_PRIVATE_UTILITIES',
        REGISTERED_MOTORIST_OBSERVER = 'REGISTERED_MOTORIST_OBSERVER',
        ROAD_AUTHORITIES = 'ROAD_AUTHORITIES',
        ROAD_OPERATOR_PATROL = 'ROAD_OPERATOR_PATROL',
        ROADSIDE_TELEPHONE_CALLER = 'ROADSIDE_TELEPHONE_CALLER',
        SPOTTER_AIRCRAFT = 'SPOTTER_AIRCRAFT',
        TRAFFIC_MONITORING_STATION = 'TRAFFIC_MONITORING_STATION',
        TRANSIT_OPERATOR = 'TRANSIT_OPERATOR',
        VEHICLE_PROBE_MEASUREMENT = 'VEHICLE_PROBE_MEASUREMENT',
        VIDEO_PROCESSING_MONITORING_STATION = 'VIDEO_PROCESSING_MONITORING_STATION',
    }

    export enum sourceType {
        DIRECT_REPORT = 'DIRECT_REPORT',
        EMAIL = 'EMAIL',
        PHONE = 'PHONE',
        FAX = 'FAX',
        POST = 'POST',
        FEED = 'FEED',
        RADIO = 'RADIO',
        TV = 'TV',
        WEB = 'WEB',
        PAGER = 'PAGER',
        TEXT = 'TEXT',
        OTHER = 'OTHER',
    }


}

