import React, { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import MobilityOptionsTable from './MobilityOptionsTable';
import HelpPopUp from '../../components/HelpPopUp';
import ButtonLinkSmall from '../../components/ButtonLinkSmall';
import RoutingDialog from '../../components/dialogs/RoutingDialog';

type MobilityOptionsRightProps = {
  onOpenUserHealthEffectsDialog: () => void;
};

const MobilityOptionsRight = ({ onOpenUserHealthEffectsDialog }: MobilityOptionsRightProps) => {
  const [routingDialogOpen, setRoutingDialogOpen] = useState(false);

  return (
    <Box>
      <MobilityOptionsTable />

      <HelpPopUp>
        <Typography sx={{ fontSize: '0.75rem' }}>
          Für die Routenberechnungen gehen wir von einer Startzeit von 06:30 Uhr am nächsten Werktag aus. Die Autorouten
          werden unter Berücksichtigung der Verkehrslage sowie Parksuchzeiten (im Raum Wien) berechnet. Für genauere
          Informationen zu passenden ÖV-Abfahrtszeiten verwenden Sie bitte einen{' '}
          <Link target="_blank" rel="noopener" href="https://anachb.vor.at/">
            ÖV-Routenplaner
          </Link>
          . Die Gesundheitseffekte und der CO<sub>2</sub>-Ausstoß wurden unter der Annahme berechnet, dass Sie 80% Ihrer
          Arbeitswege im Jahr mit der jeweiligen Route zurücklegen. Hier erhalten Sie mehr Informationen zu den{' '}
          <ButtonLinkSmall onClick={onOpenUserHealthEffectsDialog}>Berechnungsgrundlagen</ButtonLinkSmall> und zur{' '}
          <ButtonLinkSmall onClick={() => setRoutingDialogOpen(true)}>Routenoptimierung</ButtonLinkSmall>.
        </Typography>
      </HelpPopUp>

      <RoutingDialog open={routingDialogOpen} onClose={() => setRoutingDialogOpen(false)} />
    </Box>
  );
};

export default MobilityOptionsRight;
