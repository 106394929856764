import { useParams } from 'react-router-dom';

const useMunicipalityIso = () => {
  const { municipalityIso: municipalityIsoString } = useParams();

  const municipalityIso = parseInt(municipalityIsoString as string, 10);

  return municipalityIso;
};

export default useMunicipalityIso;
