import React from 'react';
import { useMatch } from 'react-router-dom';
import FooterWrapper, { LinkType } from './FooterWrapper';
import useStore from '../../setup/global-state';
import { UserRole } from '../../services/api';
import RouteFactory from '../../services/RouteFactory';
import Audience from '../../types/Audience';

const leftLinks: LinkType[] = [{ label: 'Login für BeraterInnen', to: '/beratung/login' }];
const leftAuthenticatedLinks: LinkType[] = [{ label: 'Login ändern', to: '/beratung/login-aendern' }];

const MainFooter = () => {
  const matchAudience = useMatch('/:audience/*');
  const user = useStore((state) => state.user);

  let left: LinkType[] = leftLinks;
  if (user) {
    if (user.role !== UserRole.USER) {
      left = leftAuthenticatedLinks;
    } else {
      left = [];
    }
  }

  const audience = user
    ? RouteFactory.userToAudience(user)
    : RouteFactory.slugToAudience(matchAudience?.params.audience);

  return (
    <FooterWrapper
      left={[
        ...left,
        {
          label: 'Hilfe',
          to: '/hilfe',
        },
      ]}
      right={[
        {
          label: 'Datenschutz',
          to: `/datenschutz/${RouteFactory.audienceToSlug(
            audience !== Audience.CONSULTING ? audience : Audience.COMMUTER,
          )}`,
        },
        { label: 'Impressum', to: '/impressum' },
        { label: 'Informationen zum Forschungsprojekt', href: 'https://actnow.jetzt/' },
      ]}
    />
  );
};

export default MainFooter;
