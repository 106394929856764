import React from 'react';
import NavigationLinks from './NavigationLinks';
import SubNavigationBefore from './SubNavigationBefore';
import useQueryMunicipality from '../../hooks/queries/useQueryMunicipality';

const MunicipalitySubNavigation = () => {
  const { municipalityIso, data: municipality } = useQueryMunicipality();

  return (
    <NavigationLinks
      before={<SubNavigationBefore>{municipality?.name}</SubNavigationBefore>}
      links={[
        { label: 'Übersicht', to: `/beratung/gemeinden/${municipalityIso}`, end: true },

        ...(municipality && municipality.organizations.length > 0
          ? [
              { label: 'Potenziale', to: `/beratung/gemeinden/${municipalityIso}/potenziale` },
              {
                label: 'Fahrgemeinschaften',
                to: `/beratung/gemeinden/${municipalityIso}/fahrgemeinschaften`,
              },
            ]
          : []),
      ]}
    />
  );
};

export default MunicipalitySubNavigation;
