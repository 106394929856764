/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type NotifyBySmsActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: NotifyBySmsActionStructure.actionStatus;
    beforeNotices?: BeforeNotices;
    clearNotice?: boolean;
    description?: NaturalLanguageStringStructure;
    phone?: string;
    premium?: boolean;
};

export namespace NotifyBySmsActionStructure {

    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }


}

