import React from 'react';
import { Box } from '@mui/material';

import OrganizationProfileForm from './OrganizationProfileForm';
import useQueryOrganization from '../../hooks/queries/useQueryOrganization';
import PageContainer from '../../components/PageContainer';

const OrganizationProfilePage = () => {
  const { data: organization } = useQueryOrganization();

  return (
    <PageContainer maxWidth="md">
      <Box sx={{ width: '100%' }}>{organization && <OrganizationProfileForm organization={organization} />}</Box>
    </PageContainer>
  );
};

export default OrganizationProfilePage;
