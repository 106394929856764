/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Image = {
    imageBinary?: string;
    imageContent?: Image.imageContent;
    imageRef?: string;
};

export namespace Image {

    export enum imageContent {
        MAP = 'MAP',
        GRAPHIC = 'GRAPHIC',
        LOGO = 'LOGO',
    }


}

