import React from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import QueryKeys from '../../services/QueryKeys';
import { AggregationsService, ApiError, PotentialsRoutingDto } from '../../services/api';
import PotentialsRoutingPage from '../../common/potentials/routing/PotentialsRoutingPage';
import useQueryAggregation from '../../hooks/queries/useQueryAggregation';
import HelpPopUp from '../../components/HelpPopUp';
import ButtonLink from '../../components/ButtonLink';

const AggregationPotentialsRoutingPage = () => {
  const { aggregationId, data: aggregation } = useQueryAggregation();
  const { data: potentials, error } = useQuery<PotentialsRoutingDto, ApiError, PotentialsRoutingDto>(
    QueryKeys.aggregations.potentialsRouting(aggregationId as string),
    () => AggregationsService.getPotentialsRouting({ id: aggregationId as string }),
    { retry: false },
  );

  return (
    <PotentialsRoutingPage
      potentials={potentials}
      error={error}
      privacyFallback={
        <>
          Momentan sind {aggregation?.organizationsUsersCount} von {aggregation?.organizationsSize} MitarbeiterInnen in
          den Unternehmen dieser aggregierten Ansicht registriert. Ab zwei registrierten PendlerInnen zeigen wir Ihnen
          hier den Status und die Potenziale der Arbeitsweg-Mobilität in allen Unternehmen dieser aggregierten Ansicht.
        </>
      }
      printHeading={`Potenziale | Aggregierte Ansicht: ${aggregation?.name}`}
    >
      {(setOpenDialog) => (
        <HelpPopUp>
          Hier sehen Sie die Kennwerte über alle registrierten MitarbeiterInnen in der aggregierten Ansicht.
          <Box component="span" sx={{ displayPrint: 'none' }}>
            {' '}
            Mehr Infos zur Berechnung{' '}
            <ButtonLink sx={{ textDecoration: 'underline' }} onClick={() => setOpenDialog(true)}>
              hier
            </ButtonLink>
            .
          </Box>{' '}
          Momentan sind {aggregation?.organizationsUsersCount} von {aggregation?.organizationsSize} MitarbeiterInnen
          registriert, davon konnte für {potentials?.potentialUsersCount} PendlerInnen erfolgreich eine Route berechnet
          werden. Der obere Balken zeigt Ihnen an, welcher Prozentsatz der PendlerInnen zum Zeitpunkt der Registrierung
          den jeweiligen Mobilitätsmodus nutzt. Der untere Balken gibt an, für wie viele dieser Modus die empfohlene
          gesundheitseffektive Option ist. Aus dieser Übersicht können Sie einen Überblick über den Status und die
          Potenziale der Arbeitsweg-Mobilität in der aggregierten Ansicht gewinnen.
        </HelpPopUp>
      )}
    </PotentialsRoutingPage>
  );
};

export default AggregationPotentialsRoutingPage;
