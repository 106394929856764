import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CheckboxesGroupProps<T extends string, U extends FieldValues> = {
  label: string;
  name: FieldPath<U>;
  i18nKey: string;
  options: T[];
};

const CheckboxesGroup = <T extends string, U extends FieldValues>({
  label,
  name,
  i18nKey,
  options,
}: CheckboxesGroupProps<T, U>) => {
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset" sx={{ display: 'block', marginBottom: 2 }}>
      <FormLabel component="legend" sx={{ fontWeight: 'bold', color: 'text.primary', marginBottom: 2 }}>
        {label}
      </FormLabel>
      <FormGroup>
        <Controller<FieldValues>
          name={name}
          render={({ field }) => (
            <>
              {options.map((value) => (
                <FormControlLabel
                  key={value}
                  label={<>{t(`${i18nKey}.${value}`)}</>}
                  value={value}
                  control={
                    <Checkbox
                      onChange={(event, checked) => {
                        const fieldValue = field.value || [];

                        if (checked && !fieldValue.includes(value)) {
                          field.onChange([...fieldValue, value]);
                        }

                        if (!checked && fieldValue.includes(value)) {
                          field.onChange(fieldValue.filter((item: T) => item !== value));
                        }
                      }}
                      checked={((field.value as T[]) || []).includes(value)}
                    />
                  }
                />
              ))}
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxesGroup;
