import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Grid,
  Box,
  Stack,
  styled,
  css,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MeasuresSection from '../components/MeasuresSection';
import HoverActions from './HoverActions';

const StyledChip = styled(Chip)(
  ({ theme }) =>
    css`
      font-size: 0.75rem;
      text-transform: uppercase;
      color: ${theme.palette.text.secondary};
      margin-top: ${theme.spacing(0.5)} !important;
      margin-bottom: ${theme.spacing(0.5)} !important; ;
    `,
);

const StyledAccordion = styled(Accordion)(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.spacing(1)};
      border-top: none;
      box-shadow: none;

      &::before {
        height: 0;
      }
    `,
);

type MeasureItemProps = {
  item: {
    id: number;
    title: string;
    category: string;
    effects: string[];
    summary: string;
    paragraphs: string[];
    listParagraphs?: string[];
    conditions?: string | string[];
    associatedMeasures?: string | string[];
    support?: string;
    keywords: string[];
  };
};

const MeasureItem = ({ item }: MeasureItemProps) => {
  const { t } = useTranslation(['translation', 'measuresTranslation']);
  return (
    <StyledAccordion>
      <AccordionSummary className="hover-actions-trigger">
        <Grid container columnSpacing={2} rowSpacing={1} alignItems="center">
          <Grid item xs="auto">
            <Typography variant="h1">M{item.id}</Typography>
          </Grid>

          <Grid item xs="auto" sx={{ display: 'flex' }}>
            <HoverActions title={item.title} id={item.id} />
          </Grid>

          <Grid xs="auto" item marginLeft="auto" sx={{ maxWidth: '100% !important' }}>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              <StyledChip label={<>{t(`MeasuresCategory.${item.category}`)}</>} />
              {item.effects.map((effect) => (
                <StyledChip key={effect} label={<>{t(`MeasuresEffect.${effect}`)}</>} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '2px solid #e9e9e9' }}>
        <Typography variant="h6" mb={4} mt={3}>
          {item.summary}
        </Typography>
        {item.paragraphs.map((par) => (
          <Typography key={par} mb={4}>
            {par}
          </Typography>
        ))}

        {item.listParagraphs && (
          <Box mb={4}>
            <Typography component="ul">
              {item.listParagraphs?.map((listPar) => (
                <Typography component="li" key={listPar}>
                  {listPar}
                </Typography>
              ))}
            </Typography>
          </Box>
        )}
        <MeasuresSection name="Rahmenbedingungen" item={item.conditions} />
        <MeasuresSection name="Begleitmaßnahmen" item={item.associatedMeasures} />
        <MeasuresSection name="Fördermöglichkeiten" item={item.support} />
        <MeasuresSection name="Schlagwörter" item={item.keywords.join('; ')} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default MeasureItem;
