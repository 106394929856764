import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, css, styled, Typography } from '@mui/material';
import thankYouImage from '../../assets/images/commuter/sign-up/5-thank-you.jpg';
import SubmittedPage from '../../components/SubmittedPage';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};

    &:hover {
      background-color: ${theme.palette.secondary.main};
    }
  `,
) as typeof Button;

const MobilityOptionsCalculatingPage = () => {
  const [searchParams] = useSearchParams();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown((prevState) => prevState - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <SubmittedPage title={searchParams.get('title') || ''} image={thankYouImage}>
      <Typography textAlign="center" sx={{ maxWidth: 800, marginBottom: 2 }}>
        Wir berechnen jetzt bessere Arbeitswege für Sie!
        <br />
        <br />
        <strong>Achtung</strong>: ActNow ist kein Routenplaner wie Google Maps oder Scotty - denn der schnellste Weg ist
        nicht immer der Beste! Gesundheit und Umwelt stehen bei den aktiven Routen aus ActNow im Vordergrund. Die
        Haltestelle vor der Haustür lassen wir daher gerne mal links liegen und schlagen Ihnen vor, eine Haltestelle
        später einzusteigen oder früher auszusteigen. Ein kleiner Spaziergang oder etwas Radeln an der frischen Luft
        hilft abzuschalten - und so tun Sie ganz nebenbei Ihrer Gesundheit etwas Gutes!
      </Typography>
      <StyledButton size="large" disabled={countdown > 0} component={Link} to="/pendlerinnen/mobilitaetsoptionen">
        Verstanden{countdown > 0 && ` (${countdown})`}
      </StyledButton>
    </SubmittedPage>
  );
};

export default MobilityOptionsCalculatingPage;
