import { HealthyRoutingUseCase, TripResultStructure } from './api';
import { ReactComponent as WalkIcon } from '../assets/icons/use-cases/walk.svg';
import { ReactComponent as BikeIcon } from '../assets/icons/use-cases/bike.svg';
import { ReactComponent as WalkPtIcon } from '../assets/icons/use-cases/walk-pt.svg';
import { ReactComponent as BikePtIcon } from '../assets/icons/use-cases/bike-pt.svg';
import { ReactComponent as WalkPtBikeIcon } from '../assets/icons/use-cases/walk-pt-bike.svg';
import { ReactComponent as BikePtWalkIcon } from '../assets/icons/use-cases/bike-pt-walk.svg';
import { ReactComponent as CarPtWalkIcon } from '../assets/icons/use-cases/car-pt-walk.svg';
import { ReactComponent as CarIcon } from '../assets/icons/use-cases/car.svg';
import { ReactComponent as MiscIcon } from '../assets/icons/use-cases/misc.svg';

class HealthyRoutingUseCaseService {
  public static getUseCaseFromResultId(resultId?: string) {
    if (resultId) {
      const useCase = resultId.split(':')[0] as HealthyRoutingUseCase;

      if (HealthyRoutingUseCaseService.isUseCase(useCase)) {
        return useCase;
      }
    }

    return undefined;
  }

  private static isUseCase(useCase: string): useCase is HealthyRoutingUseCase {
    return Object.values(HealthyRoutingUseCase).includes(useCase as HealthyRoutingUseCase);
  }

  public static getIcon(useCase: HealthyRoutingUseCase) {
    return {
      [HealthyRoutingUseCase.PEDESTRIAN]: WalkIcon,
      [HealthyRoutingUseCase.BICYCLE]: BikeIcon,
      [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_WALK]: WalkPtIcon,
      [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_BIKE]: BikePtIcon,
      [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_BIKE]: WalkPtBikeIcon,
      [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_WALK]: BikePtWalkIcon,
      [HealthyRoutingUseCase.CAR_PUBLICTRANSPORT_WALK]: CarPtWalkIcon,
      [HealthyRoutingUseCase.CAR]: CarIcon,
      [HealthyRoutingUseCase.MISC]: MiscIcon,
    }[useCase];
  }

  public static findTripResultStructure(tripResult: TripResultStructure[] | undefined, useCase: HealthyRoutingUseCase) {
    return tripResult?.find(({ resultId }) => resultId?.split(':')[0] === useCase);
  }
}

export default HealthyRoutingUseCaseService;
