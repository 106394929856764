import React, { MouseEvent } from 'react';
import { Divider, Typography, styled, css, Box, Stack, Link } from '@mui/material';
import ButtonLink from '../components/ButtonLink';

const StyledRoot = styled(Box)(
  css`
    position: relative;

    *:hover > &,
    .hover-actions-trigger:hover & {
      .hover-actions-title {
        transform: translateY(-6px);
      }

      .hover-actions-stack {
        visibility: visible;
        opacity: 1;
      }
    }
  `,
);

const StyledTypography = styled(Typography)(
  css`
    position: relative;
    transition: transform ease 300ms;
  `,
);

const StyledStack = styled(Stack)(
  () =>
    css`
      position: absolute;
      bottom: -10px;
      visibility: hidden;
      opacity: 0;
      transition: opacity ease 300ms;
    `,
);

const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) =>
    css`
      font-size: 0.75rem;
      color: ${theme.palette.primary.main};
      font-weight: normal;
    `,
) as typeof ButtonLink;

type HoverActionsProps = { title: string; id: number };

const HoverActions = ({ title, id }: HoverActionsProps) => (
  <StyledRoot>
    <StyledTypography className="hover-actions-title">{title}</StyledTypography>
    <StyledStack className="hover-actions-stack" direction="row" spacing={1}>
      <StyledButtonLink>Ansehen</StyledButtonLink>

      <Divider
        sx={{
          borderColor: 'primary.main',
          borderRightWidth: 1,
          height: 10,
          alignSelf: 'center',
          top: 1,
          position: 'relative',
        }}
        orientation="vertical"
        flexItem
      />

      <StyledButtonLink
        component={Link}
        href={`/massnahmen/M${id}.pdf`}
        target="_blank"
        download
        onClick={(event: MouseEvent<HTMLAnchorElement>) => event.stopPropagation()}
      >
        Herunterladen
      </StyledButtonLink>
    </StyledStack>
  </StyledRoot>
);

export default HoverActions;
