/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SotContextType = {
    calcDate?: string;
    calcTime?: string;
    journeyId?: string;
    trainName?: string;
    legIdx?: number;
    legIdxFootPathConnection?: number;
    prevLocRouteIdx?: number;
    curLocRouteIdx?: number;
    locRouteIdxFootPathConnection?: number;
    locMode: SotContextType.locMode;
};

export namespace SotContextType {

    export enum locMode {
        UNKNOWN = 'UNKNOWN',
        FROM_START = 'FROM_START',
        IN_TRAIN = 'IN_TRAIN',
        AT_PASSED_STOP = 'AT_PASSED_STOP',
        AT_CHANGE_STOP = 'AT_CHANGE_STOP',
        BEFORE_TRAVEL = 'BEFORE_TRAVEL',
        AT_DESTINATION = 'AT_DESTINATION',
        ERROR = 'ERROR',
        ERROR_SEARCH_FROM_TRAIN_BEFORE_START = 'ERROR_SEARCH_FROM_TRAIN_BEFORE_START',
        ERROR_IN_RECONSTRUCTION = 'ERROR_IN_RECONSTRUCTION',
        TO_BE_DEFINED_IN_SERVER = 'TO_BE_DEFINED_IN_SERVER',
        ERROR_TRAIN_CANCELLED = 'ERROR_TRAIN_CANCELLED',
        CHECK_COMPLETE_TRAIN = 'CHECK_COMPLETE_TRAIN',
        AT_LAST_USABLE_STOP = 'AT_LAST_USABLE_STOP',
        ERROR_ALL_TRAINS_FILTERED = 'ERROR_ALL_TRAINS_FILTERED',
        ERROR_STAY_IN_CURRENT_CONNECTION = 'ERROR_STAY_IN_CURRENT_CONNECTION',
    }


}

