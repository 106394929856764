import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import PageContainer from '../../components/PageContainer';
import OrganizationsTable from '../organizations/OrganizationsTable';
import useQueryMunicipality from '../../hooks/queries/useQueryMunicipality';

const AggregationPage = () => {
  const { data: municipality } = useQueryMunicipality();

  return (
    <PageContainer>
      <Typography variant="h2" fontSize="1rem" marginBottom={3}>
        Gemeinde
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label="Name" value={municipality?.name || ''} disabled InputLabelProps={{ shrink: true }} />
        </Grid>
      </Grid>

      <Typography variant="h2" fontSize="1rem" marginTop={3} marginBottom={3}>
        Unternehmen
      </Typography>
      <OrganizationsTable
        organizations={municipality?.organizations}
        emptyText="Es wurden noch keine Unternehmen in dieser Gemeinde angelegt"
      />
    </PageContainer>
  );
};

export default AggregationPage;
