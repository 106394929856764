import React, { useEffect, useRef } from 'react';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { Box, styled, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import * as turf from '@turf/turf';
import { createRoot } from 'react-dom/client';
import { PointDto } from '../../../services/api';
import { MunicipalitiesCountDto } from '../../../services/api/models/MunicipalitiesCountDto';
import { ReactComponent as MarkerOrganization } from '../../../assets/icons/markers/organization.svg';
import MapFallback from '../../MapFallback';

const StyledMap = styled(Box)(
  css`
    height: 100%;
    min-height: 14cm;

    @media print {
      height: 14cm;
    }
  `,
);

type CarpoolingMapProps = {
  addressPoints: PointDto[];
  municipalitiesCount: MunicipalitiesCountDto;
  distanceCircles: boolean;
};

const CarpoolingMap = ({ addressPoints, municipalitiesCount, distanceCircles }: CarpoolingMapProps) => {
  const theme = useTheme();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();

  useEffect(() => {
    // Initialize map only once
    if (!mapboxgl.supported() || !mapContainer.current || map.current || addressPoints.length < 1) {
      return;
    }

    const bounds = new mapboxgl.LngLatBounds();
    addressPoints.forEach(({ coordinates }) => bounds.extend(coordinates as [number, number]));

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/trafficon/ckt2mzei625ic18nzjxrv5u76',
      center: bounds.getCenter(),
      zoom: 11,
      touchPitch: false,
      pitchWithRotate: false,
      preserveDrawingBuffer: true,
    });
    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on('load', () => {
      if (!map.current) return;

      const visibilityBounds = new mapboxgl.LngLatBounds();

      addressPoints.forEach(({ coordinates }, index) => {
        const markerElement = document.createElement('div');
        createRoot(markerElement).render(<MarkerOrganization />);

        new mapboxgl.Marker({ element: markerElement, anchor: 'bottom' })
          .setLngLat(coordinates as LngLatLike)
          .addTo(map.current as mapboxgl.Map);

        (distanceCircles ? [2, 5, 10] : [10]).forEach((radius) => {
          const distanceCircle = turf.circle(turf.point(coordinates), radius, {
            units: 'kilometers',
          });

          if (distanceCircles) {
            const id = `distance-circle-${index}-${radius}`;

            map.current?.addSource(id, {
              type: 'geojson',
              data: distanceCircle,
            });

            map.current?.addLayer({
              id,
              type: 'line',
              source: id,
              paint: {
                'line-color': theme.palette.text.secondary,
                'line-width': 2,
                'line-opacity': 0.5,
              },
            });
          }

          if (radius === 10) {
            visibilityBounds.extend(turf.bbox(distanceCircle) as [number, number, number, number]);
          }
        });
      });

      municipalitiesCount.features.forEach(({ geometry: { coordinates } }) => {
        visibilityBounds.extend(coordinates as [number, number]);
      });

      map.current?.fitBounds(visibilityBounds, { padding: 40 });

      map.current?.addSource('municipalitiesCount', {
        type: 'geojson',
        data: municipalitiesCount,
        cluster: true,
        clusterProperties: {
          sum: ['+', ['get', 'count', ['properties']]],
        },
      });

      map.current?.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'municipalitiesCount',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': theme.palette.primary.main,
          'circle-radius': ['step', ['get', 'sum'], 16, 25, 20, 50, 24, 100, 28, 250, 32, 500, 36, 1000, 40],
        },
      });

      map.current?.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'municipalitiesCount',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'sum'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': theme.palette.common.white,
        },
      });

      map.current?.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'municipalitiesCount',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': theme.palette.primary.main,
          'circle-radius': [
            'step',
            ['get', 'count', ['properties']],
            16,
            25,
            20,
            50,
            24,
            100,
            28,
            250,
            32,
            500,
            36,
            1000,
            40,
          ],
        },
      });

      map.current?.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'municipalitiesCount',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': ['get', 'count', ['properties']],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': theme.palette.common.white,
        },
      });
    });
  });

  return mapboxgl.supported() ? <StyledMap ref={mapContainer} /> : <MapFallback />;
};

export default CarpoolingMap;
