import React, { ReactNode } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { QueryKey } from 'react-query/types/core/types';
import OrganizationMutateSelect from '../../components/forms/OrganizationMutateSelect';
import { Consultant, Organization, OrganizationStatus } from '../../services/api';
import StyledTableLink from '../../components/table/StyledTableLink';
import EmptyTable from '../../components/EmptyTable';

type OrganizationsTableProps = {
  organizations?: Organization[];
  renderActions?: (organization: Organization, index: number) => ReactNode;
  emptyText?: string;
} & (
  | {
      searchQueryKey: QueryKey;
      showStatus: true;
      showConsultant: true;
    }
  | { searchQueryKey?: undefined; showStatus?: undefined; showConsultant?: undefined }
);

const OrganizationsTable = ({
  organizations = [],
  searchQueryKey,
  showStatus,
  showConsultant,
  renderActions,
  emptyText,
}: OrganizationsTableProps) => (
  <>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Unternehmen</TableCell>
            {showStatus && <TableCell>Status</TableCell>}
            {showConsultant && <TableCell>Berater</TableCell>}
            <TableCell align="right" sx={{ width: 0 }}>
              MitarbeiterInnen
            </TableCell>
            <TableCell>Adresse</TableCell>
            {renderActions && <TableCell sx={{ width: 0 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map((organization, index) => {
            const { id, name, usersCount, size, address } = organization;

            return (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  <StyledTableLink component={Link} to={`/unternehmen/${id}/status`}>
                    {name}
                  </StyledTableLink>
                </TableCell>
                {searchQueryKey && showStatus && (
                  <TableCell>
                    <OrganizationMutateSelect
                      organization={organization}
                      searchQueryKey={searchQueryKey}
                      label="Organisations-Status"
                      name="status"
                      i18nKey="OrganizationStatus"
                      options={Object.values(OrganizationStatus)}
                    />
                  </TableCell>
                )}
                {searchQueryKey && showConsultant && (
                  <TableCell>
                    <OrganizationMutateSelect
                      organization={organization}
                      searchQueryKey={searchQueryKey}
                      label="Berater"
                      name="consultant"
                      i18nKey="Consultant"
                      options={Object.values(Consultant)}
                    />
                  </TableCell>
                )}
                <TableCell align="right">
                  {usersCount}/{size}
                </TableCell>
                <TableCell>{address}</TableCell>

                {renderActions && <TableCell>{renderActions(organization, index)}</TableCell>}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>

    {emptyText && organizations.length === 0 && <EmptyTable>{emptyText}</EmptyTable>}
  </>
);

export default OrganizationsTable;
