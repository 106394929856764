import React, { useState } from 'react';
import { Box, Button, Collapse, css, Grid, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import { Consultant, OrganizationUserRole } from '../services/api';
import CopyTextField from '../components/forms/CopyTextField';
import ConsultantLink from '../components/ConsultantLink';
import useQueryOrganization from '../hooks/queries/useQueryOrganization';
import useStore from '../setup/global-state';

const StyledTypography = styled(Typography)(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.spacing(3)};
    `,
);

const StyledLink = styled(Typography)(
  ({ theme }) => css`
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    color: ${theme.palette.primary.main};
  `,
) as typeof Typography;

const OrganizationStatusPage = () => {
  const user = useStore((state) => state.user);

  const [invitationVisible, setInvitationVisible] = useState(false);

  const { data: organization } = useQueryOrganization();

  const invitationLink = `${window.location.origin}/pendlerinnen/registrieren/1?token=${organization?.signUpToken}`;

  return (
    <PageContainer>
      <StyledTypography>
        Hallo <strong>{organization?.name}</strong>!
      </StyledTypography>
      <StyledTypography>
        Momentan sind <strong>{organization?.usersCount}</strong> von <strong>{organization?.size}</strong>{' '}
        MitarbeiterInnen registriert.
        {organization && organization.consultant !== Consultant.NOT_ASSIGNED && (
          <>
            {' '}
            Sie werden von <ConsultantLink consultant={organization.consultant} /> betreut.
          </>
        )}
      </StyledTypography>
      <StyledTypography>
        Laden Sie Ihre MitarbeiterInnen dazu ein, sich zu registrieren! Nutzen Sie dafür den direkten Einladungslink
        oder verbreiten Sie den Unternehmenscode intern. Sie können auch die Mustereinladung verwenden – der korrekte
        Einladungslink ist darin enthalten.
      </StyledTypography>
      <Grid container spacing={2} sx={{ marginBottom: (theme) => `${theme.spacing(5)}` }}>
        <Grid item md={6}>
          <CopyTextField label="Einladungslink" value={invitationLink} monospace />
        </Grid>
        <Grid item md={6}>
          <CopyTextField label="Unternehmenscode" value={organization?.signUpToken} monospace />
        </Grid>
      </Grid>

      <Box sx={{ marginBottom: 5, width: '100%' }}>
        <Collapse in={invitationVisible}>
          <CopyTextField
            label="Mustereinladung"
            multiline
            value={`Liebe Kolleginnen, liebe Kollegen,\n\nBewegung ist wichtig für unsere Gesundheit, aber oft fehlt einfach die Zeit dafür oder der „innere Schweinehund“ ist dagegen.\n\nWussten Sie, dass Sie auf Ihrem täglichen Arbeitsweg ganz nebenbei sehr viel für Ihre Gesundheit tun können? Unser Unternehmen/... nimmt am Forschungsprojekt ActNow teil, das sich mit diesem Thema befasst und Ihnen zeigt, wie Sie auf Ihrem Arbeitsweg etwas für Ihre Gesundheit tun können und nebenbei auch einen Beitrag zum Klimaschutz leisten.\n\nWir laden Sie ein, sich an dieser Initiative zu beteiligen und das ActNow-Tool zu nutzen. Dieses errechnet konkrete Routenempfehlungen für Ihren Arbeitsweg und quantifiziert den gesundheitlichen Nutzen Ihres aktiv zurückgelegten Arbeitsweges auf Basis neuester wissenschaftlicher Erkenntnisse. So bekommen Sie konkrete Vorschläge, wie Sie Ihren täglichen Weg zur Arbeit und nach Hause aktiver und klimaschonender zurücklegen können und welche Vorteile das für Sie hat.\n\nIhre Daten werden anonymisiert und nur für statistische Zwecke verwendet. Unser Unternehmen/... bekommt einen Überblick, welche Potenziale an gesünderen Arbeitswegen in der Organisation bestehen und durch welche betrieblichen Maßnahmen die Kolleginnen und Kollegen in aktiver Mobilität unterstützt werden können.\n\nWir laden Sie herzlich ein, sich an unserem Projekt zu Mobilität und Gesundheit zu beteiligen. Melden Sie sich bitte unter dem nachstehenden Link in unserem Unternehmensbereich des ActNow-Tools an:\n\n${invitationLink}\n\nBei Fragen steht Ihnen ... als verantwortliche Person im Unternehmen gerne zur Verfügung.\n\nUnter den Teilnehmerinnen und Teilnehmern verlosen wir ... Zur Teilnahme an der Verlosung antworten Sie bitte auf diese E-Mail.\n\nWir bedanken uns für Ihre Teilnahme und wünschen Ihnen viel Freude und Gesundheit auf Ihren Arbeitswegen.\n\n\nWeitere Infos zum Projekt unter https://actnow.jetzt/`}
          />
        </Collapse>
        <Collapse in={!invitationVisible}>
          <Button size="large" variant="contained" onClick={() => setInvitationVisible(true)}>
            Mustereinladung anzeigen
          </Button>
        </Collapse>
      </Box>

      <StyledLink gutterBottom component={Link} to="../profil">
        Unternehmensprofil bearbeiten
      </StyledLink>
      {user?.organization?.role === OrganizationUserRole.MANAGER && (
        <>
          <br />
          <StyledLink component={Link} to="../login-aendern">
            Login ändern
          </StyledLink>
        </>
      )}
    </PageContainer>
  );
};

export default OrganizationStatusPage;
