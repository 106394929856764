/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HealthEffectType {
    COLON_CANCER = 'COLON_CANCER',
    DIABETES = 'DIABETES',
    HEART_DISEASE = 'HEART_DISEASE',
    STROKE = 'STROKE',
}
