import { Button, css, styled } from '@mui/material';

const MapButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    background-color: ${theme.palette.common.white};

    &:hover {
      background-color: ${theme.palette.secondary.main};
    }
  `,
);

MapButton.defaultProps = { color: 'secondary', variant: 'contained', size: 'large' };

export default MapButton;
