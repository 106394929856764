import { useQuery } from 'react-query';
import { AuthenticationService } from '../../services/api';
import QueryKeys from '../../services/QueryKeys';

type UseQueryAuthenticatedUserProps = { refetchInterval?: number } | undefined;

const useQueryAuthenticatedUser = ({ refetchInterval }: UseQueryAuthenticatedUserProps = {}) =>
  useQuery(QueryKeys.users.authenticated, AuthenticationService.getAuthenticatedUser, {
    ...(refetchInterval && { refetchInterval }),
  });

export default useQueryAuthenticatedUser;
