import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import MobilityOptionsMap from './MobilityOptionsMap';
import { ReactComponent as RouteIcon } from '../../assets/icons/split-page/route.svg';
import MobilityOptionsRight from './MobilityOptionsRight';
import MapButton from './MapButton';
import ButtonLink from '../../components/ButtonLink';
import UserHealthEffectsDialog from '../../components/dialogs/UserHealthEffectsDialog';
import useQueryAuthenticatedUser from '../../hooks/queries/useQueryUser';
import HealthyRoutingUseCaseService from '../../services/HealthyRoutingUseCaseService';
import useStore from '../../setup/global-state';
import MapFallback from '../../common/MapFallback';

const MobilityOptionsPage = () => {
  const setSelectedHealthyRoutingUseCase = useStore((state) => state.setSelectedHealthyRoutingUseCase);
  const [userHealthEffectsDialogOpen, setUserHealthEffectsDialogOpen] = useState(false);
  const { data: { routes } = { routes: undefined } } = useQueryAuthenticatedUser();

  useEffect(() => {
    setSelectedHealthyRoutingUseCase(
      HealthyRoutingUseCaseService.getUseCaseFromResultId(
        routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.[0]?.resultId,
      ),
    );
  }, [routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult, setSelectedHealthyRoutingUseCase]);

  const left = useCallback(
    (onShowMap: () => void) => (
      <SplitPageTextContent Icon={RouteIcon} title={<>Ihre Übersicht</>}>
        <Typography gutterBottom>
          Auf Basis Ihrer Angaben haben wir für Sie aktive Routen berechnet, mit denen Sie auf Ihrem täglichen Weg Ihre
          Gesundheit fördern und gleichzeitig die Umwelt schonen können. Bei ÖV-Routen optimieren wir den aktiv
          zurückgelegten Weg zur bzw. ab der Haltestelle – denn oft lassen sich schon deutliche Gesundheitseffekte
          erreichen, indem man einfach regelmäßig eine Haltestelle früher aussteigt. Zum Vergleich wird die Autoroute
          ausgegeben.
        </Typography>

        <Typography gutterBottom>
          Wenn Sie es nicht schon tun, legen Sie Ihren täglichen Pendelweg doch in Zukunft aktiv zurück! Sie werden
          schon bald einen positiven Effekt auf Ihr Wohlbefinden und Ihre Gesundheit bemerken – dies ist
          wissenschaftlich belegt!
        </Typography>

        <ButtonLink onClick={() => setUserHealthEffectsDialogOpen(true)} sx={{ display: 'block' }}>
          Mehr dazu hier ↦
        </ButtonLink>

        {mapboxgl.supported() ? (
          <MapButton onClick={onShowMap} sx={{ marginTop: 4 }}>
            <Box component="span" sx={{ transform: 'rotate(180deg)', marginRight: 0.5 }}>
              ▾
            </Box>
            Route auf Karte ansehen
          </MapButton>
        ) : (
          <Box marginTop={4}>
            <MapFallback />
          </Box>
        )}
      </SplitPageTextContent>
    ),
    [],
  );

  return (
    <>
      <SplitPage
        rightPaddingDense
        map={<MobilityOptionsMap />}
        left={left}
        right={<MobilityOptionsRight onOpenUserHealthEffectsDialog={() => setUserHealthEffectsDialogOpen(true)} />}
      />

      <UserHealthEffectsDialog
        open={userHealthEffectsDialogOpen}
        onClose={() => setUserHealthEffectsDialogOpen(false)}
      />
    </>
  );
};

export default MobilityOptionsPage;
