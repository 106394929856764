import React from 'react';
import { Typography } from '@mui/material';
import SplitPage from '../components/SplitPage';
import Login from '../components/Login';
import SplitPageTextContent from '../components/SplitPageTextContent';
import Audience from '../types/Audience';
import loginImage from '../assets/images/consulting/login.jpg';
import { ReactComponent as MindIcon } from '../assets/icons/split-page/mind.svg';

const ConsultingLogin = () => (
  <SplitPage
    image={loginImage}
    left={
      <SplitPageTextContent Icon={MindIcon} title={<>Login für BeraterInnen</>}>
        <Typography>Willkommen zurück!</Typography>
      </SplitPageTextContent>
    }
    right={<Login audience={Audience.CONSULTING} />}
  />
);

export default ConsultingLogin;
