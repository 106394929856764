/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdministrativeArea } from './AdministrativeArea';
import type { ExtensionType } from './ExtensionType';
import type { ReferencePointDescription } from './ReferencePointDescription';
import type { RoadName } from './RoadName';

export type ReferencePoint = {
    administrativeArea?: AdministrativeArea;
    directionBound?: ReferencePoint.directionBound;
    directionRelative?: ReferencePoint.directionRelative;
    distanceFromPrevious?: number;
    distanceToNext?: number;
    elevatedRoadSection?: boolean;
    referencePointDescription?: ReferencePointDescription;
    referencePointDistance?: number;
    referencePointExtension?: ExtensionType;
    referencePointIdentifier?: string;
    roadName?: RoadName;
    roadNumber?: string;
};

export namespace ReferencePoint {

    export enum directionBound {
        ANTICLOCKWISE = 'ANTICLOCKWISE',
        CLOCKWISE = 'CLOCKWISE',
        NORTH_BOUND = 'NORTH_BOUND',
        NORTH_EAST_BOUND = 'NORTH_EAST_BOUND',
        EAST_BOUND = 'EAST_BOUND',
        SOUTH_EAST_BOUND = 'SOUTH_EAST_BOUND',
        SOUTH_BOUND = 'SOUTH_BOUND',
        SOUTH_WEST_BOUND = 'SOUTH_WEST_BOUND',
        WEST_BOUND = 'WEST_BOUND',
        NORTH_WEST_BOUND = 'NORTH_WEST_BOUND',
    }

    export enum directionRelative {
        BOTH = 'BOTH',
        NEGATIVE = 'NEGATIVE',
        POSITIVE = 'POSITIVE',
        UNKNOWN = 'UNKNOWN',
    }


}

