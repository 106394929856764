import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, IconButton, Stack } from '@mui/material';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import GeocodingSearchInput from '../../components/forms/GeocodingSearchInput';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const CommuterSignUpStep2Form = () => {
  const { control, watch } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'childcareFacilities',
  });

  const carpoolingChildren = watch('interestChildrenCarpooling');
  const childcareFacilities = watch('childcareFacilities');

  useEffect(() => {
    if (carpoolingChildren && childcareFacilities.length < 1) {
      append({ address: '' });
    }

    if (!carpoolingChildren && childcareFacilities.length > 0) {
      replace([]);
    }
  }, [append, carpoolingChildren, childcareFacilities.length, replace]);

  return (
    <>
      <GeocodingSearchInput label="Wohnadresse" name="address" />

      <FormControl sx={{ display: 'block' }}>
        <Controller
          defaultValue={false}
          name="interestCarpooling"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label="Haben Sie Interesse an Fahrgemeinschaften?"
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ display: 'block' }}>
        <Controller
          defaultValue={false}
          name="interestChildrenCarpooling"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label="Haben Sie Kinderbegleitwege zu absolvieren und würden gerne eine Fahrgemeinschaft bilden?"
            />
          )}
        />
      </FormControl>

      <Collapse in={carpoolingChildren} mountOnEnter unmountOnExit>
        <Stack spacing={1}>
          {fields.map((field, index) => (
            <Stack key={field.id} direction="row">
              <Box sx={{ flex: '1 0 auto' }}>
                <GeocodingSearchInput label="Kinderbetreuungsstätte" name={`childcareFacilities.${index}.address`} />
              </Box>

              <IconButton
                disabled={fields.length <= 1}
                onClick={() => remove(index)}
                sx={{
                  alignSelf: 'center',
                  marginLeft: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          ))}

          <Button
            sx={{ marginRight: 'auto' }}
            onClick={() => {
              if (fields.length < 2) {
                append({ address: '' });
              }
            }}
            disabled={fields.length >= 2}
          >
            {fields.length < 2 ? '+ Weitere hinzufügen' : 'Maximum erreicht'}
          </Button>
        </Stack>
      </Collapse>
    </>
  );
};

export default CommuterSignUpStep2Form;
