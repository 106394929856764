import React, { ComponentProps } from 'react';
import { Outlet } from 'react-router-dom';
import PageLayout from './PageLayout';
import AuthenticatedNavigation from '../navigations/AuthenticatedNavigation';
import Authenticated from '../../authentication/Authenticated';
import MainFooter from '../footer/MainFooter';

type ProtectedLayoutProps = Pick<
  ComponentProps<typeof Authenticated>,
  'authorizedRoles' | 'authorizedOrganizationRoles'
>;

const ProtectedLayout = ({ authorizedRoles, authorizedOrganizationRoles }: ProtectedLayoutProps) => (
  <Authenticated authorizedRoles={authorizedRoles} authorizedOrganizationRoles={authorizedOrganizationRoles}>
    <PageLayout header={<AuthenticatedNavigation />} footer={<MainFooter />}>
      <Outlet />
    </PageLayout>
  </Authenticated>
);

export default ProtectedLayout;
