/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternationalTextStructure } from './InternationalTextStructure';

export type FacilityStatusStructure = {
    availability?: FacilityStatusStructure.availability;
    status?: FacilityStatusStructure.status;
    statusDescription?: InternationalTextStructure;
};

export namespace FacilityStatusStructure {

    export enum availability {
        UNKNOWN = 'UNKNOWN',
        AVAILABLE = 'AVAILABLE',
        NOT_AVAILABLE = 'NOT_AVAILABLE',
        PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE',
        ADDED = 'ADDED',
        REMOVED = 'REMOVED',
    }

    export enum status {
        OK = 'OK',
        DIRTY = 'DIRTY',
        DESTROYED = 'DESTROYED',
        DAMAGED = 'DAMAGED',
        STOLEN = 'STOLEN',
        OUT_OF_ORDER = 'OUT_OF_ORDER',
    }


}

