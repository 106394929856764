/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataNameSpacesStructure } from './DataNameSpacesStructure';
import type { Duration } from './Duration';

export type ServiceRequestContextStructure = {
    allowedPredictors?: ServiceRequestContextStructure.allowedPredictors;
    checkStatusAddress?: string;
    confirmDelivery?: boolean;
    consumerAddress?: string;
    dataHorizon?: Duration;
    dataNameSpaces?: DataNameSpacesStructure;
    deliveryMethod?: ServiceRequestContextStructure.deliveryMethod;
    getDataAddress?: string;
    gmlCoordinateFormat?: string;
    language?: string;
    manageSubscriptionAddress?: string;
    maximimumNumberOfSubscriptions?: number;
    multipartDespatch?: boolean;
    notifyAddress?: string;
    predictionFunction?: string;
    requestTimeout?: Duration;
    statusResponseAddress?: string;
    subscribeAddress?: string;
    subscriberAddress?: string;
    wgsDecimalDegrees?: string;
};

export namespace ServiceRequestContextStructure {

    export enum allowedPredictors {
        AVMS_ONLY = 'AVMS_ONLY',
        ANYONE = 'ANYONE',
    }

    export enum deliveryMethod {
        DIRECT = 'DIRECT',
        FETCHED = 'FETCHED',
    }


}

