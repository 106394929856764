import React from 'react';
import NavigationLinks from './NavigationLinks';
import SubNavigationBefore from './SubNavigationBefore';
import useQueryAggregation from '../../hooks/queries/useQueryAggregation';

const AggregationSubNavigation = () => {
  const { aggregationId, data: aggregation } = useQueryAggregation();

  return (
    <NavigationLinks
      before={<SubNavigationBefore>{aggregation?.name}</SubNavigationBefore>}
      links={[
        { label: 'Bearbeiten', to: `/beratung/aggregierte-ansichten/${aggregationId}`, end: true },

        ...(aggregation && aggregation.organizations.length > 0
          ? [
              { label: 'Potenziale', to: `/beratung/aggregierte-ansichten/${aggregationId}/potenziale` },
              {
                label: 'Fahrgemeinschaften',
                to: `/beratung/aggregierte-ansichten/${aggregationId}/fahrgemeinschaften`,
              },
            ]
          : []),
      ]}
    />
  );
};

export default AggregationSubNavigation;
