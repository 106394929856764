import { Consultant, OrganizationStatus } from './api';
import { LocationNameService } from './api-geocoding';

class QueryKeys {
  static users = {
    all: ['users'],
    authenticated: ['authenticatedUser'],
    id: (id: string) => [...this.users.all, id],
    idCarpools: (id: string) => [...this.users.id(id), 'carpools'],
  };

  static organizations = {
    all: ['organizations'],
    filter: (filter: { search?: string; status?: OrganizationStatus[]; consultant?: Consultant[] }) => [
      ...this.organizations.all,
      filter,
    ],
    id: (id: string) => [...this.organizations.all, id],
    potentialsRouting: (id: string) => [...this.organizations.id(id), 'potentialsRouting'],
    potentialsCarpooling: (id: string) => [...this.organizations.id(id), 'potentialsCarpooling'],
  };

  static aggregations = {
    all: ['aggregations'],
    search: (search: string) => [...this.aggregations.all, search],
    id: (id: string) => [...this.aggregations.all, id],
    potentialsRouting: (id: string) => [...this.aggregations.id(id), 'potentialsRouting'],
    potentialsCarpooling: (id: string) => [...this.aggregations.id(id), 'potentialsCarpooling'],
  };

  static municipalities = {
    all: ['municipalities'],
    search: (search: string) => [...this.municipalities.all, search],
    iso: (iso: number) => [...this.municipalities.all, iso],
    potentialsRouting: (iso: number) => [...this.municipalities.iso(iso), 'potentialsRouting'],
    potentialsCarpooling: (iso: number) => [...this.municipalities.iso(iso), 'potentialsCarpooling'],
  };

  static geocoder = {
    all: ['geocoder'],
    query: (parameter: Parameters<typeof LocationNameService.verb1>[0]) => [...this.geocoder.all, parameter],
  };
}

export default QueryKeys;
