import React from 'react';
import { Typography, Link } from '@mui/material';
import { ReactComponent as HealthRiskChart } from '../../assets/icons/chart.svg';
import DialogContainer, { DialogContainerProps } from './DialogContainer';
import DialogTypography from './DialogTypography';
import DialogTypographySource from './DialogTypographySource';

type UserHealthEffectsDialogProps = Omit<DialogContainerProps, 'children'>;

const UserHealthEffectsDialog = ({ open, onClose }: UserHealthEffectsDialogProps) => (
  <DialogContainer open={open} onClose={onClose}>
    <DialogTypography fontWeight="bold">Körperliche Aktivität, Mobilität und Gesundheit</DialogTypography>
    <DialogTypography>
      Die Wichtigkeit von körperlicher Aktivität für die Gesundheit ist immens. Mehr frühzeitige Todesfälle sind auf
      mangelnde körperliche Aktivität als auf Rauchen Zurückzuführen (Lee et al. 2012). „Sitzen ist das neue Rauchen“,
      ist eine häufig zitierte Zusammenfassung der wissenschaftlichen Studienlage.
    </DialogTypography>
    <DialogTypography>
      Aktive, also muskelbetriebene Mobilität leistet einen wichtigen Beitrag zur Erhöhung der körperlichen Aktivität
      und damit zur Verbesserung der Gesundheit. Der tägliche Arbeitsweg bietet enormes Potenzial, um den negativen
      Folgen eines sitzenden Lebensstils entgegenzuwirken. Personen, die ihren Arbeitsweg mit dem Fahrrad zurücklegen,
      haben – gemäß einer Studie aus UK mit über 200.000 Probanden – nur ein halb so großes Mortalitätsrisiko als
      Personen, die den Arbeitsweg körperlich inaktiv zurücklegen (Celis-Morales et al. 2017). Insgesamt kann das
      Mortalitätsrisiko durch aktive Pendelmobilität um 9% verringert werden (Dutheil et al. 2020).
    </DialogTypography>
    <DialogTypography>
      Der gesundheitliche Nutzen aktiver Mobilität hat auch große betriebs- und volkswirtschaftliche Effekte. Die
      Bandbreite reicht dabei von einer Reduktion der Krankenstandstage und der Erhöhung der Produktivität, bis hin zu
      Behandlungs- und weiteren indirekten Kosten. Eine Studie aus den Niederlanden kommt zu dem Schluss, dass der
      dortige hohe Anteil des Radverkehrs zu volkswirtschaftlichen Effekten im Ausmaß von 3% des BIPs führt (Fishman et
      al. 2015).
    </DialogTypography>
    <DialogTypography fontWeight="bold">Bewegungsempfehlungen</DialogTypography>
    <DialogTypography>
      Die Weltgesundheitsorganisation{' '}
      <Link underline="none" sx={{ color: 'primary.main' }} href="https://www.who.int/" target="_blank" rel="noopener">
        (WHO)
      </Link>{' '}
      empfiehlt für Erwachsene pro Woche mindestens 150-300 Minuten körperliche Aktivität in moderater Intensität oder
      75-150 Minuten in hoher Intensität. Das entspricht – je nach Alter und Trainingszustand – beispielsweise in
      flottem Tempo gehen oder im Doppel Tennis zu spielen (mittlere Intensität) bzw. mit mindestens 18 km/h Fahrrad
      fahren, Badminton oder Fußball zu spielen (höhere Intensität). Viele weitere Informationen und Beispiele finden
      Sie dazu in den{' '}
      <Link
        underline="none"
        sx={{ color: 'primary.main' }}
        target="_blank"
        rel="noopener"
        href="https://fgoe.org/sites/fgoe.org/files/2020-06/WB17_bewegungsempfehlungen_bfrei.pdf"
      >
        Österreichischen Bewegungsempfehlungen
      </Link>{' '}
      des Fonds Gesundes Österreich. Im ActNow-Tool wird berechnet, welchen Beitrag ein aktiv zurückgelegter Arbeitsweg
      zur Erreichung der Bewegungsempfehlung leisten kann. Dabei wird eine durchschnittliche Gehgeschwindigkeit von
      4km/h und 15 km/h für Fahrradstrecken angenommen. Die Steigung wird entsprechend berücksichtigt. Aus den Faktoren
      Verkehrsmittel, Geschwindigkeit, Steigung und Distanz wird der Energieumsatz je Zeiteinheit und Route abgeleitet.
      Die ausgegebenen Werte gehen davon aus, dass die Pendelstrecke an 80% der Arbeitstage (bei Vollzeitbeschäftigung)
      wie empfohlen zurückgelegt wird.
    </DialogTypography>
    <DialogTypography fontWeight="bold">Individuelle Gesundheitseffekte</DialogTypography>
    <DialogTypography>
      Welche konkrete gesundheitliche Nutzen sich aus dem aktiv zurückgelegten Arbeitsweg ergeben, kann auf Basis der
      vorhandenen Daten und ohne sportmedizinische Untersuchung nicht gesagt werden. Für sogenannte prognostische Werte
      liegen nur Durchschnittswerte vor. Das bedeutet, aus wissenschaftlichen Studien kann beispielsweise abgeleitet
      werden, dass das Risiko für einen Schlaganfall im Durchschnitt um 10% niedriger liegt, wenn statt eines inaktiven
      Lebensstils pro Woche 100 Minuten gejoggt wird (Kyu et al. 2016). Durch die Durchschnittsbildung kann aus diesem
      Wert allerdings nicht rückgeschlossen werden, dass eine konkrete Person, die 100 Minuten pro Woche joggt, ein 10%
      geringeres Schlaganfallrisiko hat. In der Statistik nennt man dies einen statistischen Fehlschluss. Ein Vergleich
      aus dem Alltag verdeutlicht noch einmal das Problem: Franzis Opa raucht seit seinem 15. Lebensjahr täglich zwei
      Packungen Zigaretten. Weil der Opa 90 Jahre alt wurde, schließt Franzi, dass Rauchen eigentlich nicht schädlich
      sei. Würde Franzi aber die Biographie von 10.000 Großvätern kennen, wäre schnell offensichtlich, dass RaucherInnen
      im Durchschnitt (also inklusive Ausreißer nach oben, wie der eigene Opa) weniger alt werden als Nichtraucher.
    </DialogTypography>
    <DialogTypography fontWeight="bold">Körperliche Aktivität pro Woche</DialogTypography>
    <DialogTypography>
      Vor diesem Hintergrund macht sich das ActNow-Tool das Konzept von durchschnittlicher Aktivität zu nutzen.
      Basierend drauf wird der Energieumsatz je Woche, ausgedrückt in metabolischen Äquivalenten je Zeiteinheit
      (MET-Minuten), berechnet. Dafür wird auf ein Standardwerk der Medizin bzw. Sportwissenschaft zurückgegriffen
      (Ainsworth et al. 2011). Zu diesem lebensstilbedingten Grundumsatz wird dann der Energieumsatz je Routenempfehlung
      aus dem ActNow-Tool addiert.
    </DialogTypography>
    <DialogTypography fontWeight="bold">Prognostische Effekte</DialogTypography>
    <DialogTypography>
      Der berechnete Gesamtenergieumsatz wird dann in Risikoprofile für Darmkrebs, Diabetes, Herzerkrankung und
      Schlaganfall übersetzt. Dazu werden die Ergebnisse aus einer der größten Studien zum Verhältnis von körperlicher
      Aktivität und Gesundheitseffekte (Kyu et al. 2016) verwendet. Insgesamt flossen die Daten von fast 150 Millionen
      Personen in diese Untersuchung ein.
    </DialogTypography>
    <Typography textAlign="center" mb={3}>
      <HealthRiskChart />
    </Typography>
    <DialogTypography>
      Die prognostischen Effekte eines aktiv zurückgelegten Arbeitsweges werden im ActNow-Tool für Ihr Profil berechnet
      und für jede Routenoption ausgegeben. Damit wird der gesundheitliche Nutzen eines aktiv zurückgelegten
      Arbeitsweges offensichtlich. Es kann aber nicht unmittelbar auf einen Effekt in dieser Größenordnung für sich
      selbst geschlossen werden, es handelt sich um Durchschnittswerte.
    </DialogTypography>
    <DialogTypographySource>
      LEE, I. M., SHIROMA, E. J., LOBELO, F., PUSKA, P., BLAIR, S. N. & KATZMARZYK, P. T. 2012. Effect of physical
      inactivity on major non-communicable diseases worldwide: an analysis of burden of disease and life expectancy. The
      Lancet, 380, 219-229.
    </DialogTypographySource>
    <DialogTypographySource>
      CELIS-MORALES, C. A., LYALL, D. M., WELSH, P., ANDERSON, J., STEELL, L., GUO, Y., MALDONADO, R., MACKAY, D. F.,
      PELL, J. P., SATTAR, N. & GILL, J. M. R. 2017. Association between active commuting and incident cardiovascular
      disease, cancer, and mortality: prospective cohort study. BMJ, 357.
    </DialogTypographySource>
    <DialogTypographySource>
      DUTHEIL, F., PÉLANGEON, S., DUCLOS, M., VORILHON, P., MERMILLOD, M., BAKER, J. S., PEREIRA, B. & NAVEL, V. 2020.
      Protective Effect on Mortality of Active Commuting to Work: A Systematic Review and Meta-analysis. Sports
      Medicine, 50, 2237-2250.
    </DialogTypographySource>
    <DialogTypographySource>
      FISHMAN, E., SCHEPERS, P. & KAMPHUIS, C. B. M. 2015. Dutch Cycling: Quantifying the Health and Related Economic
      Benefits. American Journal of Public Health, 105, e13-e15.
    </DialogTypographySource>
    <DialogTypographySource>
      KYU, H. H., BACHMAN, V. F., ALEXANDER, L. T., MUMFORD, J. E., AFSHIN, A., ESTEP, K., VEERMAN, J. L., DELWICHE, K.,
      IANNARONE, M. L., MOYER, M. L., CERCY, K., VOS, T., MURRAY, C. J. L. & FOROUZANFAR, M. H. 2016. Physical activity
      and risk of breast cancer, colon cancer, diabetes, ischemic heart disease, and ischemic stroke events: systematic
      review and dose-response meta-analysis for the Global Burden of Disease Study 2013. BMJ, 354, i3857.
    </DialogTypographySource>
    <DialogTypographySource>
      AINSWORTH, B. E., HASKELL, W. L., HERRMANN, S. D., MECKES, N., BASSETT, D. R. J., TUDOR-LOCKE, C., GREER, J. L.,
      VEZINA, J., WHITT-GLOVER, M. C. & LEON, A. S. 2011. 2011 Compendium of Physical Activities: A Second Update of
      Codes and MET Values. Medicine & Science in Sports & Exercise, 43, 1575-1581.
    </DialogTypographySource>
    <DialogTypographySource>
      KYU, H. H., BACHMAN, V. F., ALEXANDER, L. T., MUMFORD, J. E., AFSHIN, A., ESTEP, K., VEERMAN, J. L., DELWICHE, K.,
      IANNARONE, M. L., MOYER, M. L., CERCY, K., VOS, T., MURRAY, C. J. L. & FOROUZANFAR, M. H. 2016. Physical activity
      and risk of breast cancer, colon cancer, diabetes, ischemic heart disease, and ischemic stroke events: systematic
      review and dose-response meta-analysis for the Global Burden of Disease Study 2013. BMJ, 354, i3857.
    </DialogTypographySource>
  </DialogContainer>
);

export default UserHealthEffectsDialog;
