import React from 'react';
import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigationWrapper from './NavigationWrapper';
import ActNowLogo from '../ActNowLogo';
import NavigationLinks from './NavigationLinks';

const HomeNavigation = () => (
  <NavigationWrapper sections={2}>
    <Box>
      <Link to="/" style={{ display: 'inline-block' }}>
        <ActNowLogo />
      </Link>
    </Box>

    <NavigationLinks
      links={[{ label: 'Forschungsprojekt', href: 'https://actnow.jetzt/' }]}
      after={
        <Button color="primary" component={Link} to="/">
          Tools
        </Button>
      }
    />
  </NavigationWrapper>
);

export default HomeNavigation;
