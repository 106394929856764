import React from 'react';
import { Alert, AlertTitle, Link, Typography } from '@mui/material';

const MapFallback = () => (
  <Alert icon={false} severity="info" variant="outlined" sx={{ backgroundColor: 'secondary.main' }}>
    <AlertTitle sx={{ fontWeight: 'bold' }}>WebGL wird für die Kartendarstellung benötigt</AlertTitle>
    <Typography>
      Bitte{' '}
      <Link href="https://get.webgl.org/" target="_blank" rel="noopener">
        überprüfen Sie hier
      </Link>
      , ob Ihr Browser WebGL unterstützt. Sollte WebGL deaktiviert sein, kontaktieren Sie bitte die IT-Abteilung Ihres
      Unternehmens.
    </Typography>
  </Alert>
);

export default MapFallback;
