import React from 'react';
import { Button, Stack, css, styled } from '@mui/material';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    min-width: ${theme.spacing(7)};
    padding-left: 0;
    padding-right: 0;

    &.Mui-disabled {
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
      border-width: 2px;
    }
  `,
);

type FormStepperProps = { active: number };

const FormStepper = ({ active }: FormStepperProps) => (
  <Stack direction="row" spacing={2} marginTop={6}>
    {[0, 1, 2].map((value) =>
      value === active ? (
        <Button key={value} type="submit" size="large" variant="contained">
          ✓ Schritt {value + 1}
        </Button>
      ) : (
        <StyledButton key={value} disabled size="large" variant="outlined">
          {value + 1}
        </StyledButton>
      ),
    )}
  </Stack>
);

export default FormStepper;
