import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PageContainer from './PageContainer';

const NotFoundPage = () => (
  <PageContainer>
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1" textAlign="center">
          Seite nicht gefunden
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button component={Link} to="/">
          Zur Startseite
        </Button>
      </Grid>
    </Grid>
  </PageContainer>
);

export default NotFoundPage;
