import React, { ReactNode } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import useStore from '../setup/global-state';
import { OrganizationUserRole, UserRole } from '../services/api';
import NotFoundPage from '../components/NotFoundPage';
import RouteFactory from '../services/RouteFactory';

type AuthenticatedProps = {
  authorizedRoles?: UserRole[];
  authorizedOrganizationRoles?: OrganizationUserRole[];
  children: ReactNode;
};

const Authenticated = ({ authorizedRoles, authorizedOrganizationRoles, children }: AuthenticatedProps) => {
  const user = useStore((state) => state.user);
  const location = useLocation();
  const matchAudience = useMatch('/:audience/*');

  if (!user) {
    return (
      <Navigate
        to={`/${RouteFactory.audienceToSlug(RouteFactory.slugToAudience(matchAudience?.params?.audience || ''))}/login`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    (!!authorizedRoles && authorizedRoles.includes(user.role)) ||
    (!!authorizedOrganizationRoles &&
      !!user.organization &&
      authorizedOrganizationRoles.includes(user.organization.role))
  ) {
    return children as JSX.Element;
  }

  return <NotFoundPage />;
};

export default Authenticated;
