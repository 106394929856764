import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import SplitPage from '../../components/SplitPage';
import step1Image from '../../assets/images/organization/sign-up/1-step.jpg';
import { ReactComponent as WaveIcon } from '../../assets/icons/split-page/wave.svg';
import AuthHeading from '../../components/AuthHeading';
import { Consultant } from '../../services/api';
import ConsultantLink from '../../components/ConsultantLink';

const OrganizationSignUpStep1 = () => (
  <SplitPage
    image={step1Image}
    left={
      <SplitPageTextContent
        Icon={WaveIcon}
        title={
          <>
            Potenziale erkennen,
            <br />
            effizient planen.
          </>
        }
      >
        <Typography>
          Betriebliches Mobilitätsmanagement trifft Gesundheitsförderung. Das Forschungsprojekt ActNow möchte Ihrem
          Unternehmen und Ihren MitarbeiterInnen dabei helfen, die Effekte täglicher Mobilität zu erkennen und
          Potenziale auszuschöpfen. Mit einer Registrierung und kostenlosen Beratung können Sie viel erreichen:
        </Typography>

        <ul>
          <li>Unterstützen Sie MitarbeiterInnen, nachhaltige und gesundheitsfördernde Routen zu entdecken</li>
          <li>Sparen Sie Kosten für Krankenstandstage und Parkplätze</li>
          <li>Etablieren Sie Fahrgemeinschaften im Unternehmen</li>
          <li>Erhalten Sie Maßnahmenvorschläge zur Förderung aktiver und nachhaltiger Mobilität</li>
          <li>Verbessern Sie die Umweltbilanz Ihres Unternehmens</li>
        </ul>

        <Typography>
          <strong>Jetzt mitmachen und Veränderung bewirken ↦</strong>
        </Typography>
      </SplitPageTextContent>
    }
    right={
      <>
        <AuthHeading>Registrierung für Unternehmen</AuthHeading>

        <Typography gutterBottom>Danke für Ihr Interesse am ActNow-Arbeitswegetool!</Typography>
        <Typography gutterBottom>
          Momentan ist das Tool nur in Kombination mit einer Beratung bei unseren Partnern der{' '}
          <ConsultantLink consultant={Consultant.IBG} /> und <ConsultantLink consultant={Consultant.KOMOBILE} /> im
          Rahmen des Forschungsprojekts ActNow einsetzbar. Wenn Sie jetzt Ihr Unternehmen registrieren, können diese Sie
          kontaktieren, um Sie unverbindlich über das Beratungsangebot zu informieren und Ihren Account freizuschalten.
        </Typography>
        <Typography>Wir freuen uns über Ihre Teilnahme am ActNow-Projekt!</Typography>

        <Button
          sx={{ marginTop: 8 }}
          color="primary"
          size="large"
          variant="contained"
          component={RouterLink}
          to="/unternehmen/registrieren/2"
        >
          Jetzt registrieren
        </Button>
      </>
    }
  />
);

export default OrganizationSignUpStep1;
