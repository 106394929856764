/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';
import type { OperatorRefStructure } from './OperatorRefStructure';
import type { WebLinkStructure } from './WebLinkStructure';

export type SharingServiceStructure = {
    infoURL?: WebLinkStructure;
    name?: string;
    operatorRef?: OperatorRefStructure;
    sharingModel?: SharingServiceStructure.sharingModel;
    timeBufferAfter?: Duration;
    timeBufferBefore?: Duration;
};

export namespace SharingServiceStructure {

    export enum sharingModel {
        SINGLE_STATION_BASED = 'SINGLE_STATION_BASED',
        MULTIPLE_STATION_BASED = 'MULTIPLE_STATION_BASED',
        NON_STATION_BASED = 'NON_STATION_BASED',
    }


}

