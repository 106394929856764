import { css, styled, Typography } from '@mui/material';

const StyledTableLink = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
) as typeof Typography;

export default StyledTableLink;
