import React from 'react';
import { Typography } from '@mui/material';
import SplitPage from '../components/SplitPage';
import Login from '../components/Login';
import SplitPageTextContent from '../components/SplitPageTextContent';
import Audience from '../types/Audience';
import loginImage from '../assets/images/commuter/login.jpg';
import { ReactComponent as TandemIcon } from '../assets/icons/split-page/tandem.svg';

const CommuterLogin = () => (
  <SplitPage
    image={loginImage}
    left={
      <SplitPageTextContent
        Icon={TandemIcon}
        title={
          <>
            Allianz
            <br />
            aktive
            <br />
            Mobilität
          </>
        }
      >
        <Typography>Willkommen zurück!</Typography>
      </SplitPageTextContent>
    }
    right={<Login audience={Audience.COMMUTER} />}
  />
);

export default CommuterLogin;
