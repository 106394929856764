import React, { useCallback, useEffect, useRef } from 'react';
import mapboxgl, { GeoJSONSource, LngLatLike } from 'mapbox-gl';
import { Feature, FeatureCollection, LineString, Point, Position } from 'geojson';
import { useTheme } from '@mui/material';
import * as turf from '@turf/turf';
import { createRoot } from 'react-dom/client';
import {
  ContinuousServiceStructure,
  GeoPositionStructure,
  TrackSectionStructure,
  Trias,
  TripResultStructure,
} from '../services/api';
import HealthyRoutingUseCaseService from '../services/HealthyRoutingUseCaseService';
import { ReactComponent as MarkerOrganization } from '../assets/icons/markers/organization.svg';
import { ReactComponent as MarkerUser } from '../assets/icons/markers/user.svg';
import useStore from '../setup/global-state';

const SOURCE_ID = { ROUTE_LINE_STRINGS: 'ROUTE_LINE_STRINGS', INTERCHANGE_POINTS: 'INTERCHANGE_POINTS' };

const transformCoordinates = (coordinates: GeoPositionStructure): Position => [
  coordinates.longitude as number,
  coordinates.latitude as number,
];

const trackSectionToLineString = (trackSection?: TrackSectionStructure[]) => {
  const lineStringCoordinates: Position[] = [];

  trackSection?.forEach((section) => {
    section.projection?.position?.forEach((coordinates) => {
      lineStringCoordinates.push(transformCoordinates(coordinates));
    });
  });

  return lineStringCoordinates;
};

const getGeoJsonFromTripResultStructure = (
  tripResult: TripResultStructure | undefined,
): { routeLineStrings: FeatureCollection<LineString>; interchangePoints: FeatureCollection<Point> } => {
  const routeLineStrings: FeatureCollection<LineString> = {
    type: 'FeatureCollection',
    features: [],
  };
  const interchangePoints: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: [],
  };

  if (tripResult === undefined) return { routeLineStrings, interchangePoints };

  tripResult.trip?.tripLeg?.forEach((tripLeg, index) => {
    const lineString: LineString = {
      type: 'LineString',
      coordinates: [],
    };

    if (tripLeg.continuousLeg) {
      const { continuousLeg } = tripLeg;

      const color = [
        ContinuousServiceStructure.individualMode.WALK,
        ContinuousServiceStructure.individualMode.CYCLE,
      ].includes(continuousLeg.service?.individualMode as ContinuousServiceStructure.individualMode)
        ? 'ACTIVE'
        : 'INACTIVE';
      const mode = [
        ContinuousServiceStructure.individualMode.WALK,
        ContinuousServiceStructure.individualMode.CYCLE,
        ContinuousServiceStructure.individualMode.SELF_DRIVE_CAR,
      ].includes(continuousLeg.service?.individualMode as ContinuousServiceStructure.individualMode)
        ? continuousLeg.service?.individualMode
        : 'OTHER';

      const legStart = continuousLeg.legStart?.geoPosition;
      if (legStart) {
        interchangePoints.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: transformCoordinates(legStart),
          },
          properties: { color, mode, ...(index !== 0 && { locationName: continuousLeg.legStart?.locationName?.text }) },
        });

        lineString.coordinates.push(transformCoordinates(legStart));
      }

      if (continuousLeg.legTrack?.trackSection) {
        lineString.coordinates = [
          ...lineString.coordinates,
          ...trackSectionToLineString(continuousLeg.legTrack.trackSection),
        ];
      }

      const legEnd = continuousLeg.legEnd?.geoPosition;
      if (legEnd) {
        lineString.coordinates.push(transformCoordinates(legEnd));
      }
    }

    if (tripLeg.timedLeg?.legTrack?.trackSection) {
      const {
        timedLeg: {
          legTrack: { trackSection },
        },
      } = tripLeg;

      lineString.coordinates = [...lineString.coordinates, ...trackSectionToLineString(trackSection)];

      const legStart = trackSection[0]?.projection?.position?.[0];
      if (legStart) {
        interchangePoints.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: transformCoordinates(legStart),
          },
          properties: {
            color: 'INACTIVE',
            mode: 'PUBLIC_TRANSPORT',
            locationName: tripLeg.timedLeg.legBoard?.stopPointName?.text,
          },
        });
      }
    }

    tripLeg.interchangeLeg?.navigationPath?.navigationSection?.forEach((section) => {
      section.trackSection?.projection?.position?.forEach((coordinates) => {
        lineString.coordinates.push(transformCoordinates(coordinates));
      });
    });

    const feature: Feature<
      LineString,
      {
        style: 'DOTTED' | 'LINE';
        color: 'ACTIVE' | 'INACTIVE';
      }
    > = {
      type: 'Feature',
      geometry: lineString,
      properties: {
        style: tripLeg.interchangeLeg ? 'DOTTED' : 'LINE',
        color: ['WALK', 'CYCLE'].includes(tripLeg.continuousLeg?.service?.individualMode as string)
          ? 'ACTIVE'
          : 'INACTIVE',
      },
    };

    routeLineStrings.features.push(feature);
  });

  interchangePoints.features.reverse();

  return { routeLineStrings, interchangePoints };
};

const useMapRoute = (map: mapboxgl.Map | undefined, mapIsLoading: boolean, routes: Trias | undefined) => {
  const selectedHealthyRoutingUseCase = useStore((state) => state.selectedHealthyRoutingUseCase);
  const theme = useTheme();
  const originMarker = useRef<mapboxgl.Marker>();
  const destinationMarker = useRef<mapboxgl.Marker>();
  const popup = useRef<mapboxgl.Popup>();

  const create = useCallback(
    (
      routeLineStrings: FeatureCollection<LineString>,
      interchangePoints: FeatureCollection<Point>,
      colorActive: string,
      colorInactive: string,
    ) => {
      if (!map) return;

      const originMarkerElement = document.createElement('div');
      originMarkerElement.setAttribute('aria-label', 'Wohnadresse');
      createRoot(originMarkerElement).render(<MarkerUser />);
      // eslint-disable-next-line no-param-reassign
      originMarker.current = new mapboxgl.Marker({ element: originMarkerElement, anchor: 'bottom', offset: [0, -10] })
        .setLngLat(routeLineStrings.features[0].geometry.coordinates[0] as LngLatLike)
        .addTo(map);

      const destinationMarkerElement = document.createElement('div');
      destinationMarkerElement.setAttribute('aria-label', 'Arbeitsadresse');
      createRoot(destinationMarkerElement).render(<MarkerOrganization />);
      // eslint-disable-next-line no-param-reassign
      destinationMarker.current = new mapboxgl.Marker({ element: destinationMarkerElement, anchor: 'bottom' })
        .setLngLat(
          routeLineStrings.features[routeLineStrings.features.length - 1].geometry.coordinates[
            routeLineStrings.features[routeLineStrings.features.length - 1].geometry.coordinates.length - 1
          ] as LngLatLike,
        )
        .addTo(map);

      const colorMatch: mapboxgl.Expression = [
        'match',
        ['get', 'color'],
        'ACTIVE',
        colorActive,
        'INACTIVE',
        colorInactive,
        colorInactive,
      ];
      map?.addSource(SOURCE_ID.ROUTE_LINE_STRINGS, {
        type: 'geojson',
        data: routeLineStrings,
      });
      map?.addLayer({
        id: SOURCE_ID.ROUTE_LINE_STRINGS,
        source: SOURCE_ID.ROUTE_LINE_STRINGS,
        type: 'line',
        paint: {
          'line-color': colorMatch,
          'line-width': 6,
          'line-opacity': 0.6,
          'line-dasharray': [
            'match',
            ['get', 'style'],
            'LINE',
            ['literal', [1, 0]],
            'DOTTED',
            ['literal', [0, 2]],
            ['literal', [1, 0]],
          ],
        },
        layout: {
          'line-cap': 'round',
        },
      });

      map?.addSource(SOURCE_ID.INTERCHANGE_POINTS, {
        type: 'geojson',
        data: interchangePoints,
      });
      map?.addLayer({
        id: SOURCE_ID.INTERCHANGE_POINTS,
        source: SOURCE_ID.INTERCHANGE_POINTS,
        type: 'symbol',
        layout: {
          'icon-image': ['get', 'mode'],
          'icon-size': 0.5,
          'icon-allow-overlap': true,
        },
      });

      map.on('click', SOURCE_ID.INTERCHANGE_POINTS, (event) => {
        const locationName = event.features?.[0].properties?.locationName;

        if (locationName) {
          const coordinates = (event.features?.[0].geometry as Point).coordinates.slice();

          while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.current = new mapboxgl.Popup({ offset: 24, closeButton: false })
            .setLngLat(coordinates as mapboxgl.LngLatLike)
            .setHTML(locationName as string)
            .addTo(map);
        }
      });

      map.on('mouseenter', SOURCE_ID.INTERCHANGE_POINTS, (event) => {
        const locationName = event.features?.[0].properties?.locationName;

        if (locationName) {
          // eslint-disable-next-line no-param-reassign
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      map.on('mouseleave', SOURCE_ID.INTERCHANGE_POINTS, () => {
        // eslint-disable-next-line no-param-reassign
        map.getCanvas().style.cursor = '';
      });
    },
    [map],
  );

  const update = useCallback(
    (routeLineStrings: FeatureCollection<LineString>, interchangePoints: FeatureCollection<Point>) => {
      if (!map) return;

      popup.current?.remove();

      originMarker.current?.setLngLat(routeLineStrings.features[0].geometry.coordinates[0] as LngLatLike).addTo(map);
      destinationMarker.current
        ?.setLngLat(
          routeLineStrings.features[routeLineStrings.features.length - 1].geometry.coordinates[
            routeLineStrings.features[routeLineStrings.features.length - 1].geometry.coordinates.length - 1
          ] as LngLatLike,
        )
        .addTo(map);

      (map.getSource(SOURCE_ID.ROUTE_LINE_STRINGS) as GeoJSONSource).setData(routeLineStrings);
      (map.getSource(SOURCE_ID.INTERCHANGE_POINTS) as GeoJSONSource).setData(interchangePoints);
    },
    [map],
  );

  useEffect(() => {
    if (mapIsLoading || !map || !routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult?.[0]) {
      return;
    }

    const tripResult = routes?.serviceDelivery?.deliveryPayload?.tripResponse?.tripResult;
    const { routeLineStrings, interchangePoints } = getGeoJsonFromTripResultStructure(
      selectedHealthyRoutingUseCase
        ? HealthyRoutingUseCaseService.findTripResultStructure(tripResult, selectedHealthyRoutingUseCase)
        : tripResult?.[0],
    );

    if (!map.getSource(SOURCE_ID.ROUTE_LINE_STRINGS)) {
      create(routeLineStrings, interchangePoints, theme.palette.primary.main, theme.palette.text.secondary);
    } else {
      update(routeLineStrings, interchangePoints);
    }

    map.fitBounds(turf.bbox(routeLineStrings) as mapboxgl.LngLatBoundsLike, {
      padding: { top: 172, right: 64, bottom: 64, left: 64 },
    });
  }, [
    create,
    map,
    mapIsLoading,
    routes,
    selectedHealthyRoutingUseCase,
    theme.palette.primary.main,
    theme.palette.text.secondary,
    update,
  ]);
};

export default useMapRoute;
