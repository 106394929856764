import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { AggregationsService } from '../../services/api';
import QueryKeys from '../../services/QueryKeys';
import PageContainer from '../../components/PageContainer';
import { ReactComponent as IconPlus } from '../../assets/icons/plus.svg';
import StyledTableLink from '../../components/table/StyledTableLink';
import SearchField from '../../components/forms/SearchField';
import EmptyTable from '../../components/EmptyTable';

const AggregationsPage = () => {
  const [search, setSearch] = useState('');

  const methods = useForm<{ search: string }>();
  const { handleSubmit } = methods;

  const { data: aggregations = [], isLoading } = useQuery(
    QueryKeys.aggregations.search(search),
    () => AggregationsService.findAll({ search }),
    { keepPreviousData: true },
  );

  const onSubmit = handleSubmit(({ search: searchValue }) => {
    setSearch(searchValue);
  });

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button startIcon={<IconPlus />} component={Link} to="hinzufuegen">
            Aggregierte Ansicht hinzufügen
          </Button>

          <SearchField onChange={() => onSubmit()} />
        </Box>
      </FormProvider>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Aggregierte Ansicht</TableCell>
              <TableCell align="right" sx={{ width: 0 }}>
                Unternehmen
              </TableCell>
              <TableCell align="right" sx={{ width: 0 }}>
                MitarbeiterInnen
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregations.map(({ id, name, organizationsUsersCount, organizationsSize, organizations }) => (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  <StyledTableLink component={Link} to={`/beratung/aggregierte-ansichten/${id}`}>
                    {name}
                  </StyledTableLink>
                </TableCell>
                <TableCell align="right">{organizations.length}</TableCell>
                <TableCell align="right">
                  {organizationsUsersCount}/{organizationsSize}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && aggregations.length === 0 && <EmptyTable>Keine aggregierten Ansichten vorhanden</EmptyTable>}
    </PageContainer>
  );
};

export default AggregationsPage;
