import React, { ReactNode } from 'react';
import { Box, css, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    min-width: ${theme.spacing(12.5)};
  `,
);

const StyledTypography = styled(Typography)(
  css`
    font-weight: bold;
    font-size: 1.5rem;
  `,
);

export type PotentialItemProps = { value?: number; label: ReactNode };

const PotentialItem = ({ value, label }: PotentialItemProps) => (
  <StyledBox>
    <StyledTypography>{value}</StyledTypography>
    <Typography>{label}</Typography>
  </StyledBox>
);

export default PotentialItem;
