import React from 'react';
import { Box, Button, createTheme, css, styled, ThemeProvider, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeNavigation from '../components/navigations/HomeNavigation';
import globalTheme from '../setup/theme';
import PageLayout from '../components/layouts/PageLayout';
import HomeFooter from '../components/footer/HomeFooter';
import PageContainer from '../components/PageContainer';
import RouteFactory from '../services/RouteFactory';
import Audience from '../types/Audience';

const homeTheme = createTheme({
  ...globalTheme,
  palette: {
    ...globalTheme.palette,
    primary: {
      main: '#1AAF92',
      contrastText: globalTheme.palette.common.white,
    },
    secondary: {
      main: '#BFE0D5',
    },
    text: {
      primary: '#4C6672',
    },
  },
});

const StyledContainer = styled(PageContainer)(
  css`
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  `,
);

const ItemContainer = styled(Box)(
  ({ theme }) =>
    css`
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: ${theme.spacing(2)};
    `,
);

const Item = styled(Box)(
  ({ theme }) =>
    css`
      background: ${theme.palette.common.white};
      padding: ${theme.spacing(5)};
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
);

const ButtonContainer = styled(Box)(
  ({ theme }) =>
    css`
      display: flex;
      margin-top: ${theme.spacing(4)};
    `,
);

const StyledTypographyH1 = styled(Typography)(
  ({ theme }) =>
    css`
      color: ${theme.palette.text.primary};
      font-weight: bold;
      font-size: 1.25rem;
    `,
) as typeof Typography;

const StyledTypographyH2 = styled(StyledTypographyH1)(
  ({ theme }) =>
    css`
      font-size: 0.9rem;
      margin-bottom: ${theme.spacing(3)};
    `,
) as typeof Typography;

const HomePage = () => (
  <ThemeProvider theme={homeTheme}>
    <PageLayout header={<HomeNavigation />} footer={<HomeFooter />}>
      <StyledContainer>
        <ItemContainer>
          <Item>
            <StyledTypographyH1 component="h1">Arbeitswegetool</StyledTypographyH1>
            <StyledTypographyH2 component="h2">für ganz Österreich</StyledTypographyH2>

            <Typography mb={3}>
              Das Arbeitswegetool richtet sich an <strong>Unternehmen, Betriebe, ArbeitgeberInnen</strong> und an{' '}
              <strong>PendlerInnen</strong> – unabhängig von der Länge des Arbeitswegs oder dem Verkehrsmittel.
            </Typography>
            <Typography mb={3}>
              PendlerInnen bekommen maßgeschneiderte <strong>Routenvorschläge</strong> für ihren Arbeitsweg, um diesen
              zukünftig aktiver, gesünder und nachhaltiger zu gestalten. Außerdem hilft das Tool,{' '}
              <strong>Fahrgemeinschaften</strong> zu finden und
              <strong> Kinderbegleitwege </strong>zu koordinieren.
            </Typography>
            <Typography>
              Unternehmen profitieren von einer Übersicht zu vorhandenen
              <strong> Mobilitätspotenzialen </strong>auf den Arbeitswegen ihrer MitarbeiterInnen – inklusive
              unternehmensweiter Gesundheits- und Umweltpotenziale. Um diese auszuschöpfen, steht zudem ein{' '}
              <strong>Maßnahmenkatalog</strong> zum Betrieblichen Mobilitätsmanagement zur Verfügung. Das Tool kann im
              Rahmen des Forschungsprojekts ActNow von teilnehmenden Unternehmen kostenfrei genutzt werden.
            </Typography>

            <ButtonContainer>
              <Button
                size="large"
                variant="contained"
                component={Link}
                to={RouteFactory.audienceRoot(Audience.ORGANIZATION)}
                sx={{ marginRight: '1.5em' }}
              >
                Für Unternehmen
              </Button>
              <Button
                size="large"
                variant="contained"
                component={Link}
                to={RouteFactory.audienceRoot(Audience.COMMUTER)}
              >
                Für PendlerInnen
              </Button>
            </ButtonContainer>
          </Item>
          <Item>
            <StyledTypographyH1 component="h1">Standortumfeldtool</StyledTypographyH1>
            <StyledTypographyH2 component="h2">
              für das Bundesland Salzburg und die angrenzenden oberösterreichischen Bezirke Braunau, Gmunden und
              Vöcklabruck
            </StyledTypographyH2>
            <Typography mb={3}>
              Dieses Planungswerkzeug richtet sich an alle, die sich eine Übersicht zu einem bestimmten Standort
              hinsichtlich <strong>Pendelmobilität, Erreichbarkeit</strong> von Infrastrukturen sowie Stärken und
              Schwächen in Bezug auf aktive und nachhaltige Pendelwege verschaffen möchten.
            </Typography>
            <Typography mb={3}>
              Insbesonders soll es <strong>PlanerInnen</strong> und <strong>MobilitätsberaterInnen </strong>
              unterstützen, die Rahmenbedingungen für eine aktive Pendelmobilität im Hinblick auf Verkehrs-, Umwelt- und
              Gesundheitsfaktoren zu verbessern. Ziel ist es, räumlich differenzierte Optimierungen mit transparenten
              Planungsgrundlagen zu unterstützen.
            </Typography>
            <Typography>Die Nutzung ist kostenlos und ohne Registrierung möglich.</Typography>
            <ButtonContainer>
              <Button
                size="large"
                variant="contained"
                component={MuiLink}
                href="https://ispacevm54.researchstudio.at/ActNow_latest/index.html"
                target="_blank"
                rel="noopener"
              >
                Zum Tool
              </Button>
            </ButtonContainer>
          </Item>
        </ItemContainer>
      </StyledContainer>
    </PageLayout>
  </ThemeProvider>
);

export default HomePage;
