import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ButtonLink from '../../components/ButtonLink';
import CarpoolingDialog from '../../components/dialogs/CarpoolingDialog';

const CommuterSignUpStep2TextContent = () => {
  const [carpoolingDialogOpen, setCarpoolingDialogOpen] = useState(false);

  return (
    <>
      <Typography gutterBottom>
        Um Ihnen einen gesünderen, nachhaltigeren Arbeitsweg vorschlagen zu können, geben Sie bitte zunächst Ihre
        Wohnadresse an! Diese wird zusammen mit dem Unternehmensstandort zur Routenberechnung verwendet.
      </Typography>
      <Typography gutterBottom>
        Warum für Arbeitswege oder regelmäßige Bringwege zu Kinderbetreuungseinrichtungen nicht mit NutzerInnen
        kooperieren, die den gleichen Weg haben? Wählen Sie eine Option zum Thema{' '}
        <ButtonLink onClick={() => setCarpoolingDialogOpen(true)} sx={{ textDecoration: 'underline' }}>
          Kinderbegleitwege oder Fahrgemeinschaften
        </ButtonLink>{' '}
        und wir gleichen Ihre Wege mit denen von anderen Personen Ihres Unternehmens bzw. naheliegender Unternehmen ab.
      </Typography>
      <Typography>
        Maximal einmal wöchentlich werden Sie dann per E-Mail über PendlerInnen mit ähnlichen Wegen informiert. Ob Sie
        die Person per E-Mail kontaktieren oder auf eine Kontaktanfrage reagieren, bleibt natürlich Ihnen überlassen.
        Sie können diese sowie alle weiteren Einstellungen auch später jederzeit ändern.
      </Typography>

      <CarpoolingDialog open={carpoolingDialogOpen} onClose={() => setCarpoolingDialogOpen(false)} />
    </>
  );
};

export default CommuterSignUpStep2TextContent;
