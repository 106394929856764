import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

type MeasuresSectionProps = { name: string; item: string | string[] | undefined };
const MeasuresSection = ({ name, item }: MeasuresSectionProps) => (
  <Grid container mb={4}>
    <Grid item xs={4}>
      <Typography variant="h6">{name}</Typography>
    </Grid>
    <Grid item xs={8}>
      {Array.isArray(item) ? (
        <Box>
          <Typography component="ul" sx={{ paddingLeft: (theme) => `${theme.spacing(2)}` }}>
            {item.map((listPar) => (
              <Typography component="li" key={listPar}>
                {listPar}
              </Typography>
            ))}
          </Typography>
        </Box>
      ) : (
        <Typography>{!item ? '–' : item}</Typography>
      )}
    </Grid>
  </Grid>
);

export default MeasuresSection;
