import React, { useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  css,
  styled,
  Chip,
  InputAdornment,
  Box,
} from '@mui/material';
import { useQuery } from 'react-query';
import Fuse from 'fuse.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import QueryKeys from '../../services/QueryKeys';
import useStore from '../../setup/global-state';
import { PublicCarpoolDto, UsersService } from '../../services/api';
import SearchField from '../../components/forms/SearchField';
import CarpoolItem from './CarpoolItem';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import { ReactComponent as WaypointsIcon } from '../../assets/icons/split-page/waypoints.svg';
import ButtonLink from '../../components/ButtonLink';
import CarpoolingDialog from '../../components/dialogs/CarpoolingDialog';
import step2Image from '../../assets/images/commuter/sign-up/2-step.jpg';
import EmptyTable from '../../components/EmptyTable';

const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 100%;
      max-height: calc(100vh - ${theme.spacing(37)});
    }

    ${theme.breakpoints.up('lg')} {
      max-height: calc(100vh - ${theme.spacing(59)});
    }
  `,
);

const CarpoolsPage = () => {
  const user = useStore((state) => state.user);
  const fuseSearch = useRef<Fuse<PublicCarpoolDto>>();
  const [results, setResults] = useState<PublicCarpoolDto[]>([]);
  const [carpoolingDialogOpen, setCarpoolingDialogOpen] = useState(false);

  const methods = useForm<{ search: string }>({ defaultValues: { search: '' } });
  const { handleSubmit, getValues } = methods;

  const setResultsFromSearch = (searchQuery: string, carpoolItems: PublicCarpoolDto[]) => {
    setResults(
      searchQuery !== '' && fuseSearch.current
        ? fuseSearch.current.search(searchQuery).map(({ item }) => item)
        : carpoolItems,
    );
  };

  const { data: carpools = [], isLoading } = useQuery(
    QueryKeys.users.idCarpools(user?.id as string),
    () => UsersService.getCarpools({ id: user?.id as string }),
    {
      onSuccess: (newCarpools) => {
        fuseSearch.current = new Fuse(newCarpools, {
          keys: ['otherUserEmail'],
        });
        setResultsFromSearch(getValues('search'), newCarpools);
      },
    },
  );

  const onSubmit = handleSubmit(({ search }) => {
    setResultsFromSearch(search, carpools);
  });

  return (
    <SplitPage
      rightPaddingDense
      image={step2Image}
      left={
        <SplitPageTextContent Icon={WaypointsIcon} title="Fahrgemeinschaften">
          <Typography gutterBottom>
            Hier sehen Sie andere PendlerInnen des ActNow-Tools, die ähnliche Wege wie Sie haben. Nehmen Sie doch
            Kontakt auf, um für Arbeitswege oder regelmäßige Bringwege zu Kinderbetreuungseinrichtungen mit anderen zu
            kooperieren.
          </Typography>
          <Typography gutterBottom>
            Maximal einmal wöchentlich werden Sie per E-Mail über neue PendlerInnen mit ähnlichen Wegen informiert. Ob
            Sie jemanden per E-Mail kontaktieren oder auf eine Kontaktanfrage reagieren, bleibt natürlich Ihnen
            überlassen.
          </Typography>
          <Typography gutterBottom>
            Sie haben doch kein Interesse an Fahrgemeinschaften? Sie können diese Einstellung jederzeit{' '}
            <ButtonLink component={Link} to="/pendlerinnen/profil">
              hier ändern
            </ButtonLink>
            . Ihre Fahrgemeinschafts-Matches werden dann sofort aufgelöst und Ihre E-Mail-Adresse wird anderen
            NutzerInnen nicht mehr angezeigt.
          </Typography>

          <ButtonLink onClick={() => setCarpoolingDialogOpen(true)}>Mehr dazu hier ↦</ButtonLink>

          <CarpoolingDialog open={carpoolingDialogOpen} onClose={() => setCarpoolingDialogOpen(false)} />
        </SplitPageTextContent>
      }
      right={
        <>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={onSubmit}
              sx={{ display: 'flex', alignItems: 'center', gap: (theme) => theme.spacing(2) }}
            >
              <SearchField
                onChange={() => onSubmit()}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <Chip label={results.length} color="primary" sx={{ marginRight: 2 }} />
                  </InputAdornment>
                }
              />
            </Box>
          </FormProvider>

          <StyledTableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Typ</TableCell>
                  <TableCell>E-Mail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((item) => (
                  <CarpoolItem key={item.id} item={item} />
                ))}
              </TableBody>
            </Table>

            {!isLoading && carpools.length === 0 && (
              <EmptyTable>Für Sie wurde leider noch keine Fahrgemeinschaft gefunden</EmptyTable>
            )}
          </StyledTableContainer>
        </>
      }
    />
  );
};

export default CarpoolsPage;
