import { Button, styled, css } from '@mui/material';

const ButtonLink = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    padding: 0;
    min-width: 0;
    vertical-align: baseline;
    text-transform: none;
  `,
) as typeof Button;

export default ButtonLink;
