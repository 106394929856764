import React, { useId } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AuthHeading from '../../components/AuthHeading';
import SplitPage from '../../components/SplitPage';
import SplitPageTextContent from '../../components/SplitPageTextContent';
import step3Image from '../../assets/images/commuter/sign-up/3-step.jpg';
import { ReactComponent as MuscleIcon } from '../../assets/icons/split-page/muscle.svg';
import FormStepper from '../../components/forms/FormStepper';
import ActiveMinutesPerWeek from '../active-minutes-per-week/ActiveMinutesPerWeek';
import useStore from '../../setup/global-state';
import CommuterSignUpStep3TextContent from './CommuterSignUpStep3TextContent';

const CommuterSignUpStep3 = () => {
  const setCommuterSignUpFormData = useStore((state) => state.setCommuterSignUpFormData);

  const methods = useForm({
    defaultValues: {
      activeMinutesPerWeek: 30,
    },
  });
  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const id = useId();

  return (
    <SplitPage
      image={step3Image}
      left={
        <SplitPageTextContent
          Icon={MuscleIcon}
          title={
            <>
              Ihre Gesundheit,
              <br />
              Ihr Potenzial.
            </>
          }
        >
          <CommuterSignUpStep3TextContent />
        </SplitPageTextContent>
      }
      rightPaddingDense
      right={
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((formData) => {
              setCommuterSignUpFormData(formData);

              navigate('/pendlerinnen/registrieren/4');
            })}
          >
            <AuthHeading component="label" id={id} sx={{ display: 'block', marginBottom: 2 }}>
              Körperliche Aktivität pro Woche
            </AuthHeading>

            <ActiveMinutesPerWeek labelledBy={id} />

            <FormStepper active={1} />
          </form>
        </FormProvider>
      }
    />
  );
};

export default CommuterSignUpStep3;
