import Audience from '../types/Audience';
import { OrganizationUserRole, User, UserRole } from './api';

class RouteFactory {
  public static userToAudience(user?: User) {
    if (!user) return Audience.COMMUTER;

    if ([UserRole.ADMIN, UserRole.CONSULTANT].includes(user.role)) {
      return Audience.CONSULTING;
    }

    if (user.organization?.role === OrganizationUserRole.MANAGER) {
      return Audience.ORGANIZATION;
    }

    return Audience.COMMUTER;
  }

  public static userToSlug(user?: User) {
    return RouteFactory.audienceToSlug(RouteFactory.userToAudience(user));
  }

  public static audienceRoot(audience: Audience) {
    switch (audience) {
      case Audience.CONSULTING:
        return `/${RouteFactory.audienceToSlug(audience)}/login`;
      case Audience.COMMUTER:
        return `/${RouteFactory.audienceToSlug(audience)}/login`;
      case Audience.ORGANIZATION:
        return `/${RouteFactory.audienceToSlug(audience)}/registrieren/1`;
      default:
        return '/';
    }
  }

  public static isExactAudienceSlug(slug: string) {
    return ['pendlerinnen', 'unternehmen', 'beratung'].includes(slug);
  }

  public static audienceToSlug(audience: Audience) {
    return {
      [Audience.COMMUTER]: 'pendlerinnen',
      [Audience.ORGANIZATION]: 'unternehmen',
      [Audience.CONSULTING]: 'beratung',
    }[audience];
  }

  public static slugToAudience(slug?: string) {
    switch (slug) {
      case 'pendlerinnen':
        return Audience.COMMUTER;
      case 'unternehmen':
        return Audience.ORGANIZATION;
      case 'beratung':
        return Audience.CONSULTING;
      default:
        return Audience.COMMUTER;
    }
  }

  public static userRoot(user: User) {
    if ([UserRole.ADMIN, UserRole.CONSULTANT].includes(user.role)) {
      return '/beratung/unternehmen';
    }

    if (user.organization?.role === OrganizationUserRole.MANAGER) {
      return `/unternehmen/${user.organization.organizationId}/status`;
    }

    return `/pendlerinnen/mobilitaetsoptionen`;
  }

  public static organizationLinks(organizationId: string) {
    return [
      { label: 'Status', to: `/unternehmen/${organizationId}/status` },
      { label: 'Potenziale', to: `/unternehmen/${organizationId}/potenziale` },
      { label: 'Maßnahmen', to: `/unternehmen/${organizationId}/massnahmen` },
      { label: 'Fahrgemeinschaften', to: `/unternehmen/${organizationId}/fahrgemeinschaften` },
    ];
  }
}

export default RouteFactory;
