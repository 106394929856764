import { HealthEffectType, RouteHealthEffectsDto } from './api';
import Format from './Format';

class HealthEffectsHelper {
  public static find(healthEffects: RouteHealthEffectsDto | undefined, healthEffectType: HealthEffectType) {
    return healthEffects?.riskReduction.effects?.find(({ type }) => type === healthEffectType);
  }

  public static formatRiskChange(
    healthEffects: RouteHealthEffectsDto | undefined,
    healthEffectType: HealthEffectType,
    override?: boolean,
  ) {
    const effect = HealthEffectsHelper.find(healthEffects, healthEffectType);

    return Format.undefinedFallback(effect?.riskChange, Format.percentRounded, override);
  }
}

export default HealthEffectsHelper;
