import React, { ReactElement } from 'react';
import { Box, Typography, css, styled } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    @media print {
      svg {
        width: ${theme.spacing(4)};
      }
    }
  `,
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    font-size: 0.625rem;
    color: ${theme.palette.text.secondary};
    text-align: center;

    @media print {
      margin-top: ${theme.spacing(-1.5)};
    }
  `,
);

type IconContainerProps = { icon: ReactElement; substring?: string };

const IconContainer = ({ icon, substring }: IconContainerProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
    <StyledBox>{icon}</StyledBox>
    <StyledTypography>{substring}</StyledTypography>
  </Box>
);

export default IconContainer;
