/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Organisations-Status
 */
export enum OrganizationStatus {
    REGISTERED = 'REGISTERED',
    ACTIVE = 'ACTIVE',
}
