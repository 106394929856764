import React from 'react';
import FooterWrapper from './FooterWrapper';
import RouteFactory from '../../services/RouteFactory';
import Audience from '../../types/Audience';

const HomeFooter = () => (
  <FooterWrapper
    right={[
      { label: 'Datenschutz', to: `/datenschutz/${RouteFactory.audienceToSlug(Audience.COMMUTER)}` },
      { label: 'Impressum', to: '/impressum' },
    ]}
  />
);

export default HomeFooter;
