/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionType } from './ExtensionType';
import type { OverallPeriod } from './OverallPeriod';

export type Validity = {
    validityExtension?: ExtensionType;
    validityStatus?: Validity.validityStatus;
    validityTimeSpecification?: OverallPeriod;
};

export namespace Validity {

    export enum validityStatus {
        ACTIVE = 'ACTIVE',
        SUSPENDED = 'SUSPENDED',
        DEFINED_BY_VALIDITY_TIME_SPEC = 'DEFINED_BY_VALIDITY_TIME_SPEC',
    }


}

