import React, { forwardRef, RefCallback } from 'react';
import { IMaskInput } from 'react-imask';

type SignUpTokenMaskProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const SignUpTokenMask = forwardRef<HTMLElement, SignUpTokenMaskProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="XXXX-XXXX-XXXX-XXXX"
      placeholder="____-____-____-____"
      definitions={{
        X: /[0-9,A-Z]/,
      }}
      prepare={(value: string) => value.toUpperCase()}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default SignUpTokenMask;
