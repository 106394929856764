/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { KVType } from './KVType';
import type { Message } from './Message';
import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';

export type OriginDestType = {
    Notes?: Notes;
    readonly mcpData?: Array<KVType>;
    readonly Message?: Array<Message>;
    readonly altId?: Array<string>;
    readonly mainMastAltId?: Array<string>;
    readonly Occupancy?: Array<OccupancyType>;
    name: string;
    type: string;
    id?: string;
    extId?: string;
    lon?: number;
    lat?: number;
    routeIdx?: number;
    prognosisType?: OriginDestType.prognosisType;
    time: string;
    date: string;
    tz?: number;
    scheduledTimeChanged?: boolean;
    track?: string;
    trackHidden?: boolean;
    rtTime?: string;
    rtDate?: string;
    rtTz?: number;
    rtTrack?: string;
    rtTrackHidden?: boolean;
    hasMainMast?: boolean;
    mainMastId?: string;
    mainMastExtId?: string;
    alighting?: boolean;
    boarding?: boolean;
    rtAlighting?: boolean;
    rtBoarding?: boolean;
    cancelled?: boolean;
    cancelledDeparture?: boolean;
    cancelledArrival?: boolean;
    uncertainDelay?: boolean;
    additional?: boolean;
    isBorderStop?: boolean;
    isTurningPoint?: boolean;
    hide?: boolean;
    entry?: boolean;
    mcp?: boolean;
    trainCompositionMarker?: number;
    rtCnclDataSourceType?: OriginDestType.rtCnclDataSourceType;
    psCtxArriveEarlier?: string;
    psCtxDepartLater?: string;
};

export namespace OriginDestType {

    export enum prognosisType {
        PROGNOSED = 'PROGNOSED',
        MANUAL = 'MANUAL',
        REPORTED = 'REPORTED',
        CORRECTED = 'CORRECTED',
        CALCULATED = 'CALCULATED',
    }

    export enum rtCnclDataSourceType {
        DEFAULT = 'DEFAULT',
        VDV = 'VDV',
        HIM = 'HIM',
        HRC = 'HRC',
        SIRI = 'SIRI',
        UIC = 'UIC',
        HRX = 'HRX',
        GTFS = 'GTFS',
        FIS = 'FIS',
        DDS = 'DDS',
        PAISA = 'PAISA',
        FE = 'FE',
        BLACKLIST = 'BLACKLIST',
    }


}

