import React, { ReactNode } from 'react';
import { AppBar, Box } from '@mui/material';
import PaddedToolbar from '../PaddedToolbar';
import useSubNavigationEnabled from '../../hooks/useSubNavigationEnabled';
import Loading from './Loading';

type NavigationWrapperProps = { children: ReactNode; sections: number };

const NavigationWrapper = ({ children, sections }: NavigationWrapperProps) => {
  const { subNavigationEnabled } = useSubNavigationEnabled();

  return (
    <Box sx={{ marginBottom: subNavigationEnabled ? 18 : 10, displayPrint: 'none' }}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          backgroundColor: 'common.white',
          minHeight: ({ spacing }) => spacing(10),
          paddingY: 1,
          justifyContent: 'center',
        }}
      >
        <PaddedToolbar
          sx={{
            display: 'grid',
            gridTemplateColumns: sections === 2 ? 'auto minmax(0, 1fr)' : `repeat(${sections}, 1fr)`,
            gridColumnGap: ({ spacing }) => spacing(4),
          }}
        >
          {children}
        </PaddedToolbar>

        <Loading />
      </AppBar>
    </Box>
  );
};

export default NavigationWrapper;
