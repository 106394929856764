/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Routing Status
 */
export enum RoutesStatus {
    FAILED_ROUTING = 'FAILED_ROUTING',
    FAILED_POSTPROCESSING = 'FAILED_POSTPROCESSING',
    SUCCESS = 'SUCCESS',
}
