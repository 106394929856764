import React, { ReactNode } from 'react';
import { Box, Button, css, Link, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledButton = styled(Button)(
  css`
    color: inherit;
    font-weight: normal;
    text-transform: none;

    &.active {
      font-weight: bold;
    }
  `,
) as typeof Button;

type NavigationLinksProps = {
  links: ({ label: string } & (
    | { to: string; end?: boolean; href?: undefined }
    | { to?: undefined; end?: undefined; href: string }
  ))[];
  before?: ReactNode;
  after?: ReactNode;
};

const NavigationLinks = ({ links, before, after }: NavigationLinksProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingY: 1.5 }}>
    {before}

    {links.map(({ label, to, end = false, href }) => (
      <StyledButton
        key={to || href}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(to && { component: NavLink, to, end })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(href && { component: Link, href, rel: 'noopener' })}
      >
        {label}
      </StyledButton>
    ))}

    {after}
  </Box>
);

export default NavigationLinks;
