import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PublicNavigation from '../navigations/PublicNavigation';
import PageLayout from './PageLayout';
import MainFooter from '../footer/MainFooter';
import useStore from '../../setup/global-state';
import RouteFactory from '../../services/RouteFactory';
import useRedirectedFrom from '../../hooks/useRedirectedFrom';

const PublicLayout = () => {
  const navigate = useNavigate();
  const { redirectedFrom } = useRedirectedFrom();

  const user = useStore((state) => state.user);

  useEffect(() => {
    (async () => {
      if (user) {
        navigate(redirectedFrom || RouteFactory.userRoot(user), { replace: true });
      }
    })();
  }, [redirectedFrom, navigate, user]);

  return (
    <PageLayout header={<PublicNavigation />} footer={<MainFooter />}>
      <Outlet />
    </PageLayout>
  );
};

export default PublicLayout;
