import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

type AuthHeadingProps = TypographyProps;

const AuthHeading = ({ children, ...props }: AuthHeadingProps) => (
  <Typography component="h2" fontWeight="bold" sx={{ marginBottom: 4 }} {...props}>
    {children}
  </Typography>
);

export default AuthHeading;
