import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import NavigationWrapper from './NavigationWrapper';
import useStore from '../../setup/global-state';
import ActNowLogo from '../ActNowLogo';
import NavigationLinks from './NavigationLinks';
import { AuthenticationService, OrganizationUserRole, UserRole } from '../../services/api';
import RouteFactory from '../../services/RouteFactory';
import OrganizationSubNavigation from './OrganizationSubNavigation';
import useSubNavigationEnabled from '../../hooks/useSubNavigationEnabled';
import AggregationSubNavigation from './AggregationSubNavigation';
import useLogout from '../../hooks/useLogout';
import MunicipalitySubNavigation from './MunicipalitySubNavigation';

const AuthenticatedNavigation = () => {
  const user = useStore((state) => state.user);

  const { subNavigationEnabled, organizationId, aggregationId, municipalityIso } = useSubNavigationEnabled();
  const logout = useLogout();

  const { mutate } = useMutation(() => AuthenticationService.logout(), {
    onSuccess: () => {
      logout();
    },
  });

  if (!user) {
    return null;
  }

  return (
    <NavigationWrapper sections={2}>
      <Box>
        <Link to={RouteFactory.userRoot(user)} style={{ display: 'inline-block' }}>
          <ActNowLogo />
        </Link>
      </Box>

      <Box>
        <NavigationLinks
          links={[
            ...(user && [UserRole.ADMIN, UserRole.CONSULTANT].includes(user.role)
              ? [
                  { label: 'Unternehmen', to: '/beratung/unternehmen' },
                  { label: 'Aggregierte Ansichten', to: '/beratung/aggregierte-ansichten' },
                  { label: 'Gemeinden', to: '/beratung/gemeinden' },
                ]
              : []),
            ...(user?.organization && user.organization.role === OrganizationUserRole.MANAGER
              ? RouteFactory.organizationLinks(user.organization.organizationId)
              : []),
            ...(user?.organization?.role === OrganizationUserRole.COMMUTER
              ? [
                  { label: 'Profil', to: '/pendlerinnen/profil' },
                  ...(user.interestCarpooling || user.interestChildrenCarpooling
                    ? [{ label: 'Fahrgemeinschaften', to: '/pendlerinnen/fahrgemeinschaften' }]
                    : []),
                  { label: 'Mobilitätsoptionen', to: '/pendlerinnen/mobilitaetsoptionen' },
                ]
              : []),
          ]}
          after={
            <Button color="primary" onClick={() => mutate()}>
              Logout
            </Button>
          }
        />

        {subNavigationEnabled && (
          <>
            {organizationId && <OrganizationSubNavigation />}
            {aggregationId && <AggregationSubNavigation />}
            {municipalityIso && <MunicipalitySubNavigation />}
          </>
        )}
      </Box>
    </NavigationWrapper>
  );
};

export default AuthenticatedNavigation;
