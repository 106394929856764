import create from 'zustand';
import { persist } from 'zustand/middleware';

import GlobalState from '../types/GlobalState';
import { sentrySetUser } from './sentry';

const useStore = create<GlobalState>(
  persist(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (set, get) => ({
      user: undefined,
      setUser: (user) => set({ user }),

      signUpToken: undefined,
      setSignUpToken: (signUpToken) => set({ signUpToken }),

      commuterSignUpFormData: {},
      setCommuterSignUpFormData: (formData: GlobalState['commuterSignUpFormData']) =>
        set((state) => ({
          commuterSignUpFormData: {
            ...state.commuterSignUpFormData,
            ...formData,
          },
        })),
      resetCommuterSignUpFormData: () =>
        set(() => ({
          commuterSignUpFormData: {},
        })),

      selectedHealthyRoutingUseCase: undefined,
      setSelectedHealthyRoutingUseCase: (selectedHealthyRoutingUseCase) => set({ selectedHealthyRoutingUseCase }),
    }),
    {
      name: 'actnow.trafficon.eu',
      version: 1,
      migrate: (persistedState, version) => {
        if (version < 1) {
          return {};
        }

        return persistedState;
      },
    },
  ),
);

sentrySetUser(useStore.getState().user);

useStore.subscribe(({ user }, { user: oldUser }) => {
  if (user?.id !== oldUser?.id) {
    sentrySetUser(user);
  }
});

export default useStore;
