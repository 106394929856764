import { css, styled, Typography } from '@mui/material';

const SubNavigationBefore = styled(Typography)(
  ({ theme }) => css`
    display: inline-flex;
    color: ${theme.palette.text.secondary};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  `,
);

export default SubNavigationBefore;
