/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Directions } from './Directions';
import type { JourneyDetailRef } from './JourneyDetailRef';
import type { Messages } from './Messages';
import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';
import type { ProductType } from './ProductType';
import type { ReferencedJourneyType } from './ReferencedJourneyType';
import type { Stops } from './Stops';

export type Arrival = {
    JourneyDetailRef: JourneyDetailRef;
    JourneyStatus?: Arrival.JourneyStatus;
    ProductAtStop?: ProductType;
    readonly Product?: Array<ProductType>;
    Notes?: Notes;
    Messages?: Messages;
    Directions?: Directions;
    readonly altId?: Array<string>;
    Stops?: Stops;
    readonly Occupancy?: Array<OccupancyType>;
    readonly referencedJourney?: Array<ReferencedJourneyType>;
    name: string;
    type: string;
    stop: string;
    stopid: string;
    stopExtId?: string;
    prognosisType?: Arrival.prognosisType;
    time: string;
    date: string;
    scheduledTimeChanged?: boolean;
    tz?: number;
    track?: string;
    trackHidden?: boolean;
    rtTime?: string;
    rtDate?: string;
    rtTz?: number;
    rtTrack?: string;
    rtTrackHidden?: boolean;
    cancelled?: boolean;
    partCancelled?: boolean;
    reachable?: boolean;
    redirected?: boolean;
    uncertainDelay?: boolean;
    origin?: string;
    directionFlag?: string;
    isBorderStop?: boolean;
    isTurningPoint?: boolean;
    entry?: boolean;
    rtCnclDataSourceType?: Arrival.rtCnclDataSourceType;
};

export namespace Arrival {

    export enum JourneyStatus {
        P = 'P',
        R = 'R',
        A = 'A',
        S = 'S',
    }

    export enum prognosisType {
        PROGNOSED = 'PROGNOSED',
        MANUAL = 'MANUAL',
        REPORTED = 'REPORTED',
        CORRECTED = 'CORRECTED',
        CALCULATED = 'CALCULATED',
    }

    export enum rtCnclDataSourceType {
        DEFAULT = 'DEFAULT',
        VDV = 'VDV',
        HIM = 'HIM',
        HRC = 'HRC',
        SIRI = 'SIRI',
        UIC = 'UIC',
        HRX = 'HRX',
        GTFS = 'GTFS',
        FIS = 'FIS',
        DDS = 'DDS',
        PAISA = 'PAISA',
        FE = 'FE',
        BLACKLIST = 'BLACKLIST',
    }


}

