import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import PageContainer from '../components/PageContainer';
import AuthHeading from '../components/AuthHeading';
import { ApiError, AuthenticationService, ForgotPasswordDto } from '../services/api';
import SubmittedPage from '../components/SubmittedPage';
import thankYouImage from '../assets/images/commuter/sign-up/3-step.jpg';

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDto>();

  const { mutate, error, isSuccess } = useMutation<any, ApiError, ForgotPasswordDto>((requestBody: ForgotPasswordDto) =>
    AuthenticationService.forgotPassword({ requestBody }),
  );

  return isSuccess ? (
    <SubmittedPage title="Danke!" image={thankYouImage}>
      <Typography textAlign="center">
        Der Link zum Zurücksetzen des Passworts wird Ihnen zugesandt. Bitte überprüfen Sie Ihre E-Mails.
      </Typography>
    </SubmittedPage>
  ) : (
    <PageContainer maxWidth="md">
      <form onSubmit={handleSubmit((requestBody) => mutate(requestBody))}>
        <AuthHeading>Passwort vergessen</AuthHeading>

        <Typography gutterBottom>Um Ihr Passwort zurücksetzen senden wir Ihnen einen Link zu.</Typography>

        <TextField
          label="E-Mail"
          type="email"
          error={!!errors.email || !!error}
          helperText={error && 'Es ist ein Fehler aufgetreten'}
          {...register('email', { required: true })}
        />

        <Box textAlign="center">
          <Button type="submit" color="primary" size="large" variant="contained" sx={{ marginTop: 5 }}>
            Link anfordern
          </Button>
        </Box>
      </form>
    </PageContainer>
  );
};

export default ForgotPasswordPage;
