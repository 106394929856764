import React from 'react';
import { css, Grid, Stack, styled, Typography } from '@mui/material';
import CarpoolingMap from './CarpoolingMap';
import { PotentialsCarpoolingDto } from '../../../services/api';
import PrintHeading from '../../../components/PrintHeading';
import PotentialsCount from './PotentialsCount';

const LegendItem = styled(Typography)(
  css`
    font-size: 0.75rem;
  `,
);

const LegendItemValue = styled(LegendItem)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};

    &::before {
      content: '';
      display: block;
    }
  `,
);

const LegendIsochrones = styled(LegendItemValue)(
  ({ theme }) => css`
    &::before {
      width: ${theme.spacing(2)};
      height: 1px;
      background-color: ${theme.palette.text.secondary};
    }
  `,
);

const LegendDataPoints = styled(LegendItemValue)(
  ({ theme }) => css`
    &::before {
      width: ${theme.spacing(2)};
      height: ${theme.spacing(2)};
      background-color: ${theme.palette.primary.main};
      border-radius: 50%;
    }
  `,
);

type PotentialsCarpoolingProps = {
  potentials?: PotentialsCarpoolingDto;
  distanceCircles: boolean;
  printHeading: string;
};

const PotentialsCarpooling = ({ potentials, distanceCircles, printHeading }: PotentialsCarpoolingProps) => (
  <>
    <PrintHeading>{printHeading}</PrintHeading>
    <Grid container spacing={2} sx={{ flex: '1 0 auto' }}>
      <Grid item xs={9}>
        {potentials && (
          <CarpoolingMap
            addressPoints={potentials.organizationsAddressPoints}
            municipalitiesCount={potentials.municipalitiesCount}
            distanceCircles={distanceCircles}
          />
        )}

        <Stack direction="row" spacing={3} marginTop={2}>
          <LegendItem>Legende</LegendItem>
          {distanceCircles && <LegendIsochrones>Entfernungen vom Unternehmensstandort: 2, 5, 10 km</LegendIsochrones>}
          <LegendDataPoints>Anzahl der Wohnstandorte pro Gemeinde</LegendDataPoints>
        </Stack>
      </Grid>
      <Grid item xs={3} alignSelf="center" textAlign="center">
        <Stack spacing={5}>
          <PotentialsCount
            title="MitarbeiterInnen"
            items={[
              {
                value: potentials?.organizationsUsersCount,
                label: 'registrierte',
              },
            ]}
          />

          <PotentialsCount
            title="Fahrgemeinschaften"
            items={[
              { value: potentials?.interestCarpoolingCount, label: 'interessiert an' },
              {
                value: potentials?.carpoolsCount,
                label: 'vorgeschlagene',
              },
            ]}
          />

          <PotentialsCount
            title="Kinderbegleitwege(n)"
            items={[
              { value: potentials?.interestChildrenCarpoolingCount, label: 'interessiert an' },
              {
                value: potentials?.childrenEscortsCount,
                label: 'vorgeschlagene',
              },
            ]}
          />
        </Stack>
      </Grid>
    </Grid>
  </>
);

export default PotentialsCarpooling;
