import { css, styled, Typography } from '@mui/material';

const PrintHeading = styled(Typography)(
  ({ theme }) => css`
    display: none;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: ${theme.spacing(2)};

    @media print {
      display: block;
    }
  `,
);

export default PrintHeading;
