import React, { useState } from 'react';
import { css, Slider, Stack, styled, TextField } from '@mui/material';
import { useDebounce } from 'react-use';

const ActivitySliderSlider = styled(Slider)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
  `,
);

const ActivitySliderTextField = styled(TextField)(
  ({ theme }) => css`
    width: ${theme.spacing(10)};
    flex-shrink: 0;
  `,
);

type ActivitySliderProps = {
  min: number;
  max: number;
  maxSlider?: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
  labelledBy: string;
};

const ActivitySlider = ({ min, max, maxSlider, step, value, onChange, labelledBy }: ActivitySliderProps) => {
  const [inputValue, setInputValue] = useState<number>(value);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setInputValue(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue < min) newValue = min;
    if (newValue > max) newValue = max;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (inputValue < min) {
      setInputValue(min);
    } else if (inputValue > max) {
      setInputValue(max);
    }
  };

  useDebounce(
    () => {
      onChange(inputValue);
    },
    200,
    [inputValue],
  );

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <ActivitySliderTextField
        value={inputValue}
        size="small"
        onChange={handleInputChange}
        onBlur={handleBlur}
        type="number"
        label="Minuten"
        inputProps={{
          step,
          min,
          max,
          'aria-labelledby': labelledBy,
        }}
      />
      <ActivitySliderSlider
        value={inputValue}
        onChange={handleChange}
        min={min}
        max={maxSlider || max}
        step={step}
        aria-labelledby={labelledBy}
      />
    </Stack>
  );
};

export default ActivitySlider;
