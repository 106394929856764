import React from 'react';
import { useQuery } from 'react-query';
import QueryKeys from '../../services/QueryKeys';
import { MunicipalitiesService } from '../../services/api';
import PotentialsCarpoolingPage from '../../common/potentials/carpooling/PotentialsCarpoolingPage';
import useQueryMunicipality from '../../hooks/queries/useQueryMunicipality';
import HelpPopUp from '../../components/HelpPopUp';

const MunicipalityPotentialsCarpoolingPage = () => {
  const { municipalityIso, data: municipality } = useQueryMunicipality();

  const { data: potentials } = useQuery(QueryKeys.municipalities.potentialsCarpooling(municipalityIso), () =>
    MunicipalitiesService.getPotentialsCarpooling({ iso: municipalityIso }),
  );

  return (
    <PotentialsCarpoolingPage
      potentials={potentials}
      distanceCircles={false}
      printHeading={`Fahrgemeinschaften | Gemeinde: ${municipality?.name}`}
    >
      <HelpPopUp>
        Hier sehen Sie eine Übersicht über die Fahrgemeinschaftspotenziale in der Gemeinde. Spannend, woher die
        PendlerInnen täglich einreisen oder? Nutzen Sie eine der vorgeschlagenen Maßnahmen, um Fahrgemeinschaften zu
        fördern. Aus Datenschutzgründen werden die Wohnstandorte nur angezeigt, wenn mindestens fünf PendlerInnen in
        einer Gemeinde wohnen.
      </HelpPopUp>
    </PotentialsCarpoolingPage>
  );
};

export default MunicipalityPotentialsCarpoolingPage;
