import React, { ReactNode } from 'react';
import { css, styled } from '@mui/material';
import FilterButton from './FilterButton';
import SearchField from './SearchField';

const StyledForm = styled('form')(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `,
);

type FilterHeaderProps = { children: ReactNode; onSubmit: () => void; filterCount: number };

const FilterHeader = ({ children, onSubmit, filterCount }: FilterHeaderProps) => (
  <StyledForm id="filter-header" onSubmit={onSubmit}>
    <FilterButton filterCount={filterCount}>{children}</FilterButton>

    <SearchField onChange={() => onSubmit()} />
  </StyledForm>
);

export default FilterHeader;
