/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type ManualAction = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: ManualAction.actionStatus;
    description?: NaturalLanguageStringStructure;
};

export namespace ManualAction {

    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }


}

