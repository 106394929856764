import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import OrganizationLogin from './organization/OrganizationLogin';
import OrganizationSignUp from './organization/sign-up/OrganizationSignUp';
import CommuterSignUp from './commuter/sign-up/CommuterSignUp';
import CommuterLogin from './commuter/CommuterLogin';
import HomePage from './home/HomePage';
import NotFoundPage from './components/NotFoundPage';
import PublicLayout from './components/layouts/PublicLayout';
import ProfilePage from './common/ProfilePage';
import MobilityOptionsPage from './commuter/mobility-options/MobilityOptionsPage';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import { AuthenticationService, OrganizationUserRole, UserRole } from './services/api';
import useStore from './setup/global-state';
import ConsultingLogin from './consulting/ConsultingLogin';
import OrganizationsPage from './consulting/organizations/OrganizationsPage';
import OrganizationPotentialsRoutingPage from './organization/OrganizationPotentialsRoutingPage';
import OrganizationStatusPage from './organization/OrganizationStatusPage';
import MobilityOptionsCalculatingPage from './commuter/sign-up/MobilityOptionsCalculatingPage';
import MeasuresPage from './organization/MeasuresPage';
import ForgotPasswordPage from './authentication/ForgotPasswordPage';
import ResetPasswordPage from './authentication/ResetPasswordPage';
import OrganizationPotentialsCarpoolingPage from './organization/OrganizationPotentialsCarpoolingPage';
import PrivacyPage from './common/privacy/PrivacyPage';
import ImprintPage from './common/ImprintPage';
import CarpoolsPage from './commuter/carpools/CarpoolsPage';
import OrganizationProfilePage from './organization/organization-profile-page/OrganizationProfilePage';
import AggregationsPage from './consulting/aggregations/AggregationsPage';
import AggregationPage from './consulting/aggregations/AggregationPage';
import AggregationPotentialsCarpoolingPage from './consulting/aggregations/AggregationPotentialsCarpoolingPage';
import AggregationPotentialsRoutingPage from './consulting/aggregations/AggregationPotentialsRoutingPage';
import MunicipalitiesPage from './consulting/municipalities/MunicipalitiesPage';
import MunicipalityPotentialsRoutingPage from './consulting/municipalities/MunicipalityPotentialsRoutingPage';
import MunicipalityPotentialsCarpoolingPage from './consulting/municipalities/MunicipalityPotentialsCarpoolingPage';
import MunicipalityPage from './consulting/municipalities/MunicipalityPage';
import HelpPage from './common/HelpPage';
import { SentryRoutes } from './setup/sentry';

const App = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);

  useEffect(() => {
    (async () => {
      try {
        setUser(await AuthenticationService.getAuthenticatedUser());
      } catch {
        setUser(undefined);
      }
    })();
  }, [setUser]);

  return (
    <SentryRoutes>
      <Route path="/" element={<HomePage />} />

      <Route element={user ? <ProtectedLayout authorizedRoles={Object.values(UserRole)} /> : <PublicLayout />}>
        <Route path="hilfe" element={<HelpPage />} />
        <Route path="datenschutz/*" element={<PrivacyPage />} />
        <Route path="impressum" element={<ImprintPage />} />
      </Route>

      <Route path="passwort" element={<PublicLayout />}>
        <Route path="vergessen" element={<ForgotPasswordPage />} />
        <Route path="zuruecksetzen" element={<ResetPasswordPage />} />
      </Route>

      <Route path="unternehmen">
        <Route element={<PublicLayout />}>
          <Route path="login" element={<OrganizationLogin />} />
          <Route path="registrieren/*" element={<OrganizationSignUp />} />
        </Route>

        <Route
          path=":organizationId"
          element={
            <ProtectedLayout
              authorizedRoles={[UserRole.ADMIN, UserRole.CONSULTANT]}
              authorizedOrganizationRoles={[OrganizationUserRole.MANAGER]}
            />
          }
        >
          <Route path="status" element={<OrganizationStatusPage />} />
          <Route path="potenziale" element={<OrganizationPotentialsRoutingPage />} />
          <Route path="massnahmen" element={<MeasuresPage />} />
          <Route path="fahrgemeinschaften" element={<OrganizationPotentialsCarpoolingPage />} />
          <Route path="profil" element={<OrganizationProfilePage />} />
          <Route path="login-aendern" element={<ProfilePage />} />
        </Route>
      </Route>

      <Route path="pendlerinnen">
        <Route element={<PublicLayout />}>
          <Route path="login" element={<CommuterLogin />} />
          <Route path="registrieren/*" element={<CommuterSignUp />} />
        </Route>

        <Route element={<ProtectedLayout authorizedOrganizationRoles={[OrganizationUserRole.COMMUTER]} />}>
          <Route path="mobilitaetsoptionen-berechnen" element={<MobilityOptionsCalculatingPage />} />
          <Route path="profil" element={<ProfilePage />} />
          <Route path="fahrgemeinschaften" element={<CarpoolsPage />} />
          <Route path="mobilitaetsoptionen" element={<MobilityOptionsPage />} />
        </Route>
      </Route>

      <Route path="beratung">
        <Route element={<PublicLayout />}>
          <Route path="login" element={<ConsultingLogin />} />
        </Route>

        <Route element={<ProtectedLayout authorizedRoles={[UserRole.ADMIN, UserRole.CONSULTANT]} />}>
          <Route path="unternehmen" element={<OrganizationsPage />} />

          <Route path="login-aendern" element={<ProfilePage />} />

          <Route path="aggregierte-ansichten">
            <Route index element={<AggregationsPage />} />
            <Route path=":aggregationId" element={<AggregationPage />} />
            <Route path=":aggregationId/potenziale" element={<AggregationPotentialsRoutingPage />} />
            <Route path=":aggregationId/fahrgemeinschaften" element={<AggregationPotentialsCarpoolingPage />} />
          </Route>
          <Route path="gemeinden">
            <Route index element={<MunicipalitiesPage />} />
            <Route path=":municipalityIso" element={<MunicipalityPage />} />
            <Route path=":municipalityIso/potenziale" element={<MunicipalityPotentialsRoutingPage />} />
            <Route path=":municipalityIso/fahrgemeinschaften" element={<MunicipalityPotentialsCarpoolingPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<PublicLayout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </SentryRoutes>
  );
};

export default App;
