import { Typography, styled, css } from '@mui/material';

const DialogTypographySource = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
    color: ${theme.palette.text.secondary};
  `,
);

export default DialogTypographySource;
