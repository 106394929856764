/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Aggregation } from '../models/Aggregation';
import type { CreateAggregationDto } from '../models/CreateAggregationDto';
import type { PotentialsCarpoolingDto } from '../models/PotentialsCarpoolingDto';
import type { PotentialsRoutingDto } from '../models/PotentialsRoutingDto';
import type { UpdateAggregationDto } from '../models/UpdateAggregationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AggregationsService {

    /**
     * @returns Aggregation
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: CreateAggregationDto,
    }): CancelablePromise<Aggregation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/aggregations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Aggregation
     * @throws ApiError
     */
    public static findAll({
        search,
    }: {
        search?: string,
    }): CancelablePromise<Array<Aggregation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregations',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @returns Aggregation
     * @throws ApiError
     */
    public static findOne({
        id,
    }: {
        id: string,
    }): CancelablePromise<Aggregation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Aggregation
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateAggregationDto,
    }): CancelablePromise<Aggregation> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/aggregations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static remove({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/aggregations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PotentialsRoutingDto
     * @throws ApiError
     */
    public static getPotentialsRouting({
        id,
    }: {
        id: string,
    }): CancelablePromise<PotentialsRoutingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregations/{id}/potentials/routing',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PotentialsCarpoolingDto
     * @throws ApiError
     */
    public static getPotentialsCarpooling({
        id,
    }: {
        id: string,
    }): CancelablePromise<PotentialsCarpoolingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/aggregations/{id}/potentials/carpooling',
            path: {
                'id': id,
            },
        });
    }

}
