/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConnectionReliabilityType } from './ConnectionReliabilityType';
import type { EcoType } from './EcoType';
import type { FreqType } from './FreqType';
import type { LegList } from './LegList';
import type { Messages } from './Messages';
import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';
import type { OriginDestType } from './OriginDestType';
import type { ServiceDays } from './ServiceDays';
import type { StopType } from './StopType';
import type { TariffResult } from './TariffResult';
import type { TripStatusType } from './TripStatusType';

export type TripType = {
    duration?: string;
    rtDuration?: string;
    combinedMinDuration?: string;
    return?: boolean;
    Origin: OriginDestType;
    Destination: OriginDestType;
    Messages?: Messages;
    Notes?: Notes;
    Eco?: EcoType;
    readonly EcoCmp?: Array<EcoType>;
    readonly ServiceDays?: Array<ServiceDays>;
    Freq?: FreqType;
    LegList: LegList;
    TariffResult?: TariffResult;
    calculation?: TripType.calculation;
    readonly Occupancy?: Array<OccupancyType>;
    reliability?: ConnectionReliabilityType;
    TripStatus?: TripStatusType;
    readonly via?: Array<StopType>;
    alternative?: boolean;
    hasAlternative?: boolean;
    individualChangeTimes?: boolean;
    valid?: boolean;
    idx?: number;
    tripId?: string;
    ctxRecon?: string;
    ecoUrl?: string;
    checksum?: string;
    transferCount?: number;
    combinedCount?: number;
};

export namespace TripType {

    export enum calculation {
        INITIAL = 'INITIAL',
        RETRY_SHARP = 'RETRY_SHARP',
        RETRY_UNSHARP = 'RETRY_UNSHARP',
        RETRY_DOUBLE_RADIUS = 'RETRY_DOUBLE_RADIUS',
        RETRY_UNSHARP_NEW_RADIUS = 'RETRY_UNSHARP_NEW_RADIUS',
    }


}

