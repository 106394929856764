import React, { ReactNode } from 'react';

import { Box, css, styled } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    .MuiFormControl-root:not(:last-child),
    .MuiAutocomplete-root:not(:last-child),
    .form-item:not(:last-child) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
);

type FormWrapperProps = { children: ReactNode };

const FormWrapper = ({ children }: FormWrapperProps) => <StyledBox>{children}</StyledBox>;

export default FormWrapper;
