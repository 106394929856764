import React, { useEffect, useState } from 'react';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { useDebounce } from 'react-use';
import { LocationList, LocationNameService, StopLocation } from '../../services/api-geocoding';
import QueryKeys from '../../services/QueryKeys';
import { PointDto } from '../../services/api';

export type GeocodingSearchInputFormData<FieldName extends string> = {
  [key in FieldName]: string;
} & {
  [key in `${FieldName}Point`]: PointDto;
} & {
  [key in `${FieldName}Feature`]: { name: string };
};

type GeocodingSearchInputProps<FieldName extends string, U extends GeocodingSearchInputFormData<FieldName>> = {
  label: string;
  name: FieldName & FieldPath<U>;
};

const GeocodingSearchInput = <FieldName extends string, U extends GeocodingSearchInputFormData<FieldName>>({
  label,
  name,
}: GeocodingSearchInputProps<FieldName, U>) => {
  const { register, setValue, getValues, watch } = useFormContext();

  const [inputValue, setInputValue] = useState<string>(getValues(name));
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);
  useDebounce(
    () => {
      setDebouncedInputValue(inputValue);
    },
    200,
    [inputValue],
  );

  useEffect(() => {
    register(name);
    register(`${name}Point`);
  }, [name, register]);

  const parameters: Parameters<typeof LocationNameService.verb1>[0] = { input: debouncedInputValue };
  const { data: { stopLocationOrCoordLocation } = { stopLocationOrCoordLocation: [] }, isLoading } = useQuery(
    QueryKeys.geocoder.query(parameters),
    async () => {
      const result = await LocationNameService.verb1(parameters);

      return result as LocationList & {
        stopLocationOrCoordLocation: ({ StopLocation: StopLocation } | { CoordLocation: StopLocation })[];
      };
    },
    {
      keepPreviousData: true,
      enabled: !!debouncedInputValue,
    },
  );
  // @ts-ignore
  const features = stopLocationOrCoordLocation.map(({ StopLocation: stop, CoordLocation: coord }) => stop || coord);

  const addressFeature = watch(`${name}Feature`);
  const optionsIncludeValue = !!addressFeature && !!features.find((feature) => feature.name === addressFeature.name);
  const options = [...features];

  if (addressFeature && !optionsIncludeValue) {
    options.unshift(addressFeature);
  }

  return (
    <Controller
      rules={{ required: true }}
      name={`${name}Feature` as const}
      // @ts-ignore
      defaultValue={getValues(name) || null}
      render={({ field, fieldState: { invalid } }) => (
        <Autocomplete
          {...field}
          loading={isLoading}
          noOptionsText={!inputValue ? 'Adresse eingeben' : 'Kein Ergebnis gefunden'}
          includeInputInList
          filterSelectedOptions={!optionsIncludeValue}
          options={options}
          filterOptions={(x) => x}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          isOptionEqualToValue={({ id: idA }, { id: idB }) => idA === idB}
          onInputChange={(event, value) => setInputValue(value)}
          onChange={(event, value) => {
            field.onChange(value);
            // @ts-ignore
            setValue(name, value.name);
            // @ts-ignore
            setValue(`${name}Point`, { type: 'Point', coordinates: [value.lon, value.lat] });
          }}
          forcePopupIcon={false}
          disableClearable
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField autoComplete="street-address" label={label} {...params} error={invalid} />
          )}
        />
      )}
    />
  );
};

export default GeocodingSearchInput;
