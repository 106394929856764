import React from 'react';
import { Stack, styled, Toolbar, Link as MuiLink } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import PaddedToolbar from '../PaddedToolbar';

const StyledToolbar = styled(PaddedToolbar)(
  ({ theme }) => css`
    margin-top: auto;
    height: ${theme.spacing(10)};
    background-color: ${theme.palette.text.primary};
    color: ${theme.palette.common.white};
    align-items: center;

    @media print {
      display: none;
    }
  `,
) as typeof Toolbar;

const StyledLink = styled(MuiLink)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-decoration: none;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  `,
) as typeof MuiLink;

export type LinkType = { label: string } & ({ to: string; href?: undefined } | { to?: undefined; href: string });

type FooterProps = { left?: LinkType[]; right: LinkType[] };

const FooterWrapper = ({ left, right }: FooterProps) => {
  const renderLinks = (links: LinkType[]) =>
    links.map(({ label, to, href }) =>
      to ? (
        <StyledLink component={Link} key={to} to={to}>
          {label}
        </StyledLink>
      ) : (
        <StyledLink key={href} href={href} target="_blank" rel="noopener">
          {label}
        </StyledLink>
      ),
    );

  return (
    <StyledToolbar component="footer" sx={{ displayPrint: 'none' }}>
      {left && (
        <Stack direction="row" spacing={4}>
          {renderLinks(left)}
        </Stack>
      )}

      {right && (
        <Stack direction="row" spacing={4} sx={{ marginLeft: 'auto' }}>
          {renderLinks(right)}
        </Stack>
      )}
    </StyledToolbar>
  );
};

export default FooterWrapper;
