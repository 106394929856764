import { CarpoolType, Consultant, HealthyRoutingUseCase, OrganizationStatus } from '../services/api';
import MeasuresEffect from '../types/MeasuresEffect';
import MeasuresCategory from '../types/MeasuresCategory';

const de = {
  CarpoolType: {
    [CarpoolType.CHILDREN_ESCORT]: 'Kinderbegleitweg',
    [CarpoolType.COMMUTE]: 'Arbeitsweg',
  },
  Consultant: {
    [Consultant.NOT_ASSIGNED]: 'Nicht zugeordnet',
    [Consultant.IBG]: 'IBG',
    [Consultant.KOMOBILE]: 'komobile',
  },
  HealthyRoutingUseCase: {
    [HealthyRoutingUseCase.PEDESTRIAN]: 'Zu Fuß',
    [HealthyRoutingUseCase.BICYCLE]: 'Rad',
    [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_WALK]: 'Zu Fuß + ÖV',
    [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_BIKE]: 'Rad + ÖV',
    [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_BIKE]: 'Zu Fuß + ÖV + Rad',
    [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_WALK]: 'Rad + ÖV + Zu Fuß',
    [HealthyRoutingUseCase.CAR_PUBLICTRANSPORT_WALK]: 'Auto + ÖV + Zu Fuß',
    [HealthyRoutingUseCase.CAR]: 'Auto',
    [HealthyRoutingUseCase.MISC]: 'Sonstiges (z.B. E-Scooter, E-Bike)',
  },
  HealthyRoutingUseCaseDistance: {
    [HealthyRoutingUseCase.PEDESTRIAN]: '< 2,5 km',
    [HealthyRoutingUseCase.BICYCLE]: '2,5 - 10 km',
    [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_WALK]: '> 2,5 km',
    [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_BIKE]: '> 7 km',
    [HealthyRoutingUseCase.WALK_PUBLICTRANSPORT_BIKE]: '> 7 km',
    [HealthyRoutingUseCase.BIKE_PUBLICTRANSPORT_WALK]: '> 7 km',
    [HealthyRoutingUseCase.CAR_PUBLICTRANSPORT_WALK]: '> 10 km',
    [HealthyRoutingUseCase.CAR]: '',
    [HealthyRoutingUseCase.MISC]: '',
  },
  MeasuresEffect: {
    [MeasuresEffect.WALK]: 'Zu Fuß gehen',
    [MeasuresEffect.BYCICLE]: 'Fahrrad',
    [MeasuresEffect.PUBLIC_TRANSPORT]: 'ÖV',
    [MeasuresEffect.CARPOOLING]: 'Carpooling',
    [MeasuresEffect.BIKE_SCOOTER_SHARE]: 'Bike- und Scootersharing',
    [MeasuresEffect.E_CAR]: 'E-Auto',
    [MeasuresEffect.CARSHARING]: 'Carsharing',
    [MeasuresEffect.MIKRO_PUBLIC_TRANSPORT]: 'Mikro-ÖV',
    [MeasuresEffect.TRANSPORT_AVOIDANCE]: 'Verkehrsvermeidung',
  },
  MeasuresCategory: {
    [MeasuresCategory.INCENTIVES]: 'Anreize und Förderungen',
    [MeasuresCategory.MOTIVATION]: 'Motivation und Beratung',
    [MeasuresCategory.CULTURE]: 'Unternehmens-/Organisationskultur',
    [MeasuresCategory.INFRASTRUCTURE]: 'Unternehmens-/Organisationsinfrastruktur',
  },
  OrganizationStatus: {
    [OrganizationStatus.REGISTERED]: 'Registriert (inaktiv)',
    [OrganizationStatus.ACTIVE]: 'Aktiv',
  },
};

export default de;
