import React, { ReactNode } from 'react';
import { css, styled, Typography } from '@mui/material';

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    li {
      margin-left: ${theme.spacing(4)};
    }
  `,
) as typeof Typography;

type PrivacyListItemProps = {
  children: ReactNode;
};

const PrivacyListItem = ({ children }: PrivacyListItemProps) => (
  <StyledTypography component="li">{children}</StyledTypography>
);

export default PrivacyListItem;
