/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type PublishToWebActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: PublishToWebActionStructure.actionStatus;
    description?: NaturalLanguageStringStructure;
    homePage?: boolean;
    incidents?: boolean;
    ticker?: boolean;
};

export namespace PublishToWebActionStructure {

    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }


}

