import { useQuery } from 'react-query';
import QueryKeys from '../../services/QueryKeys';
import { MunicipalitiesService } from '../../services/api';
import useMunicipalityIso from '../params/useMunicipalityIso';

const useQueryMunicipality = () => {
  const municipalityIso = useMunicipalityIso();

  return {
    municipalityIso,
    ...useQuery(QueryKeys.municipalities.iso(municipalityIso), () =>
      MunicipalitiesService.findOne({ iso: municipalityIso }),
    ),
  };
};

export default useQueryMunicipality;
