import { useMatch } from 'react-router-dom';
import { UserRole } from '../services/api';
import useStore from '../setup/global-state';

const useSubNavigationEnabled = () => {
  const user = useStore((state) => state.user);
  const matchOrganizationPages = useMatch('/unternehmen/:organizationId/*');
  const matchAggregationPages = useMatch('/beratung/aggregierte-ansichten/:aggregationId/*');
  const matchMunicipalityPages = useMatch('/beratung/gemeinden/:municipalityIso/*');

  return {
    subNavigationEnabled:
      !!user &&
      [UserRole.ADMIN, UserRole.CONSULTANT].includes(user.role) &&
      (!!matchOrganizationPages?.params.organizationId ||
        (!!matchAggregationPages?.params.aggregationId &&
          matchAggregationPages.params.aggregationId !== 'hinzufuegen') ||
        !!matchMunicipalityPages?.params.municipalityIso),

    organizationId: matchOrganizationPages?.params.organizationId,
    aggregationId: matchAggregationPages?.params.aggregationId,
    municipalityIso: matchMunicipalityPages?.params.municipalityIso
      ? parseInt(matchMunicipalityPages.params.municipalityIso as string, 10)
      : undefined,
  };
};

export default useSubNavigationEnabled;
