import React, { useEffect, useId, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css, styled, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { OrganizationUserRole, UpdateUserDto, User, UsersService } from '../services/api';
import HelpPopUp from '../components/HelpPopUp';
import CommuterSignUpStep2TextContent from '../commuter/sign-up/CommuterSignUpStep2TextContent';
import CommuterSignUpStep2Form from '../commuter/sign-up/CommuterSignUpStep2Form';
import ActiveMinutesPerWeek from '../commuter/active-minutes-per-week/ActiveMinutesPerWeek';
import FormWrapper from '../components/FormWrapper';
import { GeocodingSearchInputFormData } from '../components/forms/GeocodingSearchInput';
import QueryKeys from '../services/QueryKeys';
import CommuterSignUpStep3TextContent from '../commuter/sign-up/CommuterSignUpStep3TextContent';
import useStore from '../setup/global-state';
import SubmitButton from '../components/forms/SubmitButton';
import RemoveButton from '../components/forms/RemoveButton';
import useLogout from '../hooks/useLogout';
import PasswordForm from '../components/forms/PasswordForm';
import usePrompt from '../hooks/usePrompt';

const userDefaultValues = (user: User) => ({
  email: user.email,
  address: user.address,
  addressPoint: user.addressPoint,
  interestCarpooling: user.interestCarpooling,
  interestChildrenCarpooling: user.interestChildrenCarpooling,
  childcareFacilities:
    user.childcareFacilities?.map(({ id, address, addressPoint }) => ({ id, address, addressPoint })) || [],
  activeMinutesPerWeek: typeof user.activeMinutesPerWeek === 'number' ? user.activeMinutesPerWeek : undefined,
});

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    font-size: 1rem;
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    font-weight: bold;
    font-family: ${theme.typography.h1.fontFamily};
  `,
) as typeof Typography;

type FormData = Omit<UpdateUserDto, 'childcareFacilities'> &
  GeocodingSearchInputFormData<'address'> & {
    childcareFacilities: GeocodingSearchInputFormData<'address'>[];
  };

type ProfileFormProps = { user: User };

const ProfileForm = ({ user }: ProfileFormProps) => {
  const setUser = useStore((state) => state.setUser);
  const isCommuter = user.organization?.role === OrganizationUserRole.COMMUTER;
  const [helpOpen, setHelpOpen] = useState<'route' | 'activeMinutesPerWeek' | undefined>();
  const [redirect, setRedirect] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const methods = useForm<FormData>({
    defaultValues: userDefaultValues(user),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
  } = methods;

  const { mutate, isSuccess } = useMutation(
    (requestBody: Partial<UpdateUserDto>) => UsersService.update({ id: user?.id as string, requestBody }),
    {
      onSuccess: (updatedUser) => {
        queryClient.setQueryData(QueryKeys.users.authenticated, updatedUser);
        setUser(updatedUser);

        reset(userDefaultValues(updatedUser));

        if (
          updatedUser.organization?.role === OrganizationUserRole.COMMUTER &&
          (updatedUser.routes === null || updatedUser.routesHealthEffects === null)
        ) {
          setRedirect(true);
        }
      },
    },
  );

  useEffect(() => {
    if (redirect) {
      navigate('/pendlerinnen/mobilitaetsoptionen-berechnen?title=Änderungen gespeichert!');
    }
  }, [navigate, redirect]);

  const { mutate: mutateRemove } = useMutation(() => UsersService.remove({ id: user.id }), {
    onSuccess: async () => {
      logout();
    },
  });

  usePrompt('Sie haben nicht gespeichert. Wollen Sie die Seite dennoch verlassen?', isDirty);

  const id = useId();

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(({ addressFeature, ...formData }) => {
            const updateUserDto: UpdateUserDto = formData;
            if (updateUserDto.oldPassword === '' && updateUserDto.newPassword === '') {
              updateUserDto.oldPassword = undefined;
              updateUserDto.newPassword = undefined;
            }

            updateUserDto.childcareFacilities = (
              formData.childcareFacilities as GeocodingSearchInputFormData<'address'>[]
            ).map(({ addressFeature: childcareFacilityAddressFeature, ...childcareFacility }) => childcareFacility);

            mutate(updateUserDto);
          })}
        >
          <StyledTypography>E-Mail</StyledTypography>
          <TextField label="E-Mail" type="email" autoComplete="email" error={!!errors.email} {...register('email')} />

          <StyledTypography>Passwort</StyledTypography>
          <PasswordForm />

          {isCommuter && (
            <>
              <StyledTypography>Unternehmen</StyledTypography>
              <TextField
                disabled
                label="Unternehmen"
                type="text"
                autoComplete="organization"
                value={`${user.organization?.organization?.name}, ${user.organization?.organization?.address}`}
              />

              <StyledTypography>
                Route
                <HelpPopUp
                  buttonPosition="static"
                  buttonSize="small"
                  dialogPosition="fixed"
                  open={helpOpen === 'route'}
                  onOpen={() => setHelpOpen('route')}
                  onClose={() => setHelpOpen(undefined)}
                >
                  <CommuterSignUpStep2TextContent />
                </HelpPopUp>
              </StyledTypography>
              <CommuterSignUpStep2Form />

              <StyledTypography component="label" id={id}>
                Körperliche Aktivität pro Woche
                <HelpPopUp
                  buttonPosition="static"
                  buttonSize="small"
                  dialogPosition="fixed"
                  open={helpOpen === 'activeMinutesPerWeek'}
                  onOpen={() => setHelpOpen('activeMinutesPerWeek')}
                  onClose={() => setHelpOpen(undefined)}
                >
                  <CommuterSignUpStep3TextContent />
                </HelpPopUp>
              </StyledTypography>

              <ActiveMinutesPerWeek labelledBy={id} />
            </>
          )}

          <SubmitButton label="Speichern" isSuccess={isSuccess} />

          {isCommuter && (
            <RemoveButton entityLabel="Account" onRemove={mutateRemove}>
              Wollen Sie Ihren Account <strong>{user.email}</strong> wirklich löschen?
            </RemoveButton>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default ProfileForm;
