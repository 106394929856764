import React from 'react';
import { Chip, Link, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PublicCarpoolDto } from '../../services/api';

type CarpoolItemProps = { item: PublicCarpoolDto };

const CarpoolItem = ({ item: { isNew, type, otherUserEmail } }: CarpoolItemProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{isNew && <Chip label="Neu" color="primary" size="small" />}</TableCell>
      <TableCell>{t(`CarpoolType.${type}`)}</TableCell>
      <TableCell component="th" scope="row">
        <Link href={`mailto:${otherUserEmail}`}>{otherUserEmail}</Link>
      </TableCell>
    </TableRow>
  );
};

export default CarpoolItem;
