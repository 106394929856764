import React, { SyntheticEvent, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography, css, styled } from '@mui/material';
import { useMatch, useNavigate } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import Audience from '../../types/Audience';
import PrivacyCommuter from './PrivacyCommuter';
import RouteFactory from '../../services/RouteFactory';
import PrivacyOrganization from './PrivacyOrganization';

const StyledTabPanel = styled(TabPanel)(
  css`
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  `,
);

const PrivacyPage = () => {
  const matchAudience = useMatch('/datenschutz/:audience/*');
  const navigate = useNavigate();
  const [value, setValue] = useState<Audience>(RouteFactory.slugToAudience(matchAudience?.params.audience));

  return (
    <PageContainer maxWidth="md">
      <Box sx={{ width: '100%' }}>
        <Typography gutterBottom variant="h1">
          Datenschutz
        </Typography>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event: SyntheticEvent, newValue: string) => {
                setValue(newValue as Audience);
                navigate(RouteFactory.audienceToSlug(newValue as Audience));
              }}
              title="Zielgruppe"
            >
              <Tab label="PendlerInnen" value={Audience.COMMUTER} />
              <Tab label="Unternehmen" value={Audience.ORGANIZATION} />
            </TabList>
          </Box>
          <StyledTabPanel value={Audience.COMMUTER}>
            <PrivacyCommuter />
          </StyledTabPanel>
          <StyledTabPanel value={Audience.ORGANIZATION}>
            <PrivacyOrganization />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </PageContainer>
  );
};

export default PrivacyPage;
