import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import SubmittedPage from '../../components/SubmittedPage';
import thankYouImage from '../../assets/images/organization/sign-up/2-thank-you.jpg';
import OrganizationSignUpStep1 from './OrganizationSignUpStep1';
import OrganizationSignUpStep2 from './OrganizationSignUpStep2';

const OrganizationSignUp = () => (
  <Routes>
    <Route path="1" element={<OrganizationSignUpStep1 />} />
    <Route path="2" element={<OrganizationSignUpStep2 />} />
    <Route
      path="fertig"
      element={
        <SubmittedPage title="Danke!" image={thankYouImage}>
          <Typography textAlign="center">Sie erhalten eine E-Mail, sobald Ihr Account aktiviert wurde.</Typography>
        </SubmittedPage>
      }
    />

    <Route path="*" element={<Navigate to="1" />} />
  </Routes>
);

export default OrganizationSignUp;
