import React, { useState } from 'react';
import DialogContainer, { DialogContainerProps } from './DialogContainer';
import DialogTypography from './DialogTypography';
import ButtonLink from '../ButtonLink';
import UserHealthEffectsDialog from './UserHealthEffectsDialog';

type RoutingDialogProps = Omit<DialogContainerProps, 'children'>;

const RoutingDialog = ({ open, onClose }: RoutingDialogProps) => {
  const [userHealthEffectsDialogOpen, setUserHealthEffectsDialogOpen] = useState(false);

  return (
    <>
      <DialogContainer open={open} onClose={onClose}>
        <DialogTypography fontWeight="bold">Gesunde Routen</DialogTypography>
        <DialogTypography>
          Bewegung spielt eine zentrale Rolle für unsere Gesundheit –{' '}
          <ButtonLink
            onClick={() => {
              onClose();
              setUserHealthEffectsDialogOpen(true);
            }}
            sx={{ color: 'primary.main' }}
          >
            und der Arbeitsweg bietet eine ideale Gelegenheit, mehr Bewegung in unseren Alltag zu bringen!
          </ButtonLink>{' '}
          Daher berechnen wir aktive Routen für Ihren Arbeitsweg.
        </DialogTypography>
        <DialogTypography>
          Zunächst prüft unsere Routenlogik, ob der Weg zu Fuß oder mit dem Rad zurückgelegt werden kann. Bei einer
          Distanz von bis zu 2,5 km empfehlen wir Ihnen generell zu Fuß zu gehen, da Sie hier die meisten
          Gesundheitseffekte erreichen können. Bei Distanzen zwischen 2,5 und 10 km kommt das Rad in Frage. Zudem prüfen
          wir, wie sich der aktive Anteil einer ÖV-Route erhöhen lässt, indem ein Teil der Route zu Fuß oder mit dem Rad
          zurückgelegt wird.
        </DialogTypography>
        <DialogTypography>
          Um Gesundheitseffekte zu erreichen, muss ein bestimmtes Mindestmaß an Bewegung gegeben sein. Dies ist bei
          Fußwegen von über 900m bzw. Radwegen von über 3,5 km Länge der Fall. Für die Gesundheit kann man auch mal
          etwas mehr Zeit in Kauf nehmen – dennoch muss sich der zeitliche Mehraufwand natürlich in Grenzen halten! Zu
          lange Routen werden daher anhand definierter Schwellwerte ausgeschlossen.
        </DialogTypography>
        <DialogTypography>
          Am Ende der Berechnung steht eine Reihe von Routenoptionen, die wir nach Dauer sortieren. Die schnellste
          gesunde Route steht ganz oben in der Tabelle, danach kommen ggf. weitere Routenoptionen sowie ganz unten zum
          Vergleich die Autoroute.
        </DialogTypography>
        <DialogTypography>
          Manchmal können wir Ihnen leider keine Route mit Gesundheitseffekten vorschlagen, etwa da der Weg zu kurz oder
          zu lang ist. Werden Sie einfach selbst aktiv: Vielleicht können Sie Ihren Arbeitsweg ja mit einem kurzen
          Abstecher zum nächsten Park oder zu einem schönen Fuß- oder Radweg aktiver gestalten?
        </DialogTypography>
      </DialogContainer>
      <UserHealthEffectsDialog
        open={userHealthEffectsDialogOpen}
        onClose={() => setUserHealthEffectsDialogOpen(false)}
      />
    </>
  );
};

export default RoutingDialog;
