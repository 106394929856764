enum MeasuresEffect {
  WALK = 'WALK',
  BYCICLE = 'BYCICLE',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  CARPOOLING = 'CARPOOLING',
  BIKE_SCOOTER_SHARE = 'BIKE_SCOOTER_SHARE',
  E_CAR = 'E_CAR',
  CARSHARING = 'CARSHARING',
  MIKRO_PUBLIC_TRANSPORT = 'MIKRO_PUBLIC_TRANSPORT',
  TRANSPORT_AVOIDANCE = 'TRANSPORT_AVOIDANCE',
}

export default MeasuresEffect;
