/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionsStructure } from './ExtensionsStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type RemedyStructure = {
    description?: NaturalLanguageStringStructure;
    extensions?: ExtensionsStructure;
    remedyType?: RemedyStructure.remedyType;
};

export namespace RemedyStructure {

    export enum remedyType {
        UNKNOWN = 'UNKNOWN',
        REPLACE = 'REPLACE',
        REPAIR = 'REPAIR',
        REMOVE = 'REMOVE',
        OTHER_ROUTE = 'OTHER_ROUTE',
        OTHER_LOCATION = 'OTHER_LOCATION',
    }


}

