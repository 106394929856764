import { Container, css, styled } from '@mui/material';

const PageContainer = styled(Container)(
  ({ theme }) => css`
    @media screen {
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(2)};

      ${theme.breakpoints.up('sm')} {
        padding-top: ${theme.spacing(4)};
        padding-bottom: ${theme.spacing(4)};
      }

      ${theme.breakpoints.up('lg')} {
        padding-top: ${theme.spacing(15)};
        padding-bottom: ${theme.spacing(15)};
      }
    }
  `,
);

export default PageContainer;
