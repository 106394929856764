/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PolylineDesc } from './PolylineDesc';

export type PolylineGroup = {
    readonly polylineDesc?: Array<PolylineDesc>;
    name?: string;
    coordType?: PolylineGroup.coordType;
    layerName?: string;
};

export namespace PolylineGroup {

    export enum coordType {
        WGS_84 = 'WGS_84',
        PLANAR = 'PLANAR',
        HAFASGEO = 'HAFASGEO',
    }


}

