import React, { FC, ReactNode, SVGProps } from 'react';
import { Box, Typography, css, styled } from '@mui/material';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(4)};
  `,
);

type SplitPageTextContentProps = {
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: ReactNode;
  children: ReactNode;
};

const SplitPageTextContent = ({ Icon, title, children }: SplitPageTextContentProps) => (
  <>
    <StyledBox>
      <Icon />
    </StyledBox>
    <Typography variant="h1" sx={{ marginBottom: 4, textTransform: 'uppercase', letterSpacing: 4.8 }}>
      {title}
    </Typography>

    {children}
  </>
);

export default SplitPageTextContent;
