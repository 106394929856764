import React, { useState } from 'react';
import { Typography, css, styled, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import ButtonLink from '../components/ButtonLink';
import CarpoolingDialog from '../components/dialogs/CarpoolingDialog';
import OrganizationHealthDialog from '../components/dialogs/OrganizationHealthDialog';
import UserHealthEffectsDialog from '../components/dialogs/UserHealthEffectsDialog';
import RoutingDialog from '../components/dialogs/RoutingDialog';

const StyledLink = styled(ButtonLink)(
  css`
    text-decoration: underline;
  `,
) as typeof Button;

const HelpPage = () => {
  const [organizationHealthDialogOpen, setOrganizationHealthDialogOpen] = useState(false);
  const [routingDialogOpen, setRoutingDialogOpen] = useState(false);
  const [userHealthEffectsDialogOpen, setUserHealthEffectsDialogOpen] = useState(false);
  const [carpoolingDialogOpen, setCarpoolingDialogOpen] = useState(false);

  return (
    <>
      <PageContainer>
        <Typography gutterBottom variant="h1">
          Wie können wir helfen?
        </Typography>

        <Typography gutterBottom>
          Das ActNow-Tool wurde im Rahmen des kooperativen Forschungsprojekts „ActNow – Allianz Aktive Mobilität“
          erstellt. Im Zuge der Evaluierung des ActNow Tools sind Sie eingeladen, als Testperson das ActNow-Tool
          probeweise zu verwenden. Sie haben Fragen zu Ihren Routenergebnissen, Potenzialen oder Fahrgemeinschaften?
        </Typography>

        <Typography component="ul">
          <Typography component="li">
            <StyledLink onClick={() => setOrganizationHealthDialogOpen(true)}>Hier</StyledLink> erfahren Sie mehr
            darüber, weshalb sich aktives Pendeln sowohl für Unternehmen als auch PendlerInnen lohnt.
          </Typography>
          <Typography component="li">
            Was hat es mit den gesunden Routen auf sich? Bitte{' '}
            <StyledLink onClick={() => setRoutingDialogOpen(true)}>hier</StyledLink> entlang.
          </Typography>
          <Typography component="li">
            Wie werden die Potenziale berechnet? Das haben wir{' '}
            <StyledLink onClick={() => setUserHealthEffectsDialogOpen(true)}>hier</StyledLink> für Sie zusammengefasst.
          </Typography>
          <Typography component="li">
            Wie funktioniert die Benachrichtigung zu Fahrgemeinschaften bzw. Kooperationen für Kinderbegleitwege? Das
            erfahren Sie <StyledLink onClick={() => setCarpoolingDialogOpen(true)}>hier</StyledLink>.
          </Typography>
          <Typography component="li">
            Ihre Frage wurde damit nicht beantwortet? Wenden Sie sich als PendlerIn an Ihre Ansprechperson im
            Unternehmen bzw. als UnternehmensvertreterIn an Ihre ActNow-Beraterin / Ihren ActNow-Berater.
          </Typography>
          <Typography component="li">
            Sie haben ein technisches Problem bei der Nutzung des ActNow-Tools? Dann können Sie uns{' '}
            <StyledLink
              href="https://form.asana.com/?k=K2Hv6_nQf9WvJFcJzgZ5iQ&d=1132833451838200"
              rel="noopener"
              target="_blank"
            >
              hier
            </StyledLink>{' '}
            eine Anfrage schicken. Wenn Sie uns über das Formular eine Supportanfrage senden, werden Daten an Asana Inc.
            in den USA übertragen. Mehr dazu in unserer{' '}
            <StyledLink component={Link} to="/datenschutz">
              Datenschutzerklärung
            </StyledLink>
            .
          </Typography>
        </Typography>
      </PageContainer>

      <OrganizationHealthDialog
        open={organizationHealthDialogOpen}
        onClose={() => setOrganizationHealthDialogOpen(false)}
      />
      <RoutingDialog open={routingDialogOpen} onClose={() => setRoutingDialogOpen(false)} />
      <UserHealthEffectsDialog
        open={userHealthEffectsDialogOpen}
        onClose={() => setUserHealthEffectsDialogOpen(false)}
      />
      <CarpoolingDialog open={carpoolingDialogOpen} onClose={() => setCarpoolingDialogOpen(false)} />
    </>
  );
};

export default HelpPage;
