import React, { useState } from 'react';
import { Typography } from '@mui/material';
import ButtonLink from '../../components/ButtonLink';
import UserHealthEffectsDialog from '../../components/dialogs/UserHealthEffectsDialog';

const CommuterSignUpStep3TextContent = () => {
  const [openUserHealthEffectsDialog, setOpenUserHealthEffectsDialog] = useState(false);

  return (
    <>
      <Typography gutterBottom>
        Ein aktiver Arbeitsweg kann das Risiko für verschiedene Erkrankungen deutlich senken. Wie viel sich durch den
        Arbeitsweg erreichen lässt, hängt jedoch von individuellen Faktoren wie Alter, Gewicht, Gesundheits- und
        Trainingszustand und Lebensstil ab.
      </Typography>
      <Typography gutterBottom>
        Um diese Faktoren abzubilden, geben Sie bitte an, wie viele Minuten Sie pro Woche körperlich aktiv sind. Dies
        umfasst körperliche Aktivität in moderater Intensität oder 75-150 Minuten in hoher Intensität. Das entspricht –
        je nach Alter und Trainingszustand – beispielsweise in flottem Tempo gehen oder im Doppel Tennis zu spielen
        (mittlere Intensität) bzw. mit mindestens 18 km/h Fahrrad fahren, Badminton oder Fußball zu spielen (höhere
        Intensität).
      </Typography>
      <Typography gutterBottom>
        Sie können den Wert aber auch <strong>jederzeit ändern und ausprobieren</strong>, wie sich dies auf die
        Gesundheitspotenziale Ihrer Route auswirkt.
      </Typography>
      <Typography gutterBottom>
        Die Berechnung basiert auf aktuellen wissenschaftlichen Erkenntnissen und wurde unter Konsultation von
        MedizinerInnen konzipiert.
      </Typography>

      <ButtonLink onClick={() => setOpenUserHealthEffectsDialog(true)}>Mehr dazu hier ↦</ButtonLink>

      <UserHealthEffectsDialog
        open={openUserHealthEffectsDialog}
        onClose={() => setOpenUserHealthEffectsDialog(false)}
      />
    </>
  );
};

export default CommuterSignUpStep3TextContent;
